import {
  WSAvatar,
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  useBankingAccount,
  useBankingBalance
} from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCard
} from "../../../../query/payments/queries";
import { useAccount } from "../../../../query/users/queries";
import { BankCardLogo } from "../../../../shared/components/BankCardLogo";
import { IconInstitution } from "../../../../shared/components/IconInstitution";
import { selectorAccountInstitution } from "../../../../shared/selectors/selectorAccountInstitution";
import { selectorAccountName } from "../../../../shared/selectors/selectorAccountName";
import { selectorCountryName } from "../../../../shared/selectors/selectorCountryName";
import { selectorDebitCardBrand } from "../../../../shared/selectors/selectorDebitCardBrand";
import { selectorDebitCardName } from "../../../../shared/selectors/selectorDebitCardName";
import { selectorDefaultDestination } from "../../../../shared/selectors/selectorDefaultDestination";
import { selectorIsAccountInternational } from "../../../../shared/selectors/selectorIsAccountInternational";
import { getCountyFlagLink } from "../../../../shared/utils/countries";
import { useQueryInvoicesRowsSummary } from "../../../Invoicing/queries/useQueryInvoicesRowsSummary";
import { InvoiceRowStatus } from "../../../Invoicing/service";

export const GettingStartedSidePanel: React.FC = () => {
  const history = useHistory();

  const queryInovicesRowsSummaryOutstanging = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: {
        in: [InvoiceRowStatus.ActionRequired, InvoiceRowStatus.AwaitingClient]
      }
    }
  });

  const queryInovicesRowsSummaryDeposited = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: InvoiceRowStatus.Deposited
    }
  });

  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingBalanceBalance = useBankingBalance();

  const destination =
    queryPayoutSettings.data &&
    selectorDefaultDestination(queryPayoutSettings.data);

  const queryBankingAccount = useBankingAccount();
  const queryDebitCard = usePayoutSettingsDebitCard(
    userId,
    destination?.destinationId!,
    {
      enabled:
        !!destination?.destinationId &&
        destination.destinationType === PayoutDestinationType.Card
    }
  );

  const queryAccount = useAccount(destination?.destinationId!, {
    enabled:
      !!destination?.destinationId &&
      destination.destinationType === PayoutDestinationType.Account
  });

  const totalOutstandingInvoices =
    queryInovicesRowsSummaryOutstanging.data?.totalValue;

  const totalDepositedInvoices =
    queryInovicesRowsSummaryDeposited.data?.totalValue;

  const showOutstandingInvoicesPanel = !!totalOutstandingInvoices;
  const showDepositedInvoicesPanel = !!totalDepositedInvoices;
  const showPayoutMethodPanel = !!(
    destination?.destinationId || queryBankingAccount.data
  );

  if (
    !showOutstandingInvoicesPanel &&
    !showDepositedInvoicesPanel &&
    !showPayoutMethodPanel
  ) {
    return null;
  }

  return (
    <WSElement>
      <WSText.Heading4 mb="XL">
        {showOutstandingInvoicesPanel || showDepositedInvoicesPanel
          ? "Invoices"
          : "\u00A0"}
      </WSText.Heading4>

      {showOutstandingInvoicesPanel ? (
        <>
          <WSFlexBox.CenterY justify="space-between" wrap="nowrap">
            <WSText.ParagraphSm color="gray500">
              Outstanding invoices
            </WSText.ParagraphSm>
            <WSAvatar.Icon
              size="M"
              icon="check-envelope"
              color="blue500"
              colorBackground="blue50"
            />
          </WSFlexBox.CenterY>
          <WSText.Heading4 mt="M" formatMoney>
            {totalOutstandingInvoices}
          </WSText.Heading4>

          <WSButton.Link
            size="S"
            mt="M"
            rightIcon="chevron-right"
            onClick={() => {
              history.push("/member/invoices/outstanding");
            }}
          >
            See all outstanding invoices
          </WSButton.Link>

          <WSDivider my="2XL" />
        </>
      ) : null}

      {showDepositedInvoicesPanel ? (
        <>
          <WSFlexBox.CenterY justify="space-between" wrap="nowrap">
            <WSText.ParagraphSm color="gray500">
              Invoices deposited
            </WSText.ParagraphSm>
            <WSAvatar.Icon
              size="M"
              icon="check-double"
              color="green500"
              colorBackground="green50"
            />
          </WSFlexBox.CenterY>

          <WSText.Heading4 color="green500" mt="M">
            +{toWSMoneyString(totalDepositedInvoices)}
          </WSText.Heading4>

          <WSButton.Link
            size="S"
            mt="M"
            rightIcon="chevron-right"
            onClick={() => {
              history.push("/member/invoices/deposited");
            }}
          >
            See all deposited invoices
          </WSButton.Link>
          <WSDivider my="2XL" />
        </>
      ) : null}

      {showPayoutMethodPanel ? (
        <>
          <WSFlexBox.CenterY
            justify="space-between"
            wrap="nowrap"
            data-testid="sidePanelPayoutMethod"
          >
            <WSText.ParagraphSm color="gray500">
              Current payout method
            </WSText.ParagraphSm>
            {destination?.destinationId ? (
              destination.destinationType === PayoutDestinationType.Account ? (
                queryAccount.data?.country &&
                queryAccount.data?.country !== "US" ? (
                  <WSAvatar.Image
                    image={getCountyFlagLink(
                      queryAccount.data.country.toLowerCase()
                    )}
                    color="gray50"
                  />
                ) : queryAccount.data ? (
                  <IconInstitution
                    institutionId={queryAccount.data?.institutionId}
                  />
                ) : null
              ) : queryDebitCard.data &&
                destination.destinationType === PayoutDestinationType.Card ? (
                <BankCardLogo.Circle
                  brand={queryDebitCard.data?.brand}
                  type="debit"
                />
              ) : null
            ) : queryBankingAccount.data ? (
              <WSAvatar.Icon
                color="white"
                colorBackground="red400"
                icon="wallet"
              />
            ) : null}
          </WSFlexBox.CenterY>

          {queryPayoutSettings.data?.payoutPreferences ===
          PayoutPreferences.Standard ? (
            <WSText.Heading5 mt="M">Standard</WSText.Heading5>
          ) : queryPayoutSettings.data?.payoutPreferences ===
            PayoutPreferences.Instant ? (
            <WSText.Heading5 mt="M">Instant payout (1% fee)</WSText.Heading5>
          ) : null}

          {destination?.destinationId ? (
            destination.destinationType === PayoutDestinationType.Account ? (
              <WSText.ParagraphSm color="gray400" mt="XS">
                {queryAccount?.data
                  ? [
                      selectorIsAccountInternational(queryAccount.data)
                        ? `${selectorCountryName(
                            queryAccount.data.country as string
                          )} (${queryAccount.data.currency})`
                        : selectorAccountInstitution(queryAccount.data),
                      selectorAccountName(queryAccount.data)
                    ]
                      .filter(Boolean)
                      .join(" ")
                  : null}
              </WSText.ParagraphSm>
            ) : queryDebitCard.data &&
              destination.destinationType === PayoutDestinationType.Card ? (
              <WSText.ParagraphSm
                color="gray400"
                mt="XS"
              >{`${selectorDebitCardBrand(
                queryDebitCard.data
              )} ${selectorDebitCardName(
                queryDebitCard.data
              )}`}</WSText.ParagraphSm>
            ) : null
          ) : queryBankingAccount.data ? (
            <WSText.ParagraphSm color="gray400" mt="XS">
              Wingspan Wallet <br />
              {`${toWSMoneyString(
                queryBankingBalanceBalance.data?.balance
              )} is available`}
            </WSText.ParagraphSm>
          ) : null}

          <WSButton.Link
            size="S"
            mt="M"
            rightIcon="chevron-right"
            onClick={() => {
              history.push("/member/settings/payment-methods");
            }}
          >
            Manage payout method
          </WSButton.Link>
        </>
      ) : null}
    </WSElement>
  );
};
