import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { WSQueries } from "../../query/WSQuery";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import {
  useCollaboratorsQuery,
  useInvoiceQuery,
  useInvoiceTemplateQuery,
  useMemberClientQuery
} from "../../query/payments/queries";
import { InvoicesForm } from "../components/InvoicesForm/InvoicesForm";
import { useDefaultInvoiceSubject } from "../utils";
import {
  attachmentsToFormData,
  collaboratorToFormData,
  convertToLineItemFormData,
  creditFeeToFormData,
  defaultFrequency,
  invoiceCollaboratorToFormData,
  lateFeeToFormData,
  paymentMethodsToFormData
} from "../utils/invoicesFormUtils";

type Props = RouteComponentProps<{ invoiceId: string }>;

export const InvoicesInvoiceEdit: React.FC<Props> = ({ match }) => {
  const collaboratorsQuery = useCollaboratorsQuery();
  const qFeatureFlags = useFeatureFlags();
  const invoiceId = match.params.invoiceId;
  const defaultSubject = useDefaultInvoiceSubject();
  const invoiceQuery = useInvoiceQuery(invoiceId);
  const memberClientQuery = useMemberClientQuery(
    invoiceQuery.data?.memberClientId as string,
    {
      retry: false,
      enabled: !!invoiceQuery.data?.memberClientId
    }
  );

  const parentInvoiceTemplateQuery = useInvoiceTemplateQuery(
    invoiceQuery.data?.parentInvoiceId as string,
    {
      retry: false,
      enabled: !!invoiceQuery.data?.parentInvoiceId
    }
  );
  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSQueries
      queries={{
        memberClientQuery,
        invoiceQuery,
        collaboratorsQuery,
        qFeatureFlags
      }}
    >
      {({
        memberClientQuery: { data: memberClient },
        invoiceQuery: { data: invoice },
        collaboratorsQuery: { data: collaborators },
        qFeatureFlags: { data: featureFlags }
      }) => (
        <InvoicesForm
          context={{
            invoice,
            parentInvoiceTemplate: parentInvoiceTemplateQuery.data
          }}
          defaultValues={{
            subject: String(invoice.labels?.subject || defaultSubject),
            client: queryFeatureFlags?.data?.engagements
              ? {
                  memberClientId: "",
                  emailsCC: [],
                  payerId: invoice.clientId || null,
                  payerPayeeEngagementId:
                    (invoice.memberClientId as string) || null
                }
              : {
                  memberClientId: memberClient?.memberClientId || "",
                  emailsCC: (memberClient?.emailCC || []).map(email => ({
                    email
                  })),
                  payerId: null,
                  payerPayeeEngagementId: null
                },
            ...(featureFlags.purchaseOrderNumber
              ? {
                  purchaseOrderNumber:
                    invoice.metadata?.purchaseOrderNumber || ""
                }
              : {}),
            lineItems: (invoice?.lineItems || []).map(lineItem =>
              convertToLineItemFormData(lineItem)
            ),
            other: {
              projectName: String(invoice?.labels?.projectName || ""),
              notes: invoice?.invoiceNotes || "",
              attachments: attachmentsToFormData(
                invoice?.attachments?.customAttachmentIds
              )
            },
            due: "custom",
            customDueDate: invoice?.dueDate || new Date(),
            date:
              invoice?.metadata?.invoiceDate ||
              (invoice?.status === InvoiceStatus.Draft
                ? new Date()
                : invoice.createdAt) ||
              new Date(),
            includesLateFee:
              !!invoice.lateFeeHandling && !!invoice.lateFeeHandling?.frequency,
            lateFee: lateFeeToFormData(invoice.lateFeeHandling),
            send: {
              type: "immediately",
              date: new Date()
            },
            recurring: false,
            frequency: defaultFrequency,
            collaborators: collaboratorToFormData(
              invoice.collaborators || [],
              collaborators
            ),
            invoiceCollaborators: invoiceCollaboratorToFormData(
              invoice.collaborators || [],
              collaborators
            ),
            paymentMethods: paymentMethodsToFormData(
              invoice.acceptedPaymentMethods
            ),
            advanced: {
              sendEmails: !!invoice?.notificationPreferences?.sendInvoice,
              sendReminders: !!invoice?.notificationPreferences?.sendReminders,
              creditFeeHandling: creditFeeToFormData(invoice)
            }
          }}
        />
      )}
    </WSQueries>
  );
};
