import { Route, Switch } from "react-router-dom";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteMissingData } from "./routes/RouteMissingData";
import { RouteMissingDataPending } from "./routes/RouteMissingDataPending";

const CIP_BASE_PATH = "/member/cip";

export const CIP = () => {
  return (
    <Switch>
      <Route
        path={CIP_BASE_PATH + "/missing-data/pending"}
        component={RouteMissingDataPending}
      />

      <Route
        path={CIP_BASE_PATH + "/missing-data/:verificationLevel"}
        component={RouteMissingData}
      />

      <Route path={CIP_BASE_PATH} component={RouteIndex} />
    </Switch>
  );
};
