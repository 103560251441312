import { useWSMutation } from "../../helpers";
import { deletePayer } from "../../../services/payers";
import { QUERY_PAYER, QUERY_PAYER_LIST } from "../keys";
import { QUERY_PAYER_ROWS } from "../../search/payer/queries/useQueryPayerRows";
import { QUERY_PAYER_ROWS_SUMMARY } from "../../search/payer/queries/useQueryPayerRowsSummary";

export const useDeletePayer = () =>
  useWSMutation((id: string) => deletePayer(id), {
    dependencies: [
      QUERY_PAYER_LIST,
      QUERY_PAYER,
      QUERY_PAYER_ROWS,
      QUERY_PAYER_ROWS_SUMMARY
    ]
  });
