import {
  WSButton,
  WSCentered,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSInputMaskOld,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { CURRENT_YEAR } from "../../modules/1099NECFiling/constants/currentYear";
import {
  useCMSWebflowEnterprise,
  useCMSWebflowPartner
} from "../../query/cms/queries";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import {
  getCurrentEntepriseId,
  getShouldForceToEnablePayments,
  getShouldForceToW9FlowByActivity,
  getShouldForceToW9FlowByTaxForms
} from "../../query/platform/selectors";
import { useSession } from "../../query/session";
import { usePayeeTaxForms } from "../../query/taxForm/queries/usePayeeTaxForms";
import { useCompletePhoneVerification } from "../../query/users/mutations";
import {
  useActivities,
  useUserProfile,
  useUserTag
} from "../../query/users/queries";
import { ErrorContextKey } from "../../services/platform";
import { redirectTo1099Onboarding } from "../../shared/utils/redirectTo1099Onboarding";
import { validatorOTP } from "../../shared/validators/validatorOTP";
import { useSetWSStore, useWSStore } from "../../store";
import { formatPhoneNumber, isLegacyUser } from "../utils/phoneUtils";
import styles from "./styles.module.scss";

export interface VerifyOTPProps {}

export const VerifyOTP: React.FC<VerifyOTPProps> = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const location = useLocation();
  const setStore = useSetWSStore();
  const store = useWSStore();
  const userId = useUserId();
  const [
    completePhoneVerification,
    completePhoneVerificationMeta
  ] = useCompletePhoneVerification(userId);
  const queries = queryString.parse(location.search);
  const partnerId = queries.partner as string;
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });
  const partner = qPartner.data;
  const userProfileQuery = useUserProfile(userId);
  const activityQuery = useActivities(userId);
  const queryTaxForms = usePayeeTaxForms(CURRENT_YEAR);
  const queryFeatureFlags = useFeatureFlags();

  const enterpriseId = getCurrentEntepriseId(store, activityQuery.data);
  const enterpriseQuery = useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: !!enterpriseId
  });
  const sessionQuery = useSession({
    enabled: !!enterpriseId
  });
  useEffect(() => {
    if (sessionQuery.data && !!enterpriseId) {
      if (userTagQuery.isIdle) {
        userTagQuery.refetch();
      }
    }
  }, [sessionQuery, enterpriseId]);

  const userTagQuery = useUserTag(enterpriseId || "", {
    enabled: !!enterpriseId && sessionQuery.data,
    retry: false
  });

  const enterpriseForUserTag = userTagQuery.data
    ? {
        logo: {
          src: userTagQuery.data.member?.profile?.logoUrl
        },
        is1099FilingOnly: false
      }
    : undefined;

  const enterprise = enterpriseQuery.data || enterpriseForUserTag || undefined;

  const onFormSubmit = async (data: any) => {
    completePhoneVerificationMeta.reset();
    const request = {
      code: data.otp
    };
    await completePhoneVerification(request, {
      onSuccess: async () => {
        const nextUrl = window.sessionStorage.getItem("OTP_FLOW_NEXT_URL");

        // if (
        //   getShouldForceToVerifyEmail(
        //     userProfileQuery.data as INewUser,
        //     memberClientsQuery.data || []
        //   )
        // ) {
        //   history.replace("/member/onboarding/verify-email");
        // } else
        //
        if (
          getShouldForceToW9FlowByActivity(store, activityQuery.data) &&
          getShouldForceToW9FlowByTaxForms(queryTaxForms.data)
        ) {
          redirectTo1099Onboarding(history);
        } else if (getShouldForceToEnablePayments(activityQuery.data)) {
          history.replace("/member/cip");
        } else if (nextUrl) {
          history.replace(nextUrl);
          window.sessionStorage.removeItem("OTP_FLOW_NEXT_URL");
        } else if (isLegacyUser(userProfileQuery.data as INewUser)) {
          history.push("/member/dashboard");
        } else {
          history.push("/member/onboarding/customizing");
        }
      }
    });
  };

  return (
    <OnboardingLayout partnerLogo={enterprise?.logo.src || partner?.logo?.src}>
      {isMobile ? (
        <WSFlexBox.CenterY
          mb="M"
          onClick={() => history.replace("/member/verify/phone-number")}
        >
          <WSIcon block name="chevron-left" size="XS" mr="XS" color="gray700" />
          <WSText.ParagraphSm color="gray500">
            <b>Back</b>
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      ) : null}

      <WSCentered span={{ m: "6", xl: "4" }}>
        <WSText.Heading5 mb="M">
          Enter your 6 digit verification code
        </WSText.Heading5>
        <WSText color="gray600" mb="XL">
          We've texted your code to{" "}
          {formatPhoneNumber((location.state as any)?.phoneNumber)}.
        </WSText>
        <WSFormOld
          onSubmit={onFormSubmit}
          validationSchema={Yup.object().shape({
            otp: validatorOTP
          })}
        >
          <WSFormOld.Field
            mb="XS"
            name="otp"
            component={WSInputMaskOld}
            componentProps={{
              autoComplete: "one-time-code",
              className: styles.input,
              mask: "999999"
            }}
          />
          <WSErrorMessage
            my="M"
            error={completePhoneVerificationMeta.error}
            contextKey={ErrorContextKey.VerifyPhone}
          />
          <WSButton.Link
            type="button"
            mb="3XL"
            onClick={() =>
              history.push({
                pathname: "/member/verify/phone-number",
                state: {
                  resend: true,
                  phoneNumber: (location.state as any)?.phoneNumber
                }
              })
            }
          >
            <WSText color="blue400">Didn't receive text?</WSText>
          </WSButton.Link>
          <WSFormOld.SubmitButton
            name="verifyOtp"
            mt="3XL"
            fullWidth
            className={styles.button}
            disabled={activityQuery.isLoading || queryTaxForms.isLoading}
            loading={completePhoneVerificationMeta.isLoading}
          >
            Continue
          </WSFormOld.SubmitButton>
        </WSFormOld>
      </WSCentered>
    </OnboardingLayout>
  );
};
