import {
  toWSDateString,
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import flatten from "lodash/flatten";
import {
  usePendingWithholdingList,
  useWithholdingTransactions
} from "../../../query/taxes/queries";
import { WSQueries } from "../../../query/WSQuery";
import sortBy from "lodash/sortBy";
import {
  PendingWithholdingStatus,
  PendingWithholdingSubType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingWithholding";
import { WithholdingTransactionType } from "@wingspanhq/bookkeeping/dist/lib/interfaces/withholdingTransaction";

export const columns: Array<WSTableColumn> = [
  {
    config: {
      header: {
        text: "Transaction"
      },
      gridTemplateSizeMax: "1fr"
    },
    renderFunction: row => <WSTableCell text={row.data.title} />
  },
  {
    config: {
      header: {
        text: "Date"
      },
      gridTemplateSizeMax: "0.3fr"
      // justify: "end"
    },
    renderFunction: row => (
      <WSTableCell text={toWSDateString(row.data.date, "monthDayYear")} />
    )
  },
  {
    config: {
      header: {
        text: "Amount"
      },
      gridTemplateSizeMax: "0.3fr"
      // justify: "end"
    },
    renderFunction: row => (
      <WSText.ParagraphSm
        color="gray600"
        formatMoney="taxes"
        data-testid={`postedTransactionAmount_${row.id}`}
      >
        {row.data.amount}
      </WSText.ParagraphSm>
    )
  }
];

export const TaxesTransactions: React.FC = () => {
  const queryWithholdingTransactions = useWithholdingTransactions();
  const queryPendingTransactions = usePendingWithholdingList();

  return (
    <WSQueries
      queries={{
        queryWithholdingTransactions,
        queryPendingTransactions
      }}
    >
      {({ queryPendingTransactionsData, queryWithholdingTransactionsData }) => {
        const transactionList = flatten(queryWithholdingTransactionsData || []);

        const tableDataPostedTransactions = transactionList.map(transaction => {
          return {
            data: {
              source: transaction,
              title:
                transaction.type === WithholdingTransactionType.TaxPayment
                  ? "Payment to IRS"
                  : transaction.invoiceNumber
                  ? `Set aside from Invoice #${transaction.invoiceNumber}`
                  : transaction.amount < 0
                  ? "Deposit"
                  : "Withdraw",
              description: transaction.invoiceNumber,
              date: transaction.createdAt,
              amount: transaction.amount * -1
            },
            id: transaction.withholdingTransactionId
          };
        });

        const tableDataPendingTransactions = sortBy(
          (queryPendingTransactionsData || [])
            .filter(
              transaction =>
                transaction.status === PendingWithholdingStatus.Pending
            )
            .map(transaction => {
              return {
                data: {
                  source: transaction,
                  isPending: true,
                  amount:
                    Math.abs(transaction.amount) *
                    (transaction.type ===
                      WithholdingTransactionType.TaxWithholding &&
                    transaction.subType === PendingWithholdingSubType.Deposit
                      ? 1
                      : -1),
                  title:
                    transaction.type ===
                      WithholdingTransactionType.TaxWithholding &&
                    transaction.subType === PendingWithholdingSubType.Withdraw
                      ? "Withdraw"
                      : transaction.type ===
                          WithholdingTransactionType.TaxWithholding &&
                        transaction.subType ===
                          PendingWithholdingSubType.Deposit
                      ? "Deposit"
                      : "Payment to IRS",
                  date: transaction.createdAt
                },
                id: transaction.pendingWithholdingId
              };
            }),
          "createdAt"
        ).reverse();

        if (
          tableDataPendingTransactions.length === 0 &&
          tableDataPostedTransactions.length === 0
        ) {
          return null;
        }

        return (
          <>
            <WSText.Paragraph weight="medium" mt="XL">
              Transactions
            </WSText.Paragraph>

            <WSTable
              mt="XL"
              mb="M"
              tableData={tableDataPendingTransactions}
              columns={columns}
              showHeader
              forceShowGroups
              getGroupName={() => "Pending"}
            />

            <WSInfiniteScroll
              onLoad={() => {
                queryWithholdingTransactions.fetchMore();
              }}
              loadMore={transactionList.length > 0}
              endOfList={!queryWithholdingTransactions.canFetchMore}
              loading={!!queryWithholdingTransactions.isFetchingMore}
            >
              <WSTable
                mt="XL"
                mb="M"
                tableData={tableDataPostedTransactions}
                columns={columns}
                showHeader={!tableDataPendingTransactions.length}
                forceShowGroups
                getGroupName={() => "Posted"}
                bottomMessage={
                  !tableDataPendingTransactions.length &&
                  !tableDataPostedTransactions.length
                    ? "No activity yet"
                    : undefined
                }
              />
            </WSInfiniteScroll>
          </>
        );
      }}
    </WSQueries>
  );
};
