import { IBalanceBaseResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/banking";
import { useUrlQueryFilters } from "../../utils/router";
import { DefaultBookkeepingFilters } from "../../Bookkeeping/screens/dashboard/BookkeepingIndex";
import { createTransactionParams } from "../../Bookkeeping/screens/dashboard/BookkeepingTransactionsV2";
import {
  TransactionStatus,
  TransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { TransactionParams } from "../../query/bookkeeping/queries";

export const getAvailableBalance = (balanceData: IBalanceBaseResponse) => {
  return balanceData.available || 0;
};
export type DefaultWalletFilters = TransactionParams & { search: string };

export const defaultWalletFilters: DefaultWalletFilters = {
  ...createTransactionParams(),
  status: [TransactionStatus.Active, TransactionStatus.Inactive],
  type: [
    TransactionType.Expense,
    TransactionType.Income,
    TransactionType.Transfer,
    TransactionType.ATMTransaction,
    TransactionType.Unknown
  ],
  nonBusiness: true,
  businessBanking: true,
  search: ""
};
export const reversePercent = (value?: number) => {
  return 100 - (value || 0);
};
export const useWalletFilters = () =>
  useUrlQueryFilters<DefaultBookkeepingFilters>(defaultWalletFilters);
