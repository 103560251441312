import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";

export const ADD_TO_CALENDAR_LINK =
  "https://calendar.google.com/calendar/u/0/r?cid=c_qp3r24vlib3i7geaolra0hqr7k@group.calendar.google.com";
export const getIRSQuarters = (year = moment().year()) => {
  return [
    {
      range: [`Jan 1, ${year}`, `Apr 1, ${year}`],
      dueRange: [`Jan 13, ${year}`, `Apr 13, ${year}`],
      dueDate: `Apr 13, ${year}`,
      quarter: 1
    },
    {
      range: [`Apr 1, ${year}`, `Jul 1, ${year}`],
      dueRange: [`Apr 13, ${year}`, `Jun 13, ${year}`],
      dueDate: `Jun 13, ${year}`,
      quarter: 2
    },
    {
      range: [`Jul 1, ${year}`, `Oct 1, ${year}`],
      dueRange: [`Jun 13, ${year}`, `Sep 13, ${year}`],
      dueDate: `Sep 13, ${year}`,
      quarter: 3
    },
    {
      range: [`Oct 1, ${year}`, `Jan 1, ${year + 1}`],
      dueRange: [`Sep 13, ${year}`, `Jan 13, ${year + 1}`],
      dueDate: `Jan 13, ${year + 1}`,
      quarter: 4
    }
  ] as const;
};
export const getCurrentIRSYear = () => {
  const currentDate = moment();
  let year = currentDate.year();

  if (currentDate.month() === 0) {
    // Show previous year quarters for Jan
    year = year - 1;
  }

  return year;
};
export const CURRENT_TAX_DOCUMENTS_YEAR = getCurrentIRSYear();
export const getCurrentIRSQuarter = () => {
  const currentDate = moment();
  const year = currentDate.year();

  if (currentDate.month() === 0) {
    // Show previous year quarters for Jan
    return getIRSQuarters(year - 1)[3];
  }

  const quarters = getIRSQuarters(year);

  return (
    quarters.find(quarter => {
      if (
        currentDate.isSameOrAfter(
          moment(quarter.dueRange[0], "MMM DD, YYYY")
        ) &&
        currentDate.isBefore(moment(quarter.dueRange[1], "MMM DD, YYYY"))
      ) {
        return true;
      }
      return false;
    }) || quarters[0]
  );
};
