import {
  WSElementProps,
  WSInfiniteScroll,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  MemberClientStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { UrlIdKey } from "../../../constants/routing";
import {
  getCollaboratorCompany,
  getCollaboratorEmail,
  getCollaboratorName
} from "../../../query/payments/selectors";
import { openInNewTab } from "../../../utils/openInNewTab";
import { InvoicesCollaboratorDetails } from "../../screens/InvoicesCollaboratorDetails";
import { CompletedDeductionDetails } from "../../screens/deductions/CompletedDuductionDetails";
import { ScheduledDeductionDetails } from "../../screens/deductions/ScheduledDuductionDetails";

type Props = WSElementProps & {
  collaborators: ICollaboratorSchema[];
  listPath: string;
};

const PAGE_SIZE = 20;
export const CollaboratorsTable: React.FC<Props> = ({
  collaborators,
  listPath,
  ...elementProps
}) => {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(0);

  return (
    <>
      <Switch>
        <Route
          path={`${listPath}/:${UrlIdKey.Collaborator}/deduction/scheduled/:deductionId`}
          component={ScheduledDeductionDetails}
          exact
        />
        <Route
          path={`${listPath}/:${UrlIdKey.Collaborator}/deduction/completed/:deductionId`}
          component={CompletedDeductionDetails}
          exact
        />
        <Route
          path={`${listPath}/:${UrlIdKey.Collaborator}`}
          component={InvoicesCollaboratorDetails}
        />
      </Switch>

      <WSInfiniteScroll
        onLoad={() => {
          setPage(p => ++p);
        }}
        loadMore={collaborators.length > page * PAGE_SIZE}
        endOfList={collaborators.length <= page * PAGE_SIZE}
        loading={false}
      >
        <WSTable
          tableData={collaborators
            .slice(0, page * PAGE_SIZE)
            .map(collaborator => ({
              id: collaborator.collaboratorId,
              data: collaborator
            }))}
          columns={[
            {
              config: {},
              renderFunction: ({ data }) => {
                const company = getCollaboratorCompany(data);
                return (
                  <WSTableCell
                    avatar={{
                      type: "text",
                      text: getCollaboratorName(data) || ""
                    }}
                    text={getCollaboratorName(data)}
                    secondaryText={company}
                  />
                );
              }
            },
            {
              config: {
                hideOnScreens: ["XS"]
              },
              renderFunction: ({ data }) => (
                <WSTableCell text={getCollaboratorEmail(data)} />
              )
            },
            {
              config: {
                hideOnScreens: ["XS", "S", "M"]
              },
              renderFunction: ({ data }) =>
                data.status === MemberClientStatus.Pending ? (
                  <WSTableCell
                    text="Setup not started"
                    secondaryText="Not yet eligible for payments"
                  />
                ) : null
            }
          ]}
          onRowClick={item => {
            history.push({
              pathname: `${listPath}/${item.id}`,
              search: location.search,
              state: {
                backPath: `${listPath}${location.search}`
              }
            });
          }}
          onRowControlClick={item => {
            openInNewTab(`${listPath}/${item.id}${location.search}`);
          }}
          onRowMousewheelClick={item => {
            openInNewTab(`${listPath}/${item.id}${location.search}`);
          }}
          data-testid="collaboratorsTable"
          rowActions={({ data }) =>
            data.status === MemberClientStatus.Pending
              ? []
              : [
                  {
                    text: "Send payment",
                    onClick: () => {
                      history.push("/member/invoices/payables/create", {
                        memberClientId: data.collaboratorId
                      });
                    }
                  }
                ]
          }
          {...elementProps}
        />
      </WSInfiniteScroll>
    </>
  );
};
