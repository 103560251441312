import { COUNTRY_OPTIONS } from "../../constants/user";
import { EntityType } from "../../modules/CIP/types";
import { IS_PRODUCTION_ENV } from "../constants/environment";
import {
  INTERNATIONAL_TAX_ID_VALIDATIONS,
  TaxIdValidation,
  TEST_TAX_IDS
} from "../constants/internationalTaxId";
import { selectorCountryName } from "../selectors/selectorCountryName";

export function createGenericValidation(
  country: string
): Record<EntityType, TaxIdValidation> {
  const countryName = selectorCountryName(country) || country;
  return {
    Individual: {
      regex: /^[A-Z0-9]{6,12}$/,
      errorMessage: `Please enter a valid ${countryName} Tax ID for individuals. It should be 6-12 alphanumeric characters.`,
      name: `${countryName} Individual Tax ID`,
      description: `Tax identification number for individuals in ${countryName}.`,
      placeholder: "ABC123456789"
    },
    Business: {
      regex: /^[A-Z0-9]{6,15}$/,
      errorMessage: `Please enter a valid ${countryName} Tax ID for businesses. It should be 6-15 alphanumeric characters.`,
      name: `${countryName} Business Tax ID`,
      description: `Tax identification number for businesses in ${countryName}.`,
      placeholder: "BUS123456789012"
    }
  };
}

export function getInternationalTaxIdInfo(
  country: string,
  type: EntityType
): {
  name: string;
  description: string;
  placeholder: string;
} {
  const countryValidations =
    INTERNATIONAL_TAX_ID_VALIDATIONS[country] ||
    createGenericValidation(country);
  const validation = countryValidations[type];

  return {
    name: validation?.name || "Tax ID",
    description: validation?.description || "",
    placeholder: validation?.placeholder || "Tax ID"
  };
}

export function isInternationalTaxIdSupported(
  country: string,
  type: EntityType
): boolean {
  const countryValidations = INTERNATIONAL_TAX_ID_VALIDATIONS[country];
  return !!countryValidations && !!countryValidations[type];
}
