import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces";

export enum ConsentFormType {
  W9 = "W9",
  W8Ben = "W8Ben",
  CanadaTaxPayerCertification = "CanadaTaxPayerCertification",
  LocalTaxInformation = "LocalTaxInformation"
}

export function getConsentForms(
  payers: IPayerResponse[],
  payeeCountry: string
): {
  consentForms: ConsentFormType[];
  taxInfoSharedPayers: {
    [keyof in ConsentFormType]: IPayerResponse[];
  };
} {
  let w9SharedPayers: IPayerResponse[] = [];
  let w8BenSharedPayers: IPayerResponse[] = [];
  let canadaTaxInfoSharedPayers: IPayerResponse[] = [];
  let localTaxInfoSharedPayers: IPayerResponse[] = [];
  const payerCountries = payers.map(
    payer => payer.member?.profile?.address?.country || "US"
  );
  const consentForms: ConsentFormType[] = [];
  // Refer the consent matrix for more details https://linear.app/wingspan/issue/WIN-8745#heading-afb09602

  // If payee is from US then W9 is required irrespective of payer's country
  if (payeeCountry === "US") {
    consentForms.push(ConsentFormType.W9);
    w9SharedPayers = payers;
  }
  // If payee is non-US and payer is from US then W8Ben is required
  if (payeeCountry !== "US" && payerCountries.includes("US")) {
    consentForms.push(ConsentFormType.W8Ben);
    w8BenSharedPayers = payers.filter(
      payer => (payer.member?.profile?.address?.country || "US") === "US"
    );
  }
  // CanadaTaxPayerCertification is required when:
  // 1. Payee is from Canada
  // 2. Payer is from Canada OR other country
  if (
    payeeCountry === "CA" &&
    payerCountries.some(country => country !== "US")
  ) {
    consentForms.push(ConsentFormType.CanadaTaxPayerCertification);
    canadaTaxInfoSharedPayers = payers.filter(
      payer => (payer.member?.profile?.address?.country || "US") !== "US"
    );
  }
  // LocalTaxInformation is required when:
  // 1. Payee is not from Canada and US
  // 2. Payer is not from US
  if (
    payeeCountry !== "CA" &&
    payeeCountry !== "US" &&
    payerCountries.some(country => country !== "US")
  ) {
    consentForms.push(ConsentFormType.LocalTaxInformation);
    localTaxInfoSharedPayers = payers.filter(
      payer => (payer.member?.profile?.address?.country || "US") !== "US"
    );
  }

  return {
    consentForms: consentForms.length > 0 ? consentForms : [ConsentFormType.W9],
    taxInfoSharedPayers: {
      [ConsentFormType.W9]: w9SharedPayers,
      [ConsentFormType.W8Ben]: w8BenSharedPayers,
      [ConsentFormType.CanadaTaxPayerCertification]: canadaTaxInfoSharedPayers,
      [ConsentFormType.LocalTaxInformation]: localTaxInfoSharedPayers
    }
  };
}
