import {
  CompanyStructure,
  IMember,
  INewUser,
  IndustryVertical,
  UserAccountType
} from "@wingspanhq/users/dist/lib/interfaces";
import { FederalTaxClassification, FormData, Industry } from "../../CIP/types";

const taxClassificationMap: Record<
  CompanyStructure,
  FederalTaxClassification
> = {
  [CompanyStructure.None]: "SoleProprietorship",
  [CompanyStructure.SoleProprietorship]: "SoleProprietorship",
  [CompanyStructure.LlcSingleMember]: "LlcSingleMember",
  [CompanyStructure.LlcMultiMember]: "LlcPartnership",
  [CompanyStructure.CorporationS]: "CorporationS",
  [CompanyStructure.LLCCorporationS]: "LlcCorporationS",
  [CompanyStructure.LLCCorporationC]: "LlcCorporationC",
  [CompanyStructure.LLCPartnership]: "LlcPartnership",
  [CompanyStructure.CorporationC]: "CorporationC",
  [CompanyStructure.Partnership]: "Partnership"
};

const getMappedIndustry = (industry: IndustryVertical): Industry => {
  if (industry === IndustryVertical.AdultEntertainmentDatingOrEscortServices) {
    return "MatureEntertainmentDatingOrEscortServices";
  }

  return industry;
};

export const getUserFormData = (user: INewUser, member: IMember): FormData => {
  const isEnterprise =
    user.settings?.userAccountType === UserAccountType.enterprise;

  return {
    country: member.profile.address?.country || "US",
    type: member.profile.company?.taxId ? "Business" : "Individual",
    accountHolder: {
      firstName: user.profile.firstName || "",
      lastName: user.profile.lastName || "",
      birthday: user.profile.dob || null,
      // TODO: where do we take jobTitle/occupation from?
      jobTitle: isEnterprise ? "Finance" : "Owner",
      address: {
        country: member.profile.address?.country || "US",
        addressLine1: member.profile.address?.addressLine1 || "",
        addressLine2: member.profile.address?.addressLine2 || "",
        city: member.profile.address?.city || "",
        state: member.profile.address?.state || null,
        postalCode: member.profile.address?.postalCode || ""
      },
      ownership: isEnterprise
        ? { stake: 0, isRepresentative: true }
        : {
            stake: 100,
            isRepresentative: false
          },
      taxId: "",
      canadaQuebecEnterpriseNumber: ""
    },
    business: {
      legalBusinessName: member.profile.company?.legalBusinessName || "",
      dba: member.profile.company?.name || "",
      taxClassification: member.profile.company?.structure
        ? taxClassificationMap[member.profile.company.structure]
        : "SoleProprietorship",
      taxId: member.profile.company?.taxId || "",
      industry: member.profile.industry
        ? getMappedIndustry(member.profile.industry)
        : "LegalAccountingConsultingOrComputerProgramming",
      website: member.profile.company?.website || "",
      noWebsite: !member.profile.company?.website,
      address: {
        country: member.profile.address?.country || "US",
        addressLine1: member.profile.address?.addressLine1 || "",
        addressLine2: member.profile.address?.addressLine2 || "",
        city: member.profile.address?.city || "",
        state: member.profile.address?.state || null,
        postalCode: member.profile.address?.postalCode || ""
      },
      canadaQuebecEnterpriseNumber: "",
      numberOfEmployees: isEnterprise ? "51To250" : "None",
      stateOfIncorporation:
        member.profile.company?.stateOfIncorporation ||
        member.profile.address?.state ||
        null
    }
  };
};
