import {
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSCentered,
  WSIconList,
  WSPanel,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import {
  BulkAdjust1099AmountFaqs,
  BulkAdjust1099Collaborator
} from "./BulkAdjust1099AmountStep1";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useHistory } from "react-router-dom";
import { use1099AmountBulkAdjustImporter } from "./bulkAdjust1099AmountImporter";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCreateCollaborator,
  useDynamicUpdateCollaborator
} from "../../../query/payments/mutations";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { bulkActionFactory } from "../../../utils/bulkActionFactory";
import { getFloatFromString } from "../../../utils/amounts";

export interface BulkAdjust1099AmountStep2Props {}

export const BulkAdjust1099AmountStep2: React.FC<BulkAdjust1099AmountStep2Props> = () => {
  useBrowserPageTitle("Wingspan - 1099 Compensation Amounts");
  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;
  const { openSnackbar } = useWSSnackbar();

  const userId = useUserId();
  const collaboratorQuery = useCollaboratorsQuery();
  const [createCollaborator, createCollaboratorMeta] = useCreateCollaborator();
  const [
    updateCollaborator,
    updateCollaboratorMeta
  ] = useDynamicUpdateCollaborator();
  const [isFlatfileLoading, setIsFlatfileLoading] = useState(false);
  const [newCollaborators, setNewCollaborators] = useState<
    BulkAdjust1099Collaborator[]
  >([]);
  const [updatingCollaborators, setUpdatingCollaborators] = useState<
    Array<
      BulkAdjust1099Collaborator & {
        collaboratorId: string;
      }
    >
  >([]);
  const [newCollaboratorApiResults, setNewCollaboratorApiResults] = useState<
    {
      error?: any;
      email: string;
    }[]
  >([]);
  const [
    updateCollaboratorApiResults,
    setUpdateCollaboratorApiResults
  ] = useState<
    {
      error?: any;
      email: string;
      collaboratorId: string;
    }[]
  >([]);

  const bulkAdjust1099AmountImporter = use1099AmountBulkAdjustImporter();

  const isCollaboratorExists = (
    collaborator: ICollaboratorSchema[] = [],
    email: string
  ): boolean => {
    return (
      collaborator.filter(
        collaborator => collaborator.member.user.email === email
      ).length > 0
    );
  };

  const getCollaboratorByEmail = (
    collaborators: ICollaboratorSchema[] = [],
    email: string
  ): ICollaboratorSchema | null => {
    const collaborator = collaborators.filter(
      mc => mc.member.user.email === email
    );
    return collaborator.length > 0 ? collaborator[0] : null;
  };

  const bulkAdjust1099Amount = bulkActionFactory({
    action: async (
      collaborator: BulkAdjust1099Collaborator & { collaboratorId: string }
    ) => {
      updateCollaborator(
        {
          collaboratorId: collaborator.collaboratorId,
          form1099Balances: {
            [prevYear as keyof ICollaboratorSchema["form1099Balances"]]: {
              adjustments: getFloatFromString(collaborator.adjustmentAmount),
              issue1099: collaborator.send1099
            }
          } as any
        },
        {
          onSuccess: (collaborator: ICollaboratorSchema) => {
            setUpdateCollaboratorApiResults(prev => [
              ...prev,
              {
                email: collaborator.member?.user?.email as string,
                collaboratorId: collaborator.collaboratorId
              }
            ]);
          },
          onError: error => {
            setUpdateCollaboratorApiResults(prev => [
              ...prev,
              {
                email: collaborator.email,
                collaboratorId: collaborator.collaboratorId,
                error: error.response?.data.error || "Oops!"
              }
            ]);
          }
        }
      );
    }
  });

  const bulkCreateCollaborators = bulkActionFactory({
    action: async (record: BulkAdjust1099Collaborator) => {
      createCollaborator(
        {
          clientId: userId,
          memberName: `${record.legalFirstName} ${record.legalLastName}`,
          memberEmail: record.email
        },
        {
          onSuccess: collaborator => {
            setNewCollaboratorApiResults(prev => [
              ...prev,
              {
                email: record.email
              }
            ]);
            updateCollaborator({
              collaboratorId: collaborator.collaboratorId,
              form1099Balances: {
                [prevYear as keyof ICollaboratorSchema["form1099Balances"]]: {
                  adjustments: getFloatFromString(record.adjustmentAmount + ""),
                  issue1099: record.send1099
                }
              } as any
            });
          },
          onError: error => {
            setNewCollaboratorApiResults(prev => [
              ...prev,
              {
                email: record.email,
                error: error.response?.data.error || "Oops!"
              }
            ]);
          }
        }
      );
    }
  });

  const resultsLength =
    newCollaboratorApiResults.length + updateCollaboratorApiResults.length;
  const totalCollaborators =
    newCollaborators.length + updatingCollaborators.length;

  const importPercent = (resultsLength * 100) / totalCollaborators;

  const onUploadSpreadsheet = async () => {
    setIsFlatfileLoading(true);
    setNewCollaboratorApiResults([]);
    setUpdateCollaboratorApiResults([]);
    const results = await bulkAdjust1099AmountImporter();
    if (results.length === 0) {
      openSnackbar({
        duration: 5000,
        type: "warning",
        message:
          "Sorry! We could not find any collaborators in the file you uploaded"
      });
      return;
    }
    const newCollaborators: BulkAdjust1099Collaborator[] = [];
    const updatingCollaborators: Array<BulkAdjust1099Collaborator & {
      collaboratorId: string;
    }> = [];
    results.forEach(collaborator => {
      if (
        isCollaboratorExists(
          collaboratorQuery.data as ICollaboratorSchema[],
          collaborator.email
        )
      ) {
        const collaboratorByEmail = getCollaboratorByEmail(
          collaboratorQuery.data as ICollaboratorSchema[],
          collaborator.email
        ) as ICollaboratorSchema;
        updatingCollaborators.push({
          collaboratorId: collaboratorByEmail.collaboratorId,
          ...collaborator
        });
      } else {
        newCollaborators.push(collaborator);
      }
    });
    setNewCollaborators(newCollaborators);
    setUpdatingCollaborators(updatingCollaborators);
    await bulkAdjust1099Amount.run(updatingCollaborators);
    await bulkCreateCollaborators.run(newCollaborators);
  };

  const onRetry = async () => {
    setNewCollaboratorApiResults([]);
    setUpdateCollaboratorApiResults([]);
    const failedUpdateCollaborators = updateCollaboratorApiResults
      .filter(res => res.error)
      .map(
        fc =>
          updatingCollaborators.find(
            uc => fc.collaboratorId === uc.collaboratorId
          ) as BulkAdjust1099Collaborator & { collaboratorId: string }
      );
    const failedCreateCollaborators = newCollaboratorApiResults
      .filter(res => res.error)
      .map(
        fc =>
          newCollaborators.find(
            nc => fc.email === nc.email
          ) as BulkAdjust1099Collaborator
      );

    await bulkAdjust1099Amount.run(failedUpdateCollaborators);
    await bulkCreateCollaborators.run(failedCreateCollaborators);
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 2) * 2}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
            2 of 2
          </WSText.ParagraphSm>
          <WSText.Heading4>
            Upload adjusted 1099 compensation amounts
          </WSText.Heading4>
          <WSPanel mt="2XL">
            {(function() {
              if (
                createCollaboratorMeta.isLoading ||
                updateCollaboratorMeta.isLoading ||
                newCollaboratorApiResults.length > 0 ||
                updateCollaboratorApiResults.length > 0
              ) {
                const failedCreateCollaboratorsApiResults = newCollaboratorApiResults.filter(
                  res => res.error
                );
                const failedUpdateCollaboratorsApiResults = updateCollaboratorApiResults.filter(
                  res => res.error
                );
                return (
                  <>
                    <WSText.Heading5 mb="XL">
                      Your import adjustments summary
                    </WSText.Heading5>
                    {updatingCollaborators.length > 0 && (
                      <WSText mb="XL">
                        {failedUpdateCollaboratorsApiResults.length > 0
                          ? `${failedUpdateCollaboratorsApiResults.length}/${updatingCollaborators.length} contractors are failed to update.`
                          : `${updatingCollaborators.length} contractors are successfully updated.`}
                      </WSText>
                    )}
                    {newCollaborators.length > 0 && (
                      <WSText mb="M">
                        {failedCreateCollaboratorsApiResults.length > 0
                          ? `${failedCreateCollaboratorsApiResults.length}/${newCollaborators.length} contractors are failed to create.`
                          : `${newCollaborators.length} contractors are successfully created.`}
                      </WSText>
                    )}
                    <WSButton.Link
                      mb="2XL"
                      onClick={() => {
                        history.push(
                          "/member/invoices/contacts/collaborators/1099/adjust-compensation/download"
                        );
                      }}
                    >
                      Restart adjustments
                    </WSButton.Link>
                    {createCollaboratorMeta.isLoading ||
                    updateCollaboratorMeta.isLoading ? (
                      <WSProgressBar
                        mt="2XL"
                        percent={importPercent || 0}
                        text={`Uploading: ${resultsLength} of ${totalCollaborators} contractors...`}
                      />
                    ) : null}
                    {failedCreateCollaboratorsApiResults.length > 0 ||
                    failedUpdateCollaboratorsApiResults.length > 0 ? (
                      <WSButtons forceFullWidth>
                        <WSButton fullWidth onClick={onRetry}>
                          Try again
                        </WSButton>
                        <WSButton.Secondary
                          fullWidth
                          onClick={() =>
                            history.push(
                              "/member/invoices/contacts/collaborators"
                            )
                          }
                        >
                          Go to contractors
                        </WSButton.Secondary>
                      </WSButtons>
                    ) : (
                      <WSButton
                        fullWidth
                        onClick={() =>
                          history.push(
                            "/member/invoices/contacts/collaborators"
                          )
                        }
                      >
                        Go to contractors
                      </WSButton>
                    )}
                  </>
                );
              }
              return (
                <>
                  <WSText>
                    Don't worry if your spreadsheet has other details, but
                    please make sure it contains the following columns.
                  </WSText>

                  <WSFlexBox mt="2XL" mb="XL">
                    <WSElement style={{ flex: 1 }}>
                      <WSText weight="medium" mb="M">
                        Required
                      </WSText>
                      <WSIconList.Checks
                        items={["Email", "Adjustment amount"]}
                        mb="XL"
                      />
                    </WSElement>
                    <WSElement style={{ flex: 1 }}>
                      <WSText weight="medium" mb="M">
                        Optional
                      </WSText>
                      <WSIconList.Checks
                        items={[
                          "Legal First Name",
                          "Legal Last Name",
                          `${prevYear} Wingspan payments`,
                          "Send 1099"
                        ]}
                        mb="XL"
                      />
                    </WSElement>
                  </WSFlexBox>

                  <WSButton
                    icon="upload"
                    fullWidth
                    onClick={onUploadSpreadsheet}
                    loading={isFlatfileLoading}
                  >
                    Upload spreadsheet
                  </WSButton>
                </>
              );
            })()}
          </WSPanel>
          <BulkAdjust1099AmountFaqs />
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
