import footprint, { FootprintComponentKind } from "@onefootprint/footprint-js";
import { queryCache } from "react-query";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../../query/notifications/keys";
import { QUERY_VERIFICATIONS } from "../../../../../query/onboarding/queries/useQueryVerifications";
import { getVerificationMissingData } from "../../../../../services/api/onboarding/missingFields";
import { footprintAppearanceConfig } from "../../../../../shared/constants/footprint";
import { NotificationTemplate } from "../index";

export const BankingUpdateRequired: NotificationTemplate<never> = {
  renderTitle: props => props.subject,
  renderBody: props => props.message,
  buttonActionAsync: (_, history) => async () => {
    try {
      const missingData = await getVerificationMissingData("Banking");

      if (missingData.requiredFields && missingData.requiredFields.length > 0) {
        history.push("/member/cip/missing-data/banking");
      } else if (missingData.completeOnboardingToken) {
        const component = footprint.init({
          kind: FootprintComponentKind.Verify,
          variant: "modal",
          authToken: missingData.completeOnboardingToken,
          onComplete: () => {
            queryCache.invalidateQueries(QUERY_VERIFICATIONS);
            queryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
          },
          options: {
            showLogo: false
          },
          appearance: footprintAppearanceConfig
        });
        component.render();
      } else {
        throw new Error(
          "No completeOnboardingToken or requiredFields provided"
        );
      }
    } catch (error) {
      console.error("Error while complete verificaiton", error);
    }
  },
  buttonText: () => "Complete verification"
};
