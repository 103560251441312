import classNames from "classnames";

export const fsExcludeCN = (
  className: string | undefined,
  fsExclude: boolean | undefined
) => {
  return classNames(className, {
    "fs-exclude": fsExclude
  });
};
