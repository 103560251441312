import { WSList, WSLoader, WSText } from "@wingspanhq/fe-component-library";
import { RouteComponentProps } from "react-router-dom";
import { useQueryCustomerEntity } from "../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerificationMissingData } from "../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { components } from "../../../services/api/onboarding/types";
import { Layout } from "../../Onboarding/components/Layout";
import { FormMissingData } from "../components/FormMissingData";

type MatchParam = "tax" | "banking" | "enhanced";

const mapParamMap: Record<
  MatchParam,
  components["parameters"]["verificationLevel"]
> = {
  tax: "Tax",
  banking: "Banking",
  enhanced: "Enhanced"
};

export const RouteMissingData: React.FC<RouteComponentProps<{
  verificationLevel: MatchParam;
}>> = ({
  match: {
    params: { verificationLevel }
  }
}) => {
  const queryCustomerEntity = useQueryCustomerEntity();
  const queryMissingData = useQueryVerificationMissingData(
    mapParamMap[verificationLevel]
  );

  if (queryMissingData.isLoading || queryCustomerEntity.isLoading) {
    return (
      <Layout title="Additional Info">
        <WSLoader.Spinner size="XS" />
      </Layout>
    );
  }

  return (
    <Layout title="Additional Info">
      <WSList gap="L">
        <WSText weight="medium">Complete account verification</WSText>
        <WSText.ParagraphSm color="gray500">
          Additional information needed to complete your account verification.
          Please complete the following fields.
        </WSText.ParagraphSm>
        <FormMissingData
          requiredFields={queryMissingData.data?.requiredFields || []}
          entityType={queryCustomerEntity.data?.type}
        />
      </WSList>
    </Layout>
  );
};
