// @ts-nocheck

// NOTE: This file is deprecated and no longer used.
import {
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSCentered,
  WSIconList,
  WSPanel,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useHistory } from "react-router-dom";
import { use1099MissingInfoBulkImporter } from "./bulkUpdate1099MissingInfotImporter";
import { useUserId } from "../../../query/hooks/helpers";
import { useDynamicUpdateCollaborator } from "../../../query/payments/mutations";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import { bulkActionFactory } from "../../../utils/bulkActionFactory";
import { Upload1099MissingInfoCollaborator } from "./BulkUpdate1099MissingInfoStep1";

export interface BulkUpdate1099MissingInfoStep2Props {}

export const BulkUpdate1099MissingInfoStep2: React.FC<BulkUpdate1099MissingInfoStep2Props> = () => {
  useBrowserPageTitle("Wingspan - 1099 Missing Information");
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();
  const collaboratorQuery = useCollaboratorsQuery();
  const [
    updateCollaborator,
    updateCollaboratorMeta
  ] = useDynamicUpdateCollaborator();
  const [isFlatfileLoading, setIsFlatfileLoading] = useState(false);
  const [updatingCollaborators, setUpdatingCollaborators] = useState<
    Array<
      Upload1099MissingInfoCollaborator & {
        collaboratorId: string;
      }
    >
  >([]);
  const [
    updateCollaboratorApiResults,
    setUpdateCollaboratorApiResults
  ] = useState<
    {
      error?: any;
      email: string;
      collaboratorId: string;
    }[]
  >([]);
  const bulk1099MissingInfoImporter = use1099MissingInfoBulkImporter();

  const isCollaboratorExists = (
    collaborator: ICollaboratorSchema[] = [],
    email: string
  ): boolean => {
    return (
      collaborator.filter(
        collaborator => collaborator.member.user.email === email
      ).length > 0
    );
  };

  const getCollaboratorByEmail = (
    collaborators: ICollaboratorSchema[] = [],
    email: string
  ): ICollaboratorSchema | null => {
    const collaborator = collaborators.filter(
      mc => mc.member.user.email === email
    );
    return collaborator.length > 0 ? collaborator[0] : null;
  };

  const bulkUpdateMissingInfo = bulkActionFactory({
    action: async (
      collaborator: Upload1099MissingInfoCollaborator & {
        collaboratorId: string;
      }
    ) => {
      updateCollaborator(
        {
          collaboratorId: collaborator.collaboratorId,
          formW9Data: {
            firstName: collaborator.legalFirstName,
            lastName: collaborator.legalLastName,
            addressLine1: collaborator.addressLine1,
            addressLine2: collaborator.addressLine2,
            city: collaborator.city,
            state: collaborator.state,
            postalCode: collaborator.postalCode,
            country: collaborator.country,
            ssn: collaborator.ssn?.replace(/\D/g, ""),
            legalBusinessName: collaborator.businessName
          }
        },
        {
          onSuccess: (collaborator: ICollaboratorSchema) => {
            setUpdateCollaboratorApiResults(prev => [
              ...prev,
              {
                email: collaborator.member?.user?.email as string,
                collaboratorId: collaborator.collaboratorId
              }
            ]);
          },
          onError: error => {
            setUpdateCollaboratorApiResults(prev => [
              ...prev,
              {
                email: collaborator.email,
                collaboratorId: collaborator.collaboratorId,
                error: error.response?.data.error || "Oops!"
              }
            ]);
          }
        }
      );
    }
  });

  const resultsLength = updateCollaboratorApiResults.length;
  const totalCollaborators = updatingCollaborators.length;

  const importPercent = (resultsLength * 100) / totalCollaborators;

  const onUploadSpreadsheet = async () => {
    setIsFlatfileLoading(true);
    setUpdateCollaboratorApiResults([]);
    const results = await bulk1099MissingInfoImporter();
    if (results.length === 0) {
      openSnackbar({
        duration: 5000,
        type: "warning",
        message:
          "Sorry! We could not find any collaborators in the file you uploaded"
      });
      return;
    }
    const updatingCollaborators: Array<Upload1099MissingInfoCollaborator & {
      collaboratorId: string;
    }> = [];
    results.forEach(collaborator => {
      if (
        isCollaboratorExists(
          collaboratorQuery.data as ICollaboratorSchema[],
          collaborator.email
        )
      ) {
        const collaboratorByEmail = getCollaboratorByEmail(
          collaboratorQuery.data as ICollaboratorSchema[],
          collaborator.email
        ) as ICollaboratorSchema;
        updatingCollaborators.push({
          collaboratorId: collaboratorByEmail.collaboratorId,
          ...collaborator
        });
      }
    });
    setUpdatingCollaborators(updatingCollaborators);

    await bulkUpdateMissingInfo.run(updatingCollaborators);
  };

  const onRetry = async () => {
    setUpdateCollaboratorApiResults([]);
    const failedUpdateCollaborators = updateCollaboratorApiResults
      .filter(res => res.error)
      .map(
        fc =>
          updatingCollaborators.find(
            uc => fc.collaboratorId === uc.collaboratorId
          ) as Upload1099MissingInfoCollaborator & { collaboratorId: string }
      );

    await bulkUpdateMissingInfo.run(failedUpdateCollaborators);
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 2) * 2}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
            2 of 2
          </WSText.ParagraphSm>
          <WSText.Heading4>Upload 1099 missing information</WSText.Heading4>
          <WSPanel mt="2XL">
            {(function() {
              if (
                updateCollaboratorMeta.isLoading ||
                updateCollaboratorApiResults.length > 0
              ) {
                const failedUpdateCollaboratorsApiResults = updateCollaboratorApiResults.filter(
                  res => res.error
                );
                return (
                  <>
                    <WSText.Heading5 mb="XL">
                      Your information upload summary
                    </WSText.Heading5>
                    {updatingCollaborators.length > 0 && (
                      <WSText mb="XL">
                        {failedUpdateCollaboratorsApiResults.length > 0
                          ? `${failedUpdateCollaboratorsApiResults.length}/${updatingCollaborators.length} collaborators are failed to update.`
                          : `${updatingCollaborators.length} collaborators are successfully updated.`}
                      </WSText>
                    )}
                    <WSButton.Link
                      mb="2XL"
                      onClick={() => {
                        history.push(
                          "/member/invoices/contacts/collaborators/1099/missing-information/download"
                        );
                      }}
                    >
                      Restart update missing information
                    </WSButton.Link>
                    {updateCollaboratorMeta.isLoading ? (
                      <WSProgressBar
                        mt="2XL"
                        percent={importPercent || 0}
                        text={`Uploading: ${resultsLength} of ${totalCollaborators} collaborators...`}
                      />
                    ) : null}
                    {failedUpdateCollaboratorsApiResults.length > 0 ? (
                      <WSButtons forceFullWidth>
                        <WSButton fullWidth onClick={onRetry}>
                          Try again
                        </WSButton>
                        <WSButton.Secondary
                          fullWidth
                          onClick={() =>
                            history.push(
                              "/member/invoices/contacts/collaborators"
                            )
                          }
                        >
                          Go to collaborators
                        </WSButton.Secondary>
                      </WSButtons>
                    ) : (
                      <WSButton
                        fullWidth
                        onClick={() =>
                          history.push(
                            "/member/invoices/contacts/collaborators"
                          )
                        }
                      >
                        Go to collaborators
                      </WSButton>
                    )}
                  </>
                );
              }
              return (
                <>
                  <WSText>
                    Don't worry if your spreadsheet has other details, but
                    please make sure it contains the following columns.
                  </WSText>
                  <WSFlexBox mt="2XL" mb="XL">
                    <WSElement style={{ flex: 1 }}>
                      <WSText weight="medium" mb="M">
                        Required
                      </WSText>
                      <WSIconList.Checks
                        items={[
                          "Legal First Name",
                          "Legal Last Name",
                          "Email",
                          "Address Line1",
                          "City",
                          "State",
                          "Postal Code",
                          "Country",
                          "Tax ID"
                        ]}
                        mb="XL"
                      />
                    </WSElement>
                    <WSElement style={{ flex: 1 }}>
                      <WSText weight="medium" mb="M">
                        Optional
                      </WSText>
                      <WSIconList.Checks
                        items={["Business Name", "Address Line2"]}
                        mb="XL"
                      />
                    </WSElement>
                  </WSFlexBox>

                  <WSButton
                    icon="upload"
                    fullWidth
                    onClick={onUploadSpreadsheet}
                    loading={isFlatfileLoading}
                  >
                    Upload spreadsheet
                  </WSButton>
                </>
              );
            })()}
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
