import classNames from "classnames";
import React, { PropsWithChildren, useMemo, useRef } from "react";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import { WSTooltip, WSTooltipProps } from "../WSTooltip/WSTooltip";
import { WSIcon } from "../core";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSDivider.module.scss";

export interface WSDividerProps
  extends Omit<WSElementProps<HTMLHRElement>, "color"> {
  type?: "default" | "expand" | "split";
  label?: string;
  action?: {
    onClick: WSButtonProps<"Link">["onClick"];
    loading?: WSButtonProps<"Link">["loading"];
    text: string;
  };
  tooltip?: string | Omit<PropsWithChildren<WSTooltipProps>, "target">;
}

export const WSDivider: React.FC<WSDividerProps> = ({
  type = "default",
  label,
  className,
  action,
  tooltip,
  ...elementProps
}) => {
  const tooltipIconRef = useRef<HTMLSpanElement>(null);

  const tooltipProps: WSTooltipProps | undefined = useMemo(
    () =>
      tooltip
        ? {
            position: "right",
            target: tooltipIconRef,
            ...(typeof tooltip === "string" ? { children: tooltip } : tooltip)
          }
        : undefined,
    [tooltip]
  );

  return (
    <WSElement
      className={classNames(
        styles.divider,
        styles[`type-${type}`],
        !!label && styles.withLabel,
        className
      )}
      color="gray200"
      {...elementProps}
    >
      <WSTooltip {...tooltipProps} />

      {label && (
        <WSElement className={styles.label}>
          <WSText.ParagraphSm color="gray500">{label}</WSText.ParagraphSm>

          {tooltip && (
            <>
              <WSIcon
                size="S"
                color="gray500"
                ref={tooltipIconRef}
                ml="S"
                name="info-circle-fill"
              />
            </>
          )}
        </WSElement>
      )}

      {action && (
        <WSButton.Link
          type="button"
          className={styles.action}
          size="M"
          onClick={action.onClick}
          loading={action.loading}
        >
          {action.text}
        </WSButton.Link>
      )}
    </WSElement>
  );
};
