import { useHistory } from "react-router-dom";
import { Onboarding } from "..";
import { OnboardingModule } from "../types";

const payeeOnboardingModules: OnboardingModule[] = [
  {
    type: "create_account",
    options: {
      accountTypes: ["Business", "Individual"],
      verificationLevel: "Tax",
      show1099Message: true
    }
  },
  {
    type: "certify_tax_payer_info"
  },
  {
    type: "payout_method"
  },
  {
    type: "eligibility_requirements"
  }
];

export const RoutePayeeOnboarding: React.FC = () => {
  const history = useHistory();

  return (
    <Onboarding
      basePath="/member/onboarding/payee"
      modules={payeeOnboardingModules}
      onSuccess={() => {
        history.push("/member/dashboard");
      }}
    />
  );
};
