import { IInternalAccount } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  FundingSourceType,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount, IClient } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorAccountFullName } from "./selectorAccountFullName";
import { selectorCreditCardName } from "./selectorCreditCardName";

export const selectorDefaultPaymentMethodName = (
  client: IClient,
  payrollSettings: IPayrollSettings,
  accounts: IAccount[],
  internalAccount?: IInternalAccount
) => {
  if (client.profile.defaultPaymentMethod?.accountId) {
    const account = accounts.find(
      account =>
        account.accountId === client.profile.defaultPaymentMethod?.accountId
    );

    if (account) {
      return selectorAccountFullName(account);
    }
  } else if (client.profile.defaultPaymentMethod?.paymentMethodId) {
    const card = (client.profile.savedPaymentMethods || []).find(
      paymentMethod =>
        paymentMethod.paymentMethodId ===
        client.profile.defaultPaymentMethod?.paymentMethodId
    );

    if (card) {
      return selectorCreditCardName(card);
    }
  } else if (
    payrollSettings.fundingSource?.fundingSourceType ===
    FundingSourceType.InternalAccount
  ) {
    // TODO: make a selector for it
    return `Wingspan Wallet${
      internalAccount ? ` (${internalAccount.numbers?.account?.slice(-4)})` : ""
    }`;
  }
};
