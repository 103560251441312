import { Intervals } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { WSQueries } from "../../query/WSQuery";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useMemberClientsQuery } from "../../query/payments/queries";
import { getMemberClient } from "../../query/payments/selectors";
import { isToday } from "../../utils/dates";
import { ClientSectionValues } from "../components/InvoicesForm/ClientSection";
import { InvoicesForm } from "../components/InvoicesForm/InvoicesForm";
import { useDefaultInvoiceSubject } from "../utils";
import { defaultFrequency } from "../utils/invoicesFormUtils";

export const InvoicesCreate: React.FC<RouteComponentProps<
  any,
  any,
  {
    email?: string;
    memberClientId?: string;
    sendDate?: Date;
    isRecurring?: boolean;
    payerId?: string;
    payerPayeeEngagementId?: string;
  }
>> = ({ location }) => {
  const qFeatureFlags = useFeatureFlags();
  const memberClientsQuery = useMemberClientsQuery(
    qFeatureFlags.data?.engagements
      ? {
          enabled: false,
          initialData: [],
          refetchOnMount: false
        }
      : {}
  );

  const {
    email,
    memberClientId,
    sendDate,
    isRecurring,
    payerId = null,
    payerPayeeEngagementId = null
  } = location.state || {};
  const defaultSubject = useDefaultInvoiceSubject();

  return (
    <WSQueries
      queries={{
        memberClientsQuery,
        qFeatureFlags
      }}
    >
      {({
        memberClientsQuery: { data: memberClients },
        qFeatureFlags: { data: featureFlags }
      }) => {
        let memberClient;
        if (memberClientId) {
          memberClient = getMemberClient(memberClients, memberClientId);
        }

        let client: ClientSectionValues = {
          client: {
            memberClientId: "",
            emailsCC: [],
            payerId,
            payerPayeeEngagementId
          }
        };

        if (memberClient) {
          client = {
            client: {
              memberClientId: memberClient.memberClientId,
              emailsCC: (memberClient.emailCC || []).map(email => ({
                email
              })),
              payerId,
              payerPayeeEngagementId
            }
          };
        }

        return (
          <InvoicesForm
            defaultValues={{
              ...client,
              subject: defaultSubject,
              email,
              ...(featureFlags.purchaseOrderNumber
                ? { purchaseOrderNumber: "" }
                : {}),
              lineItems: [
                {
                  description: "",
                  rate: "fixed",
                  customUnit: "",
                  remove: "",
                  discount: {
                    type: "percentage",
                    description: ""
                  },
                  reimbursableExpense: false
                }
              ],
              other: {
                projectName: "",
                notes: "",
                attachments: []
              },
              due: "on-receipt",
              customDueDate: new Date(),
              date: new Date(),
              includesLateFee: false,
              lateFee: {
                amount: 5,
                type: "fixed",
                every: 1,
                interval: Intervals.Week,
                overriden: false
              },
              recurring: isRecurring || false,
              send: {
                type: sendDate
                  ? isToday(sendDate)
                    ? "immediately"
                    : "date"
                  : "immediately",
                date: sendDate || new Date()
              },
              frequency: defaultFrequency,
              collaborators: [],
              invoiceCollaborators: [],
              paymentMethods: {
                acceptAllPMs: true,
                acceptCreditCard: true,
                acceptACH: true,
                acceptManual: true
              },
              advanced: {
                sendEmails: true,
                sendReminders: true,
                creditFeeHandling: {
                  clientPaysProcessingFees: true,
                  clientProcessingFeePercentage: 2.9
                }
              }
            }}
          />
        );
      }}
    </WSQueries>
  );
};
