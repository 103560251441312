import footprint, { FootprintComponentKind } from "@onefootprint/footprint-js";
import { queryCache } from "react-query";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../../query/notifications/keys";
import { QUERY_VERIFICATIONS } from "../../../../../query/onboarding/queries/useQueryVerifications";
import { getVerificationMissingData } from "../../../../../services/api/onboarding/missingFields";
import { footprintAppearanceConfig } from "../../../../../shared/constants/footprint";
import { NotificationTemplate } from "../index";

export const TaxFailed: NotificationTemplate<never> = {
  renderTitle: props => props.subject,
  renderBody: props => props.message,
  buttonActionAsync: () => async () => {
    try {
      const missingData = await getVerificationMissingData("Tax");

      if (missingData.completeOnboardingToken) {
        const component = footprint.init({
          kind: FootprintComponentKind.Verify,
          variant: "modal",
          authToken: missingData.completeOnboardingToken,
          onComplete: () => {
            queryCache.invalidateQueries(QUERY_VERIFICATIONS);
            queryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
          },
          options: {
            showLogo: false
          },
          appearance: footprintAppearanceConfig
        });
        component.render();
      } else {
        throw new Error("No completeOnboardingToken  provided");
      }
    } catch (error) {
      console.error("Error while complete verificaiton", error);
    }
  },
  buttonText: () => "Complete verification"
};
