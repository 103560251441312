import { WSElementProps, WSPill } from "@wingspanhq/fe-component-library";
import { Form1099Status } from "@wingspanhq/payments/dist/interfaces";
import React from "react";

export interface FilingStatusProps extends WSElementProps {
  status: Form1099Status;
  isCorrection: boolean;
}

export const TaxFormStatusBadge: React.FC<FilingStatusProps> = ({
  status,
  isCorrection,
  ...props
}) => {
  switch (status) {
    case Form1099Status.NeedsActionInfo:
      return <WSPill theme="warning" icon text="Needs action" {...props} />;
    case Form1099Status.NeedsActionDispute: {
      const prefix = isCorrection ? "Correction" : "Change";

      return (
        <WSPill theme="warning" icon text={`${prefix} suggested`} {...props} />
      );
    }
    case Form1099Status.Ready: {
      const prefix = isCorrection ? "Correction" : "Form";

      return <WSPill theme="blue" text={`${prefix} ready`} {...props} />;
    }
    case Form1099Status.Excluded:
      return <WSPill text="Form excluded" {...props} />;
    case Form1099Status.Submitted:
    case Form1099Status.Accepted:
    case Form1099Status.Rejected:
    default: {
      const prefix = isCorrection ? "Correction" : "Form";

      return <WSPill text={`${prefix} ${status.toLowerCase()}`} {...props} />;
    }
  }
};
