import {
  WSButton,
  WSControl,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MIGRATION_BASE_PATH } from "..";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { useQueryVerificationMissingData } from "../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { useWSAnalytics } from "../../../utils/WSAnalytics";
import { Preview } from "../../CIP/components/Confirmation/Preview";
import { Form } from "../../CIP/components/Form";
import { FormData } from "../../CIP/types";
import { Layout } from "../../Onboarding/components/Layout";
import { getUserFormData } from "../utils/getUserFormData";
import { migrate } from "../utils/migrate";

export const RouteVerify = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const queryMissingData = useQueryVerificationMissingData("Banking");

  const [formData, setFormData] = useState<FormData | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [consent, setConsent] = useState(false);

  const analytics = useWSAnalytics();

  useEffect(() => {
    if (!formData && queryUser.data && queryMember.data) {
      setFormData(getUserFormData(queryUser.data, queryMember.data));
    }
  }, [formData, queryMember.data, queryUser.data]);

  const [submit, meta] = useWSMutation(
    async () => {
      const email = queryUser.data?.email;
      const phone = queryUser.data?.phone.number;

      if (!formData || !email || !phone) throw new Error("Invalid data");

      await migrate(formData, email, phone);
    },
    {
      onSuccess: () => {
        analytics.track.other("Banking Migration Completed", {
          migrationType: "migrateAccountToLead"
        });
        history.push(MIGRATION_BASE_PATH + "/verify-success");
      }
    }
  );

  if (isEdit) {
    return (
      <Layout
        title="Banking service updates"
        onClose={() => {
          history.push("/member");
        }}
      >
        <WSPanel>
          <Form
            defaultValues={formData || undefined}
            onCancel={() => {
              setIsEdit(false);
            }}
            onSubmit={newData => {
              setFormData(newData);
              setIsEdit(false);
            }}
            ssnProvided
          />
        </WSPanel>
      </Layout>
    );
  }

  return (
    <Layout
      title="Banking service updates"
      onClose={() => {
        history.push("/member");
      }}
    >
      <WSList gap="L">
        <WSText.Paragraph weight="medium">
          Account Migration to Lead Bank and Information Verification
        </WSText.Paragraph>

        <WSText.ParagraphSm color="gray600">
          You've chosen to migrate your account to Lead Bank Please review the
          migration details and verify your information:
        </WSText.ParagraphSm>

        <WSPanel>
          <WSInfoBox transparent title="Migration details:">
            <ol>
              <li>
                <b>Account changes:</b>
                <ul>
                  <li>Your account number and routing number will change</li>
                  <li>
                    New details will be provided on the day of your migration
                  </li>
                </ul>
              </li>

              <li>
                <b>Debit Card:</b>
                <ul>
                  <li>
                    Your current card will be deactivated at the time of
                    migration
                  </li>
                  <li>
                    A new card will be available to order by the end of August
                  </li>
                </ul>
              </li>

              <li>
                <b>Scheduled Transactions:</b>
                <ul>
                  <li>
                    Any recurring or scheduled transactions will be
                    automatically updated
                  </li>
                </ul>
              </li>

              <li>
                <b>Account History:</b>
                <ul>
                  <li>
                    Your complete transaction history and statements will
                    transfer to your new account
                  </li>
                </ul>
              </li>

              <li>
                <b>Online Banking:</b>
                <ul>
                  <li>
                    You'll see the new bank reflected in your Wingspan interface
                    immediately after migration
                  </li>
                </ul>
              </li>

              <li>
                <b>FDIC Insurance:</b>
                <ul>
                  <li>Your funds remain insured throughout the transition</li>
                </ul>
              </li>
            </ol>
          </WSInfoBox>
        </WSPanel>

        <WSPanel>
          <WSInfoBox transparent title="Choosing your upgrade time:" mb="L">
            <ul>
              <li>
                You can complete the upgrade process between July 23 and July
                30.
              </li>
              <li>
                If you finish before 1pm ET on any day, we'll upgrade your
                account after 2pm ET the same day. If you complete the process
                after 1pm ET, we'll upgrade your account after 2pm ET the
                following day.
              </li>
            </ul>
          </WSInfoBox>

          <WSInfoBox transparent title="On your upgrade day:">
            <ul>
              <li>
                After 2pm ET, we'll transition you to your new account. You'll
                receive your new account details right away.
              </li>
              <li>
                From 2pm ET until 1am ET the next day, we'll be setting up your
                account. Your full account access will be restored by 1am ET,
                often sooner.
              </li>
            </ul>
          </WSInfoBox>
        </WSPanel>

        <WSText mt="2XL" weight="medium">
          Verify your information:
        </WSText>
        <WSText.ParagraphSm color="gray500">
          Federal law requires us to have your current information. Please
          confirm your tax information.
        </WSText.ParagraphSm>

        {formData && (
          <Preview
            data={formData}
            onEdit={() => {
              setIsEdit(true);
            }}
            ssnProvided
          />
        )}

        <WSPanel>
          <WSList gap="L">
            <WSInfoBox
              transparent
              title="To complete the migration, you must review and accept:"
            >
              <ul>
                <li>
                  <a
                    href="https://www.wingspan.app/banking-disclosures/business-deposit-account-agreement"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lead Bank Deposit Account Agreement
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lead.bank/privacy-and-terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lead Bank Privacy Policy & Terms
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wingspan.app/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wingspan Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wingspan.app/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wingspan Privacy Policy
                  </a>
                </li>
              </ul>
            </WSInfoBox>

            <WSInfoBox>
              These agreements reflect your new banking relationship with Lead
              Bank and any updates to Wingspan's services.
              <br />
              <br />
              This change is made pursuant to the terms of your current account
              agreement. Wingspan is not a bank. Banking services are provided
              by Lead Bank, member FDIC.
            </WSInfoBox>

            <WSControl
              type="checkbox"
              value={consent}
              onChange={setConsent}
              size="S"
              label={`By selecting "Accept All Agreements," you acknowledge that you have read and agree to be bound by these terms.`}
            />
          </WSList>
        </WSPanel>

        <WSErrorMessage
          contextKey="CIP"
          error={meta.error}
          forceShowApiErrors
        />

        <WSButton
          fullWidth
          disabled={!consent}
          onClick={submit}
          loading={meta.isLoading}
        >
          Continue
        </WSButton>
      </WSList>
    </Layout>
  );
};
