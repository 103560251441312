import {
  useIsMobile,
  useModalContext,
  WSDivider,
  WSInfiniteScroll,
  WSPage,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText
} from "@wingspanhq/fe-component-library";
import flatten from "lodash/flatten";
import React, { useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useAllCollaboratorGroupsQuery } from "../../../query/payments/queries";
import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import {
  mapAndRevertParamsSortToTable,
  mapAndRevertTableSortToParams
} from "../../../utils/tableSortingHelpers";
import { CollaboratorGroupDetails } from "./CollaboratorGroupDetails";
import {
  ADD_COLLABORATOR_GROUP_MODAL,
  AddCollaboratorGroupModal
} from "./modals/AddCollaboratorGroupModal";
import { DeleteCollaboratorGroupModal } from "./modals/DeleteCollaboratorGroupModal";
import {
  UPDATE_COLLABORATOR_GROUP_MODAL,
  UpdateCollaboratorGroupModal
} from "./modals/UpdateCollaboratorGroupModal";

export const InvoicesCollaboratorsGroups: React.FC = () => {
  const { openModal } = useModalContext();
  const isMobile = useIsMobile();
  const history = useHistory();
  const [sortParams, setSortParams] = useState<
    Parameters<typeof useAllCollaboratorGroupsQuery>[0]
  >({ updatedAt: "desc" } as const);
  const qGroups = useAllCollaboratorGroupsQuery(sortParams);
  const featureFlagsQuery = useFeatureFlags();

  const groups = flatten(qGroups.data || []);

  const tableData = groups.map(group => {
    return {
      data: {
        name: group.name,
        description: group.description
      },
      id: group.collaboratorGroupId
    };
  });

  const onNameSort = () => {
    setSortParams(prevState => ({
      name: mapAndRevertTableSortToParams(prevState?.name)
    }));
  };

  const onDescriptionSort = () => {
    setSortParams(prevState => ({
      description: mapAndRevertTableSortToParams(prevState?.description)
    }));
  };

  const columns: Array<WSTableColumn> = [
    {
      config: {
        gridTemplateSizeMax: "0.5fr",
        sortDirection: mapAndRevertParamsSortToTable(sortParams.name),
        onColumnSort: onNameSort,
        header: { text: "Name" }
      },
      renderFunction: row => <WSTableCell text={row.data.name} />
    },
    {
      config: {
        gridTemplateSizeMax: "1fr",
        sortDirection: mapAndRevertParamsSortToTable(sortParams.description),
        onColumnSort: onDescriptionSort,
        header: { text: "Description" }
      },
      renderFunction: row => <WSTableCell text={row.data.description} />
    }
  ];

  return (
    <>
      <Route
        path="/member/invoices/contacts/collaborators/groups/:collaboratorGroupId"
        component={CollaboratorGroupDetails}
      />
      <AddCollaboratorGroupModal />
      <UpdateCollaboratorGroupModal />
      <DeleteCollaboratorGroupModal />
      <WSPage
        title="Groups"
        primaryButton={{
          label: "Add group",
          name: "addGroup",
          onClick: () => openModal(ADD_COLLABORATOR_GROUP_MODAL)
        }}
      >
        <>
          {isMobile && <WSDivider type="expand" my="XL" />}

          <WSInfiniteScroll
            onLoad={() => {
              qGroups.fetchMore();
            }}
            loadMore={groups.length > 0}
            endOfList={!qGroups.canFetchMore}
            loading={!!qGroups.isFetchingMore}
          >
            <WSTable
              mt="2XL"
              bottomMessage={
                qGroups.isFetched && tableData.length === 0 ? (
                  <WSText>
                    No groups yet.{" "}
                    <WSPersistentUpgradeButton
                      feature={WSFrontendFeature.InviteAndPayCollaborator}
                      kind="Link"
                      onClick={() => {
                        openModal(ADD_COLLABORATOR_GROUP_MODAL);
                      }}
                    >
                      Add group
                    </WSPersistentUpgradeButton>
                  </WSText>
                ) : (
                  ""
                )
              }
              rowMenuActions={rowItem => [
                {
                  label: "Edit",
                  onClick: () => {
                    openModal(UPDATE_COLLABORATOR_GROUP_MODAL, {
                      id: rowItem.id,
                      name: rowItem.data.name,
                      description: rowItem.data.description
                    });
                  }
                }
                // {
                //   label: "Delete",
                //   onClick: () => {
                //     openModal(DELETE_COLLABORATOR_GROUP_MODAL, {
                //       id: rowItem.id,
                //       name: rowItem.data.name
                //     });
                //   }
                // }
              ]}
              columns={columns}
              tableData={tableData}
              onRowClick={({ id }) => {
                history.push(
                  "/member/invoices/contacts/collaborators/groups/" + id
                );
              }}
            />
          </WSInfiniteScroll>
        </>
      </WSPage>
    </>
  );
};
