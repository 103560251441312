import {
  WSActions,
  WSButton,
  WSControlGroup,
  WSForm,
  WSFormProps,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { COUNTRY_OPTIONS } from "../../../../constants/user";
import { getEntityTypeLabel } from "../../helpers/entityType";
import { FormData } from "../../types";
import { AccountHolderFields } from "./AccountHolderFields";
import { BusinessFields } from "./BusinessFields";
import { defaultValues as emptyDefaultValues } from "./defaultValues";
import { getValidationSchema } from "./validationSchema";
import { openIntercom } from "../../../../shared/utils/intercom";

type Props = {
  disabledEditAccountType?: boolean;
  defaultValues?: WSFormProps<FormData>["defaultValues"];
  onSubmit?: WSFormProps<FormData>["onSubmit"];
  onCancel?: () => void;
  ssnProvided?: boolean;
};

export const Form: React.FC<Props> = ({
  onSubmit,
  disabledEditAccountType,
  onCancel,
  defaultValues,
  ssnProvided
}) => {
  return (
    <WSForm<FormData>
      defaultValues={{
        ...emptyDefaultValues,
        ...defaultValues
      }}
      validationSchema={getValidationSchema({ ssnProvided })}
      onSubmit={onSubmit}
    >
      {formContext => (
        <WSList gap="XL">
          <WSPanel>
            <WSSectionToolbar title="Account type" mb="L" />
            <WSList gap="2XL">
              <WSForm.Field
                name="country"
                onChange={(value: string | null) => {
                  formContext.setValue("business.address.country", value);
                  formContext.setValue("accountHolder.address.country", value);
                }}
                component={WSSelect}
                label="Country of operation"
                componentProps={{
                  required: true,
                  disabled: disabledEditAccountType,
                  internalSearch: true,
                  options: COUNTRY_OPTIONS
                }}
              />
              <WSForm.Value name="country">
                {country => (
                  <WSForm.Field
                    name="type"
                    component={WSControlGroup}
                    componentProps={{
                      required: true,
                      type: "radio",
                      disabled: disabledEditAccountType,
                      chip: true,
                      options: [
                        {
                          label: getEntityTypeLabel("Business", country),
                          value: "Business",
                          tooltip:
                            "Your entity information will be used on invoices and tax documentation"
                        },
                        {
                          label: getEntityTypeLabel("Individual", country),
                          value: "Individual",
                          tooltip:
                            "Your personal information will be used on invoices and tax documentation"
                        }
                      ]
                    }}
                  />
                )}
              </WSForm.Value>

              {disabledEditAccountType ? (
                <WSInfoBox>
                  Once the account creation process has started, the account
                  type settings cannot be changed. If you need to modify your
                  selection, please{" "}
                  <WSButton.Link size="M" onClick={openIntercom}>
                    contact support.
                  </WSButton.Link>
                </WSInfoBox>
              ) : (
                <WSInfoBox>
                  Once the account creation process has started, the account
                  type settings cannot be changed.
                </WSInfoBox>
              )}
            </WSList>
          </WSPanel>

          <WSForm.Value name="type">
            {type => (
              <>
                <BusinessFields hidden={!type || type !== "Business"} />
                <AccountHolderFields ssnProvided={ssnProvided} />
              </>
            )}
          </WSForm.Value>

          <WSActions
            orientation="horizontal"
            alignment="fill"
            buttons={[
              {
                kind: "Secondary",
                label: "Cancel",
                onClick: onCancel,
                visible: !!onCancel
              },
              {
                label: "Continue",
                type: "submit"
              }
            ]}
          />
          <WSText.ParagraphXs color="gray400">
            The information on this page is required to setup your Wingspan
            account, if you exit or leave without completing this step, you will
            return to this page the next time you sign-in
          </WSText.ParagraphXs>
        </WSList>
      )}
    </WSForm>
  );
};
