import { FootprintAppearance } from "@onefootprint/footprint-js";

export const footprintAppearanceConfig: FootprintAppearance = {
  theme: "light",
  variables: {
    containerWidth: "580px",
    borderRadius: "2px",
    colorAccent: "#0284c7",
    colorError: "#b91c1c",
    borderColorError: "#b91c1c",
    buttonPrimaryBg: "#0284c7",
    buttonPrimaryHoverBg: "#0369a1",
    buttonPrimaryActiveBg: "#0c4a6e",
    buttonPrimaryColor: "#FFF",
    buttonPrimaryBorderColor: "#0284c7",
    labelColor: "#9ca3af",
    inputBorderWidth: "1px",
    inputPlaceholderColor: "#9ca3af",
    inputColor: "#374151",
    inputBg: "#FFFFFF",
    inputBorderColor: "#d1d5db",
    inputFocusBorderColor: "#0284c7",
    inputFocusElevation: "0 0 0 0.25rem #bae6fd",
    inputErrorBorderColor: "#b91c1c",
    inputErrorElevation: "none",
    inputErrorFocusElevation: "0 0 0 0.25rem #fecaca",
    hintColor: "#b91c1c"
  },
  rules: {
    button: {
      padding: "8px 16px",
      height: "36px"
    }
  }
};
