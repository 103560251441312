import {
  useModalContext,
  WSButton,
  WSButtons,
  WSIcon,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { useAdvocate, useMemberProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { useHistory } from "react-router";

export const BOOKKEEPING_TROUBLE_WITH_SYNCING =
  "BOOKKEEPING_TROUBLE_WITH_SYNCING";

export const TroubleWithSyncing: React.FC = () => {
  const userId = useUserId();
  const { closeModal } = useModalContext();
  const history = useHistory();
  const qMember = useMemberProfile(userId);
  const qAdvocate = useAdvocate(qMember.data?.advocateId as string, {
    enabled: qMember.isFetched
  });

  return (
    <WSModal name={BOOKKEEPING_TROUBLE_WITH_SYNCING} size="XS">
      {({ institution }) => (
        <WSQueries queries={{ qMember, qAdvocate }}>
          {({ qAdvocate }) => (
            <>
              <WSText.Heading5 mb="M">
                <WSIcon
                  block
                  name="alert-circle"
                  size="M"
                  mr="M"
                  color="red500"
                />
                We’re having trouble syncing with {institution}
              </WSText.Heading5>
              <WSText mb="M">
                This is likely a temporary connection issue. Your member
                advocate, {qAdvocate.data.firstName}, will reach out soon to
                help.
              </WSText>
              <WSButtons mt="2XL" format="modal">
                <WSButton.Primary
                  data-testid="tryOtherImportMethod"
                  onClick={() => {
                    closeModal(BOOKKEEPING_TROUBLE_WITH_SYNCING);
                  }}
                >
                  Try other import method
                </WSButton.Primary>
              </WSButtons>
            </>
          )}
        </WSQueries>
      )}
    </WSModal>
  );
};
