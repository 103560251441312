import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFormOld,
  WSModal,
  WSTextArea,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdateCollaboratorsGroup } from "../../../../query/payments/mutations";

type Props = WSElementProps;

export const UPDATE_COLLABORATOR_GROUP_MODAL =
  "UPDATE_COLLABORATOR_GROUP_MODAL";

export const UpdateCollaboratorGroupModal: React.FC<Props> = () => {
  const { closeModal } = useModalContext();

  const [updateGroup, updateGroupMeta] = useUpdateCollaboratorsGroup();

  return (
    <WSModal name={UPDATE_COLLABORATOR_GROUP_MODAL} size="S" title="Edit group">
      {props => (
        <WSFormOld
          defaultValues={{
            name: props.name,
            description: props.description
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            description: Yup.string().required("Description is required")
          })}
          onSubmit={async formData => {
            await updateGroup(
              {
                id: props.id,
                name: formData.name,
                description: formData.description
              },
              {
                onSuccess() {
                  closeModal(UPDATE_COLLABORATOR_GROUP_MODAL);
                }
              }
            );
          }}
        >
          <WSFormOld.Field
            mb="XL"
            label="Name"
            name="name"
            component={WSTextInput}
          />

          <WSFormOld.Field
            mb="XL"
            label="Description"
            name="description"
            component={WSTextArea}
          />

          <WSErrorMessage
            mt="XL"
            error={updateGroupMeta.error}
            contextKey="UpdateCollaboratorsGroup"
          />

          <WSButtons mt="3XL" forceFullWidth>
            <WSFormOld.SubmitButton data-testid="submit">
              Update group
            </WSFormOld.SubmitButton>
            <WSButton.Tertiary
              onClick={() => closeModal(UPDATE_COLLABORATOR_GROUP_MODAL)}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </WSFormOld>
      )}
    </WSModal>
  );
};
