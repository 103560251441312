import isEqual from "lodash/isEqual";
import React, { useCallback, useImperativeHandle, useMemo } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSChip, WSPill } from "../common";
import { WSText } from "../core";
import { WSButton } from "../core/WSButton/WSButton.component";
import { useIsMobile } from "../layout";
import { ActiveFilters, useActiveFilters } from "./ActiveFilters";
import { Sidebar } from "./Sidebar";
import styles from "./WSFilters.module.scss";
import { SidebarType, WSFiltersProps, WSFiltersRef, WSFilterValue, WSFilterValues } from "./types";
import { useInternalState } from "./useInternalState";
import { hasValues } from "./utils/hasValues";

export * from "./types";
export * from "./utils/dateRange";

export const WSFilters = React.forwardRef<WSFiltersRef, WSFiltersProps>(
  (props, ref) => {
    const {
      primaryFilters: _primaryFilters,
      secondaryFilters: _secondaryFilters,
      quickFilters: _quickFilters,
      primaryFiltersButton,
      secondaryFiltersButton,
      className,
      openedByDefault,
      searchable,
      onOpen,
      onClose,
      onClear,
      onFilter,
      onChange,
      values,
      info,
      ...otherProps
    } = props;

    const isMobile = useIsMobile();

    const {
      primaryFilters,
      secondaryFilters,
      quickFilters,
      primaryButton,
      secondaryButton
    } = useInternalState({
      _primaryFilters,
      _secondaryFilters,
      _quickFilters,
      primaryFiltersButton,
      secondaryFiltersButton,
      values
    });

    const [openedSidebar, setOpenedSidebar] = React.useState<
      SidebarType | undefined
    >(openedByDefault);

    useImperativeHandle(
      ref,
      () => ({
        open: (type: SidebarType) => {
          setOpenedSidebar(type);
          onOpen?.(type);
        },
        close: () => {
          setOpenedSidebar(undefined);
          onClose?.();
        }
      }),
      [onOpen, onClose]
    );

    const handleSubmit = useCallback(
      (newFilterValue: WSFilterValues) => onFilter(newFilterValue),
      [onFilter]
    );

    const handleClear = useCallback(() => {
      onChange?.({});
      onFilter({});
    }, [onChange, onFilter]);

    const handleFilterChange = useCallback(
      (name: string, newValue?: WSFilterValue) => {
        const newValues = { ...values, [name]: newValue };

        onFilter(newValues);
        onChange?.(newValues);
      },
      [onChange, onFilter, values]
    );

    const showClearButton = useMemo(() => hasValues(values), [values]);

    const activePrimaryFilters = useActiveFilters(values, primaryFilters);
    const activeSecondaryFilters = useActiveFilters(values, secondaryFilters);

    return (
      <>
        <WSElement {...otherProps}>
          <WSElement className={styles.navigation}>
            <WSElement className={styles.navigationButtons}>
              {primaryFilters.length ? (
                <WSButton.Tertiary
                  badgeNumber={activePrimaryFilters.length || undefined}
                  type="button"
                  rightIcon="chevron-down"
                  size="S"
                  onClick={() => {
                    setOpenedSidebar("primary");
                    onOpen?.("primary");
                  }}
                >
                {primaryButton}
                </WSButton.Tertiary>) : null}

              {secondaryFilters.length ? (
                <WSButton.Tertiary
                  badgeNumber={activeSecondaryFilters.length || undefined}
                  rightIcon="chevron-down"
                  type="button"
                  size="S"
                  onClick={() => {
                    setOpenedSidebar("secondary");
                    onOpen?.("secondary");
                  }}
                >
                  {secondaryButton}
                </WSButton.Tertiary>
              ) : null}

              {!isMobile && showClearButton && (
                <WSButton.Secondary
                  size="S"
                  type="button"
                  rightIcon="exit"
                  onClick={handleClear}
                >
                  Clear filters
                </WSButton.Secondary>
              )}
            </WSElement>

            {quickFilters?.length ? (
              <WSElement className={styles.navigationQuickFilters}>
                <WSButton.Link
                  size="S"
                  type="button"
                  icon="instant-circle-fill"
                  onClick={() => {
                    setOpenedSidebar("quick");
                    onOpen?.("quick");
                  }}
                >
                  Quick filters
                </WSButton.Link>
                {quickFilters.map((quickFilter, index) => (
                  <WSPill
                    key={index}
                    text={(quickFilter.shortLabel !== undefined ? quickFilter.shortLabel : quickFilter.label) as string}
                    type="light"
                    outline={isEqual(values, quickFilter.preset)}
                    onClick={() => {
                      handleSubmit(quickFilter.preset);
                    }}
                    icon={quickFilter.icon}
                    theme={quickFilter.theme}
                  />
                ))}
              </WSElement>
            ) : null}

            {info ? (
              <WSElement className={styles.navigationInfo}>
                <WSText.ParagraphXs color="gray500">
                  Showing:
                </WSText.ParagraphXs>
                <WSChip
                  pillText={`${info.count} items`}
                  theme="neutral"
                  rightText={info.amount}
                  pillType="noBackground"
                />
              </WSElement>
            ) : null}
          </WSElement>

          {!isMobile && (
            <ActiveFilters
              activeFilters={[
                ...activePrimaryFilters,
                ...activeSecondaryFilters
              ]}
              values={values}
              onFilterChange={handleFilterChange}
              showHideButton
            />
          )}
        </WSElement>

        {quickFilters?.length && openedSidebar === "quick" ? (
          <Sidebar
            quickFilters={quickFilters}
            filters={[...primaryFilters, ...secondaryFilters]}
            values={values}
            onChange={onChange}
            onClose={() => {
              setOpenedSidebar(undefined);
              onClose?.();
            }}
            onFilter={handleSubmit}
          />
        ) : null}

        {secondaryFilters?.length && openedSidebar === "secondary" ? (
          <Sidebar
            filters={secondaryFilters}
            values={values}
            onChange={onChange}
            onClose={() => {
              setOpenedSidebar(undefined);
              onClose?.();
            }}
            onFilter={handleSubmit}
          />
        ) : null}

        {openedSidebar === "primary" && (
          <Sidebar
            filters={primaryFilters}
            values={values}
            onChange={onChange}
            onClose={() => {
              setOpenedSidebar(undefined);
              onClose?.();
            }}
            searchable={searchable}
            onFilter={handleSubmit}
          />
        )}
      </>
    );
  }
) as (<V extends WSFilterValues>(
  p: WSFiltersProps<V> & { ref?: React.Ref<WSFiltersRef> }
) => React.ReactElement) & { displayName: string };

WSFilters.displayName = "WSFilters";
