import React from "react";
import { Moment } from "moment/moment";
import { useIsDesktop } from "@wingspanhq/fe-component-library";

export const NextPaymentInfo: React.FC<{
  deadlineFromNow: string;
  currentDueDate: Moment;
}> = ({ deadlineFromNow, currentDueDate }) => {
  const isMobile = !useIsDesktop();

  const isFuture = currentDueDate.isAfter(new Date(), "day");
  const isToday = currentDueDate.isSame(new Date(), "day");

  const diff = isFuture
    ? ` due in ${deadlineFromNow} `
    : isToday
    ? " today "
    : ` was due ${deadlineFromNow} ago`;

  return (
    <>
      Next quarterly payment{diff} {isMobile ? <br /> : null} (
      <b>{currentDueDate.format("MMM DD")}</b>).
    </>
  );
};
