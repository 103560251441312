import { MutationConfig } from "react-query";
import { useWSMutation } from "../../../query/helpers";
import {
  IInvitation,
  INewUser,
  IUserCreateRequest,
  UserAccountType
} from "@wingspanhq/users/dist/lib/interfaces";

import {
  useCreateSession,
  useCreateUser,
  useIdentifyAuthentication
} from "../../../query/users/mutations";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSStore } from "../../../store";
import { useHistory } from "react-router-dom";
import { IInvitationResponse } from "@wingspanhq/users";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { useQueryAcknowledgement } from "../../../query/onboarding/queries/useQueryAcknowledgement";
import { useMutationUpdateAcknowledgement } from "../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { acknowledgementVersions } from "../../../shared/constants/acknowledgements";
import { operations } from "../../../services/api/onboarding/types";

export type SignUpFormData = {
  email: string;
  password: string;
  remember: boolean;
};

type MutationResponse = INewUser;
type MutationError =
  | WSServiceError
  | { message: string; url: string; domain: string };

export const useSubmitSignUpForm = (
  invitationData: IInvitationResponse,
  invitation: IInvitation,
  config?: MutationConfig<MutationResponse, MutationError, SignUpFormData>
) => {
  const history = useHistory();
  const [identifyAuth] = useIdentifyAuthentication();
  const [createUser] = useCreateUser();
  const [createSession] = useCreateSession();

  const store = useWSStore();

  const queryWingspanTosAcceptanceAcknowledgement = useQueryAcknowledgement(
    "WingspanTosAcceptance"
  );
  const queryWingspanPrivacyPolicyAcceptanceAcknowledgement = useQueryAcknowledgement(
    "WingspanPrivacyPolicyAcceptance"
  );
  const queryElectronicDisclosureAndConsentAcknowledgement = useQueryAcknowledgement(
    "ElectronicDisclosureAndConsent"
  );

  const [updateAcknowledgement] = useMutationUpdateAcknowledgement();

  return useWSMutation<MutationResponse, MutationError, SignUpFormData>(
    async data => {
      return new Promise<MutationResponse>(async (resolve, reject) => {
        const request: IUserCreateRequest & {
          context?: string;
          inviteCode?: string;
          isPublicSignUp?: boolean;
        } = {
          ...data,
          invitationToken: invitation.invitationToken,
          notificationSettings: {
            reviewNotifications: true,
            newsletters: true
          },
          context: "PublicSignUp",
          ...(invitationData.context === PlatformContextType.Payee
            ? {
                context: "EmployerSignUp",
                settings: { userAccountType: UserAccountType.enterprise }
              }
            : {})
        };

        try {
          const identifyResult = await identifyAuth(
            { email: request.email },
            {
              throwOnError: true
            }
          );

          if (identifyResult?.authenticationStrategy === "SingleSignOn") {
            reject({
              message: "SingleSignOn",
              url: identifyResult.authenticationUrl || "",
              domain: request.email.split("@").pop() || ""
            });
            return;
          }
        } catch {}

        return await createUser(request, {
          onSuccess: async user =>
            await createSession(
              {
                ...data
              },
              {
                onSuccess: async () => {
                  resolve(user);

                  // Check if the user has not accepted the below acknowledgements
                  if (
                    queryWingspanTosAcceptanceAcknowledgement.data
                      ?.acknowledgementStatus !== "Given" ||
                    queryWingspanPrivacyPolicyAcceptanceAcknowledgement.data
                      ?.acknowledgementStatus !== "Given" ||
                    queryElectronicDisclosureAndConsentAcknowledgement.data
                      ?.acknowledgementStatus !== "Given"
                  ) {
                    // set the acknowledgement for the below
                    // 1. WingspanTosAcceptance
                    // 2. WingspanPrivacyPolicyAcceptance
                    // 3. ElectronicDisclosureAndConsent
                    const consents: operations["getAcknowledgement"]["parameters"]["path"]["acknowledgementName"][] = [
                      "WingspanTosAcceptance",
                      "WingspanPrivacyPolicyAcceptance",
                      "ElectronicDisclosureAndConsent"
                    ];
                    await Promise.all(
                      consents.map(async consent => {
                        updateAcknowledgement({
                          acknowledgementName: consent,
                          requestData: {
                            acknowledgementName: consent,
                            acknowledgementStatus: "Given",
                            version: acknowledgementVersions[consent]
                          }
                        });
                      })
                    );
                  }
                },
                onError: reject
              }
            ),
          onError: async error => {
            if (
              error.response?.status === 401 &&
              error.response?.data?.errorSubType === "EmailVerificationRequired"
            ) {
              history.push({
                pathname: "/member/verification-sent-to-email",
                search: history.location.search,
                state: {
                  email: request.email,
                  growthSource: store.growthAttributionDetails?.growthSource
                }
              });
              return;
            }

            reject(error);
          }
        });
      });
    },
    config
  );
};
