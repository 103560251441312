import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { queryCache, ReactQueryMutationsConfig } from "react-query";
import { filesService } from "../../services/files";
import { downloadFileFromBuffer } from "../../utils/files";
import { helloSignClient, helloSignPromise } from "../../utils/helloSign";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSMutation } from "../helpers";
import {
  QUERY_COLLABORATOR,
  QUERY_COLLABORATORS,
  QUERY_MEMBER_CLIENT,
  QUERY_MEMBER_CLIENTS
} from "../payments/keys";
import {
  QUERY_FILES_MEMBER_PRIVATE_FILES,
  QUERY_FILES_MEMBER_PUBLIC_FILES
} from "./keys";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../notifications/keys";
import { paymentsService } from "../../services/payments";
import { QUERY_PAYER_ROWS_ALL } from "../search/payer/queries/useQueryPayerRowsAll";
import { QUERY_PAYER_ROWS } from "../search/payer/queries/useQueryPayerRows";
import { QUERY_PAYER } from "../payers/keys";
import { QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS } from "../../modules/Onboarding/queries/useQueryAllPayersWithEngagements";

export const useMemberPrivateCreate = () =>
  useWSMutation(filesService.member.private.create, {
    dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
  });

export const useMemberPrivateHiddenCreate = () =>
  useWSMutation(
    (formData: FormData) => filesService.member.private.create(formData, true),
    {
      dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
    }
  );

export const useMemberPrivateFileCreate = (
  params: Partial<{
    hidden: boolean;
    tags: string[];
    viewerIds: string[];
  }>
) =>
  useWSMutation(
    (formData: FormData) =>
      filesService.member.private.create(
        formData,
        params.hidden,
        params.tags,
        params.viewerIds
      ),
    {
      dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
    }
  );

export const useMemberPublicCreate = () =>
  useWSMutation(filesService.member.public.create, {
    dependencies: [QUERY_FILES_MEMBER_PUBLIC_FILES]
  });

export const useMemberPublicDelete = () =>
  useWSMutation(filesService.member.public.delete, {
    dependencies: [QUERY_FILES_MEMBER_PUBLIC_FILES]
  });

export const useMemberPrivateDelete = () =>
  useWSMutation(filesService.member.private.delete, {
    dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
  });

export const useSignDocument = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    any,
    WSServiceError,
    { documentId?: string; memberClientId?: string }
  >(
    async ({ documentId, memberClientId }) => {
      if (!documentId) {
        throw new Error("Document id is not provided");
      }

      const document = await filesService.document.getSigningUrls(documentId);

      const signUrl = document.signingUrls?.member?.url;

      if (signUrl) {
        helloSignClient.open(signUrl);

        const hsStatus = await helloSignPromise();
        if (hsStatus === "signed") {
          // Force api to refetch data from hello sign
          await filesService.document.getSigningUrls(documentId);

          openSnackbar({
            icon: {
              name: "check"
            },
            message: "Document signed"
          });
        }

        if (memberClientId) {
          await paymentsService.memberClient.get(memberClientId);
        }
      } else {
        throw new Error("No sign url on a document");
      }
    },
    {
      awaitDependencies: [QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS],
      onSuccess: (_, { memberClientId }) => {
        queryCache.invalidateQueries(QUERY_MEMBER_CLIENTS);
        queryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
        queryCache.invalidateQueries(QUERY_PAYER_ROWS_ALL);
        queryCache.invalidateQueries(QUERY_PAYER_ROWS);

        if (memberClientId) {
          queryCache.invalidateQueries([QUERY_PAYER, memberClientId]);
          queryCache.invalidateQueries([QUERY_MEMBER_CLIENT, memberClientId]);
        }
      },
      onError: () => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
      }
    }
  );
};

export const useCountersignDocument = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    any,
    WSServiceError,
    { documentId?: string; collaboratorId?: string }
  >(
    async ({ documentId, collaboratorId }) => {
      if (!documentId) {
        throw new Error("Document id is not provided");
      }

      const document = await filesService.document.getSigningUrls(documentId);

      const signUrl = document.signingUrls?.client?.url;

      if (signUrl) {
        helloSignClient.open(signUrl);

        const hsStatus = await helloSignPromise();
        if (hsStatus === "signed") {
          // Force api to refetch data from hello sign
          await filesService.document.getSigningUrls(documentId);

          openSnackbar({
            icon: {
              name: "check"
            },
            message: "Document signed"
          });
        }
        await filesService.document.getSigningUrls(documentId);
        if (collaboratorId) {
          await paymentsService.collaborator.get(collaboratorId);
        }
      } else {
        throw new Error("No sign url on a document");
      }
    },
    {
      onSuccess: (_, { collaboratorId }) => {
        queryCache.invalidateQueries(QUERY_COLLABORATORS);
        queryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
        if (collaboratorId) {
          queryCache.invalidateQueries([QUERY_COLLABORATOR, collaboratorId]);
        }
      },
      onError: () => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
      }
    }
  );
};

export const useDownloadPrivateDocument = (
  config?: ReactQueryMutationsConfig<ArrayBuffer, WSServiceError>
) =>
  useWSMutation(filesService.member.private.download, {
    ...config
  });

export const useDownloadMemberDocument = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<any, WSServiceError, { documentId: string }>(
    async ({ documentId }) => {
      const documentFiles = await filesService.document.saveFiles(documentId);

      if (!documentFiles.files?.member) {
        throw new Error("No file provided for client");
      }

      const fileId = documentFiles.files.member;
      const file = await filesService.member.private.get(fileId);
      const data = await filesService.member.private.download(fileId);

      downloadFileFromBuffer(data, file.filename, file.mimetype);
    },
    {
      onError: () => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
      },
      onSuccess: () => {
        openSnackbar({
          icon: {
            name: "check"
          },
          message: "Document downloaded"
        });
      }
    }
  );
};

export const useDownloadClientDocument = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<any, WSServiceError, { documentId: string }>(
    async ({ documentId }) => {
      const documentFiles = await filesService.document.saveFiles(documentId);

      if (!documentFiles.files?.client) {
        throw new Error("No file provided for client");
      }

      const fileId = documentFiles.files.client;
      const file = await filesService.member.private.get(fileId);
      const data = await filesService.member.private.download(fileId);

      downloadFileFromBuffer(data, file.filename, file.mimetype);
    },
    {
      onError: () => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
      },
      onSuccess: () => {
        openSnackbar({
          icon: {
            name: "check"
          },
          message: "Document downloaded"
        });
      }
    }
  );
};
