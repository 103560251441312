import React from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from "react-google-login";
import signInWithGoogleIcon from "../../../assets/icons/signInWithGoogleIcon.png";
import { ISignInWithGoogleRequest } from "../../../services/users";
import { Button } from "../Button/Button";
import styles from "./GoogleAuthButton.module.scss";
import cn from "classnames";

export interface GoogleAuthButtonProps {
  onLoginSuccess: (request: ISignInWithGoogleRequest) => void;
  onLoginFailure: (error: any) => void;
  className?: string;
  loginHint?: string;
  wrapClick?: (callback: () => void) => void;
}

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = props => {
  const onLoginSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const request: ISignInWithGoogleRequest = {
      idToken: (response as GoogleLoginResponse).tokenId
    };
    props.onLoginSuccess(request);
  };

  const onLoginFailure = (error: any) => {
    const { error: errorMessage } = error;
    // handle individual error messages
    let passOnError = false;
    // if message is not about idpi or closing the popup, show it
    if (
      !(
        errorMessage === "idpiframe_initialization_failed" ||
        errorMessage === "popup_closed_by_user"
      )
    ) {
      passOnError = true;
    }
    if (passOnError) {
      props.onLoginFailure(error);
    }
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_SIGN_IN_WITH_GOOGLE_CLIENT_ID || ""}
      onSuccess={onLoginSuccess}
      onFailure={onLoginFailure}
      loginHint={props.loginHint}
      render={renderProps => (
        <Button
          kind="Secondary"
          className={cn(styles.base, props.className)}
          type="button"
          onClick={() => {
            if (props.wrapClick) {
              props.wrapClick(renderProps.onClick);
            } else {
              renderProps.onClick();
            }
          }}
          disabled={renderProps.disabled}
        >
          <img src={signInWithGoogleIcon} className={styles.icon} alt="" />
          {props.children}
        </Button>
      )}
    />
  );
};
