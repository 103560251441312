import {
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSForm,
  WSInfoBox,
  WSInputDate,
  WSInputMask,
  WSInputText,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { WS_LINKS } from "../../../../types/wsLinks";
import { canadaQuebecEnterpriseNumberProps } from "../../helpers/canadaQuebecEnterpriseNumber";
import { getTaxIdLabel, getTaxIdMask } from "../../helpers/taxId";
import { useModalWhyWeCollectInfo } from "../ModalWhyWeCollectInfo";
import { AddressFields } from "./AddressFields";
import { OwnershipFields } from "./OwnershipFields";

type Props = { ssnProvided?: boolean } & WSElementProps;

const groupName = "accountHolder";

export const AccountHolderFields: React.FC<Props> = ({
  ssnProvided,
  ...props
}) => {
  const modal = useModalWhyWeCollectInfo();

  return (
    <WSElement {...props}>
      <WSPanel>
        <WSSectionToolbar
          title="Account holder information (You)"
          button={{
            label: "Why do we collect this info?",
            onClick: modal.open
          }}
        />
        <WSText.ParagraphSm color="gray500">
          Wingspan is required to collect this information for compliance
          purposes. Your information is{" "}
          <a href={WS_LINKS.security} target="_blank" rel="noreferrer">
            certified secure
          </a>
          .
        </WSText.ParagraphSm>
        <WSDivider my="L" />

        <WSList gap="XL">
          <WSInfoBox title="">
            Spell your first and last name exactly as shown on your
            government-issued ID.
          </WSInfoBox>

          <WSFlexBox wrap="nowrap" gap="2XL">
            <WSForm.Field
              name={groupName + ".firstName"}
              label="Legal first name"
              component={WSInputText}
              componentProps={{
                required: true,
                placeholder: "Enter first name",
                fsExclude: true
              }}
            />

            <WSForm.Field
              name={groupName + ".lastName"}
              label="Legal last name"
              component={WSInputText}
              componentProps={{
                required: true,
                placeholder: "Enter last name",
                fsExclude: true
              }}
            />
          </WSFlexBox>

          <WSForm.Field
            name={groupName + ".jobTitle"}
            label="Job title / occupation"
            component={WSInputText}
            componentProps={{
              required: true,
              placeholder: "Enter your job title / occupation",
              helperText:
                "Enter the role or description that best describes your work"
            }}
          />

          <WSForm.Value name="type">
            {type => (
              <>
                <OwnershipFields
                  name={groupName + ".ownership"}
                  hidden={type !== "Business"}
                />

                {type === "Business" && <WSDivider my="S" />}
              </>
            )}
          </WSForm.Value>

          <WSForm.Field
            name={groupName + ".birthday"}
            label="Birthday"
            component={WSInputDate}
            componentProps={{
              required: true,
              placeholder: "MM/DD/YYYY"
            }}
          />

          <WSForm.Values names={["type", groupName + ".address.country"]}>
            {values =>
              values.type === "Individual" &&
              values[groupName + ".address.country"] === "US" && (
                <WSInfoBox title="Federal tax classification">
                  Note: This is default classification for individual account
                  types that operate with an SSN. If you have an EIN, please go
                  back and select the business account type
                  <WSText.ParagraphSm weight="medium" mt="XS">
                    Sole proprietorship / Single member LLC
                  </WSText.ParagraphSm>
                </WSInfoBox>
              )
            }
          </WSForm.Values>
          {!ssnProvided && (
            <WSForm.Value name={groupName + ".address.country"}>
              {country =>
                country === "US" || country === "CA" ? (
                  <WSForm.Field
                    key={country + "-tax-id"}
                    name={groupName + ".taxId"}
                    label={getTaxIdLabel("Individual", country)}
                    component={WSInputMask}
                    componentProps={{
                      required: true,
                      placeholder: getTaxIdMask("Individual", country),
                      mask: getTaxIdMask("Individual", country),
                      unmask: true,
                      fsExclude: true
                    }}
                  />
                ) : (
                  <WSForm.Field
                    key={country + "-tax-id"}
                    name={groupName + ".taxId"}
                    label={getTaxIdLabel("Individual", country)}
                    component={WSInputText}
                    componentProps={{
                      required: true,
                      fsExclude: true
                    }}
                  />
                )
              }
            </WSForm.Value>
          )}

          <AddressFields
            name={groupName + ".address"}
            label="Personal address"
          />

          <WSForm.Values
            names={[
              "type",
              groupName + ".address.state",
              groupName + ".address.country"
            ]}
          >
            {values => {
              const type = values.type;
              const country = values[groupName + ".address.country"];
              const state = values[groupName + ".address.state"];

              const isNeqVisible =
                type === "Individual" && country === "CA" && state === "QC";

              return (
                <WSForm.Field
                  hidden={!isNeqVisible}
                  name={groupName + ".canadaQuebecEnterpriseNumber"}
                  label="Québec Enterprise Number (NEQ)"
                  component={WSInputMask}
                  componentProps={canadaQuebecEnterpriseNumberProps}
                />
              );
            }}
          </WSForm.Values>
        </WSList>
      </WSPanel>
    </WSElement>
  );
};
