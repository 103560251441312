import {
  IMemberClient,
  IPayeeTaxFormResponse,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  EmailVerificationStatus,
  IActivity,
  IActivityContextType,
  IGrowthSourceNames,
  INewUser
} from "@wingspanhq/users/dist/lib/interfaces";
import { getHasPendingEligibilityRequirements } from "../../Invoices/utils/setUp";
import { WSStore } from "../../store";
import { GrowthSourceNames } from "../../utils/growthAttribution";

export const getShouldForceToVerifyEmail = (
  user: INewUser,
  memberClients: IMemberClient[]
) => {
  const hasPendingEligibilityRequirements = getHasPendingEligibilityRequirements(
    memberClients
  );
  const isEmailVerified =
    user.emailVerificationState?.status === EmailVerificationStatus.Verified;
  return !isEmailVerified && hasPendingEligibilityRequirements;
};

export const getShouldForceToW9FlowByTaxForms = (
  taxForms?: IPayeeTaxFormResponse[]
) => {
  if (!taxForms || taxForms.length === 0) {
    return false;
  }

  return taxForms.some(taxForm => {
    const isW9InfoConfirmed = taxForm.recipientConfirmedW9Info === true;
    const isPreFiling = [
      TaxFormStatus.Pending,
      TaxFormStatus.NeedsAction,
      TaxFormStatus.ReadyToSubmitToIrs,
      TaxFormStatus.Excluded
    ].includes(taxForm.status);
    return isPreFiling && !isW9InfoConfirmed;
  });
};

export const getShouldForceToW9FlowByActivity = (
  store: WSStore,
  activity?: IActivity
) => {
  const is1099Context =
    activity?.context?.type === IActivityContextType.Form1099;
  const isSignUp1099 = !!store?.signUp1099;
  const isW9ConfirmationInitialStep =
    activity?.flows.w9Confirmation?.currentStep === 1;

  return is1099Context || isSignUp1099 || isW9ConfirmationInitialStep;
};

export const getShouldForceToEnablePayments = (activity?: IActivity) =>
  !activity?.flows.nec1099Setup &&
  !activity?.flows.w9Confirmation &&
  (activity?.context?.growthSource === IGrowthSourceNames.Enterprise ||
    activity?.context?.growthSource === IGrowthSourceNames.Collaborator) &&
  !activity?.flows.paymentsSetup?.complete;

export const getShouldForceTo1099PayerSetup = (activity?: IActivity) => {
  return (
    activity?.flows.nec1099Setup && !activity?.flows.nec1099Setup?.complete
  );
};

export const getCurrentPartnerId = (store: WSStore, activity?: IActivity) =>
  (store.growthAttributionDetails?.growthSource === GrowthSourceNames.Partner &&
    store.growthAttributionDetails?.growthName) ||
  (activity?.context?.growthSource === GrowthSourceNames.Partner &&
    activity.context?.growthSourceName) ||
  "";

export const getCurrentEntepriseId = (
  store: WSStore,
  activity?: IActivity,
  user?: INewUser
) =>
  ((store.growthAttributionDetails?.growthSource ===
    GrowthSourceNames.Enterprise &&
    store.growthAttributionDetails?.growthName) ||
    (activity?.context?.growthSource === GrowthSourceNames.Enterprise &&
      activity.context?.growthSourceName) ||
    user?.labels?.growth_name ||
    "") as string;
