import {
  WSControl,
  WSElement,
  WSElementProps,
  WSField,
  WSForm,
  WSInfoBox,
  WSInputNumber,
  WSList
} from "@wingspanhq/fe-component-library";
import get from "lodash/get";

type Props = { name: string } & WSElementProps;

export const OwnershipFields: React.FC<Props> = ({ name, ...props }) => {
  return (
    <WSElement {...props}>
      <WSList gap="XL">
        <WSForm.Field
          name={name + ".stake"}
          label="Approximate ownership stake (%)"
          component={WSInputNumber}
          componentProps={{
            required: true,
            placeholder: "Enter percentage (%)",
            min: 0,
            max: 100
          }}
        />

        <WSForm.Value name={name + ".stake"}>
          {stake =>
            !!stake && stake < 25 ? (
              <WSInfoBox title="Important:">
                You need to be a beneficial owner or representative to verify
                your business
                <br />
                <br />
                If you are not a beneficial owner or representative of the
                business (such as a controller or officer), we recommend having
                either a beneficial owner or representative of the business
                setup the account instead. Otherwise, we cannot successfully
                verify your business.
                <WSForm.Field
                  mt="M"
                  name={name + ".isRepresentative"}
                  component={WSControl}
                  componentProps={{
                    type: "checkbox",
                    size: "S",
                    label:
                      "I confirm that I’m a representative of the business with less than 25% ownership stake."
                  }}
                />
                <WSForm.Context>
                  {({ errors }) => {
                    const error = get(errors, name + ".isRepresentative");
                    return error ? (
                      <WSField message={error.message} status="error" />
                    ) : null;
                  }}
                </WSForm.Context>
              </WSInfoBox>
            ) : null
          }
        </WSForm.Value>
      </WSList>
    </WSElement>
  );
};
