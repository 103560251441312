import {
  WSSelectOption,
  WSSelectSearch,
  WSSelectSearchProps
} from "@wingspanhq/fe-component-library";
import {
  IPayerEngagementListResponse,
  IPayerResponse
} from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { useCallback, useMemo, useState } from "react";
import { useQueryPayerRows } from "../../../../query/search/payer/queries/useQueryPayerRows";
import { getPayerEngagements } from "../../../../services/payerEngagements";
import { useAddClientModal } from "../../components/AddClientModal/useAddClientModal";
import {
  selectorPayerEngagementName,
  selectorPayerEngagements
} from "../../../Payees/selectors/selectorPayerEngagements";

type Props = Omit<WSSelectSearchProps, "options"> & {
  defaultSearchText?: string;
  onAddNewPayer: (
    payer: IPayerResponse,
    engagements: IPayerEngagementListResponse
  ) => void;
};

export const SelectPayerEngagement: React.FC<Props> = ({
  defaultSearchText,
  onAddNewPayer,
  ...otherProps
}) => {
  const [searchText, setSearchText] = useState(defaultSearchText);
  const queryPayerRows = useQueryPayerRows({
    filter: {
      searchString: searchText || undefined
    }
  });

  const onReachMenuEnd = useCallback(() => {
    if (queryPayerRows.canFetchMore) {
      queryPayerRows.fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPayerRows.canFetchMore]);

  const modalAddClient = useAddClientModal();

  const options = useMemo(() => {
    const options: WSSelectOption[] = [];

    (queryPayerRows.data || []).forEach(payer => {
      if (!payer.user) {
        return;
      }

      const payerNames = wsName({
        user: payer.user,
        member: payer.member,
        payeeOwnedData: payer.payeeOwnedData
      });

      const name = payerNames.getResolvedName();
      const secondaryName = payerNames.getResolvedSecondaryName();

      const filteredEngagements = selectorPayerEngagements(
        payer.engagements,
        false,
        false
      );

      filteredEngagements.forEach((e, index, all) => {
        options.push({
          value: payer.payerId + "/" + e.payerPayeeEngagementId,
          label: [
            name,
            all.length > 1 ? selectorPayerEngagementName(e, true) : undefined
          ]
            .filter(Boolean)
            .join(" • "),
          labels: [secondaryName].filter(Boolean) as string[],
          avatar: {
            type: "text",
            text: name
          }
        });
      });
    });

    return options;
  }, [queryPayerRows.data]);

  return (
    <WSSelectSearch
      {...otherProps}
      // label="Payer"
      placeholder="Select an option"
      options={options}
      status={queryPayerRows.isLoading ? "loading" : otherProps.status}
      externalSearchText={searchText}
      onExternalSearchChange={setSearchText}
      isListLoading={
        queryPayerRows.isFetching && !queryPayerRows.isFetchingMore
      }
      onReachMenuEnd={onReachMenuEnd}
      menuFooterText="Don't see a Client name? Add a new client to your client list."
      menuFooterAction={{
        label: "Add client",
        onClick: async () => {
          await modalAddClient.open({
            async onSuccess(payer) {
              if (payer) {
                const engagements = await getPayerEngagements(payer.payerId);
                onAddNewPayer(payer, engagements);
              }
            }
          });
        }
      }}
    />
  );
};
