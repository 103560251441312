import { WSCard, WSElementProps } from "@wingspanhq/fe-component-library";
import React from "react";
import { openInNewTab } from "../../../utils/openInNewTab";

type Taxes101Props = WSElementProps;

export const Taxes101: React.FC<Taxes101Props> = props => {
  return (
    <WSCard
      title="Freelance Taxes 101 with Little Fish Accounting"
      content="Learn the common deductible expenses for taxes. Little Fish Accounting's checklist to help you navigate freelance tax deductions"
      headerLeft={{
        icon: "caret-right",
        color: "gray500",
        colorBackground: "gray50"
      }}
      onClick={() => {
        openInNewTab(
          "https://my.wingspan.app/member/knowledge-center/freelance-taxes-101"
        );
      }}
      size="S"
      {...props}
    />
  );
};
