import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { IInvoiceTemplate } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import {
  useInvoiceTemplatesQuery,
  useMemberClientQuery
} from "../../query/payments/queries";
import { getInvoiceTemplate } from "../../query/payments/selectors";
import { WSQueries } from "../../query/WSQuery";
import { DrawerLayout } from "../components/DrawerLayout/DrawerLayout";
import {
  InvoiceSeriesTable,
  SeriesTableItemData
} from "../components/InvoiceSeriesTable/InvoiceSeriesTable";
import { SeriesInvoiceActionsModal } from "../components/SeriesInvoiceActionsModal/SeriesInvoiceActionsModal";

export const InvoicesSeriesDetails: React.FC<RouteComponentProps<{
  invoiceTemplateId: string;
}>> = ({ match, location }) => {
  const history = useHistory();
  const parentInvoiceTemplateId = match.params.invoiceTemplateId;
  const invoiceTemplatesQuery = useInvoiceTemplatesQuery();
  const parentInvoiceTemplate = getInvoiceTemplate(
    invoiceTemplatesQuery.data || [],
    parentInvoiceTemplateId
  ) as IInvoiceTemplate;
  const memberClientQuery = useMemberClientQuery(
    parentInvoiceTemplate?.invoiceData.memberClientId as string,
    {
      retry: false,
      enabled: !!parentInvoiceTemplate?.invoiceData.memberClientId
    }
  );
  const [showAllUpcoming, setShowAllUpcoming] = useState(false);
  const [showAllPast, setShowAllPast] = useState(false);

  const onBack = () => {
    history.replace({
      pathname: location.pathname.replace("/series", ""),
      search: location.search
    });
  };

  const upcomingData: SeriesTableItemData[] = [];
  const pastData: SeriesTableItemData[] = [];

  (parentInvoiceTemplate.scheduleDates || []).forEach((scheduleDate, index) => {
    if (scheduleDate.date > new Date()) {
      upcomingData.push({
        index,
        scheduleDate
      });
    } else {
      pastData.push({
        index,
        scheduleDate
      });
    }
  });

  const initialUpcomingLimit = pastData.length > 0 ? 3 : 6;

  return (
    <DrawerLayout title="Series details" onBack={onBack}>
      <SeriesInvoiceActionsModal />
      <WSQueries
        queries={{
          memberClientQuery
        }}
      >
        {({ memberClientQuery: { data: memberClient } }) => {
          return (
            <>
              <WSText mb="XL">Select invoice to edit</WSText>

              {upcomingData.length > 0 && (
                <>
                  <WSText.ParagraphSm color="gray500" mb="XS">
                    Upcoming invoices
                  </WSText.ParagraphSm>
                  <InvoiceSeriesTable
                    data={upcomingData.slice(
                      0,
                      showAllUpcoming ? undefined : initialUpcomingLimit
                    )}
                    parentInvoiceTemplate={parentInvoiceTemplate}
                    memberClient={memberClient}
                    mb="XS"
                  />
                  {!showAllUpcoming &&
                    upcomingData.length - initialUpcomingLimit > 0 && (
                      <WSButton.Link
                        onClick={() => {
                          setShowAllUpcoming(true);
                        }}
                      >
                        Load {upcomingData.length - initialUpcomingLimit} more
                      </WSButton.Link>
                    )}
                </>
              )}

              {pastData.length > 0 && (
                <>
                  <WSText.ParagraphSm color="gray500" mt="2XL" mb="XS">
                    Past invoices
                  </WSText.ParagraphSm>
                  <InvoiceSeriesTable
                    noActions
                    data={pastData.slice(0, showAllPast ? undefined : 3)}
                    parentInvoiceTemplate={parentInvoiceTemplate}
                    memberClient={memberClient}
                    mb="XS"
                  />
                  {!showAllPast && pastData.length - 3 > 0 && (
                    <WSButton.Link
                      onClick={() => {
                        setShowAllPast(true);
                      }}
                    >
                      Load {pastData.length - 3} more
                    </WSButton.Link>
                  )}
                </>
              )}
            </>
          );
        }}
      </WSQueries>
    </DrawerLayout>
  );
};
