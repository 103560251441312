import { IInvitation } from "@wingspanhq/users/dist/lib/interfaces";

export const parseInvitationData = (
  search?: string
): IInvitation | undefined => {
  try {
    const params = Object.fromEntries(new URLSearchParams(search));
    const result = JSON.parse(atob(params.data));

    if (result && result.invitationToken && result.inviterUserId) {
      return result;
    }
  } catch (e) {}

  return undefined;
};
