import { useMemo, useState } from "react";
import { KEY_SELECTED_ORGANIZATION_ACCOUNT_EMAIL } from "../constants/storage";

export const useStoredSelectedAccountEmail = () => {
  const [email, setEmail] = useState(
    window.sessionStorage.getItem(KEY_SELECTED_ORGANIZATION_ACCOUNT_EMAIL) || ""
  );

  const setEmailWithStorage = useMemo(() => {
    return (email: string) => {
      window.sessionStorage.setItem(
        KEY_SELECTED_ORGANIZATION_ACCOUNT_EMAIL,
        email
      );
      setEmail(email);
    };
  }, [setEmail]);

  return [email, setEmailWithStorage] as const;
};