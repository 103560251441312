import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { FormRepresentative } from "../../components/FormRepresentative";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { OnboardingModuleCreateAccount, OnboardingStep } from "../../types";
import { getPersonalData } from "../../utils/getDataFromProfile";
import { CreateAccountContext } from "./types";

export const Representative: React.FC<OnboardingStep> = ({
  onNext,
  onBack
}) => {
  const [context, setContext] = useOnboardingContext<CreateAccountContext>();

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;
  const verificationLevelIsBanking =
    onboardingModule?.options?.verificationLevel === "Banking";

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const profileData = useMemo(
    () => ({
      representative: getPersonalData(queryUser.data?.profile)
    }),
    [queryUser.data?.profile]
  );

  return (
    <WSList gap="2XL">
      <FormRepresentative
        defaultValues={context.representative || profileData}
        country={context.accountType?.country || "US"}
        onBack={onBack}
        onSubmit={data => {
          setContext(prev => ({
            ...prev,
            representative: data
          }));
          onNext?.();
        }}
        includeBeneficialOwners={verificationLevelIsBanking}
        requireTaxId={verificationLevelIsBanking}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
