import {
  WSActions,
  WSControl,
  WSElement,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useMutationUpdateAcknowledgement } from "../../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { ErrorContextKey } from "../../../../services/platform";
import { acknowledgementVersions } from "../../../../shared/constants/acknowledgements";
import { OnboardingStep } from "../../types";

export const Tax1099Consent: React.FC<OnboardingStep> = ({ onNext }) => {
  const [consent, setConsent] = useState(false);
  const [
    updateAcknowledgement,
    updateAcknowledgementMeta
  ] = useMutationUpdateAcknowledgement();
  return (
    <WSList gap="2XL">
      <WSPanel>
        <WSList gap="2XL">
          <WSElement>
            <WSText.Paragraph weight="medium" color="gray600" mb="M">
              Consent to e-delivery (paperless)
            </WSText.Paragraph>
            <WSText.ParagraphSm weight="book" color="gray600">
              By using Wingspan's services you agree that the information
              provided is accurate and that you consent to receive tax documents
              electronically as follows:
            </WSText.ParagraphSm>
          </WSElement>
          <WSInfoBox>
            <p>By submitting, you certify, under penalties of perjury, that:</p>
            <ul>
              <li>
                You consent to receive all tax documents electronically instead
                of in paper format.
              </li>
              <li>
                You understand that you will need access to a computer or mobile
                device with internet connectivity, a valid email address, and
                appropriate software to view these documents.
              </li>
              <li>
                You have the right to request a paper copy of any tax document
                at no additional cost, which does not constitute a withdrawal of
                consent for electronic delivery.
              </li>
              <li>
                You may withdraw your consent for electronic delivery at any
                time by notifying the company in writing or through the
                designated process in the platform.
              </li>
              <li>
                You agree to promptly notify the company of any changes to your
                email address or other relevant contact information.
              </li>
            </ul>
            <br />

            <WSControl
              type="checkbox"
              name="agreement"
              label="By providing the information on this form, I hereby certify that I have read, understood, and agree to the terms for electronic delivery of tax documents as outlined above."
              value={consent}
              onChange={value => {
                setConsent(value);
              }}
              size="S"
            />
          </WSInfoBox>

          <WSErrorMessage
            error={updateAcknowledgementMeta.error}
            contextKey={ErrorContextKey.ElectronicTaxFormConsent}
          />
        </WSList>
      </WSPanel>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            disabled: !consent,
            onClick: async () => {
              await updateAcknowledgement(
                {
                  acknowledgementName: "ElectronicTaxFormConsent",
                  requestData: {
                    acknowledgementName: "ElectronicTaxFormConsent",
                    acknowledgementStatus: "Given",
                    version: acknowledgementVersions["ElectronicTaxFormConsent"]
                  }
                },
                {
                  onSuccess: () => {
                    onNext?.();
                  }
                }
              );
            },
            loading: updateAcknowledgementMeta.isLoading
          }
        ]}
      />

      <WSText.ParagraphXs weight="book" color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will require to
        do this later.
      </WSText.ParagraphXs>
    </WSList>
  );
};
