import { IMemberClientRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/memberClient";
import {
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableItem
} from "@wingspanhq/fe-component-library";
import {
  RequirementStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import React from "react";
import {
  useDownloadMemberDocument,
  useSignDocument
} from "../../../../query/files/mutations";
import {
  WSTableAction,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";

type PayerRequirementsTableProps = {
  hideActions?: boolean;
  compact?: boolean;
  eligibilityRequirements: Array<IMemberClientRequirementResponse>;
  onSign?: () => void;
};

export const requirementTypeToLabelMap: Record<RequirementType, string> = {
  [RequirementType.Signature]: "e-Signature"
};

const isRequirementReadyToSign = (
  requirement: IMemberClientRequirementResponse
) => {
  return (
    requirement.status !== RequirementStatus.Complete &&
    !(
      requirement.status === RequirementStatus.Pending &&
      requirement.document?.events?.memberSignedAt
    )
  );
};

export const PayerRequirementsTable: React.FC<PayerRequirementsTableProps> = ({
  eligibilityRequirements,
  hideActions,
  compact,
  onSign
}) => {
  const [sign, signMeta] = useSignDocument();
  const [downloadPdf] = useDownloadMemberDocument();

  const tableData: WSTableItem<
    IMemberClientRequirementResponse
  >[] = eligibilityRequirements.map(requirement => ({
    id: requirement.eligibilityRequirementId,
    data: requirement
  }));

  const handleRequirementDownload = async (
    requirement: IMemberClientRequirementResponse
  ) => {
    await downloadPdf({
      documentId: requirement?.documentId || ""
    });
  };

  const handleRequirementSign = async (
    requirement: IMemberClientRequirementResponse
  ) => {
    await sign(
      { documentId: requirement.documentId },
      {
        onSuccess() {
          onSign?.();
        }
      }
    );
  };

  const columns: Array<WSTableColumn<IMemberClientRequirementResponse>> = [
    {
      config: {
        header: {
          text: "Requirement"
        },
        gridTemplateSizeMax: "0.5fr"
      },
      renderFunction: rowItem => (
        <WSTableCell text={rowItem.data.document?.title || "--"} />
      )
    },
    {
      config: {
        header: {
          text: "Type"
        },
        hideOnScreens: compact ? ["XS", "S", "M", "L", "XL"] : ["XS"],
        gridTemplateSizeMax: "0.5fr"
      },
      renderFunction: rowItem => (
        <WSTableCell
          icon="edit-line"
          text={
            requirementTypeToLabelMap[rowItem.data.requirementType] ??
            rowItem.data.requirementType
          }
        />
      )
    },
    {
      config: {
        header: {
          text: "Status"
        },
        gridTemplateSizeMax: "0.5fr"
      },
      renderFunction: rowItem => {
        const requirement = rowItem.data;
        {
          /*
          const statusToComponentMap: Record<RequirementStatus, WSPillProps> = {
           [RequirementStatus.Complete]: {
             icon: "check-circle",
             text: "Complete",
             theme: "success"
           },
           [RequirementStatus.Pending]: {
             icon: "alert",
             text: "Pending",
             theme: "warning"
           },
           [RequirementStatus.Sent]: {
             icon: "send-mail",
             text: "Sent",
             theme: "blue"
           },
           [RequirementStatus.New]: {
             icon: "envelope-open",
             text: "New",
             theme: "neutral"
           }
         };
         */
        }
        return (
          <WSTableCell
            // pill={statusToComponentMap[requirement.status as RequirementStatus]}
            pill={
              requirement.status === RequirementStatus.Complete
                ? {
                    theme: "success",
                    text: "Complete",
                    icon: true
                  }
                : {
                    theme: "warning",
                    text: "Pending",
                    icon: true
                  }
            }
            secondaryText={
              requirement.status === RequirementStatus.Pending
                ? requirement.document?.events?.memberSignedAt &&
                  !requirement.document?.events?.clientSignedAt
                  ? "Awaiting client signature"
                  : requirement.document?.events?.clientSignedAt &&
                    !requirement.document?.events?.memberSignedAt
                  ? "Your signature required"
                  : ""
                : ""
            }
          />
        );
      }
    }
  ];

  return (
    <WSTable<IMemberClientRequirementResponse>
      rowActions={({ data: requirement }) => {
        const actions: WSTableAction[] = [];

        if (isRequirementReadyToSign(requirement)) {
          actions.push({
            onAsyncClick: async () => {
              await handleRequirementSign(requirement);
            },
            text: "Start",
            kind: "Link",
            hideOn: []
          });
        }

        return hideActions ? [] : actions;
      }}
      rowMenuActions={({ data: requirement }) => {
        const actions: WSTableRowMenuAction[] = [];

        if (requirement?.documentId) {
          actions.push({
            onAsyncClick: async () => {
              await handleRequirementDownload(requirement);
            },
            label: "Download PDF",
            icon: "download"
          });
        }

        if (isRequirementReadyToSign(requirement)) {
          actions.push({
            onAsyncClick: async () => {
              await handleRequirementSign(requirement);
            },
            label: "Sign",
            icon: "edit-line"
          });
        }

        // hide actions if only sign is available
        return hideActions ? [] : requirement?.documentId ? actions : [];
      }}
      columns={columns}
      tableData={tableData}
      data-testid="requirementsTable"
    />
  );
};
