import React, { useCallback, useMemo } from "react";
import { WSControl } from "../WSControl/WSControl";
import { WSElement } from "../WSElement/WSElement.component";
import { WSText } from "../core";
import styles from "./WSTable.module.scss";
import { WSTableColumn, WSTableItem } from "./types";

type HeaderProps = {
  name: string;
  tableData: Array<WSTableItem>;
  showSelectionColumn?: boolean;
  selection?: Array<WSTableItem>;
  onSelectionChange?: (selection: Array<WSTableItem>) => void;
  columns: Array<WSTableColumn>;
};

export const GroupHeader: React.FC<HeaderProps> = ({
  name,
  tableData = [],
  showSelectionColumn,
  selection = [],
  onSelectionChange,
  columns
}) => {
  // Get the ids of the selected items that are in the current group
  const groupSelectionIds = useMemo(
    () =>
      selection.reduce<string[]>((acc, item) => {
        const matchesCurrentGroup = !!tableData.find(
          (groupItem) => groupItem.id === item.id
        );

        if (matchesCurrentGroup) {
          return [...acc, item.id];
        }
        return acc;
      }, []),
    [selection, tableData]
  );

  const isAllSelected = useMemo(() => {
    if (
      groupSelectionIds.length > 0 &&
      groupSelectionIds.length < tableData.length
    ) {
      return "indeterminate";
    }

    return groupSelectionIds.length === tableData.length;
  }, [groupSelectionIds.length, tableData.length]);

  const handleSelectAll = useCallback(() => {
    if (isAllSelected === true) {
      onSelectionChange?.(
        selection.filter((item) => !groupSelectionIds.includes(item.id))
      );
    } else {
      const newSelection = selection.filter(
        (item) => !groupSelectionIds.includes(item.id)
      );
      newSelection.push(...tableData);

      onSelectionChange?.(newSelection);
    }
  }, [
    groupSelectionIds,
    isAllSelected,
    onSelectionChange,
    selection,
    tableData
  ]);

  const nameStyles = useMemo(
    () => ({
      gridColumnStart: showSelectionColumn ? 2 : 1,
      gridColumnEnd: `span ${
        showSelectionColumn ? columns.length - 1 : columns.length
      }`
    }),
    [columns.length, showSelectionColumn]
  );

  return (
    <WSElement className={styles.groupHeader}>
      {showSelectionColumn && (
        <WSElement className={styles.headerCell}>
          <WSControl
            type="multistep-checkbox"
            value={isAllSelected}
            onChange={handleSelectAll}
          />
        </WSElement>
      )}

      <WSElement className={styles.headerCell} style={nameStyles}>
        <WSText.ParagraphSm weight="medium" color="gray500">
          {name}
        </WSText.ParagraphSm>
      </WSElement>
    </WSElement>
  );
};
