import { WSAlert, WSPanel, WSSidebar } from "@wingspanhq/fe-component-library";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useGetPayerEngagementQuery } from "../../../../query/payerEngagements/queries/useGetPayerEngagementQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { getParentPath } from "../../../../utils/goToParentRoute";
import { EngagementInfoPanel } from "./EngagementInfoPanel";
import React, { useState } from "react";
import { getEngagementEligibilityRequirementsCompleted } from "../../utils/engagement";
import {
  IPayerResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { PayerIntegrationWidget } from "../../components/IntegrationsSection/PayerIntegrationWidget";
import { usePayerQuery } from "../../../../query/payers/queries/usePayerQuery";
import { selectorPayerEngagementName } from "../../../Payees/selectors/selectorPayerEngagements";
import { PayerRequirementsTable } from "../../components/PayerRequirementsTable";

export const RouteEngagementDetails: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ clientId: string; engagementId: string }>();
  const { clientId, engagementId } = match.params;
  const queryEngagement = useGetPayerEngagementQuery(clientId, engagementId);
  const queryPayer = usePayerQuery(clientId);

  const backPath = location.state?.backPath || getParentPath(history);

  return (
    <WSSidebar.Container
      onClose={() => {
        history.replace(backPath);
      }}
    >
      <WSQueries queries={{ queryEngagement, queryPayer }}>
        {({ queryEngagementData: payerEngagement, queryPayerData }) => {
          return (
            <WSSidebar.Layout
              header={selectorPayerEngagementName(payerEngagement, true)}
            >
              <Inner payerEngagement={payerEngagement} payer={queryPayerData} />
            </WSSidebar.Layout>
          );
        }}
      </WSQueries>
    </WSSidebar.Container>
  );
};

const Inner: React.FC<{
  payerEngagement: IPayerEngagementResponse;
  payer: IPayerResponse;
}> = ({ payerEngagement, payer }) => {
  const [showWarning, setShowWarning] = useState<boolean>(true);

  const areRequirementsCompleted = getEngagementEligibilityRequirementsCompleted(
    payerEngagement
  );
  const isArchived =
    payerEngagement.status === PayerPayeeEngagementStatus.Inactive;

  const eligibilityRequirements = payerEngagement.eligibilityRequirements || [];

  return (
    <>
      <EngagementInfoPanel engagement={payerEngagement} mb="XL" />
      {showWarning && !areRequirementsCompleted && !isArchived ? (
        <WSAlert
          theme="warning"
          icon="alert"
          title="Eligibility requirements incomplete"
          mb="L"
          onDismiss={() => setShowWarning(false)}
        >
          Complete all eligibility requirements to get paid.
        </WSAlert>
      ) : null}
      {eligibilityRequirements.length ? (
        <WSPanel noBorder p="NONE" mb="XL">
          <PayerRequirementsTable
            eligibilityRequirements={eligibilityRequirements}
            compact
            hideActions={
              payerEngagement.status === PayerPayeeEngagementStatus.Inactive
            }
          />
        </WSPanel>
      ) : null}
      <PayerIntegrationWidget
        isInactive={
          payerEngagement.status === PayerPayeeEngagementStatus.Inactive
        }
        payerId={payer.payerId}
        engagementId={payerEngagement.payerPayeeEngagementId}
        customerId={
          payerEngagement?.payeeOwnedData?.integration?.quickbooks?.customerId
        }
        itemId={
          payerEngagement?.payeeOwnedData?.integration?.quickbooks?.itemId
        }
      />
    </>
  );
};
