import {
  WSElementProps,
  WSGrid,
  WSPanel
} from "@wingspanhq/fe-component-library";

import styles from "./styles.module.scss";
import { InfoItem } from "../../../components/ClientInfoPanel/InfoItem";
import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { getTmpEngagementName } from "../../../../Payees/selectors/getTmpEngagementName";
import { IEngagement } from "@wingspanhq/payments/dist/interfaces";

export interface EngagementInfoPanelProps extends WSElementProps {
  engagement:
    | (IPayerEngagementResponse & { description?: string })
    | IEngagement;
}

export const EngagementInfoPanel: React.FC<EngagementInfoPanelProps> = ({
  engagement,
  ...elementProps
}) => {
  const { type } = engagement;
  return (
    <WSPanel {...elementProps}>
      <WSGrid className={styles.panel}>
        <WSGrid.Item mb="L" span={{ xs: "12", s: "6" }}>
          <InfoItem
            label="Engagement name"
            value={getTmpEngagementName(engagement)}
          />
        </WSGrid.Item>
        {/*
        {type && (
          <WSGrid.Item mb="L" span={{ xs: "12", s: "6" }}>
            <InfoItem
              label="Type"
              value={getEngagementLabelByType(type, { descriptive: true })}
            />
          </WSGrid.Item>
        )}
        */}
        {engagement.description && (
          <WSGrid.Item mb="L" span={{ xs: "12" }}>
            <InfoItem
              label="Description"
              value={engagement.description}
              singleLine={false}
            />
          </WSGrid.Item>
        )}
      </WSGrid>
    </WSPanel>
  );
};
