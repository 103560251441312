import React from "react";
import { useMemberProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { TaxesPreview } from "./TaxesPreview";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useUserId } from "../../../query/hooks/helpers";
import { TaxesDashboard } from "./TaxesDashboard";

export const TaxesMain: React.FC = () => {
  const memberId = useUserId();
  const qMember = useMemberProfile(memberId);

  return (
    <WSQueries queries={{ member: qMember }}>
      {({ member }) => {
        const status = member.data.profile.withholdings?.tax?.status;
        if (
          MemberWithholdingStatus.Active === status ||
          MemberWithholdingStatus.Paused === status
        ) {
          return <TaxesDashboard />;
        }

        return <TaxesPreview />;
      }}
    </WSQueries>
  );
};
