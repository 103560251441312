import {
  WSButton,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { openIntercom } from "../../../shared/utils/intercom";
import { Layout } from "../../Onboarding/components/Layout";

export const RouteWithdrawSuccess = () => {
  const history = useHistory();

  return (
    <Layout
      title="Withdraw funds"
      onClose={() => {
        history.push("/member");
      }}
    >
      <WSList gap="L">
        <WSPanel>
          <WSText weight="medium">Funds withdrawal initiated</WSText>
          <WSText.ParagraphSm>
            Your funds transfer from your Wingspan Wallet and Tax Withholding to
            bank account has been initiated.
          </WSText.ParagraphSm>
        </WSPanel>

        <WSPanel>
          <WSInfoBox title="What to expect:" transparent>
            <ul>
              <li>Your funds will arrive in up to one (1) business day</li>
              <li>
                Your Wingspan Wallet and Tax Withholding accounts will be closed
              </li>
              <li>
                You cannot re-open Wingspan Wallet and Tax Withholding accounts
                until our banking services transition to Lead Bank is complete.
              </li>
            </ul>
          </WSInfoBox>
        </WSPanel>

        <WSButton
          fullWidth
          onClick={() => {
            history.push("/member");
          }}
        >
          Close
        </WSButton>

        <WSText.ParagraphSm color="gray600">
          If you have any questions or concerns, you can find more information
          and connect with our <a onClick={openIntercom}>support team here</a>.
        </WSText.ParagraphSm>
      </WSList>
    </Layout>
  );
};
