import {
  useIsMobile,
  WSActions,
  WSButtonProps,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import { classNames } from "primereact/utils";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { INVOICING_PATH } from "../..";
import styles from "./index.module.scss";
import { safePageKeyboardEventHandling } from "../../../../utils/keyboard";

export type PageToolarProps = {
  title: string;
  breadcrumb?: string;
  buttons: (WSButtonProps & { label: string })[];
  showSearch?: boolean;
} & WSElementProps;

export const PageToolbar: React.FC<PageToolarProps> = ({
  title,
  breadcrumb,
  buttons,
  showSearch,
  ...elementProps
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleSearch = useCallback(() => {
    history.push(INVOICING_PATH + "/search");
  }, [history]);

  const handleDocumentKeyUp = useCallback(
    safePageKeyboardEventHandling((event: KeyboardEvent) => {
      if (event.key === "/") {
        if (
          showSearch &&
          [
            INVOICING_PATH + "/drafts",
            INVOICING_PATH + "/outstanding",
            INVOICING_PATH + "/in-progress",
            INVOICING_PATH + "/deposited",
            INVOICING_PATH
          ].includes(history.location.pathname)
        ) {
          handleSearch();
        }
      }
    }),
    [handleSearch, showSearch, history]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleDocumentKeyUp);
    return () => {
      document.removeEventListener("keyup", handleDocumentKeyUp);
    };
  }, [handleDocumentKeyUp]);

  return (
    <WSElement
      {...elementProps}
      className={classNames(
        styles.pageToolbar,
        breadcrumb && styles.withBreadcrumb
      )}
    >
      <WSElement>
        {breadcrumb && (
          <WSFlexBox.CenterY wrap="nowrap" mb="M">
            <WSText.ParagraphSm
              weight="medium"
              color="gray400"
              onClick={() => {
                history.push(INVOICING_PATH);
              }}
            >
              Invoicing
            </WSText.ParagraphSm>
            <WSIcon name="chevron-right" color="gray400" mx="S" size="M" />
            <WSText.ParagraphSm weight="medium">
              {breadcrumb}
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        )}

        <WSText kind={isMobile ? "Heading4" : "Heading2"}>{title}</WSText>
      </WSElement>

      <WSActions
        buttons={[
          ...buttons,
          {
            icon: "search",
            kind: "Tertiary",
            onClick: handleSearch,
            label: "Search"
          }
        ]}
      />
    </WSElement>
  );
};
