import {
  SelectOptionOld,
  useWSSnackbar,
  WSActions,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSForm,
  WSInputText,
  WSList,
  WSPanel,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import {
  contactCompanyValidator,
  contactNameValidator
} from "../../../../utils/validators";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import {
  CustomFieldResourceType,
  ICustomField,
  IPayeeResponse,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import {
  getDefaultValuesForAdditionalData,
  getYupValidationSchema
} from "../../../../utils/jsonFormSchema";
import { WSQueries } from "../../../../query/WSQuery";
import orderBy from "lodash/orderBy";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { selectorIsEnterpriseUser } from "../../../../shared/selectors/selectorIsEnterpriseUser";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../../query/integrations/queries";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  QUICKBOOKS_ENTITY,
  RefreshModal,
  useQboRefreshModal
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { useCreatePayee } from "../../../../query/payee/mutations/useCreatePayee";
import React from "react";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import { selectQuickbooksUserOptions } from "../../../Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../Integrations/selectors/selectQuickbooksAccountOrItemOptions";
import { selectorIsQBOActive } from "../../../../shared/selectors/selectorIsQBOActive";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

export interface CreateContractorModalProps {
  onClose(result?: IPayeeResponse): void;

  onSuccess?: (client?: IPayeeResponse) => Promise<void>;
}

export const CreateContractorModal: React.FC<CreateContractorModalProps> = ({
  onClose,
  onSuccess
}) => {
  const { openSnackbar } = useWSSnackbar();

  const userId = useUserId();
  const qboRefreshModal = useQboRefreshModal();

  const queryUserProfile = useUserProfile(userId);
  const queryFeatureFlags = useFeatureFlags();

  //qbo queries
  const queryIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const queryIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const queryIntegrationsQuickbooksExpenseAccounts = useIntegrationsQuickbooksAccountExpenses();

  const queryCollaboratorGroups = useCollaboratorGroupsQuery();

  const [createPayee, createPayeeMeta] = useCreatePayee();

  const isQuickbooksActive = selectorIsQBOActive(
    queryFeatureFlags.data,
    queryIntegrationsQuickbooks.data
  );

  const queryCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });

  const prepareDynamicFormFields = (
    customFields: ICustomField[],
    prefix?: string
  ) => {
    return orderBy(customFields, "type", "desc").map(field => {
      switch (field.type) {
        case WSDataTypes.String:
          return (
            <WSForm.Field
              key={field.key}
              name={prefix ? `${prefix}.${field.key}` : field.key}
              label={`${field.name}${field.required ? " *" : ""}`}
              component={WSInputText}
              componentProps={{
                placeholder: field.name
              }}
            />
          );
        case WSDataTypes.Boolean:
          return (
            <WSForm.Field
              key={field.key}
              name={prefix ? `${prefix}.${field.key}` : field.key}
              component={WSCheckboxToggle}
              componentProps={{ label: field.name }}
            />
          );
        default:
          return (
            <WSForm.Field
              key={field.key}
              name={prefix ? `${prefix}.${field.key}` : field.key}
              label={`${field.name}${field.required ? " *" : ""}`}
              component={WSInputText}
              componentProps={{
                placeholder: field.name
              }}
            />
          );
      }
    });
  };

  return (
    <WSQueries
      queries={{
        queryUserProfile,
        queryCollaboratorCustomFields,
        queryCollaboratorGroups
      }}
    >
      {({
        queryUserProfileData: userProfile,
        queryCollaboratorCustomFieldsData: collaboratorCustomFields,
        queryCollaboratorGroupsData: collaboratorGroups
      }) => {
        const isEnterpriseUser = selectorIsEnterpriseUser(userProfile);
        const { data: integrationState } = queryIntegrationsQuickbooks;
        const {
          data: integrationQuickbooksVendors
        } = queryIntegrationsQuickbooksVendors;
        const {
          data: integrationQuickbooksExpenseAccounts
        } = queryIntegrationsQuickbooksExpenseAccounts;

        const quickbooksVendorOptions: SelectOptionOld[] = selectQuickbooksUserOptions(
          integrationQuickbooksVendors
        );

        const quickbooksAccountsOptions: SelectOptionOld[] = selectQuickbooksAccountOrItemOptions(
          integrationQuickbooksExpenseAccounts
        );

        const defaultAccount = (
          integrationQuickbooksExpenseAccounts ?? []
        ).find(
          acct =>
            acct.accountId === integrationState?.defaults?.expenseAccountId
        );

        const customFieldsValidationSchema = getYupValidationSchema(
          collaboratorCustomFields
        );
        return (
          <>
            <WSForm
              defaultValues={{
                email: "",
                company: "",
                name: "",
                qboExpenseAccountId: "",
                qboVendorId: "",
                externalId: "",
                customFields: getDefaultValuesForAdditionalData(
                  collaboratorCustomFields
                ),
                collaboratorGroupId: (collaboratorGroups.find(
                  i => i.defaultGroup
                ) as any)?.collaboratorGroupId
              }}
              validationSchema={Yup.object().shape({
                email: validatorEmail.required("Required"),
                company: contactCompanyValidator,
                name: contactNameValidator,
                customFields: Yup.object().shape(customFieldsValidationSchema)
              })}
              onSubmit={async data => {
                const result = await createPayee({
                  email: data.email,
                  payerOwnedData: {
                    ...((data.qboVendorId || data.qboExpenseAccountId) && {
                      integration: {
                        quickbooks: {
                          vendorId: data.qboVendorId,
                          expenseAccountId: data.qboExpenseAccountId
                        }
                      }
                    }),
                    payeeCompanyName: data.company,
                    payeeName: data.name,
                    customFields: data.customFields,
                    payeeExternalId: data.externalId
                  },
                  collaboratorGroupId: data.collaboratorGroupId
                });

                if (result) {
                  await onSuccess?.(result);
                  onClose(result);
                  openSnackbar({
                    message: "Contractor added successfully",
                    type: "success"
                  });
                }
              }}
            >
              <WSList gap="L">
                <WSForm.Field
                  name="email"
                  label="Contractor email *"
                  component={WSInputText}
                  componentProps={{
                    placeholder: "Contractor email"
                  }}
                />
                <WSForm.Field
                  name="company"
                  label="Contractor company"
                  component={WSInputText}
                  componentProps={{
                    placeholder: "Contractor company"
                  }}
                />
                <WSForm.Field
                  name="name"
                  label="Contractor full name"
                  component={WSInputText}
                  componentProps={{
                    placeholder: "Contractor full name"
                  }}
                />
                {prepareDynamicFormFields(
                  collaboratorCustomFields,
                  "customFields"
                )}
                {collaboratorGroups.length > 0 && (
                  <>
                    <WSDivider my="S" />
                    <WSForm.Field
                      name="collaboratorGroupId"
                      label="Group"
                      component={WSSelect}
                      componentProps={{
                        options: collaboratorGroups.map(collaboratorGroup => ({
                          label: collaboratorGroup.name,
                          value: (collaboratorGroup as any).collaboratorGroupId
                        }))
                      }}
                    />
                  </>
                )}
                {isEnterpriseUser && (
                  <>
                    <WSDivider my="S" />
                    <WSForm.Field
                      name="externalId"
                      label="External ID"
                      component={WSInputText}
                      componentProps={{
                        placeholder: "External ID"
                      }}
                    />
                  </>
                )}
                <WSPanel noBorder p="M" colorBackground="gray50">
                  <WSText.ParagraphSm color="gray600">
                    After adding a contractor, they will be invited by the email
                    provided to sign-up for a Wingspan account in order to
                    receive payments from you.
                  </WSText.ParagraphSm>
                </WSPanel>
                {isQuickbooksActive &&
                  quickbooksVendorOptions.length > 0 &&
                  quickbooksAccountsOptions.length > 0 && (
                    <WSElement>
                      <WSDivider mb="L" label="Quickbooks Mapping" />
                      <WSText.ParagraphSm color="gray500" mb="XL">
                        Because you have an active integration with QBO, please
                        map this new Contractor to an existing Vendor, or leave
                        blank and we will create a new one. You can also set a
                        default expense account for payable line items.
                      </WSText.ParagraphSm>
                      <WSForm.Field
                        mb="L"
                        name="qboVendorId"
                        component={WSSelect}
                        componentProps={{
                          placeholder: "Not mapped, create new Vendor",
                          options: quickbooksVendorOptions,
                          menuFooterAction: {
                            label: "Resync QBO Vendors",
                            icon: "refresh-v",
                            onClick: () => {
                              qboRefreshModal.open({
                                entity: QUICKBOOKS_ENTITY.VENDORS
                              });
                            }
                          }
                        }}
                        label="QBO Vendor"
                      />
                      <WSForm.Field
                        mb="L"
                        name="qboExpenseAccountId"
                        component={WSSelect}
                        componentProps={{
                          options: quickbooksAccountsOptions,
                          placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                          menuFooterAction: {
                            label: "Resync QBO Expenses Accounts",
                            icon: "refresh-v",
                            onClick: () => {
                              qboRefreshModal.open({
                                entity: QUICKBOOKS_ENTITY.EXPENSES
                              });
                            }
                          }
                        }}
                        label="Default QBO Expense Account"
                      />
                      <RefreshModal />
                    </WSElement>
                  )}

                <WSErrorMessage
                  error={createPayeeMeta.error}
                  contextKey="CreatePayee"
                />

                <WSActions
                  mt="XL"
                  alignment="fill"
                  buttons={[
                    {
                      label: "Add contractor",
                      kind: "Primary",
                      type: "submit",
                      loading: createPayeeMeta.isLoading
                    },
                    {
                      label: "Cancel",
                      kind: "Secondary",
                      type: "button",
                      onClick() {
                        onClose();
                      }
                    }
                  ]}
                />
              </WSList>
            </WSForm>
          </>
        );
      }}
    </WSQueries>
  );
};
