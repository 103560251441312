import { WSGrid, WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { PayrollSettings } from "../../Invoices/screens/payables/PayrollSettings";
import { Tabs } from "../../components/Tabs";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { WSQueries } from "../../query/WSQuery";
import { InvoicingConfig } from "../../Invoices/screens/payables/InvoicingConfig";

export const BillingSettings: React.FC = props => {
  const history = useHistory();
  const qFeatureFlags = useFeatureFlags();

  return (
    <WSPage
      title={
        qFeatureFlags.data?.invoicingConfig
          ? "Payable & Payroll Settings"
          : "Payroll"
      }
    >
      <WSGrid>
        <WSGrid.Item span={{ m: "10" }}>
          <Tabs
            tabs={[
              ...(qFeatureFlags.data?.invoicingConfig
                ? [
                    {
                      path: "/member/settings/billing/payables",
                      label: "Payables settings"
                    }
                  ]
                : []),
              {
                path: "/member/settings/billing/payroll",
                label: "Payroll settings"
              }
            ]}
          />
          <WSQueries queries={{ qFeatureFlags }}>
            {({ qFeatureFlags: { data: featureFlags } }) => (
              <Switch>
                <Route
                  path="/member/settings/billing/payroll"
                  component={PayrollSettings}
                />
                {featureFlags.invoicingConfig ? (
                  <>
                    <Route
                      path="/member/settings/billing/payables/edit"
                      render={props => (
                        <InvoicingConfig {...props} isEditing={true} />
                      )}
                    />
                    <Route
                      path="/member/settings/billing/payables"
                      component={InvoicingConfig}
                      exact
                    />
                  </>
                ) : null}
                <Redirect
                  from="*"
                  to={
                    featureFlags.invoicingConfig
                      ? "/member/settings/billing/payables"
                      : "/member/settings/billing/payroll"
                  }
                />
              </Switch>
            )}
          </WSQueries>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
