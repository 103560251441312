import {
  useIsMobile,
  WSButton,
  WSDivider,
  WSElement,
  WSFiltersOld,
  WSLoader,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useMemberClientsQuery } from "../../query/payments/queries";
import { getClients } from "../../query/payments/selectors";
import { ClientsTable } from "../components/ClientsTable/ClientsTable";
import { useModalAddClient } from "../components/ModalAddClient";

export const InvoicesClients: React.FC = () => {
  const memberClientsQuery = useMemberClientsQuery();
  const [searchText, setSearchText] = useState("");
  const isMobile = useIsMobile();
  const modalAddClient = useModalAddClient();
  const history = useHistory();

  let filteredMemberClients = getClients(memberClientsQuery.data || []);

  if (searchText) {
    filteredMemberClients = filteredMemberClients.filter(
      memberClient =>
        memberClient.company?.toLowerCase().includes(searchText) ||
        memberClient.name?.toLowerCase().includes(searchText) ||
        memberClient.emailTo?.toLowerCase().includes(searchText) ||
        memberClient.emailCC?.some(email =>
          email
            .toLowerCase()
            .toLowerCase()
            .includes(searchText)
        )
    );
  }

  const handler = () => {
    modalAddClient.open({
      onSuccess: memberClient => {
        history.push(
          `/member/invoices/contacts/clients/${memberClient.memberClientId}`,
          { backPath: "/member/invoices/contacts/clients" }
        );
      }
    });
  };

  return (
    <WSPage
      title="Clients"
      primaryButtonComponent={props => (
        <WSButton name="createNewClient" onClick={handler} {...props}>
          Add client
        </WSButton>
      )}
    >
      {memberClientsQuery.isLoading ? (
        <WSElement mb="XL" style={{ width: 25 }}>
          <WSLoader.Spinner size="XS" />
        </WSElement>
      ) : (
        <WSFiltersOld
          onSearch={newSearchText => {
            setSearchText(newSearchText.toLowerCase());
          }}
          searchQuery={searchText}
          mb="M"
        />
      )}

      {filteredMemberClients.length > 0 ? (
        <ClientsTable
          pageLimit={15}
          memberClients={filteredMemberClients}
          listPath="/member/invoices/contacts/clients"
        />
      ) : memberClientsQuery.isLoading ? (
        <WSLoader.Spinner />
      ) : searchText ? (
        <WSText>
          No results found.{" "}
          <WSButton.Link
            onClick={() => {
              setSearchText("");
            }}
          >
            Clear search
          </WSButton.Link>
        </WSText>
      ) : (
        <WSText>
          No clients yet.{" "}
          <WSButton.Link onClick={handler}>Add a client</WSButton.Link>
        </WSText>
      )}
    </WSPage>
  );
};
