import {
  WSButton,
  WSAvatar,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSIconList,
  WSLogomark,
  WSPanel,
  WSText,
  WSTextInput,
  useWSModal
} from "@wingspanhq/fe-component-library";
import {
  IRedactedMember,
  UserType
} from "@wingspanhq/users/dist/lib/interfaces";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../../assets/images/ws-logo.svg";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import {
  useCreateClientWithoutUserId,
  useCreateSession,
  useCreateSessionWithGoogleAuthToken,
  useCreateUser
} from "../../../query/users/mutations";
import { getRedactedUserName } from "../../../query/users/selectors";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import { validatorPassword } from "../../../shared/validators/validatorPassword";
import { WS_LINKS } from "../../../types/wsLinks";
import { useWSAnalytics } from "../../../utils/WSAnalytics";
import { GrowthSourceNames } from "../../../utils/growthAttribution";
import { GoogleAuthButton } from "./GoogleAuthButton";
import { ModalCreateAccountWarning } from "./ModalCreateAccountWarning";
import { ModalNotYou } from "./ModalNotYou";
import styles from "./index.module.scss";

export type AuthProps = {
  clientEmailToPrefill?: string;
  onSuccess?: () => void;
  signUp?: boolean;
  signUpAsMember?: boolean;
  memberId?: string;
  member?: IRedactedMember;
} & WSElementProps;

export const Auth: React.FC<AuthProps> = ({
  clientEmailToPrefill,
  onSuccess,
  signUp,
  signUpAsMember,
  memberId,
  member
}) => {
  const history = useHistory();
  const analytics = useWSAnalytics();

  const [createUser, createUserMeta] = useCreateUser();
  const [createSession, createSessionMeta] = useCreateSession();
  const [createClient, createClientMeta] = useCreateClientWithoutUserId();
  const [
    createSessionWithGoogleAuthToken,
    createSessionWithGoogleAuthTokenMeta
  ] = useCreateSessionWithGoogleAuthToken();
  const [isSignUp, setIsSignUp] = useState(!!signUp || !!signUpAsMember);
  const [formError, setFormError] = useState("");

  const growthAttributionDetails = {
    growthSource: GrowthSourceNames.Invoice,
    growthName: member ? getRedactedUserName(member.user) : undefined,
    growthUserId: memberId
  };

  const [isPresetEmailVisible, setIsPresetEmailVisible] = useState(
    !!clientEmailToPrefill
  );
  const modalNotYou = useWSModal(ModalNotYou, { size: "S" });
  const modalCreateAccountWarning = useWSModal(ModalCreateAccountWarning, {
    size: "S"
  });

  useEffect(() => {
    if (clientEmailToPrefill) setIsPresetEmailVisible(true);
  }, [clientEmailToPrefill, isSignUp]);

  const onSubmit = async (data: any) => {
    if (isSignUp) {
      createUser(
        { ...data, userType: UserType.Client },
        {
          onSuccess: () => {
            analytics.track.signUp({
              growthAttributionDetails,
              signUpMethod: "Email"
            });
            createSession(data, {
              onSuccess: session => {
                const userId = session.userId as string;
                createClient(
                  { userId, data: { userId } },
                  {
                    onSuccess: () => {
                      onSuccess?.();

                      if (signUpAsMember) {
                        window.open("/member/onboarding/customizing", "_self");
                      }
                    },
                    onError: error => {
                      if (error.response?.status === 400) {
                        onSuccess?.();
                      }
                    }
                  }
                );
              }
            });
          }
        }
      );
    } else {
      createSession(data, {
        onSuccess: () => {
          onSuccess?.();
        }
      });
    }
  };

  const imgProps: any = {
    src: logo
  };

  return (
    <>
      <WSFlexBox.Center>
        <WSLogomark size="XS" />
      </WSFlexBox.Center>

      <WSDivider my="XL" />

      <WSElement mb="2XL">
        <WSText.Heading5>
          {isSignUp
            ? "Create a free Wingspan account"
            : "Sign in to your Wingspan account"}
        </WSText.Heading5>
        {isSignUp && (
          <WSIconList.Checks
            mt="S"
            items={[
              "Secure, 1-click payments",
              "Enable Autopay to pay on time, every time",
              "Manage multiple freelancers",
              "Automated tax documents"
            ]}
          />
        )}
      </WSElement>

      <WSFormOld
        onSubmit={onSubmit}
        defaultValues={{
          email: clientEmailToPrefill || "",
          password: ""
        }}
        validationSchema={Yup.object().shape({
          email: validatorEmail.required("Email is required"),
          password: validatorPassword.required("Password is required")
        })}
      >
        <WSFormOld.Field
          hidden={isPresetEmailVisible}
          name="email"
          label="Email"
          mb="M"
          component={WSTextInput}
          componentProps={{
            type: "email"
          }}
        />

        {isPresetEmailVisible && (
          <WSPanel mb="M" p="S">
            <WSFlexBox alignItems="center">
              <WSAvatar.Icon icon="user" mr="S" />
              <WSElement>
                <WSText>{clientEmailToPrefill}</WSText>
                <WSButton.Link
                  type="button"
                  onClick={() => {
                    modalNotYou.open({
                      email: clientEmailToPrefill || "",
                      onChange: () => {
                        setIsPresetEmailVisible(false);
                      },
                      isSignUp
                    });
                  }}
                >
                  Not you?
                </WSButton.Link>
              </WSElement>
            </WSFlexBox>
          </WSPanel>
        )}

        <WSFormOld.Field
          name="password"
          label="Password"
          mb="S"
          component={WSTextInput}
          componentProps={{
            type: "password"
          }}
        />

        <WSButton.Link
          onClick={() => {
            history.push("/payment/reset-password");
          }}
        >
          Forgot password?
        </WSButton.Link>

        <WSButton.Primary
          mt="XL"
          fullWidth
          loading={
            createUserMeta.isLoading ||
            createSessionMeta.isLoading ||
            createClientMeta.isLoading
          }
          name={isSignUp ? "signUp" : "signIn"}
        >
          {isSignUp ? "Create account" : "Sign in"}
        </WSButton.Primary>

        <WSErrorMessage
          mt="M"
          contextKey="Auth"
          error={
            formError ||
            createUserMeta.error ||
            createSessionMeta.error ||
            createClientMeta.error
          }
        />
      </WSFormOld>

      <WSDivider my="S" label="or" />

      <WSElement>
        <GoogleAuthButton
          clientEmailToPrefill={clientEmailToPrefill}
          isLoading={createSessionWithGoogleAuthTokenMeta.isLoading}
          onLoginSuccess={token => {
            createSessionWithGoogleAuthToken(
              {
                idToken: token
              },
              {
                onSuccess: () => {
                  if (isSignUp) {
                    analytics.track.signUp({
                      growthAttributionDetails,
                      signUpMethod: "Google"
                    });
                  }
                  onSuccess?.();

                  if (signUpAsMember) {
                    window.open("/member/onboarding/customizing", "_self");
                  }
                }
              }
            );
          }}
          onLoginFailure={() => {
            setFormError(
              "Sorry! There was an error signing in with your Google account"
            );
          }}
        >
          {isSignUp ? "Sign up" : "Sign in"} with Google
        </GoogleAuthButton>
      </WSElement>

      <WSDivider
        mt="2XL"
        mb="S"
        label={isSignUp ? "Have an account?" : "Don’t have an account?"}
      />
      <WSButton.Link
        onClick={() => {
          if (isSignUp) {
            setIsSignUp(false);
          } else if (clientEmailToPrefill) {
            modalCreateAccountWarning.open({
              onContinue: () => {
                setIsSignUp(true);
              }
            });
          } else {
            setIsSignUp(true);
          }
        }}
        fullWidth
        name={isSignUp ? "signIn" : "signUp"}
      >
        {isSignUp ? "Sign in" : "Create an account"}
      </WSButton.Link>

      <WSText.ParagraphSm mt="2XL" color="gray600">
        By proceeding you agree to Wingspan's{" "}
        <a
          href={WS_LINKS.wingspanTosAcceptance}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>{" "}
        {"& "}
        <a
          href={WS_LINKS.wingspanPrivacyPolicyAcceptance}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        . This site is protected by reCAPTCHA and the Google{" "}
        <a
          href={WS_LINKS.googlePrivacyPolicy}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href={WS_LINKS.googleTermOfService}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{" "}
        apply.
      </WSText.ParagraphSm>
    </>
  );
};
