import { yupResolver } from "@hookform/resolvers";
import React from "react";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  UnpackNestedValue,
  useForm,
  UseFormMethods
} from "react-hook-form";
import { deepTrim } from "../../../utils/deepTrim";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../WSElement/WSElement.component";
import { WSFormContext } from "./WSFormContext";
import { WSFormDebug } from "./WSFormDebug";
import { WSFormField } from "./WSFormField";
import { WSFormSubmitButton } from "./WSFormSubmitButton";
import { WSFormValue } from "./WSFormValue";
import { WSFormValues } from "./WSFormValues";

export type WSFormProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  WSElementProps,
  "onSubmit"
> & {
  onSubmit?: (data: UnpackNestedValue<TFieldValues>) => void;
  defaultValues?: UnpackNestedValue<DeepPartial<TFieldValues>>;
  validationSchema?: Parameters<typeof yupResolver>[0];
  children:
    | ((context: UseFormMethods<TFieldValues>) => React.ReactNode)
    | React.ReactNode;
};

function Form<TFieldValues extends FieldValues = FieldValues>(
  props: WSFormProps<TFieldValues>,
  ref: React.Ref<HTMLFormElement>
) {
  const {
    children,
    className,
    onSubmit,
    validationSchema,
    defaultValues,
    ...otherProps
  } = props;
  const methods = useForm({
    defaultValues,
    resolver: (values) => {
      const trimmedValues = deepTrim(values);

      return validationSchema
        ? yupResolver(validationSchema)(trimmedValues)
        : ({
            values: trimmedValues,
            errors: {}
          } as any);
    }
  });
  const { layoutProps, systemProps, ...formProps } = detachLayoutProps(
    otherProps
  );

  return (
    <FormProvider {...methods}>
      <WSElement
        as="form"
        ref={ref}
        className={className}
        onSubmit={async (event) => {
          event.stopPropagation();

          if (onSubmit) {
            await methods.handleSubmit(onSubmit)(event);

            const form = event.target as HTMLElement;
            const element = form.querySelector("[data-error]") as HTMLElement;
            if (element) {
              setTimeout(() => {
                element?.focus();
              }, 200);

              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }
        }}
        {...layoutProps}
        {...formProps}
      >
        {children instanceof Function ? children(methods) : children}
      </WSElement>
    </FormProvider>
  );
}

type FormForwardRef = <TFieldValues extends FieldValues = FieldValues>(
  props: WSFormProps<TFieldValues> & {
    ref?: React.ForwardedRef<HTMLFormElement>;
  }
) => ReturnType<typeof Form>;

const WSFormMain = React.forwardRef(Form) as FormForwardRef;

export const WSForm = Object.assign(WSFormMain, {
  Field: WSFormField,
  Context: WSFormContext,
  Value: WSFormValue,
  Values: WSFormValues,
  SubmitButton: WSFormSubmitButton,
  Debug: WSFormDebug
});
