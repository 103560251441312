import {
  WSButton,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { Layout } from "../../Onboarding/components/Layout";

export const RouteMissingDataPending = () => {
  const history = useHistory();

  return (
    <Layout title="Additional Info">
      <WSList gap="2XL">
        <WSPanel>
          <WSText weight="medium">Awaiting verification</WSText>
          <WSText.ParagraphSm color="gray500">
            Thank you for providing the necessary information and documents.
            Check back on your “Get started” homepage for updates.
            <br />
            <br />
            The review period typically takes about one (1) business day, during
            this time you may not be able to send/receive payments or access
            banking features.
          </WSText.ParagraphSm>
        </WSPanel>

        <WSButton
          fullWidth
          onClick={() => {
            history.push("/member");
          }}
        >
          Continue
        </WSButton>
      </WSList>
    </Layout>
  );
};
