import {
  useModalContext,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSLoader,
  WSMessageBox,
  WSModal,
  WSPanel,
  WSSidebar,
  WSSwitchInput,
  WSText
} from "@wingspanhq/fe-component-library";

import {
  AutoPayStrategy,
  CustomFieldResourceType,
  DeductionStatus,
  ICollaboratorSchema,
  ICollaboratorV2,
  MemberClientStatus,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import orderBy from "lodash/orderBy";
import React, { useMemo, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSPersistentUpgradeButton } from "../../components/Membership/WSPersistentUpgradeButton";
import { SidePanelSection } from "../../components/SidePanel";
import { UrlIdKey } from "../../constants/routing";
import { TaxFormStatusBadge } from "../../modules/1099NECFiling/deprecated/components/TaxFormStatusBadge";
import { createPPL } from "../../PersonalPayLink/utils";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../query/integrations/selectors";
import {
  QUERY_COLLABORATOR,
  QUERY_COLLABORATORS
} from "../../query/payments/keys";
import { useUpdateCollaborator } from "../../query/payments/mutations";
import {
  useCollaboratorGroupsQuery,
  useCollaboratorQuery,
  useCollaboratorV2Query
} from "../../query/payments/queries";
import {
  getCollaboratorCompany,
  getCollaboratorEmail,
  getCollaboratorName,
  getCurrentYearForm1099Balance
} from "../../query/payments/selectors";
import { useClientQuery, useUserProfile } from "../../query/users/queries";
import { WSQueries } from "../../query/WSQuery";
import { paymentsService } from "../../services/payments";
import { WSFrontendFeature } from "../../Settings/utils/subscriptionUtils";
import { BankAccount } from "../../shared/components/BankAccount";
import { BankCard } from "../../shared/components/BankCard";
import { selectorIsEnterpriseUser } from "../../shared/selectors/selectorIsEnterpriseUser";
import { selectorIsForm1099CorrectionPresent } from "../../shared/selectors/selectorIsForm1099CorrectionPresent";
import { WS_LINKS } from "../../types/wsLinks";
import { getParentPath } from "../../utils/goToParentRoute";
import { openInNewTab } from "../../utils/openInNewTab";
import { CollaboratorOnboardingRequirements } from "../components/CollaboratorOnboardingRequirements/CollaboratorOnboardingRequirements";
import { CollaboratorsGroupsForm } from "../components/CollaboratorsGroupsForm/CollaboratorsGroupsForm";
import { CollaboratorDeductionsList } from "../components/Deductions/DeductionsList";
import { DocumentsSection } from "../components/DocumentsSection";
import {
  EDIT_COLLABORATOR_ADDITIONAL_INFO_MODAL,
  EditCollaboratorAdditionalInfoModal
} from "../components/EditCollaboratorAdditionalInfoModal/EditCollaboratorAdditionalInfoModal";
import {
  EDIT_COLLABORATOR_INTEGRATIONS_MODAL,
  EditCollaboratorIntegrationsModal
} from "../components/EditCollaboratorIntegrationsModal/EditCollaboratorIntegrationsModal";
import { QuickbooksHistory } from "../components/QuickbooksHistory/QuickbooksHistory";
import TaxDocumentsSection, {
  DocumentsSectionType
} from "../components/TaxDocumentsSection";
import getDocumentsMetaFromProfile from "../components/TaxDocumentsSection/getDocumentsMetaFromProfile";
import { AddCollaboratorToGroupModal } from "./collaborators/AddCollaboratorToGroupModal";
import {
  DELETE_COLLABORATOR_MODAL,
  DeleteCollaboratorModal
} from "./collaborators/modals/DeleteCollaboratorModal";
import { useCustomFieldsAll } from "../../query/customFields/queries/useCustomFieldsAll";
import { getIntegrationUserSelectLabel } from "../../modules/Integrations/utils/getIntegrationUserSelectLabel";

const PAGE_TITLE = "Contractor profile";

type Props = RouteComponentProps<
  { [UrlIdKey.Collaborator]: string },
  {},
  { backPath: string }
>;

export const InvoicesCollaboratorDetails: React.FC<Props> = ({
  match,
  location
}) => {
  const history = useHistory();
  const backPath = location.state?.backPath || getParentPath(history);
  const collaboratorId = match.params[UrlIdKey.Collaborator];
  const collaboratorQuery = useCollaboratorQuery(collaboratorId);
  const collaboratorV2Query = useCollaboratorV2Query(
    collaboratorQuery.data?.memberId || "",
    {
      enabled: !!collaboratorQuery.data?.memberId
    }
  );
  const qGroups = useCollaboratorGroupsQuery();

  return (
    <WSSidebar
      onClose={() => {
        history.push(backPath);
      }}
      header={PAGE_TITLE}
    >
      <WSQueries queries={{ collaboratorQuery, collaboratorV2Query, qGroups }}>
        {({
          collaboratorQuery: { data: collaborator },
          collaboratorV2Query: { data: collaboratorV2 }
        }) => (
          <Inner
            collaborator={collaborator}
            collaboratorV2={collaboratorV2}
            backPath={backPath}
          />
        )}
      </WSQueries>
    </WSSidebar>
  );
};

const Inner: React.FC<{
  collaborator: ICollaboratorSchema;
  collaboratorV2: ICollaboratorV2;
  backPath: string;
}> = ({ collaborator, collaboratorV2, backPath }) => {
  const history = useHistory();
  const [updateCollaborator, updateCollaboratorMeta] = useUpdateCollaborator(
    collaborator.collaboratorId
  );
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const user = userQuery.data;
  const { createdBy, ...additionalData } = collaborator?.labels || {};
  const { openModal } = useModalContext();
  const company = getCollaboratorCompany(collaborator);
  const name = getCollaboratorName(collaborator);

  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];

  const qGroups = useCollaboratorGroupsQuery();
  const qFeatureFlags = useFeatureFlags();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const qIntegrationsQuickbooksExpenseAccounts = useIntegrationsQuickbooksAccountExpenses();

  const form1099Balances = useMemo(
    () => getCurrentYearForm1099Balance(collaboratorV2),
    [collaboratorV2]
  );

  const integrationState = qIntegrationsQuickbooks.data;
  const integrationQuickbooksVendors =
    qIntegrationsQuickbooksVendors.data || [];
  const integrationQuickbooksExpenseAccounts =
    qIntegrationsQuickbooksExpenseAccounts.data || [];

  const isQuickbooksActive =
    integrationState &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(integrationState));

  const is1099DocumentsEnabled = qFeatureFlags.data?.nec1099Disputes;

  const defaultAccount = integrationQuickbooksExpenseAccounts.find(
    acct => acct.accountId === integrationState?.defaults?.expenseAccountId
  );

  const mappedVendor = integrationQuickbooksVendors.find(
    vendor => collaborator.integration?.quickbooks?.vendorId === vendor.vendorId
  );
  const mappedExpenseAccount = integrationQuickbooksExpenseAccounts.find(
    acct =>
      acct.accountId === collaborator.integration?.quickbooks?.expenseAccountId
  );
  const collaboratorGroup = (qGroups.data || []).find(cg =>
    collaborator.collaboratorGroupIds?.includes(cg.collaboratorGroupId)
  );

  const documents = useMemo(() => getDocumentsMetaFromProfile(collaboratorV2), [
    collaboratorV2
  ]);

  const isEnterpriseUser = user && selectorIsEnterpriseUser(user);

  const uploadDocumentsEnabled = qFeatureFlags?.data?.uploadDocuments;
  return (
    <>
      <WSPanel noBorder mb="XL">
        <WSText.Heading5 mb="M" data-testid="collaboratorName">
          {company || name}
        </WSText.Heading5>

        {company && (
          <WSText weight="medium" mb="XL">
            {name}
          </WSText>
        )}

        {collaborator.status === MemberClientStatus.Pending ? (
          <WSMessageBox.Warning noBorder mt="XL">
            <WSFlexBox.Center>
              <WSFlexBox.CenterY mt="M">
                <WSIcon
                  block
                  name="alert-circle"
                  size="S"
                  color="amber400"
                  mr="M"
                />
                <WSText weight="medium" inline>
                  Not yet eligible for payments
                </WSText>
              </WSFlexBox.CenterY>
            </WSFlexBox.Center>
            <WSText.ParagraphSm mt="XL">
              Your invite was sent. We're still waiting for this person to
              complete their onboarding requirements before they can receive
              payments.
            </WSText.ParagraphSm>
            {/*<WSButton destructive
              mt="XL"
              fullWidth
              onClick={() => {
                sendCollaboratorInvite(collaborator.collaboratorId, {
                  onSuccess: () => {
                    openSnackbar({
                      message: `Invite re-sent to ${collaborator.member.user
                        .profile?.firstName || collaborator.member.user.email}`
                    });
                  }
                });
              }}
            >
              Resend invite
            </WSButton>*/}
          </WSMessageBox.Warning>
        ) : (
          <WSPersistentUpgradeButton
            feature={WSFrontendFeature.InviteAndPayCollaborator}
            kind="Secondary"
            onClick={() => {
              if (collaborator.member.user.tag) {
                openInNewTab(
                  createPPL(collaborator.member.user.tag, {
                    includeProtocol: true
                  })
                );
              }
            }}
            fullWidth
          >
            Send payment
          </WSPersistentUpgradeButton>
        )}
      </WSPanel>

      <WSPanel noBorder mb="XL">
        <WSText.Heading5 mb="M">Contractor info</WSText.Heading5>

        <WSText.ParagraphSm mb="XS" color="gray500">
          Email
        </WSText.ParagraphSm>
        <WSText mb="XL">{getCollaboratorEmail(collaborator)}</WSText>

        <CollaboratorsGroupsForm
          collaboration={collaborator}
          collaboratorName={getCollaboratorName(collaborator)}
        />
      </WSPanel>

      {qFeatureFlags.data?.collaboratorOnboardingRequirements && (
        <WSPanel noBorder mb="XL">
          <CollaboratorOnboardingRequirements
            collaboratorId={collaborator.collaboratorId}
          />
        </WSPanel>
      )}

      {uploadDocumentsEnabled ? (
        <WSPanel noBorder mb="XL">
          <DocumentsSection
            mb="XL"
            type={DocumentsSectionType.Collaborator}
            payeeId={collaborator.memberId}
            payerId={collaborator.clientId}
          />
        </WSPanel>
      ) : null}

      {is1099DocumentsEnabled && documents && documents.length > 0 && (
        <TaxDocumentsSection
          mb="XL"
          type={DocumentsSectionType.Collaborator}
          documents={documents}
          renderBadge={({ data }) => (
            <TaxFormStatusBadge
              status={data.status}
              isCorrection={
                form1099Balances
                  ? selectorIsForm1099CorrectionPresent(form1099Balances)
                  : false
              }
            />
          )}
          testid="documents-section"
        />
      )}

      {(collaboratorCustomFields.length > 0 || isEnterpriseUser) && (
        <WSPanel noBorder mb="XL">
          <EditCollaboratorAdditionalInfoModal
            collaboratorId={collaborator.collaboratorId}
          />
          <WSFlexBox wrap="nowrap" justify="space-between">
            <WSText.Heading5 mb="M">Custom fields</WSText.Heading5>
            <WSButton.Link
              type="button"
              onClick={() => {
                openModal(EDIT_COLLABORATOR_ADDITIONAL_INFO_MODAL);
              }}
            >
              Edit
            </WSButton.Link>
          </WSFlexBox>
          {isEnterpriseUser ? (
            <WSElement mb="M">
              <WSText.ParagraphSm color="gray500">
                External Collaborator ID
              </WSText.ParagraphSm>
              <WSText>{collaborator.clientData?.externalId || "–"}</WSText>
            </WSElement>
          ) : null}
          {orderBy(collaboratorCustomFields, "type", "desc").map(field => {
            return (
              <WSElement mb="M">
                <WSText.ParagraphSm color="gray500">
                  {field.name}
                </WSText.ParagraphSm>
                <WSText>
                  {field.type === WSDataTypes.Boolean
                    ? additionalData[field.key] === "true"
                      ? "Yes"
                      : "No"
                    : additionalData[field.key] || "-"}
                </WSText>
              </WSElement>
            );
          })}
        </WSPanel>
      )}

      <CollaboratorDeductionsList
        collaboratorId={collaborator.collaboratorId}
        clientId={collaborator.clientId}
        memberId={collaborator.memberId}
        collaboratorEmail={collaborator.member.user.email}
        onRowClick={d => {
          if (d.status === DeductionStatus.Pending) {
            history.push(
              `${history.location.pathname}/deduction/scheduled/${d.deductionId}?${history.location.search}`
            );
          } else {
            history.push(
              `${history.location.pathname}/deduction/completed/${d.deductionId}?${history.location.search}`
            );
          }
        }}
      />

      {isQuickbooksActive && (
        <>
          <EditCollaboratorIntegrationsModal
            collaboratorId={collaborator.collaboratorId}
          />
          <WSPanel
            noBorder
            mb="XL"
            data-testid="collaboratorIntegrationMapping"
          >
            <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="M">
              <WSText.Heading5>Integrations Mappings</WSText.Heading5>
              <WSButton.Link
                onClick={() => {
                  openModal(EDIT_COLLABORATOR_INTEGRATIONS_MODAL);
                }}
              >
                Edit
              </WSButton.Link>
            </WSFlexBox.CenterY>

            <WSText.ParagraphSm mb="XS" color="gray500">
              QBO Vendor
            </WSText.ParagraphSm>
            <WSText mb="M">
              {mappedVendor
                ? getIntegrationUserSelectLabel(mappedVendor)
                : "Not mapped"}
            </WSText>
            <WSText.ParagraphSm mb="XS" color="gray500">
              Default QBO Expense Account
            </WSText.ParagraphSm>
            <WSText>
              {mappedExpenseAccount
                ? mappedExpenseAccount.fullyQualifiedName
                : `Not mapped, use default "${defaultAccount?.fullyQualifiedName}"`}
            </WSText>
          </WSPanel>

          <QuickbooksHistory
            Wrapper={WSPanel}
            noBorder
            entityId={collaborator.collaboratorId}
          />
        </>
      )}

      <AutopaySection collaborator={collaborator} />

      <WSPanel noBorder mt="XL">
        <DeleteCollaboratorModal
          backPath={backPath}
          collaboratorId={collaborator.collaboratorId}
          collaboratorName={getCollaboratorName(collaborator)}
        />
        <WSButton.Secondary
          name="deleteCollaborator"
          destructive
          onClick={() => {
            openModal(DELETE_COLLABORATOR_MODAL);
          }}
          loading={updateCollaboratorMeta.isLoading}
        >
          Delete contractor
        </WSButton.Secondary>
      </WSPanel>
      <AddCollaboratorToGroupModal />
    </>
  );
};

const AutopaySection: React.FC<{
  collaborator: ICollaboratorSchema;
}> = ({ collaborator }) => {
  const { openModal, closeModal } = useModalContext();
  const CONFIRM_AUTOPAY_MODAL_NAME = "CONFIRM_AUTOPAY_MODAL_NAME";

  const userId = useUserId();
  const qFeatureFlags = useFeatureFlags();
  const qClient = useClientQuery(userId);

  const collaboratorId = collaborator.collaboratorId;
  const companyName =
    getCollaboratorCompany(collaborator) ||
    getCollaboratorName(collaborator) ||
    "";

  const [agreement, setAgreement] = useState(false);

  const dependencies = [
    QUERY_COLLABORATORS,
    [QUERY_COLLABORATOR, collaboratorId],
    [QUERY_COLLABORATORS, collaboratorId]
  ];

  const [onStartAutopay, onStartAutopayMeta] = useWSMutation(
    async () => {
      await paymentsService.collaborator.update(collaboratorId, {
        clientData: {
          autoPayStrategy: AutoPayStrategy.All
        }
      });
    },
    {
      onSuccess: () => closeModal(CONFIRM_AUTOPAY_MODAL_NAME),
      dependencies
    }
  );

  const [onStopAutopay, onStopAutopayMeta] = useWSMutation(
    async () => {
      await paymentsService.collaborator.update(collaboratorId, {
        clientData: {
          autoPayStrategy: AutoPayStrategy.None
        }
      });
    },
    {
      dependencies
    }
  );

  return (
    <WSQueries
      queries={{ qFeatureFlags, qClient }}
      renderLoader={() => null}
      renderErrors={() => null}
    >
      {({ qFeatureFlags, qClient }) => {
        const clientProfile = qClient.data.profile;

        return qFeatureFlags.data.autopayV1 ? (
          <SidePanelSection>
            <WSFlexBox.CenterY justify="space-between" mb="XL">
              <WSText.Heading5>Autopay</WSText.Heading5>

              <WSElement>
                {onStartAutopayMeta.isLoading || onStopAutopayMeta.isLoading ? (
                  <WSLoader.Spinner size="S" />
                ) : (
                  <WSSwitchInput
                    name="autopay"
                    value={
                      collaborator.clientData?.autoPayStrategy ===
                      AutoPayStrategy.All
                    }
                    onChange={async () => {
                      if (
                        collaborator.clientData?.autoPayStrategy ===
                        AutoPayStrategy.All
                      ) {
                        await onStopAutopay();
                      } else {
                        setAgreement(false);
                        openModal(CONFIRM_AUTOPAY_MODAL_NAME);
                      }
                    }}
                  />
                )}
              </WSElement>
            </WSFlexBox.CenterY>

            <WSText mt="M">
              {`Automatically charge your chosen payment method on the due date of each invoice ${companyName} sends.`}
            </WSText>

            <WSModal
              name={CONFIRM_AUTOPAY_MODAL_NAME}
              size="S"
              title={`Consent to paying ${companyName} automatically?`}
            >
              <WSText mt="XL">
                {`Easily automate your ${companyName} invoice payments with our Autopay feature.`}
              </WSText>
              <WSText mt="XL">
                By enrolling, you authorize us to securely charge your payment
                method for each invoice, ensuring timely and hassle-free
                transactions. Review the terms, provide your consent, and let
                Autopay manage the rest.
              </WSText>

              <WSText weight="medium" my="XL">
                Payment Method
              </WSText>
              {clientProfile.defaultPaymentMethod?.accountId && (
                <WSPanel mb="2XL">
                  <BankAccount
                    accountId={clientProfile.defaultPaymentMethod.accountId}
                  />
                </WSPanel>
              )}

              {clientProfile.defaultPaymentMethod?.paymentMethodId && (
                <WSPanel mb="2XL">
                  <BankCard
                    type="credit"
                    cardId={clientProfile.defaultPaymentMethod.paymentMethodId}
                  />
                </WSPanel>
              )}

              <WSCheckboxToggle
                my="XL"
                name="agreenment"
                label={`I agree to enroll in Autopay for ${companyName}`}
                value={agreement}
                onChange={value => {
                  setAgreement(value);
                }}
              />

              <WSButton
                disabled={!agreement}
                fullWidth
                onClick={onStartAutopay}
                loading={onStartAutopayMeta.isLoading}
              >
                Continue
              </WSButton>

              <WSText.ParagraphXs color="gray500" mt="XL">
                You can always turn off autopay:{" "}
                <WSButton.Link
                  size="S"
                  onClick={() => openInNewTab(WS_LINKS.autopayHelpGuide)}
                >
                  Autopay Help Guide
                </WSButton.Link>
              </WSText.ParagraphXs>
            </WSModal>
          </SidePanelSection>
        ) : null;
      }}
    </WSQueries>
  );
};
