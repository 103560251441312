import {
  WSElement,
  WSField,
  WSForm,
  WSInputMask
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import styles from "./index.module.scss";

export type FormDataPhoneNumber = {
  countryCode: string;
  phoneNumber: string;
};

export const validationSchemaPhoneNumber = Yup.object().shape({
  countryCode: Yup.string()
    .required("Required")
    .min(1, "Required"),
  phoneNumber: Yup.string()
    .when("countryCode", {
      is: "1",
      then: Yup.string().length(10, "Phone number should be 10 characters"),
      otherwise: Yup.string()
        .min(5, "Phone number should at least 5 characters")
        .max(15, "Limit is 15 characters")
    })
    .required("Required")
});

type Props = {
  name: string;
  label?: string;
  required?: boolean;
};

export const phoneFormDataToE164 = (data: FormDataPhoneNumber) =>
  `+${data.countryCode}${data.phoneNumber}`;

export const FormPartialPhoneNumber: React.FC<Props> = ({
  name,
  label,
  required
}) => {
  return (
    <WSField label={label} as="div" required={required}>
      <WSElement className={styles.container}>
        <WSForm.Field
          name={name + ".countryCode"}
          component={WSInputMask}
          componentProps={{
            mask: "+9?999",
            slotChar: "",
            unmask: true,
            placeholder: "+1",
            autoComplete: "tel-country-code"
          }}
        />

        <WSForm.Field
          name={name + ".phoneNumber"}
          component={WSInputMask}
          componentProps={{
            mask: "(999) 999-9999",
            unmask: true,
            placeholder: "(999) 999-9999",
            autoComplete: "tel-national"
          }}
        />
      </WSElement>
    </WSField>
  );
};
