import {
  BulkStatus,
  IBulkBatch,
  IBulkCollaboratorBatch,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";
import { TruncateOptions } from "lodash";
import truncate from "lodash/truncate";
import { isNil, isNotNil } from "../../../utils";

export interface ILabelsDictionary {
  [label: string]: string | boolean | null;
}
interface IBulkBatchWithFilename {
  status: BulkStatus;
  labels: ILabelsDictionary;
}

/**
 *
 * @param bulkBatch bulk batch response
 * @param options lodash/truncate options
 * @returns string
 */
export function getUploadedFilename(
  bulkBatch: IBulkBatchWithFilename,
  options?: TruncateOptions
): string {
  if (isNil(bulkBatch)) {
    return "";
  }
  const filename = options
    ? truncate(bulkBatch.labels.filename as string, options)
    : (bulkBatch.labels.filename as string);

  if (bulkBatch.status === BulkStatus.Open) {
    return filename || "";
  } else {
    return filename || "Unnamed Payable Batch";
  }
}
