import React from "react";
import { useQueryCustomerEntity } from "../../../query/onboarding/queries/useQueryCustomerEntity";
import { Form } from "./Form";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = {
  onSuccess?: () => void;
  onBack?: () => void;
  withPanel?: boolean;
};

export const FormManuallyAddInternationalAccount: React.FC<Props> = ({
  onSuccess,
  onBack,
  withPanel
}) => {
  const queryCustomerEntity = useQueryCustomerEntity();
  const [submit, submitMeta] = useMutationSubmit({
    onSuccess
  });

  if (!queryCustomerEntity.data) {
    return null;
  }

  return (
    <Form
      onSubmit={data => {
        submit({ data });
      }}
      error={submitMeta.error}
      isLoading={submitMeta.isLoading}
      defaultValues={{
        country: queryCustomerEntity.data.country
      }}
      onBack={onBack}
      withPanel={withPanel}
    />
  );
};
