import { WSLoader } from "@wingspanhq/fe-component-library";
import { Route, Switch } from "react-router-dom";
import { InvoicesCreate } from "../../Invoices/screens/InvoicesCreate";
import { InvoicesDuplicateInvoice } from "../../Invoices/screens/InvoicesDuplicateInvoice";
import { InvoicesDuplicateInvoiceTemplate } from "../../Invoices/screens/InvoicesDuplicateInvoiceTemplate";
import { InvoicesInvoiceConfirmation } from "../../Invoices/screens/InvoicesInvoiceConfirmation";
import { InvoicesInvoiceEdit } from "../../Invoices/screens/InvoicesInvoiceEdit";
import { InvoicesInvoiceTemplateConfirmation } from "../../Invoices/screens/InvoicesInvoiceTemplateConfirmation";
import { InvoicesInvoiceTemplateEdit } from "../../Invoices/screens/InvoicesInvoiceTemplateEdit";
import { InvoicesZeroState } from "../../Invoices/screens/InvoicesZeroState";
import { useUserId } from "../../query/hooks/helpers";
import { useQueryVerifications } from "../../query/onboarding/queries/useQueryVerifications";
import { useActivities } from "../../query/users/queries";
import { useBrowserPageTitle } from "../../shared/hooks/useBrowserPageTitle";
import { selectorIsOnboardingComplete } from "../../shared/selectors/selectorIsOnboardingComplete";
import { RouteDashboard } from "./routes/RouteDashboard";
import { RouteScheduled } from "./routes/RouteScheduled";
import { RouteSearch } from "./routes/RouteSearch";

export const INVOICING_PATH = "/member/invoices";

export const Invoicing: React.FC = () => {
  useBrowserPageTitle("Invoicing");

  const userId = useUserId();
  const queryActivity = useActivities(userId);
  const queryVerifications = useQueryVerifications();

  return queryActivity.isLoading || queryVerifications.isLoading ? (
    <WSLoader.Spinner />
  ) : selectorIsOnboardingComplete(
      queryActivity.data,
      queryVerifications.data
    ) ? (
    <Switch>
      <Route
        path={INVOICING_PATH + "/:invoiceId/edit"}
        component={InvoicesInvoiceEdit}
      />
      <Route
        path={INVOICING_PATH + "/:invoiceId/duplicate"}
        exact
        component={InvoicesDuplicateInvoice}
      />
      <Route
        path={INVOICING_PATH + "/template/:invoiceTemplateId/edit"}
        component={InvoicesInvoiceTemplateEdit}
      />
      <Route
        path={INVOICING_PATH + "/template/:invoiceTemplateId/duplicate"}
        exact
        component={InvoicesDuplicateInvoiceTemplate}
      />
      <Route
        path={INVOICING_PATH + "/:invoiceId/send-success"}
        exact
        component={InvoicesInvoiceConfirmation}
      />
      <Route
        path={INVOICING_PATH + "/template/:invoiceTemplateId/send-success"}
        exact
        component={InvoicesInvoiceTemplateConfirmation}
      />

      <Route path={INVOICING_PATH + "/scheduled"} component={RouteScheduled} />
      <Route path={INVOICING_PATH + "/create"} component={InvoicesCreate} />
      <Route path={INVOICING_PATH + "/search"} component={RouteSearch} />
      <Route path={INVOICING_PATH} component={RouteDashboard} />
    </Switch>
  ) : (
    <InvoicesZeroState />
  );
};
