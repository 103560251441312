import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { RoutePayeesDashboard } from "./routes/RouteDashboard";
import { RoutePayeeDetails } from "./routes/RoutePayeeDetails";
import {
  PAYEES_ARCHIVED_PATH,
  PAYEES_DETAILS_ROUTE,
  PAYEES_ENGAGEMENTS_DETAILS_PATH,
  PAYEES_ENGAGEMENTS_LIST_PATH,
  PAYEES_GROUPS_LIST_PATH,
  PAYEES_ROOT_PATH,
  PAYEES_SEARCH_PATH
} from "./utils";
import { RouteArchivedPayees } from "./routes/RouteArchivedPayees";
import { RoutePayeesSearch } from "./routes/RoutePayeesSearch/RoutePayeesSearch";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { RouteCreateEngagement } from "./routes/RouteCreateEngagement";
import { RouteEngagementDetails } from "./routes/RouteEngagementDetails";

export const PayeesIndex: React.FC = () => {
  useBrowserPageTitle("Contractors");
  const queryFeatureFlags = useFeatureFlags();

  return (
    <Switch>
      <Route path={PAYEES_SEARCH_PATH} component={RoutePayeesSearch} />
      <Route
        path={`${PAYEES_ROOT_PATH}/create-engagement`}
        component={RouteCreateEngagement}
      />
      <Route
        path={PAYEES_ENGAGEMENTS_DETAILS_PATH}
        component={RouteEngagementDetails}
      />
      <Route
        path={
          queryFeatureFlags.data?.engagementsList
            ? PAYEES_ENGAGEMENTS_LIST_PATH
            : PAYEES_GROUPS_LIST_PATH
        }
        component={RoutePayeesDashboard}
      />

      <Route
        path={PAYEES_ARCHIVED_PATH}
        component={RouteArchivedPayees}
        exact
      />
      <Route path={PAYEES_DETAILS_ROUTE} component={RoutePayeeDetails} />
      <Route path={PAYEES_ROOT_PATH} component={RoutePayeesDashboard} />

      <Redirect from="*" to={PAYEES_ROOT_PATH} />
    </Switch>
  );
};
