import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { PaymentsSetup } from "../../modules/PaymentsSetup";
import { SetupParams } from "../../modules/PaymentsSetup/types";
import { selectorPayoutMethodsFromQueryParam } from "../../shared/selectors/selectorPayoutMethodsFromQueryParam";

export const InvoicesPaymentsSetup = () => {
  const history = useHistory();

  const params = useMemo<SetupParams>(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tinParam = urlSearchParams.get("tin");
    const enabledPayoutMethodsParam = urlSearchParams.get(
      "enabled_payout_methods"
    );

    const skipTaxVerification = tinParam === "0";
    const availablePayoutMethods = enabledPayoutMethodsParam
      ? selectorPayoutMethodsFromQueryParam(enabledPayoutMethodsParam)
      : undefined;

    return {
      skipTaxVerification,
      availablePayoutMethods
    };
  }, []);

  return (
    <PaymentsSetup
      basePath="/member/invoices/set-up"
      onBack={() => {
        history.push("/member/invoices");
      }}
      onFinish={() => {
        history.push("/member/invoices/complete");
      }}
      params={params}
    />
  );
};
