import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { WSQuery } from "../../../query/WSQuery";

export const TURN_ON_PAYMENTS = "TURN_ON_PAYMENTS";

export const TaxesModalTurnOnPayment: React.FC = () => {
  const memberId = useUserId();
  const history = useHistory();
  const { closeModal } = useModalContext();
  const qMemberProfile = useMemberProfile(memberId);

  return (
    <WSModal name={TURN_ON_PAYMENTS} size="S" title="Enable payments">
      <WSQuery query={qMemberProfile}>
        {member => {
          const withholdingTax = member.data.profile?.withholdings?.tax;

          return (
            <>
              <WSText mb="XS">
                Set aside {withholdingTax?.rate}% of each invoice paid through
                Wingspan.
              </WSText>
              <WSButtons mt="2XL" forceFullWidth format="modal">
                <WSButton.Primary
                  size="M"
                  onClick={async () => {
                    history.push("/member/cip");

                    closeModal(TURN_ON_PAYMENTS);
                  }}
                >
                  Enable payments
                </WSButton.Primary>
                <WSButton.Tertiary
                  size="M"
                  onClick={() => {
                    closeModal(TURN_ON_PAYMENTS);
                  }}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </>
          );
        }}
      </WSQuery>
    </WSModal>
  );
};
