import {
  WSButton,
  WSButtons,
  WSGrid,
  WSPage,
  WSPreview,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import image1 from "../../assets/images/invoices-feature-1.png";
import image2 from "../../assets/images/invoices-feature-2.png";
import image3 from "../../assets/images/invoices-feature-3.png";
import image4 from "../../assets/images/invoices-feature-4.png";
import { useSetWSStore, useWSStore } from "../../store";

export const InvoicesZeroState: React.FC = () => {
  const isMobile = useIsMobile();
  const store = useWSStore();
  const setStore = useSetWSStore();
  const history = useHistory();

  return (
    <WSPage>
      <WSGrid>
        <WSGrid.Item span={{ l: "5" }}>
          <WSText.Heading4 mb="M">Get paid your way.</WSText.Heading4>
          <WSText mb={isMobile ? "XL" : "3XL"} color="gray600">
            Send invoices effortlessly with tools that make it easier for
            clients to pay up (and harder for them not to).
          </WSText>

          <WSButtons>
            <WSButton
              onClick={() => {
                history.push("/member/cip");
              }}
              name="enablePayments"
            >
              Set up Payments
            </WSButton>
            {store.productActivationAfterOnboarding && (
              <WSButton.Secondary
                onClick={() => {
                  history.push("/member/dashboard");
                  setStore({
                    productActivationAfterOnboarding: false
                  });
                }}
              >
                Explore Wingspan
              </WSButton.Secondary>
            )}
          </WSButtons>
        </WSGrid.Item>
      </WSGrid>

      <WSPreview
        mt={isMobile ? "2XL" : "5XL"}
        list={[
          {
            title: "Personal payment links",
            description:
              "Share your personal pay link. Get paid. It’s actually that simple.",
            image: image1,
            imageWidth: 392
          },
          {
            title: "Supercharged custom invoices",
            description:
              "Set up clients on retainer, add late fees, and manage who pays for processing fees.",
            image: image2,
            imageWidth: 315
          },
          {
            title: "Late invoice automation",
            description:
              "Never send another “following up” email again — Wingspan chases overdue invoices on your behalf.",
            image: image3,
            imageWidth: 373
          },
          {
            title: "Payment notifications",
            description:
              "Know exactly when payments hit your bank account, not just when clients say they’re sent.",
            image: image4,
            imageWidth: 396
          }
        ]}
      />
    </WSPage>
  );
};
