import {
  useModalContext,
  WSButton,
  WSCopyText,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSPill,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  AutoPayStrategy,
  ICollaboratorV2,
  IMemberClient
} from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo, useState } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";

import capitalize from "lodash/capitalize";
import { SidePanelSection } from "../../components/SidePanel";
import { Tooltip } from "../../components/Tooltip";
import { UrlIdKey } from "../../constants/routing";
import NEC1099FilingDetailsSummary from "../../modules/1099NECFiling/deprecated/NEC1099FilingDetailsSummary";
import { WSQueries } from "../../query/WSQuery";
import { useMemberPrivateFiles } from "../../query/files/queries";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers,
  useIntegrationsQuickbooksItems
} from "../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../query/integrations/selectors";
import {
  useMemberClientQuery,
  useMemberClientV2Query
} from "../../query/payments/queries";
import {
  getClientCompany,
  getClientName
} from "../../query/payments/selectors";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { getMemberCompanyName } from "../../query/users/selectors";
import { NEC_1099_SUMMARY_SLUG } from "../../shared/constants/nec1099";
import { copyText } from "../../utils/copyText";
import { getParentPath } from "../../utils/goToParentRoute";
import { ClientEligibilityRequirements } from "../components/ClientEligibilityRequirements/ClientEligibilityRequirements";
import {
  DELETE_CLIENT_MODAL,
  DeleteClientModal
} from "../components/DeleteClientModal/DeleteClientModal";
import { DocumentsSection } from "../components/DocumentsSection";
import {
  EDIT_CLIENT_INTEGRATIONS_MODAL,
  EditClientIntegrationsModal
} from "../components/EditClientIntegrationsModal/EditClientIntegrationsModal";
import {
  EDIT_CONTACT_INFO_MODAL,
  EditContactInfoModal
} from "../components/EditContactInfoModal/EditContactInfoModal";
import {
  EDIT_CONTACT_NAME_MODAL,
  EditContactNameModal
} from "../components/EditContactNameModal/EditContactNameModal";
import { QuickbooksHistory } from "../components/QuickbooksHistory/QuickbooksHistory";
import TaxDocumentsSection, {
  DocumentsSectionType
} from "../components/TaxDocumentsSection";
import getDocumentsMetaFromProfile from "../components/TaxDocumentsSection/getDocumentsMetaFromProfile";
import { getAutopayLink } from "../utils";
import styles from "./InvoicesContactDetails.module.scss";
import { getIntegrationUserSelectLabel } from "../../modules/Integrations/utils/getIntegrationUserSelectLabel";

const PAGE_TITLE = "Client profile";

type Props = RouteComponentProps<
  { [UrlIdKey.Client]: string },
  {},
  { backPath: string }
>;

export const InvoicesClientDetails: React.FC<Props> = ({
  match,
  location,
  history
}) => {
  const backPath = location.state?.backPath || getParentPath(history);
  const clientId = match.params[UrlIdKey.Client];
  const memberClientQuery = useMemberClientQuery(clientId);
  const memberClientV2Query = useMemberClientV2Query(
    memberClientQuery.data?.clientId || "",
    {
      enabled: memberClientQuery.data
    }
  );

  return (
    <Switch>
      {/* year specific routes */}
      <Route path={`${match.path}/${NEC_1099_SUMMARY_SLUG}`}>
        <NEC1099FilingDetailsSummary onClose={() => history.push(match.url)} />
      </Route>

      <Route path={`${match.path}`}>
        <WSSidebar.Container
          onClose={() => {
            history.push(backPath);
          }}
        >
          <WSQueries queries={{ memberClientQuery, memberClientV2Query }}>
            {({
              memberClientQuery: { data: memberClient },
              memberClientV2Query: { data: clientV2 }
            }) => (
              <WSSidebar.Layout header={PAGE_TITLE}>
                <Inner
                  memberClient={memberClient}
                  clientV2={clientV2}
                  backPath={backPath}
                />
              </WSSidebar.Layout>
            )}
          </WSQueries>
        </WSSidebar.Container>
      </Route>
    </Switch>
  );
};

const Inner: React.FC<{
  memberClient: IMemberClient;
  clientV2: ICollaboratorV2;
  backPath: string;
}> = ({ memberClient, clientV2, backPath }) => {
  const history = useHistory();
  const location = useLocation();
  const { openModal } = useModalContext();
  const userId = useUserId();

  const [
    isInternationalBankDetailsVisible,
    setIsInternationalBankDetailsVisible
  ] = useState(false);

  const company = getClientCompany(memberClient);
  const name = getClientName(memberClient);

  const qFeatureFlags = useFeatureFlags();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers();
  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems();
  const qDocuments = useMemberPrivateFiles({
    hidden: true,
    tags: [`payer:${memberClient.clientId}`]
  });

  const isQuickbooksActive =
    qIntegrationsQuickbooks.data &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

  const is1099DocumentsEnabled = qFeatureFlags.data?.nec1099Disputes;

  const defaultItem = (qIntegrationsQuickbooksItems.data || []).find(
    item => item.itemId === qIntegrationsQuickbooks.data?.defaults?.itemId
  );

  const mappedCustomer = (qIntegrationsQuickbooksCustomers.data || []).find(
    customer =>
      memberClient.integration?.quickbooks?.customerId === customer.customerId
  );
  const mappedItem = (qIntegrationsQuickbooksItems.data || []).find(
    item => memberClient.integration?.quickbooks?.itemId === item.itemId
  );

  const documents = useMemo(
    () =>
      getDocumentsMetaFromProfile(clientV2)?.filter(
        document => document.isShared
      ),
    [clientV2]
  );

  const uploadDocumentsEnabled = qFeatureFlags?.data?.uploadDocuments;

  return (
    <>
      <SidePanelSection first>
        <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="M">
          <WSText.Heading5 data-testid="clientName">{name}</WSText.Heading5>
          <WSButton.Link
            name="editClientName"
            onClick={() => {
              openModal(EDIT_CONTACT_NAME_MODAL);
            }}
          >
            Edit
          </WSButton.Link>
        </WSFlexBox.CenterY>

        {company && (
          <WSText weight="medium" mb="XL">
            {company}
          </WSText>
        )}

        <WSButton.Secondary
          onClick={() => {
            history.push("/member/invoices/create", {
              backPath: location.pathname,
              memberClientId: memberClient.memberClientId
            });
          }}
          fullWidth
        >
          Create invoice
        </WSButton.Secondary>
      </SidePanelSection>

      <SidePanelSection>
        <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="M">
          <WSText.Heading5>Contact info</WSText.Heading5>
          <WSButton.Link
            onClick={() => {
              openModal(EDIT_CONTACT_INFO_MODAL);
            }}
          >
            Edit
          </WSButton.Link>
        </WSFlexBox.CenterY>

        <WSText.ParagraphSm mb="XS" color="gray500">
          Client contact email
        </WSText.ParagraphSm>
        <WSText mb="XL">{memberClient.emailTo}</WSText>

        <WSText.ParagraphSm mb="XS" color="gray500">
          Additional emails
        </WSText.ParagraphSm>
        <WSText>
          {memberClient.emailCC && memberClient.emailCC.length > 0
            ? memberClient.emailCC.join(", ")
            : "–"}
        </WSText>
      </SidePanelSection>

      {memberClient.eligibilityRequirements &&
        memberClient.eligibilityRequirements.length > 0 && (
          <SidePanelSection>
            <ClientEligibilityRequirements
              memberClientId={memberClient.memberClientId}
            />
          </SidePanelSection>
        )}

      {(memberClient.internationalWireAccount?.routingNumber ||
        memberClient.achCreditAccount.routingNumber) && (
        <SidePanelSection>
          <WSText.Heading5 mb="M">
            Wire transfers{" "}
            <Tooltip
              trigger={
                <WSIcon
                  block
                  name="info-circle"
                  ml="XS"
                  color="gray500"
                  className={styles.icon}
                />
              }
              toolTipClassName={styles.toolTipContent}
              arrowClassName={styles.arrow}
              placement="right"
            >
              <WSText.ParagraphSm color="gray600">
                This is the secure payment gateway setup for this client that
                routes money to your direct deposit without you having to
                provide your own banking info.
              </WSText.ParagraphSm>
            </Tooltip>
          </WSText.Heading5>

          <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="M">
            <WSElement>
              <WSText.ParagraphSm mb="XS" color="gray500">
                Bank name
              </WSText.ParagraphSm>
              <WSText>
                {isInternationalBankDetailsVisible
                  ? memberClient.internationalWireAccount.bankName
                  : memberClient.achCreditAccount.bankName}
              </WSText>
            </WSElement>
            <WSButton.Link
              onClick={() => {
                copyText(
                  isInternationalBankDetailsVisible
                    ? memberClient.internationalWireAccount.bankName
                    : memberClient.achCreditAccount.bankName,
                  "Bank name copied to clipboard"
                );
              }}
            >
              Copy
            </WSButton.Link>
          </WSFlexBox.CenterY>

          {(isInternationalBankDetailsVisible
            ? memberClient.internationalWireAccount.routingNumber
            : memberClient.achCreditAccount.routingNumber) && (
            <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="M">
              <WSElement>
                <WSText.ParagraphSm mb="XS" color="gray500">
                  Routing number
                </WSText.ParagraphSm>
                <WSText>
                  {isInternationalBankDetailsVisible
                    ? memberClient.internationalWireAccount.routingNumber
                    : memberClient.achCreditAccount.routingNumber}
                </WSText>
              </WSElement>
              <WSButton.Link
                onClick={() => {
                  copyText(
                    isInternationalBankDetailsVisible
                      ? (memberClient.internationalWireAccount
                          .routingNumber as string)
                      : (memberClient.achCreditAccount.routingNumber as string),
                    "Routing number copied to clipboard"
                  );
                }}
              >
                Copy
              </WSButton.Link>
            </WSFlexBox.CenterY>
          )}

          <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="M">
            <WSElement>
              <WSText.ParagraphSm mb="XS" color="gray500">
                Account number
              </WSText.ParagraphSm>
              <WSText>
                {isInternationalBankDetailsVisible
                  ? memberClient.internationalWireAccount.accountNumber
                  : memberClient.achCreditAccount.accountNumber}
              </WSText>
            </WSElement>
            <WSButton.Link
              onClick={() => {
                copyText(
                  isInternationalBankDetailsVisible
                    ? memberClient.internationalWireAccount.accountNumber
                    : memberClient.achCreditAccount.accountNumber,
                  "Account number copied to clipboard"
                );
              }}
            >
              Copy
            </WSButton.Link>
          </WSFlexBox.CenterY>

          {((isInternationalBankDetailsVisible &&
            memberClient.internationalWireAccount.swiftCode) ||
            (!isInternationalBankDetailsVisible &&
              memberClient.achCreditAccount.swiftCode)) && (
            <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
              <WSElement>
                <WSText.ParagraphSm mb="XS" color="gray500">
                  SWIFT
                </WSText.ParagraphSm>
                <WSText>
                  {isInternationalBankDetailsVisible
                    ? memberClient.internationalWireAccount.swiftCode
                    : memberClient.achCreditAccount.swiftCode}
                </WSText>
              </WSElement>

              <WSButton.Link
                onClick={() => {
                  copyText(
                    (isInternationalBankDetailsVisible
                      ? memberClient.internationalWireAccount.swiftCode
                      : memberClient.achCreditAccount.swiftCode) || "",
                    "SWIFT code copied to clipboard"
                  );
                }}
              >
                Copy
              </WSButton.Link>
            </WSFlexBox.CenterY>
          )}

          {memberClient.internationalWireAccount && (
            <WSButton.Link
              type="button"
              mt="XL"
              onClick={() => {
                setIsInternationalBankDetailsVisible(value => !value);
              }}
            >
              {isInternationalBankDetailsVisible
                ? "View domestic wire details"
                : "View international wire details"}
            </WSButton.Link>
          )}
        </SidePanelSection>
      )}

      {/*

        TODO: Decom this and all associated for 2021 form1099Balances

        {memberClient.form1099Balances?.["2021"] && (
        <SidePanelSection>
          <WSText.Heading5 mb="M">Tax information 2021</WSText.Heading5>

          <WSElement mb="M">
            <WSText.ParagraphSm mb="XS" color="gray500">
              On Platform Gross Income
            </WSText.ParagraphSm>
            <WSText formatMoney>
              {memberClient.form1099Balances["2021"].platformIncome}
            </WSText>
          </WSElement>

          <WSElement mb="M">
            <WSText.ParagraphSm mb="XS" color="gray500">
              Off Platform Adjustments
            </WSText.ParagraphSm>
            <WSText formatMoney>
              {memberClient.form1099Balances["2021"].adjustments}
            </WSText>
          </WSElement>

          <WSElement mb="M">
            <WSText.ParagraphSm mb="XS" color="gray500">
              Send 1099 Filing
            </WSText.ParagraphSm>
            <WSText>
              {(memberClient.form1099Balances["2021"] as any).issue1099
                ? "Yes"
                : "No"}
            </WSText>
          </WSElement>
        </SidePanelSection>
      )} */}

      {isQuickbooksActive && (
        <>
          <EditClientIntegrationsModal
            memberClientId={memberClient.memberClientId}
          />
          <SidePanelSection data-testid="clientIntegrationMapping">
            <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="M">
              <WSText.Heading5>Integrations Mappings</WSText.Heading5>
              <WSButton.Link
                onClick={() => {
                  openModal(EDIT_CLIENT_INTEGRATIONS_MODAL);
                }}
              >
                Edit
              </WSButton.Link>
            </WSFlexBox.CenterY>

            <WSText.ParagraphSm mb="XS" color="gray500">
              QBO Customer
            </WSText.ParagraphSm>
            <WSText mb="M">
              {mappedCustomer
                ? getIntegrationUserSelectLabel(mappedCustomer)
                : "Not mapped"}
            </WSText>
            <WSText.ParagraphSm mb="XS" color="gray500">
              Default QBO Item
            </WSText.ParagraphSm>
            <WSText mb="M">
              {mappedItem
                ? mappedItem.fullyQualifiedName
                : `Not mapped, use default "${defaultItem?.fullyQualifiedName}"`}
            </WSText>
          </SidePanelSection>

          <QuickbooksHistory
            Wrapper={SidePanelSection}
            entityId={memberClient.memberClientId}
          />
        </>
      )}

      {uploadDocumentsEnabled &&
      qDocuments?.data &&
      qDocuments.data.length > 0 ? (
        <WSPanel noBorder mb="XL" mt="XL">
          <DocumentsSection
            mb="XL"
            type={DocumentsSectionType.Client}
            payeeId={userId}
            payerId={memberClient.clientId}
          />
        </WSPanel>
      ) : null}

      {is1099DocumentsEnabled && documents && documents.length > 0 && (
        <TaxDocumentsSection
          type={DocumentsSectionType.Client}
          documents={documents}
          showAmounts
          testid="documents-section"
        />
      )}

      <AutopaySection memberClient={memberClient} />

      <SidePanelSection>
        <DeleteClientModal
          clientId={memberClient.memberClientId}
          clientName={name}
          backPath={backPath}
        />
        <WSButton.Secondary
          destructive
          onClick={() => {
            openModal(DELETE_CLIENT_MODAL);
          }}
          name="deleteClient"
        >
          Delete client
        </WSButton.Secondary>
      </SidePanelSection>

      <EditContactInfoModal memberClientId={memberClient.memberClientId} />
      <EditContactNameModal memberClientId={memberClient.memberClientId} />
    </>
  );
};

const AutopaySection: React.FC<{
  memberClient: IMemberClient;
}> = ({ memberClient }) => {
  const userId = useUserId();
  const qFeatureFlags = useFeatureFlags();
  const qUserProfile = useUserProfile(userId);
  const qMemberProfile = useMemberProfile(userId);

  const paymentMethod = memberClient.autoPaySettings?.paymentMethod
    ?.sourceMetadata?.institution
    ? `${capitalize(
        memberClient.autoPaySettings?.paymentMethod?.sourceMetadata.institution
      )}${
        memberClient.autoPaySettings.paymentMethod.sourceMetadata.mask
          ? ` (${memberClient.autoPaySettings.paymentMethod.sourceMetadata.mask})`
          : ""
      }`
    : undefined;

  return (
    <WSQueries
      queries={{ qUserProfile, qFeatureFlags, qMemberProfile }}
      renderLoader={() => null}
      renderErrors={() => null}
    >
      {({ qUserProfile, qFeatureFlags, qMemberProfile }) => {
        return qFeatureFlags.data.autopayV1 ? (
          <SidePanelSection>
            <WSFlexBox.CenterY justify="space-between">
              <WSText.Heading5>Autopay</WSText.Heading5>
              {memberClient.autoPaySettings?.autoPayStrategy ===
              AutoPayStrategy.All ? (
                <WSPill theme="success" text="On" />
              ) : (
                <WSPill text="Off" />
              )}
            </WSFlexBox.CenterY>

            {memberClient.autoPaySettings?.autoPayStrategy ===
            AutoPayStrategy.All ? (
              <WSText mt="M">
                Each invoice you send will be automatically paid on the due date
                unless this client disables Autopay.
              </WSText>
            ) : (
              <WSText mt="M">
                To set up Autopay, share this link with your client and they can
                set up automatic payments.
              </WSText>
            )}

            <WSText.ParagraphSm weight="medium" mt="M">
              Payment method:
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>{paymentMethod || "None"}</WSText.ParagraphSm>

            <WSCopyText
              mt="M"
              isURL
              shareTitle="Autopay link"
              data={getAutopayLink({
                tag: qUserProfile.data.tag,
                companyName: getMemberCompanyName(qMemberProfile.data),
                memberClientId: memberClient.memberClientId,
                email: memberClient.emailTo
              })}
            />
          </SidePanelSection>
        ) : null;
      }}
    </WSQueries>
  );
};
