import { WSList, toWSDateString } from "@wingspanhq/fe-component-library";
import { selectorCountryName } from "../../../../shared/selectors/selectorCountryName";
import { employersNumberLabelMap } from "../../helpers/employersNumber";
import { insdustryLabelMap } from "../../helpers/industry";
import { taxClassificationLabelMap } from "../../helpers/taxClassification";
import { getTaxIdLabel } from "../../helpers/taxId";
import { FormData } from "../../types";
import { Section } from "./Section";

type Props = {
  data: FormData;
  onEdit: () => void;
  ssnProvided?: boolean;
};

export const Preview: React.FC<Props> = ({ data, onEdit, ssnProvided }) => {
  if (!data.country) {
    return null;
  }

  return (
    <WSList gap="S">
      <Section
        title="Account type"
        onEdit={onEdit}
        data={[
          {
            label: "Country of operation",
            value: selectorCountryName(data.country)
          },
          {
            label: "Account type",
            value: data.type
          }
        ]}
      />

      {data.type === "Business" && (
        <Section
          title="Business information"
          onEdit={onEdit}
          data={[
            {
              label: "Legal business name",
              value: data.business.legalBusinessName,
              fsExclude: true
            },
            {
              label: "Doing business as (DBA)",
              value: data.business.dba,
              fsExclude: true
            },
            {
              label: "Federal tax classification",
              value: data.business.taxClassification
                ? taxClassificationLabelMap[data.business.taxClassification]
                : null
            },
            {
              label: getTaxIdLabel("Business", data.country),
              value: data.business.taxId,
              fsExclude: true
            },
            {
              label: "Industry",
              value: data.business.industry
                ? insdustryLabelMap[data.business.industry]
                : null
            },
            {
              label: "Business website",
              value: data.business.website || "None"
            },
            {
              label: "Employees",
              value: employersNumberLabelMap[data.business.numberOfEmployees]
            },
            {
              label: "Business address",
              value: [
                data.business.address.addressLine1,
                data.business.address.addressLine2,
                [
                  data.business.address.city,
                  data.business.address.state,
                  data.business.address.postalCode
                ].join(" ")
              ]
                .filter(Boolean)
                .join("\n"),
              fsExclude: true
            },
            data.business.canadaQuebecEnterpriseNumber
              ? {
                  label: "Québec Enterprise Number (NEQ)",
                  value: data.business.canadaQuebecEnterpriseNumber,
                  fsExclude: true
                }
              : null,
            {
              label: "State/Territory of incorporation",
              value: data.business.stateOfIncorporation
            }
          ]}
        />
      )}

      <Section
        title="Account holder information (You)"
        onEdit={onEdit}
        data={[
          {
            label: "Legal first name",
            value: data.accountHolder.firstName,
            fsExclude: true
          },
          {
            label: "Legal last name",
            value: data.accountHolder.lastName,
            fsExclude: true
          },
          {
            label: "Job title / occupation",
            value: data.accountHolder.jobTitle
          },
          {
            label: "Country",
            value: data.accountHolder.address.country
              ? selectorCountryName(data.accountHolder.address.country)
              : null
          },
          {
            label: getTaxIdLabel("Individual", data.country),
            value: ssnProvided ? "*********" : data.accountHolder.taxId,
            fsExclude: true
          },
          {
            label: "Birthday",
            value: data.accountHolder.birthday
              ? toWSDateString(data.accountHolder.birthday, "monthDayYear")
              : null
          },
          {
            label: "Residential address",
            value: [
              data.accountHolder.address.addressLine1,
              data.accountHolder.address.addressLine2,
              [
                data.accountHolder.address.city,
                data.accountHolder.address.state,
                data.accountHolder.address.postalCode
              ].join(" ")
            ]
              .filter(Boolean)
              .join("\n"),
            fsExclude: true
          },
          data.type === "Individual" &&
          data.accountHolder.canadaQuebecEnterpriseNumber
            ? {
                label: "Québec Enterprise Number (NEQ)",
                value: data.accountHolder.canadaQuebecEnterpriseNumber,
                fsExclude: true
              }
            : null
        ]}
      />
    </WSList>
  );
};
