import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import { IWebflowBenefitCategory } from "@wingspanhq/cms/dist/lib/interfaces/webflow/benefits";
import { WSElement, WSPage, WSText } from "@wingspanhq/fe-component-library";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { Tabs } from "../../components/Tabs";
import { WSQueries } from "../../query/WSQuery";
import { useCMSWebflowBenefits } from "../../query/cms/queries";
import { useUserId } from "../../query/hooks/helpers";
import { getCurrentEntepriseId } from "../../query/platform/selectors";
import { useActivities, useUserProfile } from "../../query/users/queries";
import { useWSStore } from "../../store";
import {
  getSegmentAnonymousIdFromLocalStorage,
  track
} from "../../utils/analytics";
import { BenefitBlock } from "../components/BenefitBlock/BenefitBlock.module";
import { BenefitsRequestBenefit } from "../modals/BenefitsRequestBenefit";
import {
  isBenefitAlwaysAdditional,
  isBenefitHidden,
  isBenefitIncludedInSubscriptionLevel,
  sortCMSBenefits
} from "../utils";
import { getBenefitsByTitle } from "../utils/getBenefitByTitle";
import styles from "./Benefits.module.scss";
import { BenefitsCMSBlock } from "./BenefitsCMSBlock";

export const AllAdditionalCoverage: React.FC<RouteComponentProps> = ({
  history
}) => {
  useBrowserPageTitle("Benefits - Insurance");
  const userId = useUserId();
  const userProfileQuery = useUserProfile(userId);
  const store = useWSStore();
  const qActivities = useActivities(userId);
  const qCMSWebflowBenefits = useCMSWebflowBenefits();

  return (
    <WSPage
      title="Insurance"
      primaryButton={{
        label: "Request a new benefit",
        onClick() {
          const anonymousId = getSegmentAnonymousIdFromLocalStorage();
          const {
            email,
            profile: { firstName, lastName }
          } = userProfileQuery.data as INewUser;
          const fullName = `${firstName} ${lastName}`;
          const typeFormReference = createPopup(
            `https://wingspan.typeform.com/to/xXS6mQu8#name=${fullName}&email=${email}&userid=${userId}&anonymousid=${anonymousId}`,
            {
              onSubmit: responseId => {
                track("Request a new benefit form submitted", {
                  responseId: responseId
                });
              }
            }
          );
          typeFormReference.open();
        }
      }}
    >
      <WSQueries
        queries={{
          qCMSWebflowBenefits,
          userProfileQuery,
          qActivities
        }}
        renderLoader={() => <WSPage />}
      >
        {({ userProfileQuery, qCMSWebflowBenefits, qActivities }) => {
          const cmsBenefits = qCMSWebflowBenefits.data.filter(
            benefit =>
              !isBenefitHidden(benefit) &&
              (isBenefitAlwaysAdditional(benefit) ||
                !isBenefitIncludedInSubscriptionLevel(benefit))
          );

          const enterpriseId = getCurrentEntepriseId(
            store,
            qActivities.data,
            userProfileQuery.data
          );

          return (
            <>
              <BenefitsRequestBenefit />
              {history.location.pathname.includes("mind-and-body-support") ? (
                <WSText my="2XL" className={styles.text}>
                  Get discounts on alternative medicine and chiropractic through
                  Wingspan.
                  <b>
                    Keep an eye out for Therapy Benefits as well, coming soon!
                  </b>
                </WSText>
              ) : history.location.pathname.includes(
                  "save-money-on-medical-bills"
                ) ? (
                <WSText my="2XL" className={styles.text}>
                  Skip the doctor and get free 24/7 access to virtual care, 1:1
                  support negotiating insurance bills, savings on prescriptions,
                  and more included in your Wingspan Membership.
                </WSText>
              ) : history.location.pathname.includes("insurance") ? (
                <WSText my="2XL" className={styles.text}>
                  Accessing high quality insurance for independent contractors
                  is difficult. Wingspan has partnered with providers who have
                  designed plans specifically for independent professionals.
                </WSText>
              ) : null}
              <Tabs
                tabs={[
                  /*    ...(isPremium
                    ? []
                    : [
                        {
                          path:
                            "/member/benefits/additional-coverage/all/mind-and-body-support",
                          title: isMobile
                            ? "Mind & Body"
                            : "Mind and body support"
                        },
                        {
                          path:
                            "/member/benefits/additional-coverage/all/save-money-on-medical-bills",
                          title: isMobile
                            ? "Medical Bill Savings"
                            : "Save money on medical bills"
                        }
                      ]),*/
                  {
                    path: "/member/benefits/additional-coverage/all/insurance",
                    label: "Insurance"
                  }
                ]}
              />
              <Switch>
                <Route
                  path="/member/benefits/additional-coverage/all/mind-and-body-support"
                  exact
                  render={() => (
                    <WSElement>
                      {cmsBenefits
                        ?.filter(benefit =>
                          [
                            IWebflowBenefitCategory.Wellness,
                            IWebflowBenefitCategory.Other
                          ].includes(benefit.category)
                        )
                        .sort((a, b) => sortCMSBenefits(a, b))
                        .map(benefit => (
                          <BenefitsCMSBlock
                            benefit={benefit}
                            parentPath="additional-coverage/all"
                          />
                        ))}
                    </WSElement>
                  )}
                />
                <Route
                  path="/member/benefits/additional-coverage/all/save-money-on-medical-bills"
                  exact
                  render={() => (
                    <WSElement>
                      {cmsBenefits
                        ?.filter(benefit =>
                          [IWebflowBenefitCategory.Health].includes(
                            benefit.category
                          )
                        )
                        .sort((a, b) => sortCMSBenefits(a, b))
                        .map(benefit => (
                          <BenefitsCMSBlock
                            benefit={benefit}
                            parentPath="additional-coverage/all"
                          />
                        ))}
                    </WSElement>
                  )}
                />

                <Route
                  path="/member/benefits/additional-coverage/all/insurance"
                  exact
                  render={() => (
                    <WSElement>
                      <BenefitBlock
                        parentPath="additional-coverage/all"
                        {...getBenefitsByTitle("Health Insurance")}
                      />
                      {cmsBenefits
                        ?.filter(benefit =>
                          [IWebflowBenefitCategory.Insurance].includes(
                            benefit.category
                          )
                        )
                        .filter(
                          benefit =>
                            // TODO: should we implement it in cms?
                            // Don't display Life insurance for "finfit" company https://linear.app/wingspan/issue/WIN-4075
                            !(
                              benefit.slug === "life-insurance" &&
                              (enterpriseId === "finfit" ||
                                userId === "SIRR90o4HHL04a_qsXRXkV")
                            )
                        )
                        .sort((a, b) => sortCMSBenefits(a, b))
                        .map(benefit => (
                          <BenefitsCMSBlock
                            benefit={benefit}
                            parentPath="additional-coverage/all"
                          />
                        ))}
                      <BenefitBlock
                        parentPath="additional-coverage/all"
                        {...getBenefitsByTitle("Pet Insurance")}
                      />
                    </WSElement>
                  )}
                />

                <Redirect
                  from="*"
                  to={"/member/benefits/additional-coverage/all/insurance"}
                />
              </Switch>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
