import {
  toWSDateString,
  toWSMoneyString,
  useIsMobile,
  useWSModal,
  WSFlexBox,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableItem,
  WSTableProps,
  WSTableToolbar
} from "@wingspanhq/fe-component-library";
import React, { useCallback, useMemo, useState } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { InstantPayoutsModal } from "../../../../Invoices/screens/InstantPayoutsModal";
import { InvoicesInvoiceDetails } from "../../../../Invoices/screens/InvoicesInvoiceDetails";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { IInvoiceRow } from "../../service";
import { getInvoiceStatusPillProps } from "./getInvoiceStatusPillProps";
import { InvoiceRecentUpdate } from "./InvoiceRecentUpdate";
import { ModalBulkAccept } from "./ModalBulkAccept";
import { ModalBulkDispute } from "./ModalBulkDispute";
import { useInvoiceActions } from "./useInvoiceAcitons";

export type InvoicesTableItem = WSTableItem<InvoicesTableItemData>;

export type InvoicesTableItemData = IInvoiceRow;

type Props = {
  invoices: IInvoiceRow[];
} & Omit<WSTableProps<InvoicesTableItemData>, "tableData">;

export const TableInvoices: React.FC<Props> = ({ invoices, ...tableProps }) => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const modalBulkAccept = useWSModal(ModalBulkAccept);
  const modalBulkDispute = useWSModal(ModalBulkDispute);

  const [selection, setSelection] = useState<WSTableItem[]>([]);

  const match = useRouteMatch();
  const tableData: InvoicesTableItem[] = invoices.map(invoice => ({
    id: invoice.invoiceId,
    data: invoice
  }));

  const onView = useCallback(
    (item: InvoicesTableItem) => {
      history.push({
        pathname: location.pathname + "/" + item.id,
        search: location.search,
        state: {
          backPath: location.pathname
        }
      });
    },
    [history, location.pathname, location.search]
  );

  const getMenuActions = useInvoiceActions();

  const onViewInNewTab = useCallback(
    (item: InvoicesTableItem) => {
      openInNewTab(location.pathname + "/" + item.id);
    },
    [location.pathname]
  );

  const toolbarActions = useMemo(
    () => [
      {
        label: "Dispute",
        onClick: () => {
          modalBulkDispute.open({
            data: selection.map(item => item.data),
            onFinish: () => {
              setSelection([]);
            }
          });
        }
      },
      {
        label: "Accept",
        onClick: () => {
          modalBulkAccept.open({
            data: selection.map(item => item.data),
            onFinish: () => {
              setSelection([]);
            }
          });
        }
      }
    ],
    [modalBulkAccept, modalBulkDispute, selection]
  );

  const columns: WSTableColumn<IInvoiceRow>[] = useMemo(() => {
    const columns: WSTableColumn<IInvoiceRow>[] = [
      {
        config: {
          header: {
            text: "Client"
          },
          gridTemplateSizeMax: "2fr"
        },
        renderFunction: ({ data: invoice }) => {
          const name =
            invoice.primaryName ||
            invoice.client?.profile?.company?.name ||
            invoice.client?.profile?.company?.legalBusinessName ||
            invoice.clientCompany ||
            invoice.clientName;

          const primaryName = name ? name : invoice.clientEmail || "--";

          return (
            <WSTableCell
              avatar={{
                type: "text",
                text: primaryName
              }}
              text={primaryName}
              secondaryText={
                invoice.secondaryName
                  ? invoice.secondaryName
                  : name
                  ? invoice.clientEmail
                  : undefined
              }
              truncationOptions={{
                text: {
                  allowTwoLines: true
                }
              }}
            />
          );
        }
      },
      {
        config: {
          hideOnScreens: ["XS", "S", "M"],
          header: {
            text: "Invoice #"
          },
          gridTemplateSizeMin: "min-content",
          gridTemplateSizeMax: "1fr"
        },
        renderFunction: ({ data: invoice }) => (
          <WSTableCell text={invoice.invoiceNumber} />
        )
      },
      {
        config: {
          header: {
            text: "Amount"
          },
          gridTemplateSizeMin: "min-content",
          gridTemplateSizeMax: "1fr"
        },
        renderFunction: ({ data: invoice }) => (
          <WSTableCell
            text={toWSMoneyString(invoice.amount)}
            secondaryText={invoice.lineItems?.[0]?.description}
          />
        )
      },
      {
        config: {
          hideOnScreens: ["XS", "S", "M", "L"],
          header: {
            text: "Invoice date"
          },
          gridTemplateSizeMax: "1fr"
        },
        renderFunction: ({ data: invoice }) => (
          <WSTableCell
            text={toWSDateString(invoice.createdAt, "monthDayYear")}
          />
        )
      },
      {
        config: {
          hideOnScreens: ["XS", "S"],
          header: {
            text: "Due date"
          },
          gridTemplateSizeMax: "1fr"
        },
        renderFunction: ({ data: invoice }) => (
          <WSTableCell text={toWSDateString(invoice.dueDate, "monthDayYear")} />
        )
      },
      {
        config: {
          hideOnScreens: ["XS"],
          header: {
            text: "Status"
          },
          gridTemplateSizeMax: "min-content"
        },
        renderFunction: ({ data: invoice }) => (
          <WSFlexBox gap="XS" direction="column">
            <WSTableCell pill={getInvoiceStatusPillProps(invoice)} />
            {!isMobile && <InvoiceRecentUpdate invoice={invoice} />}
          </WSFlexBox>
        )
      }
    ];

    return columns;
  }, [isMobile]);

  return (
    <>
      <Route
        path={match.path + "/:invoiceId"}
        component={InvoicesInvoiceDetails}
        exact
      />

      <Route
        path={match.path + "/:invoiceId/instant-deposit"}
        component={InstantPayoutsModal}
        exact
      />

      {!tableProps.loading && selection.length > 0 && (
        <WSTableToolbar
          mb="M"
          onClear={() => {
            setSelection([]);
          }}
          count={selection.length}
          actions={toolbarActions}
          actionsLabel="Bulk actions"
        />
      )}

      <WSTable<InvoicesTableItemData>
        {...tableProps}
        tableData={tableData}
        selection={selection}
        onSelectionChange={setSelection}
        columns={columns}
        onRowClick={onView}
        onRowControlClick={onViewInNewTab}
        onRowMousewheelClick={onViewInNewTab}
        rowMenuActions={item => {
          const { data: invoice } = item;
          const menuItems: WSMenuItem[] = [];

          menuItems.push({
            icon: "search",
            label: "View",
            onClick: () => {
              onView(item);
            }
          });

          menuItems.push(...getMenuActions(invoice));

          return menuItems;
        }}
      />
    </>
  );
};
