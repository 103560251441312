import {
  useIsMobile,
  useModalContext,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSModal,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  IInvoice,
  IMemberClient,
  IMemberClientForm1099Balances,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import { flatten } from "lodash";
import React, { useCallback, useRef } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useRouteMatch } from "react-router-dom";

import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import { UrlIdKey } from "../../../constants/routing";
import { getNonEmployeeCompensation } from "../../../modules/1099NECFiling/deprecated/utils/getTotalsItems";
import { WSQueries } from "../../../query/WSQuery";
import {
  useInvoicesFilteredQuery,
  useMemberClientQuery
} from "../../../query/payments/queries";
import { getClientCompanyName } from "../../../utils/client";
import { openInNewTab } from "../../../utils/openInNewTab";

import { NEC_1099_SUMMARY_SLUG } from "../../../shared/constants/nec1099";
import { Payments1099CollaboratorTable } from "./Payments1099CollaboratorTable";
import styles from "./styles.module.scss";
import { Actions } from "../../../components/Actions";

export const HOW_NEC_TOTAL_CALCULATED_MODAL = "HOW_NEC_TOTAL_CALCULATED_MODAL";

export const HowNECTotalCalculatedModal = () => {
  return (
    <WSModal size="S" name={HOW_NEC_TOTAL_CALCULATED_MODAL}>
      <WSText weight="medium" mb="M">
        How is this value calculated?
      </WSText>
      <WSText color="gray600" mb="M">
        The Non-Employment Compensation total is the sum of all payments paid to
        you during the tax year. Your payer may also choose to include card
        processing fees or reimbursable expenses.
      </WSText>
    </WSModal>
  );
};

export const Payments1099CollaboratorView: React.FC = () => {
  const history = useHistory();
  const downloadCsvRef = useRef<HTMLElement>(null);
  const match = useRouteMatch<{
    year: string;
    memberClientId: string;
  }>();
  const memberClientId = match.params[UrlIdKey.Client];
  const year = parseInt(
    match.params[UrlIdKey.Year]
  ) as keyof ICollaboratorSchema["form1099Balances"];
  const isMobile = useIsMobile();
  const { openModal } = useModalContext();
  const memberClientQuery = useMemberClientQuery(memberClientId);
  const invoicesQuery = useInvoicesFilteredQuery({
    status: {
      "!=": InvoiceStatus.Cancelled
    },
    memberClientId,
    "lineItems.labels.taxForm1099": year
  });

  const populateCollaboratorsCsvFromInvoices = (invoices: IInvoice[]) => {
    return flatten(invoices.map(prepareCSVRowsFromInvoice));
  };

  const prepareCSVRowsFromInvoice = (invoice: IInvoice) => {
    return invoice.lineItems.map(lineItem => ({
      collaboratorId: invoice.memberClientId,
      dueDate: invoice.dueDate,
      paidDate: invoice.events.paidAt,
      lineItemTitle: lineItem.description,
      lineItemDescription: lineItem.detail || "",
      invoiceNotes: invoice.invoiceNotes,
      amount: lineItem.totalCost,
      reimbursable: lineItem.reimbursableExpense
    }));
  };

  const downloadCsv = useCallback(() => {
    if (downloadCsvRef.current) downloadCsvRef.current.click();
  }, []);

  return (
    <WSQueries queries={{ memberClientQuery, invoicesQuery }}>
      {({
        memberClientQuery: { data: memberClient },
        invoicesQuery: { data: invoices }
      }) => {
        const currentForm1099Balance =
          memberClient.form1099Balances[
            year as keyof IMemberClient["form1099Balances"]
          ];

        const download1099PaymentsCSVProps = {
          filename: `Wingspan_Payments_Included_1099_${year}.csv`,
          headers: [
            { label: "Contractor ID", key: "collaboratorId" },
            { label: "Due Date", key: "dueDate" },
            { label: "Pay Date", key: "paidDate" },
            { label: "Line Item Title", key: "lineItemTitle" },
            { label: "Line Item Description", key: "lineItemDescription" },
            { label: "Invoice Notes", key: "invoiceNotes" },
            { label: "Amount", key: "amount" },
            { label: "Reimbursable", key: "reimbursable" }
          ],
          data: populateCollaboratorsCsvFromInvoices(invoices)
        };

        return (
          <WSPage
            breadcrumb={{
              icon: "arrow-left",
              label: "Back",
              onClick: () => history.goBack()
            }}
            title={
              <>
                <WSText.Heading1 mr="M" inline>
                  Payments from {getClientCompanyName(memberClient)}
                </WSText.Heading1>
                <WSText.ParagraphSm
                  className={styles.filingYear}
                  inline
                  color="gray500"
                >
                  {year}
                </WSText.ParagraphSm>
              </>
            }
            primaryButtonComponent={
              isMobile
                ? props => (
                    <WSPersistentUpgradeButton
                      name="createPayable"
                      feature={WSFrontendFeature.InviteAndPayCollaborator}
                      icon="download"
                      onClick={downloadCsv}
                      {...props}
                    >
                      Download CSV
                    </WSPersistentUpgradeButton>
                  )
                : undefined
            }
            sideContent={
              isMobile ? (
                undefined
              ) : (
                <WSFlexBox.CenterY>
                  <WSButton mr="M" name="downloadCSV" onClick={downloadCsv}>
                    Download CSV
                  </WSButton>
                  <Actions
                    name="actions"
                    items={[
                      {
                        label: "FAQ",
                        onClick() {
                          openInNewTab(
                            "https://wingspan.readme.io/docs/frequently-asked-questions"
                          );
                        }
                      }
                    ]}
                  />
                </WSFlexBox.CenterY>
              )
            }
          >
            <HowNECTotalCalculatedModal />
            <CSVLink {...download1099PaymentsCSVProps}>
              <WSElement ref={downloadCsvRef} as="span"></WSElement>
            </CSVLink>

            {/* NEC Total Details */}
            <WSFlexBox.CenterY justify="space-between" mb="M">
              <WSFlexBox.Center>
                <WSText.Heading5 mr="XS">
                  Non-Employment Compensation total
                </WSText.Heading5>
                <WSIcon
                  block
                  name="info-circle"
                  color="gray500"
                  onClick={() => openModal(HOW_NEC_TOTAL_CALCULATED_MODAL)}
                />
              </WSFlexBox.Center>
              <WSText.Heading4 formatMoney="default">
                {getNonEmployeeCompensation(
                  currentForm1099Balance as IMemberClientForm1099Balances
                )}
              </WSText.Heading4>
            </WSFlexBox.CenterY>

            {/* Show Manual adjustments if any */}

            {currentForm1099Balance &&
            currentForm1099Balance?.adjustments !== 0 ? (
              <WSFlexBox.CenterY justify="space-between">
                <WSFlexBox.Center>
                  <WSText mr="XS">
                    Adjustment manually added by{" "}
                    {getClientCompanyName(memberClient)}
                  </WSText>
                </WSFlexBox.Center>
                <WSText.ParagraphSm formatMoney="default">
                  {currentForm1099Balance.adjustments}
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
            ) : null}

            {/* 1099 Payments Table


            <WSInfiniteScroll
              onLoad={() => {
                payablesQuery.fetchMore();
              }}
              loadMore={payables.length > 0}
              endOfList={!payablesQuery.canFetchMore}
              loading={!!payablesQuery.isFetchingMore}
            >
              <Payments1099Table
                mt="3XL"
                basePath={`/${memberClientId}/${NEC_1099_SUMMARY_SLUG}`}
                payables={payables}
                invoices={invoicesQuery}
                year={year}
              />
            </WSInfiniteScroll>

            */}

            <Payments1099CollaboratorTable
              mt="3XL"
              basePath={`*/${memberClientId}/${NEC_1099_SUMMARY_SLUG}`}
              invoices={invoices}
              year={year}
            />
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
