export const QUERY_PAYMENTS_STATUS = "PAYMENTS_STATUS";
export const QUERY_INVOICES = "INVOICES";
export const QUERY_UNSENT_INVOICES = "QUERY_UNSENT_INVOICES";
export const QUERY_OUTSTANDING_INVOICES = "QUERY_OUTSTANDING_INVOICES";
export const QUERY_PAID_INVOICES = "QUERY_PAID_INVOICES";
export const QUERY_OUTSTANDING_INVOICES_STATS =
  "QUERY_OUTSTANDING_INVOICES_STATS";
export const QUERY_PAID_INVOICES_STATS = "QUERY_PAID_INVOICES_STATS";
export const QUERY_INVOICE = "QUERY_INVOICE";
export const QUERY_INVOICE_TEMPLATES = "INVOICE_TEMPLATES";
export const QUERY_INVOICE_TEMPLATE = "QUERY_INVOICE_TEMPLATE";
export const QUERY_CLIENTS_V2 = "QUERY_CLIENTS_V2";
export const QUERY_CLIENT_V2 = "QUERY_CLIENT_V2";

export const QUERY_MEMBER_CLIENTS = "MEMBER_CLIENTS";
export const QUERY_RECENT_MEMBER_CLIENTS = "QUERY_RECENT_MEMBER_CLIENTS";
export const QUERY_MEMBER_CLIENT = "QUERY_MEMBER_CLIENT";
export const QUERY_COLLABORATORS = "QUERY_COLLABORATORS";
export const QUERY_COLLABORATORS_V2 = "QUERY_COLLABORATORS_V2";
export const QUERY_MISSING_INFO_COLLABORATORS =
  "QUERY_MISSING_INFO_COLLABORATORS";
export const QUERY_COLLABORATOR_GROUPS = "QUERY_COLLABORATOR_GROUPS";
export const QUERY_COLLABORATOR_GROUP = "QUERY_COLLABORATOR_GROUP";
export const QUERY_ALL_COLLABORATOR_GROUPS = "QUERY_ALL_COLLABORATOR_GROUPS";
export const QUERY_ELIGIBILITY_REQUIREMENTS = "QUERY_ELIGIBILITY_REQUIREMENTS";
export const QUERY_ELIGIBILITY_REQUIREMENT = "QUERY_ELIGIBILITY_REQUIREMENT";
export const QUERY_COLLABORATOR = "QUERY_COLLABORATOR";
export const QUERY_COLLABORATOR_V2 = "QUERY_COLLABORATOR_V2";
export const QUERY_COLLABORATOR_EVENTS = "QUERY_COLLABORATOR_EVENTS";
export const QUERY_CLIENT_INVOICE = "QUERY_CLIENT_INVOICE";
export const QUERY_CLIENT_INVOICE_FEES = "QUERY_CLIENT_INVOICE_FEES";
export const QUERY_CLIENT_INVOICE_TEMPLATE = "QUERY_CLIENT_INVOICE_TEMPLATE";
export const QUERY_BANK_STATEMENTS_LIST = "QUERY_BANK_STATEMENTS_LIST";
export const QUERY_BANK_STATEMENT = "QUERY_BANK_STATEMENT";
export const QUERY_PAYABLES = "QUERY_PAYABLES";
export const QUERY_PAYABLE = "QUERY_PAYABLE";
export const QUERY_PAYROLL_RUNS = "QUERY_PAYROLL_RUNS";
export const QUERY_COLLABORATOR_SETTINGS_ADDITIONAL_DATA =
  "QUERY_COLLABORATOR_SETTINGS_ADDITIONAL_DATA";
export const QUERY_COLLABORATOR_SETTINGS_ADDITIONAL_ITEM_DATA =
  "QUERY_COLLABORATOR_SETTINGS_ADDITIONAL_ITEM_DATA";
export const QUERY_PAYROLL_SETTINGS = "QUERY_PAYROLL_SETTINGS";
export const QUERY_CARD_LIST = "QUERY_CARD_LIST";
export const QUERY_CARD = "QUERY_CARD";
export const QUERY_PAYOUT_SETTINGS = "QUERY_PAYOUT_SETTINGS";
export const QUERY_PAYOUT_SETTINGS_NEW = "QUERY_PAYOUT_SETTINGS_NEW";
export const QUERY_PAYOUT_SETTINGS_DEBIT_CARDS =
  "QUERY_PAYOUT_SETTINGS_DEBIT_CARDS";
export const QUERY_BANK_INSTITUTION = "QUERY_BANK_INSTITUTION";
export const QUERY_COLLABORATOR_DEDUCTIONS = "QUERY_COLLABORATOR_DEDUCTIONS";
export const QUERY_COLLABORATOR_DEDUCTION = "QUERY_COLLABORATOR_DEDUCTION";
export const QUERY_BULK_PAYABLE_BATCH = "QUERY_BULK_PAYABLE_BATCH";
export const QUERY_BULK_PAYABLE_BATCH_IMPORT_SUMMARY =
  "QUERY_BULK_PAYABLE_BATCH_IMPORT_SUMMARY";
export const QUERY_BULK_PAYABLE_FAILED_BATCH_ITEMS =
  "QUERY_BULK_PAYABLE_FAILED_BATCH_ITEMS";
export const QUERY_BULK_PAYABLE_BATCH_ITEM_LIST =
  "QUERY_BULK_PAYABLE_BATCH_ITEM_LIST";
export const QUERY_BULK_PAYABLE_BATCH_ITEM_PROCESSED_LIST =
  "QUERY_BULK_PAYABLE_BATCH_ITEM_PROCESSED_LIST";
export const QUERY_BULK_PAYABLE_BATCH_ITEM_NOT_STARTED_LIST =
  "QUERY_BULK_PAYABLE_BATCH_ITEM_NOT_STARTED_LIST";

export const QUERY_BULK_COLLABORATOR_BATCH = "QUERY_BULK_COLLABORATOR_BATCH";
export const QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST =
  "QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST";
export const QUERY_BULK_COLLABORATOR_BATCH_ITEM_NOT_STARTED_LIST =
  "QUERY_BULK_COLLABORATOR_BATCH_ITEM_NOT_STARTED_LIST";

export const QUERY_BULK_CALCULATE_1099_BATCH =
  "QUERY_BULK_CALCULATE_1099_BATCH";

export const QUERY_COLLABORATOR_OPEN_PAYABLES =
  "QUERY_COLLABORATOR_OPEN_PAYABLES";

export const QUERY_REPORTS_COLLABORATORS = "QUERY_REPORTS_COLLABORATORS";

export const QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY =
  "QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY";

export const QUERY_REPORTS_PAYROLL = "QUERY_REPORTS_PAYROLL";

export const QUERY_PAYROLL_IMMEDIATE_PAYABLES =
  "QUERY_PAYROLL_IMMEDIATE_PAYABLES";

export const QUERY_REPORTS_OPEN_PAYABLES_AGING =
  "QUERY_REPORTS_OPEN_PAYABLES_AGING";
export const QUERY_REPORTS_OPEN_LINE_ITEM_AGING =
  "QUERY_REPORTS_OPEN_LINE_ITEM_AGING";

export const QUERY_PAID_PAYABLES = "QUERY_PAID_PAYABLES";
