import {
  WSActions,
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  FundingSourceType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYROLL_SETTINGS
} from "../../../../query/payments/keys";
import { paymentsService } from "../../../../services/payments";

type Props = {
  setFundingSource?: boolean;
  setStandardPayoutDestination?: boolean;
  onBack?: () => void;
  onContinue?: () => void;
};

export const FormActivateWallet: React.FC<Props> = ({
  setFundingSource,
  setStandardPayoutDestination,
  onBack,
  onContinue
}) => {
  const userId = useUserId();

  const [submit, meta] = useWSMutation(
    async () => {
      // 1. Create wallet

      // 2. Set payroll funding method
      if (setFundingSource) {
        // await paymentsService.payrollSettings.update(userId, {
        //   fundingSource: {
        //     fundingSourceCurrency: Currency.USD,
        //     fundingSourceId: "",
        //     fundingSourceType: FundingSourceType.InternalAccount
        //   }
        // });
      }

      // 3. Set payout method
      if (setStandardPayoutDestination) {
        // await paymentsService.payoutSettings.update(userId, {
        //   payoutPreferences: PayoutPreferences.Standard
        // });
      }
    },
    {
      onSuccess: onContinue,
      dependencies: [QUERY_PAYROLL_SETTINGS, QUERY_PAYOUT_SETTINGS]
    }
  );

  return (
    <WSForm
      validationSchema={Yup.object().shape({
        agreement: Yup.boolean()
          .nullable()
          .required("Required")
      })}
      onSubmit={submit}
    >
      <WSList gap="2XL">
        <WSPanel>
          <WSList gap="2XL">
            <WSElement>
              <WSSectionToolbar title="Activate Wingspan banking features" />
              <WSText.ParagraphSm color="gray500">
                You’ve opted to activate a Wingspan banking feature. Review and
                agree to all the agreements and terms below to continue.
              </WSText.ParagraphSm>

              <WSInfoBox>
                By clicking continue you agree that you have read, understood,
                and agree to the following:
                <br />
                <br />
                <ul>
                  <li>
                    <a href="" target="_blank" rel="noopener">
                      Business Deposit Account Agreement
                    </a>
                    : By opening an account with us, you agree to the terms
                    outlined in our Wingspan Business Deposit Account Agreement.
                    Please review this document carefully.
                  </li>

                  <li>
                    <a href="" target="_blank" rel="noopener">
                      Privacy Notice
                    </a>
                    : We value your privacy. Our Lead Privacy Notice details how
                    we collect, use, and protect your personal information.
                  </li>
                </ul>
                <br />
                Wingspan is not a financial services technology company and is
                not a bank. Banking services provided by Lead Bank, member FDIC.
              </WSInfoBox>
            </WSElement>

            <WSForm.Field
              name="agreement"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label:
                  "I have read, and agree to all the above-mentioned documents and their contents.",
                size: "S"
              }}
            />

            <WSErrorMessage contextKey="Wallet" error={meta.error} />
          </WSList>
        </WSPanel>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              kind: "Secondary",
              onClick: onBack,
              visible: !!onBack,
              type: "button"
            },
            {
              label: "Continue",
              loading: meta.isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
