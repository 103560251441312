import { IAuthorizedAccount, IMember } from "@wingspanhq/users";
import { redirectByScope } from "./redirectByScope";
import { useHistory } from "react-router-dom";
import { useResetWSStore } from "../../../store";
import { useRequestPrincipalUser } from "../../../query/users/mutations";
import { useModalPassword } from "../components/ModalPassword/useModalPassword";
import { useStoredDefaultSelectedAccount } from "./useStoredDefaultSelectedAccount";
import { useStoredSelectedAccountEmail } from "./useStoredSelectedAccountEmail";
import { useAuthorizedScopeGroups } from "../../../shared/utils/teamUtils";
import { useModalSelectAccounts } from "../components/ModalSelectAccounts/useModalSelectAccounts";
import { useMemo } from "react";

type Props = {
  userId: string;
  member: IMember;
  accounts: IAuthorizedAccount[];
};

export const useSwitchingAccount = () => {
  const history = useHistory();
  const {
    isAdminSessionExists,
    isRequestingUserSessionExists,
    isRequestingUserTokenFromURL
  } = useAuthorizedScopeGroups();
  const [
    storedDefaultSelectedEmail,
    setStoredDefaultSelectedEmail
  ] = useStoredDefaultSelectedAccount();
  const [
    storedSelectedEmail,
    setStoredSelectedEmail
  ] = useStoredSelectedAccountEmail();

  const resetWSStore = useResetWSStore();
  const [
    requestPrincipalUser,
    requestPrincipalUserMeta
  ] = useRequestPrincipalUser();
  const modalPassword = useModalPassword();
  const modalSelectAccounts = useModalSelectAccounts();

  const openSelectAccountsModal = useMemo(
    () => () => {
      if (!window.TEST_ENV) {
        modalSelectAccounts.open({});
      }
    },
    [modalSelectAccounts]
  );

  return async ({ accounts, member }: Props) => {
    if (
      isRequestingUserSessionExists ||
      isAdminSessionExists ||
      isRequestingUserTokenFromURL
    ) {
      return false;
    }

    // If the default user is already logged in, don't redirect to select organization account
    if (storedSelectedEmail) {
      return false;
    }

    if (member?.user?.email === storedDefaultSelectedEmail) {
      return false;
    }

    try {
      if (accounts.length > 0) {
        if (!storedSelectedEmail && !storedDefaultSelectedEmail) {
          openSelectAccountsModal();
        }
      }

      const selectedAuthorizedAccount = accounts.find(
        account =>
          account.accessibleMember.user.email === storedDefaultSelectedEmail
      );

      if (selectedAuthorizedAccount) {
        await requestPrincipalUser(
          {
            email: selectedAuthorizedAccount.accessibleMember.user.email,
            captchaToken: await window.grecaptcha.execute(
              process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY || "",
              {
                action: "reauth"
              }
            ),
            captchaVersion: "3"
          },
          {
            onSuccess: () => {
              setStoredSelectedEmail(storedDefaultSelectedEmail);
              redirectByScope(selectedAuthorizedAccount, history, resetWSStore);
            },
            onError: async err => {
              if (err.response?.status === 401) {
                const result = await modalPassword.open(
                  {
                    account: selectedAuthorizedAccount
                  },
                  {
                    blockClose: false
                  }
                );

                if (result) {
                  setStoredSelectedEmail(storedDefaultSelectedEmail);
                  redirectByScope(
                    selectedAuthorizedAccount,
                    history,
                    resetWSStore
                  );
                } else {
                  setStoredSelectedEmail(member?.user?.email || "");
                }
              }
            }
          }
        );

        return false;
      } else if (accounts.length > 0) {
        openSelectAccountsModal();
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };
};
