import { FormData, FormDataAccountHolder, FormDataBusiness } from "../../types";
import { FormDataAddress } from "./AddressFields";

const defaultValueAddress: FormDataAddress = {
  country: "US",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postalCode: ""
};

export const defaultValuesBusiness: FormDataBusiness = {
  legalBusinessName: "",
  dba: "",
  taxClassification: null,
  taxId: "",
  industry: null,
  website: "",
  noWebsite: false,
  numberOfEmployees: "1To10",
  address: defaultValueAddress,
  canadaQuebecEnterpriseNumber: "",
  stateOfIncorporation: null
};

export const defaultValuesAccountHolder: FormDataAccountHolder = {
  firstName: "",
  lastName: "",
  ownership: {
    stake: 100,
    isRepresentative: false
  },
  jobTitle: "",
  birthday: null,
  taxId: "",
  address: defaultValueAddress,
  canadaQuebecEnterpriseNumber: ""
};

export const defaultValues: FormData = {
  country: "US",
  type: "Business",
  business: defaultValuesBusiness,
  accountHolder: defaultValuesAccountHolder
};
