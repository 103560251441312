import { format } from "date-fns";
import { updateAcknowledgement } from "../../../services/api/onboarding/acknowledgements";
import {
  createCustomerEntity,
  getCustomerEntity,
  updateCustomerData
} from "../../../services/api/onboarding/customer";
import { getVerificationMissingData } from "../../../services/api/onboarding/missingFields";
import { components } from "../../../services/api/onboarding/types";
import {
  getVerifications,
  performVerification
} from "../../../services/api/onboarding/verifications";
import { acknowledgementVersions } from "../../../shared/constants/acknowledgements";
import { FormData } from "../../CIP/types";

export const migrate = async (
  data: FormData,
  email: string,
  phoneNumber: string
) => {
  if (!data.type || !data.country) {
    throw new Error("Invalid form data");
  }

  let verifications:
    | {
        tax?: components["schemas"]["VerificationStatus"];
        banking?: components["schemas"]["VerificationStatus"];
        enhanced?: components["schemas"]["VerificationStatus"];
      }
    | undefined;

  let motinoredEntity: components["schemas"]["CustomerEntity"];

  try {
    // Check if there is customer entity
    motinoredEntity = await getCustomerEntity();
  } catch (error) {
    // Create it if it doesn't exist
    motinoredEntity = await createCustomerEntity({
      country: data.country,
      type: data.type
    });
  }

  try {
    verifications = await getVerifications();
  } catch (error) {
    console.error("Failed to get verifications", error);
  }

  // Update customer data
  if (data.type === "Business") {
    try {
      await updateCustomerData("Entity", {
        customerData: {
          phoneNumber: phoneNumber || undefined,
          numberOfEmployees: data.business.numberOfEmployees || undefined,
          legalBusinessName: data.business.legalBusinessName || undefined,
          doingBusinessAs: data.business.dba || undefined,
          federalTaxClassification:
            data.business.taxClassification || undefined,
          businessTaxId: data.business.taxId || undefined,
          industry: data.business.industry || undefined,
          website: data.business.noWebsite ? "None" : data.business.website,

          addressLine1: data.business.address.addressLine1 || undefined,
          addressLine2: data.business.address.addressLine2 || undefined,
          city: data.business.address.city || undefined,
          region: data.business.address.state || undefined,
          postalCode: data.business.address.postalCode || undefined,
          country: data.business.address.country || undefined,

          regionOfFormation: data.business.stateOfIncorporation || undefined
        }
      });
    } catch (error) {
      if (motinoredEntity.type === "Individual") {
        console.error("Mismatch between entity type and data", error);
      } else {
        throw error;
      }
    }

    await updateCustomerData("Representative", {
      customerData: {
        email: email,
        phoneNumber: phoneNumber || undefined,
        firstName: data.accountHolder.firstName || undefined,
        lastName: data.accountHolder.lastName || undefined,
        occupation: data.accountHolder.jobTitle || undefined,
        dateOfBirth: data.accountHolder.birthday
          ? format(data.accountHolder.birthday, "yyyy-MM-dd")
          : undefined,
        country: data.accountHolder.address.country || undefined,
        addressLine1: data.accountHolder.address.addressLine1 || undefined,
        addressLine2: data.business.address.addressLine2 || undefined,
        city: data.accountHolder.address.city || undefined,
        region: data.accountHolder.address.state || undefined,
        postalCode: data.accountHolder.address.postalCode || undefined
      },
      ownershipStake: data.accountHolder.ownership.stake ?? 0
    });
  } else {
    await updateCustomerData("Entity", {
      customerData: {
        email: email,
        phoneNumber: phoneNumber || undefined,
        firstName: data.accountHolder.firstName || undefined,
        lastName: data.accountHolder.lastName || undefined,
        occupation: data.accountHolder.jobTitle || undefined,
        dateOfBirth: data.accountHolder.birthday
          ? format(data.accountHolder.birthday, "yyyy-MM-dd")
          : undefined,
        country: data.accountHolder.address.country || undefined,
        addressLine1: data.accountHolder.address.addressLine1 || undefined,
        addressLine2: data.business.address.addressLine2 || undefined,
        city: data.accountHolder.address.city || undefined,
        region: data.accountHolder.address.state || undefined,
        postalCode: data.accountHolder.address.postalCode || undefined
      }
    });
  }

  if (verifications?.banking === "None") {
    try {
      await performVerification("Banking");
    } catch (error) {
      const missingData = await getVerificationMissingData("Banking");

      if (missingData.requiredFields) {
        throw new Error(
          "Missing required fields: " +
            missingData.requiredFields.map(field => field).join(", ")
        );
      } else {
        throw error;
      }
    }
  }

  const acknowledgements: components["schemas"]["AcknowledgementName"][] = [
    "DepositAccountHolderAgreement",
    "WingspanTosAcceptance",
    "WingspanPrivacyPolicyAcceptance",
    "LeadBankTerms"
  ];

  const acknowledgementPromises = acknowledgements.map(acknowledgementName =>
    updateAcknowledgement(acknowledgementName, {
      acknowledgementName: acknowledgementName,
      acknowledgementStatus: "Given",
      version: acknowledgementVersions[acknowledgementName]
    })
  );

  await Promise.all(acknowledgementPromises);
};
