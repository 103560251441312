import {
  SelectOptionOld,
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSModal,
  WSSelectOld
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../query/integrations/selectors";
import { useUpdateCollaborator } from "../../../query/payments/mutations";
import { useCollaboratorQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { selectQuickbooksUserOptions } from "../../../modules/Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../modules/Integrations/selectors/selectQuickbooksAccountOrItemOptions";

type Props = WSElementProps & {
  collaboratorId: string;
  collaboratorV2Id?: string;
};

export const EDIT_COLLABORATOR_INTEGRATIONS_MODAL =
  "EDIT_COLLABORATOR_INTEGRATIONS_MODAL";

export const EditCollaboratorIntegrationsModal: React.FC<Props> = ({
  collaboratorId,
  collaboratorV2Id
}) => {
  const qFeatureFlags = useFeatureFlags();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const qIntegrationsQuickbooksExpenseAccounts = useIntegrationsQuickbooksAccountExpenses();

  const qCollaborator = useCollaboratorQuery(collaboratorId);
  const [updateCollaborator, updateCollaboratorMeta] = useUpdateCollaborator(
    collaboratorId,
    collaboratorV2Id
  );
  const { openModal, closeModal } = useModalContext();

  return (
    <WSModal
      name={EDIT_COLLABORATOR_INTEGRATIONS_MODAL}
      size="S"
      title="Contractor integrations"
    >
      <WSQueries
        queries={{
          qFeatureFlags,
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksVendors,
          qIntegrationsQuickbooksExpenseAccounts,
          qCollaborator
        }}
      >
        {({
          qFeatureFlags: { data: featureFlags },
          qIntegrationsQuickbooks: { data: integrationsQuickbooks },
          qIntegrationsQuickbooksVendors: {
            data: integrationsQuickbooksVendors
          },
          qIntegrationsQuickbooksExpenseAccounts: {
            data: integrationQuickbooksExpenseAccounts
          },
          qCollaborator: { data: memberClient }
        }) => {
          const isQuickbooksActive =
            featureFlags.forceShowQBO ||
            (integrationsQuickbooks &&
              isQuickbooksServiceActive(integrationsQuickbooks));

          const integrationState = qIntegrationsQuickbooks.data;

          if (isQuickbooksActive) {
            const quickbooksVendorOptions: SelectOptionOld[] = selectQuickbooksUserOptions(
              integrationsQuickbooksVendors
            );

            const quickbooksAccountsOptions: SelectOptionOld[] = selectQuickbooksAccountOrItemOptions(
              integrationQuickbooksExpenseAccounts
            );

            const defaultAccount = integrationQuickbooksExpenseAccounts.find(
              acct =>
                acct.accountId === integrationState?.defaults?.expenseAccountId
            );

            const mappedVendor = memberClient.integration?.quickbooks?.vendorId;
            const mappedExpenseAccount =
              memberClient.integration?.quickbooks?.expenseAccountId;

            return (
              <>
                <WSFormOld<{
                  qboVendorId?: string | undefined;
                  qboExpenseAccountId?: string | undefined;
                }>
                  defaultValues={{
                    qboVendorId: mappedVendor,
                    qboExpenseAccountId: mappedExpenseAccount
                  }}
                  onSubmit={values => {
                    const updatePayload = {
                      integration: {
                        quickbooks: {
                          vendorId: values.qboVendorId
                            ? values.qboVendorId
                            : null,
                          expenseAccountId: values.qboExpenseAccountId
                            ? values.qboExpenseAccountId
                            : null
                        }
                      }
                    };
                    updateCollaborator(updatePayload, {
                      onSuccess: () => {
                        closeModal(EDIT_COLLABORATOR_INTEGRATIONS_MODAL);
                      }
                    });
                  }}
                >
                  <WSFlexBox mb="XS"></WSFlexBox>
                  <WSFormOld.Field
                    mb="XL"
                    name="qboVendorId"
                    component={WSSelectOld}
                    componentProps={{
                      placeholder: "Not mapped, create new Vendor",
                      options: quickbooksVendorOptions,
                      searchable: true,
                      cleanable: true,
                      placeholderActions: [
                        {
                          label: "Resync QBO Vendors",
                          icon: "refresh-v",
                          callback() {
                            openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                              entity: QUICKBOOKS_ENTITY.VENDORS
                            });
                          }
                        }
                      ]
                    }}
                    label="QBO Vendor"
                  />
                  <WSFormOld.Field
                    mb="2XL"
                    name="qboExpenseAccountId"
                    component={WSSelectOld}
                    componentProps={{
                      options: quickbooksAccountsOptions,
                      placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                      cleanable: true,
                      searchable: true,
                      placeholderActions: [
                        {
                          label: "Resync QBO Expenses Accounts",
                          icon: "refresh-v",
                          callback() {
                            openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                              entity: QUICKBOOKS_ENTITY.EXPENSES
                            });
                          }
                        }
                      ]
                    }}
                    label="Default QBO Expense Account"
                  />

                  <WSErrorMessage
                    mb="XL"
                    error={updateCollaboratorMeta.error}
                    contextKey="EditCollaboratorIntegrations"
                  />

                  <WSButtons format="modal">
                    <WSButton loading={updateCollaboratorMeta.isLoading}>
                      Update
                    </WSButton>
                    <WSButton.Tertiary
                      onClick={() => {
                        closeModal(EDIT_COLLABORATOR_INTEGRATIONS_MODAL);
                      }}
                    >
                      Cancel
                    </WSButton.Tertiary>
                  </WSButtons>
                </WSFormOld>
                <RefreshModal />
              </>
            );
          } else {
            closeModal(EDIT_COLLABORATOR_INTEGRATIONS_MODAL);
            return null;
          }
        }}
      </WSQueries>
    </WSModal>
  );
};
