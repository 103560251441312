import { WSTableColumn } from "@wingspanhq/fe-component-library";
import { ReportsColumn } from "../../types";
import { IPayableAgingReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatTableValue } from "../../utils/formatTableValue";

export const selectorTableColumns = (
  columns: ReportsColumn<IPayableAgingReportResponse>[],
  data: IPayableAgingReportResponse[]
) => {
  return [
    ...columns.map(
      column =>
        ({
          config: {
            header: { text: column?.label || "-" }
          },
          renderFunction: rowData =>
            formatTableValue(column?.getTableCell(rowData.data))
        } as WSTableColumn<IPayableAgingReportResponse>)
    )
  ];
};
