import React from "react";
import {
  useWSModal,
  WSActions,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import { openInNewTab } from "../../../utils/openInNewTab";
import { WS_LINKS } from "../../../types/wsLinks";

export const NotYourEmailPayee: React.FC<{
  payerName?: string;
  email: string;
  userId: string;
  userName?: string;
  isSignIn?: boolean;
  onClose(isNotMe: boolean): void;
}> = ({
  onClose,
  payerName = "Wingspan",
  email,
  userId,
  userName,
  isSignIn
}) => {
  return (
    <>
      <WSText.ParagraphSm m="L" weight="book">
        This email is associated with {payerName}'s records. If you:
        <WSElement as="ul" ml="XL" my="M">
          <WSElement as="li">
            Have an existing Wingspan account with a different email
          </WSElement>
          <WSElement as="li">Need to change your email address</WSElement>
          <WSElement as="li">Can't access the provided email</WSElement>
        </WSElement>
        Please contact the person who invited you for assistance.
      </WSText.ParagraphSm>
      <WSActions
        orientation="vertical"
        buttons={[
          {
            label: "Get help",
            onClick: () => {
              openInNewTab(
                WS_LINKS.onboardingHelpGuide({ email, userId, userName })
              );
              onClose(false);
            },
            rightIcon: "link",
            kind: "Link"
          },
          {
            label: `Sign ${isSignIn ? "in" : "up"} with: ${email}`,
            onClick: async () => {
              onClose(false);
            }
          }
        ]}
      />
    </>
  );
};

export const useNotYourEmailPayee = () =>
  useWSModal(NotYourEmailPayee, {
    title: "Not you?",
    size: "S"
  });
