import FlatfileImporter from "@flatfile/adapter";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import { COUNTRY_OPTIONS, US_STATES_OPTIONS } from "../../../constants/user";
import { useUserId } from "../../../query/hooks/helpers";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { getVisibleCollaborators } from "../../../query/payments/selectors";
import { useUserProfile } from "../../../query/users/queries";
import { IS_DEV_ENV } from "../../../shared/constants/environment";
import { flatfileTheme } from "../../../utils/flatfileTheme";
import { Upload1099MissingInfoCollaborator } from "./BulkUpdate1099MissingInfoStep1";

const apiKey = process.env.REACT_APP_FLATFILE_API_KEY || "";

export const use1099MissingInfoBulkImporter = () => {
  const userId = useUserId();
  const user = useUserProfile(userId);
  const collaboratorsQuery = useCollaboratorsQuery();

  const getCollaboratorByEmail = (
    collaborators: ICollaboratorSchema[],
    email: string
  ): ICollaboratorSchema | null => {
    const collaborator = collaborators.filter(
      mc => mc.member.user.email === email
    );
    return collaborator.length > 0 ? collaborator[0] : null;
  };

  const importer = new FlatfileImporter(apiKey, {
    ...flatfileTheme,
    type: "Contractors",
    title: "Update 1099 missing information and import Contractors to Wingspan",
    autoDetectHeaders: true,
    devMode: IS_DEV_ENV,
    managed: true,
    disableManualInput: true,
    displayEncoding: false,
    fields: [
      {
        label: "Legal First Name",
        key: "legalFirstName",
        validators: [{ validate: "required" }]
      },
      {
        label: "Legal Last Name",
        key: "legalLastName",
        validators: [{ validate: "required" }]
      },
      {
        label: "Email",
        key: "email",
        validators: [{ validate: "required" }]
      },
      {
        label: "Business Name",
        key: "businessName"
      },
      {
        label: "Address Line1",
        key: "addressLine1",
        validators: [{ validate: "required" }]
      },
      {
        label: "Address Line2",
        key: "addressLine2"
      },
      {
        label: "City",
        key: "city",
        validators: [{ validate: "required" }]
      },
      {
        label: "State",
        key: "state",
        validators: [{ validate: "required" }],
        type: "select",
        options: US_STATES_OPTIONS
      },
      {
        label: "Postal Code",
        key: "postalCode",
        validators: [
          {
            validate: "regex_matches",
            regex: "^[0-9]{5}$",
            error: "Invalid postal code"
          }
        ]
      },
      {
        label: "Country",
        key: "country",
        validators: [{ validate: "required" }],
        type: "select",
        options: COUNTRY_OPTIONS.filter(country => country.value === "US")
      },
      {
        label: "Tax ID",
        alternates: ["Social Security Number"],
        key: "ssn",
        validators: [
          {
            validate: "regex_matches",
            regex: "^[0-9]{9}$",
            error: "Invalid tax id"
          }
        ]
      }
    ]
  });

  importer.registerRecordHook(async (record: any) => {
    const collaborators = getVisibleCollaborators(
      collaboratorsQuery.data as ICollaboratorSchema[]
    );
    const collaborator = getCollaboratorByEmail(
      collaborators,
      record.email as string
    );
    const output: any = {};
    if (collaborator) {
      // write validations if any
    } else {
      output.email = {
        value: record.email,
        info: [
          {
            message:
              "New contractor will be created as there is no contractor exists with this email",
            level: "info"
          }
        ]
      };
    }
    return output;
  });

  importer.setCustomer({
    userId: userId,
    name: `${user.data?.profile.firstName} ${user.data?.profile.lastName}`
  });

  return async () => {
    try {
      const results = await importer.requestDataFromUser();
      await importer.close();
      return results.validData as Array<Upload1099MissingInfoCollaborator>;
    } catch (error) {
      console.info(error || "window close");
      throw error;
    }
  };
};
