import { WSButton, WSEmptyState } from "@wingspanhq/fe-component-library";

export interface RecentPayablesTableProps {
  payables: any[];
}

export const RecentPayablesTable: React.FC<RecentPayablesTableProps> = ({
  payables
}) => {
  return payables.length > 0 ? (
    <>
      <div>RecentPayablesTable</div>
      <WSButton.Link
        mb="M"
        onClick={() => {
          alert("TODO");
        }}
      >
        View all in payables page
      </WSButton.Link>
    </>
  ) : (
    <WSEmptyState
      type="inbox"
      title="No payables yet"
      description="Once a contractor’s payable is added to this engagement, it will appear here"
      hasBorder={true}
      bgColor="white"
    />
  );
};
