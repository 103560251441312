const locale = "en-US";

export const WSDateFormats = [
  "default",
  "input",
  "mobileInput",
  "month",
  "monthDate",
  "monthDayYear",
  "year",
  "timeframe"
] as const;
export type WSDateFormatsType = typeof WSDateFormats[number];

export type WSDateObject = {
  toString: () => string;
  formattedValue: string;
  value: number;
};

const today = new Date();
const currentYear = today.getFullYear();

// Stolen straight from SO https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
const timeSince = (date: number) => {
  const seconds = Math.floor((new Date().getTime() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
};

export const toWSDate = (
  value: any,
  type: WSDateFormatsType = "default"
): WSDateObject => {
  let formattedValue = "";
  try {
    value = new Date(value);
    if (Number.isNaN(value.getTime())) {
      throw new Error("Formatter toWSDate handled invalid value");
    }
    if (type === "default") {
      const isCurrentYear = value.getFullYear() === currentYear;
      // If it's current year:
      // - Fri, Jan 29 at 6:28pm
      // Else:
      // - Oct 8, 1989 at 12:00am
      formattedValue = `${
        (isCurrentYear
          ? `${value.toLocaleString(locale, {
              weekday: "short"
            })}, `
          : "") +
        value.toLocaleString(locale, { month: "short", day: "numeric" }) +
        (isCurrentYear ? "" : `, ${value.getFullYear()}`)
      } at ${value
        .toLocaleString(locale, { timeStyle: "short" })
        .toLocaleLowerCase()
        .replace(" ", "")}`;
    } else if (type === "month") {
      formattedValue = value.toLocaleString(locale, { month: "long" });
    } else if (type === "monthDayYear") {
      formattedValue = value.toLocaleString(locale, {
        month: "short",
        day: "2-digit",
        year: "numeric"
      });
    } else if (type === "year") {
      formattedValue = value.toLocaleString(locale, { year: "numeric" });
    } else if (type === "input") {
      formattedValue = value.toLocaleString(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      });
    } else if (type === "mobileInput") {
      formattedValue = `${value.toLocaleString(locale, {
        year: "numeric"
      })}-${value.toLocaleString(locale, {
        month: "2-digit"
      })}-${value.toLocaleString(locale, {
        day: "2-digit"
      })}`;
    } else if (type === "monthDate") {
      formattedValue = value.toLocaleString(locale, {
        month: "short",
        day: "2-digit"
      });
    } else if (type === "timeframe") {
      formattedValue = `${timeSince(value)} ago`;
    }
  } catch {
    formattedValue = "-";
  }
  return {
    toString: () => formattedValue,
    formattedValue,
    value
  };
};

export const toWSDateString = (
  value: any,
  type: WSDateFormatsType = "default"
): string => toWSDate(value, type).formattedValue;
