import {
  useWSModal,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export const TaxesModalQuarterInfo: React.FC = () => {
  return (
    <WSElement>
      <WSText.ParagraphSm mb="M">
        We recommend sending your quarterly estimates 2 weeks before the due
        date to avoid the IRS’s penalty for underpayment of estimated taxes.
      </WSText.ParagraphSm>
      <WSMessageBox.Info noBorder>
        <WSText.Heading5 mb="M">Important:</WSText.Heading5>
        <WSText.ParagraphSm mb="M" color="gray600">
          If your quarterly payment request was sent after the IRS quarterly
          deadline, then you will have missed the deadline and the payment will
          not be applied to the past quarter. Instead, the payment will go
          towards the next quarter’s payment.
        </WSText.ParagraphSm>
        <WSText.ParagraphSm mb="M" color="gray600">
          For example,
          <ul>
            <WSElement as="li" ml="M">
              If the IRS deadline is April 13 for Q1 and your payment request
              was sent on April 14, then it will go towards the Q2 quarterly
              payment to the IRS.
            </WSElement>
            <WSElement as="li" ml="M">
              Q1 will remain unpaid, penalties from the IRS may apply
            </WSElement>
          </ul>
        </WSText.ParagraphSm>
      </WSMessageBox.Info>

      <WSText.ParagraphSm mt="XL" color="gray500">
        TIP: If you’re tempted to withdraw for non-emergencies, we recommend
        making regular payments to the IRS.
      </WSText.ParagraphSm>
    </WSElement>
  );
};

export const useModalTaxesQuarterInfo = () =>
  useWSModal(TaxesModalQuarterInfo, {
    title: "When should I send my quarterly estimate payment to the IRS?",
    size: "M"
  });
