import {
  ICardDetails,
  ICardResponse
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  BulkStatus,
  ClientWorkFlowStatus,
  FrequencyAndScheduleStatus,
  IBankingApplicationForm,
  IBankStatement,
  IBulkBatchCreate,
  IBulkBatchUpdate,
  IBulkCollaboratorBatch,
  IBulkCollaboratorItem,
  IBulkCollaboratorItemCreate,
  IBulkCollaboratorItemUpdate,
  IBulkPayableBatch,
  IBulkPayableBatchCreate,
  IBulkPayableBatchUpdate,
  IBulkPayableItem,
  IBulkPayableItemCreate,
  IBulkPayableItemUpdate,
  ICalculate1099Request,
  IClientInvoice,
  IClientInvoiceCreateRequest,
  IClientInvoiceTemplate,
  IClientInvoiceTemplateCreateRequest,
  IClientInvoiceTemplateUpdateRequest,
  IClientInvoiceUpdateRequest,
  ICollaboratorCreateRequest,
  ICollaboratorEvents,
  ICollaboratorGroupResponse,
  ICollaboratorSchema,
  ICollaboratorsReportRequest,
  ICollaboratorUpdateRequest,
  ICollaboratorV2,
  IEligibilityRequirementCreateRequest,
  IInstitutionResponse,
  IInvoice,
  IInvoiceCreateRequest,
  IInvoiceFeeCalculation,
  IInvoicePdfGenerationResponse,
  IInvoiceRefundRequest,
  IInvoiceTemplate,
  IInvoiceTemplateCreateRequest,
  IInvoiceTemplateUpdateRequest,
  IInvoiceUpdateRequest,
  IMark1099AsUndeliveredRequest,
  IMark1099AsUndeliveredResponse,
  IMccResponse,
  IMemberClient,
  IMemberClientCreateRequest,
  IMemberClientFormW9Info,
  IMemberClientUpdateRequest,
  InvoiceStatus,
  IPayableCreateRequest,
  IPayableSchema,
  IPayablesSummary,
  IPayableUpdateRequest,
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  IPaymentCard,
  IPaymentCardCreateInitiateResponse,
  IPaymentCardOneTimeCreateRequest,
  IPayoutDestinationUpdate,
  IPayRequest,
  IPayrollSettings,
  IPayrollSettingsUpdate,
  IRecipientVerificationRequest,
  IRecipientVerificationResponse,
  IRemail1099Request,
  IRemail1099Response,
  IServiceStatusResponse,
  IServiceUpdateRequest,
  ITaxFormCorrectionRequest,
  ITaxFormCorrectionResponse,
  ITaxFormCreateRequest,
  ITaxFormInviteRequest,
  ITaxFormInviteResponse,
  ITaxFormRedeliveryRequest,
  ITaxFormResponse,
  ITaxFormSubmissionDownloadRequest,
  ITaxFormUpdateRequest,
  ITinVerificationResponse,
  MemberClientStatus,
  MemberClientTaxStatus,
  MemberWorkFlowStatus,
  PaymentCardType,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import {
  IBulkCalculation1099ItemCreate,
  IBulkCalculation1099ItemUpdate
} from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";
import {
  ICardCodeRequest,
  ICardCodeResponse,
  ICardCreateRequest,
  ICardTokenRequest,
  ICardTokenResponse,
  ICardUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/card";
import {
  ICollaboratorGroupCreateRequest,
  ICollaboratorGroupRequirementUpdate,
  ICollaboratorGroupUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/collaboratorGroup";
import {
  IDeductionCreateRequest,
  IDeductionResponse,
  IDeductionUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/deductions";
import { IPayoutSettingsResponse } from "@wingspanhq/payments/dist/interfaces/api/payoutSettings";
import {
  ICollaboratorsPayoutsSummaryReportRequest,
  ICollaboratorsPayoutsSummaryReportResponse,
  ICollaboratorsReportResponse,
  ILineItemsAgingReportRequest,
  ILineItemsAgingReportResponse,
  IPayableAgingReportRequest,
  IPayableAgingReportResponse,
  IPayrollReportResponse
} from "@wingspanhq/payments/dist/interfaces/api/reports";
import {
  IBulkCalculation1099Batch,
  IBulkCalculation1099Item
} from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { IEligibilityRequirement } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import { getInvoiceRow } from "../modules/Invoicing/service";
import { Nullable } from "../types/utilities";
import { getChangedData } from "../utils/getChangedData";
import { isSameDate, ListRequestQuery, sleep } from "../utils/serviceHelper";
import { servicePayments } from "./servicePayments";

const service = servicePayments;

/* Service */

const getService = async (): Promise<IServiceStatusResponse> => {
  const { data } = await service.get("/service");
  return data;
};

const updateService = async (
  userId: string,
  requestData: IServiceUpdateRequest
): Promise<IServiceStatusResponse> => {
  const { data } = await service.patch(`/service/${userId}`, requestData);

  return data;
};

/* MCC */

const getMCCs = async (): Promise<IMccResponse[]> => {
  const { data } = await service.get("/mcc");
  return data;
};

/* Member Client */

interface MemberClientListFilter {
  memberId?:
    | string
    | {
        "!=": string;
      };
  clientId?:
    | string
    | {
        "!=": string;
      };
  name?:
    | string
    | {
        contains: string;
      };
  company?:
    | string
    | {
        contains: string;
      };
  emailTo?:
    | string
    | {
        contains: string;
      };
  status?:
    | MemberClientStatus
    | {
        in?: MemberClientStatus[];
      };
}

interface MemberClientListSort {
  updatedAt?: "asc" | "desc";
}

export interface MemberClientListQuery
  extends ListRequestQuery<MemberClientListFilter, MemberClientListSort> {}

const getMemberClients = async (
  query?: MemberClientListQuery
): Promise<IMemberClient[]> => {
  const { data } = await service.get("/memberClient", {
    params: query
  });
  return data;
};

const getMemberClientsListSize = async (
  query?: MemberClientListQuery
): Promise<number> => {
  const { headers } = await service.get("/memberClient", {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getMemberClient = async (id: string): Promise<IMemberClient> => {
  const { data } = await service.get(`/memberClient/${id}`);
  return data;
};

const createMemberClient = async (
  requestData: IMemberClientCreateRequest
): Promise<IMemberClient> => {
  const { data } = await service.post("/memberClient", requestData);
  return data;
};

const updateMemberClient = async (
  id: string,
  requestData: IMemberClientUpdateRequest
): Promise<IMemberClient> => {
  const { data } = await service.patch(`/memberClient/${id}`, requestData);
  return data;
};

const deleteMemberClient = async (id: string): Promise<IMemberClient> => {
  const { data } = await service.delete(`/memberClient/${id}`);
  return data;
};

/* V2 Client */

const getClientsV2 = async (
  query?: ListRequestQuery<
    CollaboratorFilter,
    {
      updatedAt?: "asc" | "desc";
    }
  >
): Promise<ICollaboratorV2[]> => {
  const { data } = await service.get("/v2/client", {
    params: query
  });
  return data;
};

const getClientsV2ListSize = async (
  query?: ListRequestQuery<
    CollaboratorFilter,
    {
      updatedAt?: "asc" | "desc";
    }
  >
): Promise<number> => {
  const { headers } = await service.get("/v2/client", {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getClientV2 = async (id: string): Promise<ICollaboratorV2> => {
  const { data } = await service.get(`/v2/client/${id}`);
  return data;
};

/* Collaborator */

export interface CollaboratorFilter {
  memberId?:
    | string
    | {
        "!=": string;
      };
  clientId?:
    | string
    | {
        "!=": string;
      };
  name?:
    | string
    | {
        contains: string;
      };
  company?:
    | string
    | {
        contains: string;
      };
  emailTo?:
    | string
    | {
        contains: string;
      };
  status?:
    | MemberClientStatus
    | {
        in?: MemberClientStatus[];
      };
  "labels.bulkBatchId"?:
    | string
    | {
        in?: string[];
      };
  taxStatus?: {
    "="?: MemberClientTaxStatus;
    "!="?: MemberClientTaxStatus;
  };
}

const getCollaborators = async (
  query?: ListRequestQuery<
    CollaboratorFilter,
    {
      updatedAt?: "asc" | "desc";
    }
  >
): Promise<ICollaboratorSchema[]> => {
  const { data } = await service.get("/collaborator", {
    params: query
  });
  return data;
};

const getCollaboratorsListSize = async (
  query?: ListRequestQuery<
    CollaboratorFilter,
    {
      updatedAt?: "asc" | "desc";
    }
  >
): Promise<number> => {
  const { headers } = await service.get("/collaborator", {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getCollaboratorsV2 = async (
  query?: ListRequestQuery<
    CollaboratorFilter,
    {
      updatedAt?: "asc" | "desc";
    }
  >
): Promise<ICollaboratorV2[]> => {
  const { data } = await service.get("/v2/collaborator", {
    params: query
  });
  return data;
};

const getCollaboratorsV2ListSize = async (
  query?: ListRequestQuery<
    CollaboratorFilter,
    {
      updatedAt?: "asc" | "desc";
    }
  >
): Promise<number> => {
  const { headers } = await service.get("/v2/collaborator", {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getCollaboratorV2 = async (id: string): Promise<ICollaboratorV2> => {
  const { data } = await service.get(`/v2/collaborator/${id}`);
  return data;
};

const getCollaborator = async (id: string): Promise<ICollaboratorSchema> => {
  const { data } = await service.get(`/collaborator/${id}`);
  return data;
};

const getCollaboratorEvents = async (
  id: string
): Promise<ICollaboratorEvents> => {
  const { data } = await service.get(`/collaborator/${id}/events`);
  return data;
};

const createCollaborator = async (
  requestData: ICollaboratorCreateRequest,
  xWingspanUser?: string // Sub account id
): Promise<ICollaboratorSchema> => {
  const { data } = await service.post(
    "/collaborator",
    requestData,
    xWingspanUser
      ? {
          headers: {
            "x-wingspan-user": xWingspanUser
          }
        }
      : undefined
  );
  return data;
};

type IMemberClientFormW9InfoNullable = Nullable<IMemberClientFormW9Info>;

export type ICollaboratorUpdateRequestW9Nullable = Omit<
  ICollaboratorUpdateRequest,
  "formW9Data"
> & {
  formW9Data?: Partial<IMemberClientFormW9InfoNullable>;
};

const updateCollaborator = async (
  id: string,
  requestData: ICollaboratorUpdateRequestW9Nullable
): Promise<ICollaboratorSchema> => {
  const { data } = await service.patch(`/collaborator/${id}`, requestData);
  return data;
};

const deleteCollaborator = async (id: string): Promise<ICollaboratorSchema> => {
  const { data } = await service.delete(`/collaborator/${id}`);
  return data;
};

export const download1099 = async (
  id: string,
  year: string,
  index: number
): Promise<Blob> => {
  const { data: blob } = await service.get(
    `/collaborator/${id}/download-1099/${year}/${index}`,
    {
      responseType: "blob"
    }
  );

  return blob;
};

const downloadW9 = async (id: string): Promise<Blob> => {
  const { data: blob } = await service.get(`/collaborator/${id}/download-w9`, {
    responseType: "blob"
  });
  return blob;
};

/* Collaborator group */

const getCollaboratorGroups = async (
  params: any
): Promise<ICollaboratorGroupResponse[]> => {
  const { data } = await service.get("/collaborator-group", { params });
  return data;
};

const getCollaboratorGroup = async (
  collaboratorGroupId: string
): Promise<ICollaboratorGroupResponse> => {
  const { data } = await service.get(
    "/collaborator-group/" + collaboratorGroupId
  );
  return data;
};

const createCollaboratorGroup = async (
  params: ICollaboratorGroupCreateRequest
): Promise<ICollaboratorGroupResponse> => {
  const { data } = await service.post("/collaborator-group", params);
  return data;
};

const updateCollaboratorGroup = async (
  id: string,
  params: ICollaboratorGroupUpdateRequest
): Promise<ICollaboratorGroupResponse> => {
  const { data } = await service.patch(`/collaborator-group/${id}`, params);
  return data;
};

const deleteCollaboratorGroup = async (
  id: string
): Promise<ICollaboratorGroupResponse> => {
  const { data } = await service.delete(`/collaborator-group/${id}`);
  return data;
};

const addCollaboratorToGroup = async (
  collaboratorId: string,
  groupId: string
): Promise<ICollaboratorSchema> => {
  const { data } = await service.patch(
    `/collaborator/${collaboratorId}/add-group/${groupId}`,
    {}
  );
  return data;
};

const removeCollaboratorFromGroup = async (
  collaboratorId: string,
  groupId: string
): Promise<ICollaboratorSchema> => {
  const { data } = await service.patch(
    `/collaborator/${collaboratorId}/remove-group/${groupId}`,
    {}
  );
  return data;
};

const deleteCollaboratorGroupRequirement = async (
  collaboratorGroupId: string,
  eligibilityRequirementId: string
): Promise<ICollaboratorGroupResponse> => {
  const { data } = await service.delete(
    `/collaborator-group/${collaboratorGroupId}/eligibility-requirement/${eligibilityRequirementId}`
  );
  return data;
};

const replaceCollaboratorGroupRequirement = async (
  collaboratorGroupId: string,
  eligibilityRequirementId: string,
  params: ICollaboratorGroupRequirementUpdate
): Promise<ICollaboratorGroupResponse> => {
  const { data } = await service.patch(
    `/collaborator-group/${collaboratorGroupId}/eligibility-requirement/${eligibilityRequirementId}`,
    params
  );
  return data;
};

/* Invoice */

export interface InvoiceListFilter {
  memberId?: string;
  clientId?: string;
  "lineItems.labels.bulkBatchId"?:
    | string
    | {
        in?: string[];
      };
  memberClientId?:
    | string
    | {
        in?: string[];
      };
  status?:
    | InvoiceStatus
    | {
        "!="?: InvoiceStatus;
        in?: InvoiceStatus[];
      };
  "client.workflowStatus"?:
    | ClientWorkFlowStatus
    | {
        "!="?: ClientWorkFlowStatus;
        in?: ClientWorkFlowStatus[];
        notIn?: ClientWorkFlowStatus[];
      };
  "member.workflowStatus"?:
    | MemberWorkFlowStatus
    | {
        "!="?: MemberWorkFlowStatus;
        in?: MemberWorkFlowStatus[];
        notIn?: MemberWorkFlowStatus[];
      };
  dueDate?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  "events.openedAt"?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  "events.paidAt"?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  createdAt?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  updatedAt?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  "labels.invoiceType"?:
    | string
    | {
        "!=": string;
      };
  "labels.creationSource"?:
    | string
    | {
        "!=": string;
      };
  "lineItems.labels.taxForm1099"?: keyof ICollaboratorSchema["form1099Balances"];
  "internal.collaborators.parentInvoiceId"?: string;
  "internal.payroll.payrollId"?: string;
}

interface InvoiceListSort {
  updatedAt?: "asc" | "desc";
  createdAt?: "asc" | "desc";
  "events.paidAt"?: "asc" | "desc";
}

export interface InvoiceListQuery
  extends ListRequestQuery<InvoiceListFilter, InvoiceListSort> {}

export interface InvoiceListStats {
  totalAmount: number;
}

const getInvoices = async (query?: InvoiceListQuery): Promise<IInvoice[]> => {
  const { data } = await service.get("/invoice", { params: query });
  return data;
};

const getInvoicesListSize = async (
  query?: InvoiceListQuery
): Promise<number> => {
  const { headers } = await service.get("/invoice", {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getInvoicesListStats = async (
  query?: InvoiceListQuery
): Promise<InvoiceListStats> => {
  const { headers } = await service.get("/invoice", {
    params: { ...query, page: { size: 1 } }
  });
  return {
    totalAmount: Number(headers["x-ws-custom-invoice-amount-total"])
  };
};

const getInvoice = async (id: string): Promise<IInvoice> => {
  const { data } = await service.get(`/invoice/${id}`);
  return data;
};

const createInvoice = async (
  requestData: IInvoiceCreateRequest
): Promise<IInvoice> => {
  const { data } = await service.post("/invoice", requestData);
  return data;
};

const updateInvoice = async (
  id: string,
  requestData: IInvoiceUpdateRequest,
  config?: {
    waitForRowUpdate?: boolean;
  }
): Promise<IInvoice> => {
  const { data: invoice } = await service.patch<IInvoice>(
    `/invoice/${id}`,
    requestData
  );

  if (config?.waitForRowUpdate) {
    try {
      for (let i = 0; i < 3; i++) {
        const row = await getInvoiceRow(id);
        if (isSameDate(row.updatedAt, invoice.updatedAt)) {
          return invoice;
        }

        await sleep(1000);
      }
    } catch (error) {
      console.error("Failed to get invoice row", error);
    }
  }

  return invoice;
};

const sendInvoiceReminder = async (id: string) => {
  const { data } = await service.post(`/invoice/${id}/send`);
  return data;
};

const generateInvoicePdf = async (
  id: string
): Promise<IInvoicePdfGenerationResponse> => {
  const { data } = await service.post(`/invoice/${id}/generate`);
  return data;
};

const refundInvoice = async (
  id: string,
  requestData: IInvoiceRefundRequest
) => {
  const { data } = await service.post(`/invoice/${id}/refund`, requestData);
  return data;
};

const payInvoice = async (id: string) => {
  const { data } = await service.post(`/invoice/${id}/pay`);
  return data;
};

const deleteInvoice = async (id: string) => {
  const { data } = await service.delete(`/invoice/${id}`);
  return data;
};

/* Payble */

export interface PayablesFilter {
  memberId?: string;
  clientId?: string;
  memberClientId?:
    | string
    | {
        in?: string[];
      };
  status?:
    | InvoiceStatus
    | {
        "!="?: InvoiceStatus;
        in?: InvoiceStatus[];
      };
  "client.workflowStatus"?:
    | ClientWorkFlowStatus
    | {
        "!="?: ClientWorkFlowStatus;
        in?: ClientWorkFlowStatus[];
        notIn?: ClientWorkFlowStatus[];
      };
  "member.workflowStatus"?:
    | MemberWorkFlowStatus
    | {
        "!="?: MemberWorkFlowStatus;
        in?: MemberWorkFlowStatus[];
        notIn?: MemberWorkFlowStatus[];
      };
  dueDate?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  "events.openedAt"?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  "events.paidAt"?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  createdAt?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  updatedAt?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  "labels.invoiceType"?:
    | string
    | {
        "!=": string;
      };
  "internal.collaborators.parentInvoiceId"?: string;
  "lineItems.labels.taxForm1099"?:
    | number
    | {
        "="?: number;
        "!="?: number;
        exists?: boolean;
      };
}

export type PayablesWithSummary = {
  data: IPayableSchema[];
  summary: {
    listSize: number;
    totalValue: number;
  };
};

export type PayablesSort = {
  updatedAt?: "asc" | "desc";
  "events.paidAt"?: "asc" | "desc";
  "internal.events.paymentDueAt"?: "asc" | "desc";
};

const getPayables = async (
  query?: ListRequestQuery<PayablesFilter, PayablesSort>,
  userId?: string
): Promise<PayablesWithSummary> => {
  const { headers, data } = await service.get("/payable", {
    params: query,
    headers: {
      "x-wingspan-user": userId
    }
  });

  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"]),
      totalValue: Number(headers["x-ws-custom-invoice-amount-total"])
    }
  };
};

const getPayable = async (id: string): Promise<IPayableSchema> => {
  const { data } = await service.get(`/payable/${id}`);
  return data;
};

const createPayable = async (
  requestData: IPayableCreateRequest
): Promise<IPayableSchema> => {
  const { data } = await service.post("/payable", requestData);
  return data;
};

const updatePayable = async (
  id: string,
  requestData: IPayableUpdateRequest
): Promise<IPayableSchema> => {
  const { data } = await service.patch(`/payable/${id}`, requestData);
  return data;
};
const deletePayable = async (id: string) => {
  const { data } = await service.delete(`/payable/${id}`);
  return data;
};

/* Invoice template */

interface InvoiceTemplateListFilter {
  memberId?: string;
  status?:
    | FrequencyAndScheduleStatus
    | {
        "!="?: FrequencyAndScheduleStatus;
        in?: FrequencyAndScheduleStatus[];
      };
}

interface InvoiceTemplateListSort {
  createdAt?: "asc" | "desc";
  updatedAt?: "asc" | "desc";
}

export interface InvoiceTemplateListQuery
  extends ListRequestQuery<
    InvoiceTemplateListFilter,
    InvoiceTemplateListSort
  > {}

const getInvoiceTemplates = async (
  query?: InvoiceTemplateListQuery
): Promise<IInvoiceTemplate[]> => {
  const response = await service.get("/invoice-template", { params: query });
  return response.data;
};

const getInvoiceTemplatesListSize = async (
  query?: InvoiceTemplateListQuery
): Promise<number> => {
  const { headers } = await service.get("/invoice-template", {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getInvoiceTemplate = async (id: string): Promise<IInvoiceTemplate> => {
  const response = await service.get(`/invoice-template/${id}`);
  return response.data;
};

const createInvoiceTemplate = async (
  data: IInvoiceTemplateCreateRequest
): Promise<IInvoiceTemplate> => {
  const response = await service.post("/invoice-template", data);
  return response.data;
};

const updateInvoiceTemplate = async (
  id: string,
  data: IInvoiceTemplateUpdateRequest
): Promise<IInvoiceTemplate> => {
  const response = await service.patch(`/invoice-template/${id}`, data);
  return response.data;
};

const deleteInvoiceTemplate = async (id: string): Promise<IInvoiceTemplate> => {
  const response = await service.delete(`/invoice-template/${id}`);
  return response.data;
};

/* Client Invoice */

const getClientInvoices = async (
  query?: ListRequestQuery<
    InvoiceListFilter,
    {
      updatedAt: "asc" | "desc";
    }
  >
): Promise<IClientInvoice[]> => {
  const response = await service.get("/client/invoice", {
    params: query
  });
  return response.data;
};

const getClientInvoice = async (id: string): Promise<IClientInvoice> => {
  const response = await service.get(`/client/invoice/${id}`);
  return response.data;
};

const createClientInvoice = async (
  data: IClientInvoiceCreateRequest
): Promise<IClientInvoice> => {
  const response = await service.post("/client/invoice", data);
  return response.data;
};

const updateClientInvoice = async (
  id: string,
  data: IClientInvoiceUpdateRequest
): Promise<IClientInvoice> => {
  const response = await service.patch(`/client/invoice/${id}`, data);
  return response.data;
};

const getClientInvoiceFees = async (
  id: string
): Promise<IInvoiceFeeCalculation> => {
  const response = await service.get(`/client/invoice/${id}/fees`);
  return response.data;
};

const payClientInvoice = async (
  id: string,
  data: IPayRequest
): Promise<IClientInvoice> => {
  const response = await service.post(`/client/invoice/${id}/pay`, data);
  return response.data;
};

/* Client Invoice Template */

const getClientInvoiceTemplates = async (): Promise<IClientInvoiceTemplate[]> => {
  const response = await service.get("/client/invoice-template");
  return response.data;
};

const getClientInvoiceTemplate = async (
  id: string
): Promise<IClientInvoiceTemplate> => {
  const response = await service.get(`/client/invoice-template/${id}`);
  return response.data;
};

const createClientInvoiceTemplate = async (
  data: IClientInvoiceTemplateCreateRequest
): Promise<IClientInvoiceTemplate> => {
  const response = await service.post("/client/invoice-template", data);
  return response.data;
};

const updateClientInvoiceTemplate = async (
  id: string,
  data: IClientInvoiceTemplateUpdateRequest
): Promise<IClientInvoiceTemplate> => {
  const response = await service.patch(`/client/invoice-template/${id}`, data);
  return response.data;
};

/* Banking Statement */

const listBankingStatements = async (): Promise<IBankStatement[]> => {
  const response = await service.get("/banking/statement");
  return response.data;
};

const getBankingStatement = async (
  statementId: string
): Promise<IBankStatement> => {
  const response = await service.get(`/banking/statement/${statementId}`);
  return response.data;
};

const downloadBankingStatement = async (statementId: string): Promise<Blob> => {
  const response = await service.get(
    `/banking/statement/${statementId}/download`,
    {
      responseType: "blob"
    }
  );
  return response.data;
};

const getBankingApplication = async (
  userId: string
): Promise<IBankingApplicationForm> => {
  const response = await service.get(`/service/banking/${userId}/application`);
  return response.data;
};

/* Eligibility requirements */

export interface EligibilityRequirementFilter {
  eligibilityRequirementId?: {
    in: string[];
  };
}

const getEligibilityRequirements = async (
  query?: ListRequestQuery<EligibilityRequirementFilter>
): Promise<IEligibilityRequirement[]> => {
  const response = await service.get(
    "/collaborator-settings/eligibility-requirement",
    { params: query }
  );
  return response.data;
};

const getEligibilityRequirement = async (
  id: string
): Promise<IEligibilityRequirement> => {
  const response = await service.get(
    "/collaborator-settings/eligibility-requirement/" + id
  );
  return response.data;
};

const createEligibilityRequirement = async (
  requestData: IEligibilityRequirementCreateRequest
): Promise<IEligibilityRequirement> => {
  const response = await service.post(
    "/collaborator-settings/eligibility-requirement",
    requestData
  );
  return response.data;
};

const deleteEligibilityRequirement = async (
  id: string
): Promise<IEligibilityRequirement> => {
  const response = await service.delete(
    "/collaborator-settings/eligibility-requirement/" + id
  );
  return response.data;
};

/* Payables summary */

const getPayablesSummary = async (): Promise<IPayablesSummary> => {
  const response = await service.get("/summary/payables");
  return response.data;
};

/* Payroll settings */
const getPayrollSettings = async (
  memberId: string
): Promise<IPayrollSettings> => {
  const response = await service.get(`/payroll-settings/${memberId}`);
  return response.data;
};

const updatePayrollSettings = async (
  memberId: string,
  data: IPayrollSettingsUpdate
): Promise<IPayrollSettings> => {
  const response = await service.patch(`/payroll-settings/${memberId}`, data);
  return response.data;
};

/* Cards  */

const createCard = async (data: ICardCreateRequest): Promise<ICardResponse> => {
  const response = await service.post("/banking/card", data);
  return response.data;
};

const replaceCard = async (payload: {
  id: string;
  data: ICardCreateRequest;
}): Promise<ICardResponse> => {
  const response = await service.post(
    `/banking/card/${payload.id}/replace`,
    payload.data
  );
  return response.data;
};

const getCards = async (): Promise<ICardResponse[]> => {
  const response = await service.get("/banking/card");
  return response.data;
};

export type ICardDetailsResponse = {
  cardId: string;
  memberId: string;
} & ICardDetails;

const getCard = async (id: string): Promise<ICardDetailsResponse> => {
  const response = await service.get(`/banking/card/${id}`);
  return response.data;
};

const updateCard = async (
  id: string,
  data: ICardUpdateRequest
): Promise<ICardResponse> => {
  const response = await service.patch(`/banking/card/${id}`, data);
  return response.data;
};

const deleteCard = async (id: string): Promise<ICardResponse> => {
  const response = await service.delete(`/banking/card/${id}`);
  return response.data;
};

/* Card token */

const createCardToken = async (
  id: string,
  data: ICardCodeRequest
): Promise<ICardCodeResponse> => {
  const response = await service.post(`/banking/card/${id}/token`, data);
  return response.data;
};

const updateCardToken = async (
  id: string,
  data: ICardTokenRequest
): Promise<ICardTokenResponse> => {
  const response = await service.patch(`/banking/card/${id}/token`, data);
  return response.data;
};

/* Payout Settings */

const getPayoutSettings = async (
  memberId: string
): Promise<IPayoutSettingsResponse> => {
  const response = await service.get(`/payout-settings/${memberId}`);
  return response.data;
};

const updatePayoutSettings = async (
  memberId: string,
  updateData: {
    payoutPreferences?: PayoutPreferences;
    standard?: {
      destinationType: PayoutDestinationType;
      percentage?: number;
      destinationId: string;
    } | null;
    instant?: {
      destinationType: PayoutDestinationType;
      percentage?: number;
      destinationId: string;
    } | null;
  }
): Promise<IPayoutSettingsResponse> => {
  const payoutSettings = await getPayoutSettings(memberId);

  const payoutDestinationsUpdate: IPayoutDestinationUpdate[] = payoutSettings.payoutDestinations.map(
    _ => ({})
  );

  // Update standard destination
  if (updateData.standard !== undefined) {
    const indexOfCurrentStandardPayoutMethod = payoutSettings.payoutDestinations.findIndex(
      d => d.payoutMethod === PayoutPreferences.Standard
    );

    if (indexOfCurrentStandardPayoutMethod >= 0) {
      if (updateData.standard === null) {
        // Delete standard destination
        payoutDestinationsUpdate[
          indexOfCurrentStandardPayoutMethod
        ] = null as any;
      } else {
        // Update standard destination
        payoutDestinationsUpdate[
          indexOfCurrentStandardPayoutMethod
        ] = getChangedData(
          payoutSettings.payoutDestinations[indexOfCurrentStandardPayoutMethod],
          updateData.standard
        );
      }
    } else {
      // Create a standard destination
      if (updateData.standard !== null) {
        payoutDestinationsUpdate.push({
          payoutMethod: PayoutPreferences.Standard,
          percentage: 100,
          ...updateData.standard
        });
      }
    }
  }

  // Update instant destination
  if (updateData.instant !== undefined) {
    const indexOfCurrentInstantPayoutMethod = payoutSettings.payoutDestinations.findIndex(
      d => d.payoutMethod === PayoutPreferences.Instant
    );

    if (indexOfCurrentInstantPayoutMethod >= 0) {
      if (updateData.instant === null) {
        // Delete instant destination
        payoutDestinationsUpdate[
          indexOfCurrentInstantPayoutMethod
        ] = null as any;
      } else {
        // Update instant destination
        payoutDestinationsUpdate[
          indexOfCurrentInstantPayoutMethod
        ] = getChangedData(
          payoutSettings.payoutDestinations[indexOfCurrentInstantPayoutMethod],
          updateData.instant
        );
      }
    } else {
      // Create instant destination
      if (updateData.instant !== null) {
        payoutDestinationsUpdate.push({
          payoutMethod: PayoutPreferences.Instant,
          percentage: 100,
          ...updateData.instant
        });
      }
    }
  }

  const response = await service.patch(`/payout-settings/${memberId}`, {
    payoutPreferences: updateData.payoutPreferences,
    payoutDestinations: payoutDestinationsUpdate
  });

  return response.data as IPayoutSettingsResponse;
};

const updateWalletDistribution = async (
  memberId: string,
  walletDistributionPercentage: number
): Promise<IPayoutSettingsResponse> => {
  const payoutSettings = await getPayoutSettings(memberId);

  const payoutDestinationsUpdate: (IPayoutDestinationUpdate | null)[] = payoutSettings.payoutDestinations.map(
    _ =>
      walletDistributionPercentage === 100
        ? null
        : {
            percentage: 100 - walletDistributionPercentage
          }
  );

  const response = await service.patch(`/payout-settings/${memberId}`, {
    payoutDestinations: payoutDestinationsUpdate
  });

  return response.data as IPayoutSettingsResponse;
};

// TODO: remove these interfaces and replace with those in payments

export enum CardBrand {
  AmericanExpress = "AmericanExpress",
  Visa = "Visa",
  Mastercard = "Mastercard",
  Discover = "Discover",
  DinersClub = "DinersClub",
  JCB = "JCB"
}

export interface ICheckbookCard {
  cardId: string;
  brand: CardBrand;
  last4: string;
  expirationYYYY: string;
  expirationMM: string;
  address: IAddress;
}

export interface ICheckbookCardCreate {
  cardNumber: string;
  expYYYY: string;
  expMM: string;
  cvv?: string;
  name: string;
  address?: IAddress;
}

// -- remove meeeee ^ --

const getPayoutSettingsDebitCards = async (
  memberId: string
): Promise<ICheckbookCard[]> => {
  const response = await service.get(`/payout-settings/${memberId}/debit-card`);
  return response.data;
};

const getPayoutSettingsDebitCard = async (
  memberId: string,
  debitCardId: string
): Promise<ICheckbookCard> => {
  const response = await service.get(
    `/payout-settings/${memberId}/debit-card/${debitCardId}`
  );
  if (response.data?.length > 0) {
    return response.data[0];
  }
  return response.data;
};

const createPayoutSettingsDebitCard = async (
  memberId: string,
  data: ICheckbookCardCreate
): Promise<ICheckbookCard> => {
  const response = await service.post(
    `/payout-settings/${memberId}/debit-card`,
    data
  );
  return response.data;
};

const deletePayoutSettingsDebitCard = async (
  memberId: string,
  debitCardId: string
): Promise<ICheckbookCard> => {
  const response = await service.delete(
    `/payout-settings/${memberId}/debit-card/${debitCardId}`
  );
  return response.data;
};

/* Payout Settings */

const getInstitution = async (id: string): Promise<IInstitutionResponse> => {
  const response = await service.get(`/banking/institution/${id}`);
  return response.data;
};

/* Deductions */

const createClientDeduction = async (
  data: IDeductionCreateRequest
): Promise<IDeductionResponse> => {
  const response = await service.post(`/client-deduction`, data);
  return response.data;
};

const getClientDeductions = async (
  params: any
): Promise<IDeductionResponse[]> => {
  const response = await service.get(`/client-deduction`, { params });
  return response.data;
};

const getClientDeduction = async (id: string): Promise<IDeductionResponse> => {
  const response = await service.get(`/client-deduction/${id}`);
  return response.data;
};

const updateClientDeduction = async (
  id: string,
  data: IDeductionUpdateRequest
): Promise<IDeductionResponse> => {
  const response = await service.patch(`/client-deduction/${id}`, data);
  return response.data;
};

const deleteClientDeduction = async (
  id: string
): Promise<IDeductionResponse> => {
  const response = await service.delete(`/client-deduction/${id}`);
  return response.data;
};

const createCollaboratorDeduction = async (
  data: IDeductionCreateRequest
): Promise<IDeductionResponse> => {
  const response = await service.post(`/collaborator-deduction`, data);
  return response.data;
};

const getCollaboratorDeductions = async (
  params: any
): Promise<IDeductionResponse[]> => {
  const response = await service.get(`/collaborator-deduction`, { params });
  return response.data;
};

const getCollaboratorDeduction = async (
  id: string
): Promise<IDeductionResponse> => {
  const response = await service.get(`/collaborator-deduction/${id}`);
  return response.data;
};

const updateCollaboratorDeduction = async (
  id: string,
  data: IDeductionUpdateRequest
): Promise<IDeductionResponse> => {
  const response = await service.patch(`/collaborator-deduction/${id}`, data);
  return response.data;
};

const deleteCollaboratorDeduction = async (
  id: string
): Promise<IDeductionResponse> => {
  const response = await service.delete(`/collaborator-deduction/${id}`);
  return response.data;
};

// bulk collaborator upload

const createBulkCollaboratorBatch = async (
  data: IBulkBatchCreate
): Promise<IBulkCollaboratorBatch> => {
  const response = await service.post(`/bulk/collaborator/batch`, data);
  return response.data;
};

const updateBulkCollaboratorBatch = async (
  id: string,
  data: IBulkBatchUpdate
): Promise<IBulkCollaboratorBatch> => {
  const response = await service.patch(`/bulk/collaborator/batch/${id}`, data);
  return response.data;
};

const getBulkCollaboratorBatch = async (
  id: string
): Promise<IBulkCollaboratorBatch> => {
  const response = await service.get(`/bulk/collaborator/batch/${id}`);
  return response.data;
};

const createBulkCollaboratorBatchItem = async (
  bulkCollaboratorBatchId: string,
  data: IBulkCollaboratorItemCreate
): Promise<IBulkCollaboratorItem> => {
  const response = await service.post(
    `/bulk/collaborator/batch/${bulkCollaboratorBatchId}/item`,
    data
  );
  return response.data;
};

const updateBulkCollaboratorBatchItem = async (
  bulkCollaboratorBatchId: string,
  bulkCollaboratorItemId: string,
  data: IBulkCollaboratorItemUpdate
): Promise<IBulkCollaboratorItem> => {
  const response = await service.patch(
    `/bulk/collaborator/batch/${bulkCollaboratorBatchId}/item/${bulkCollaboratorItemId}`,
    data
  );
  return response.data;
};

const getBulkCollaboratorBatchItems = async (
  batchId: string,
  status: BulkStatus[]
): Promise<{
  data: IBulkCollaboratorItem[];
  summary: { listSize: number };
}> => {
  const { headers, data } = await service.get(
    `/bulk/collaborator/batch/${batchId}/item`,
    {
      params: { filter: { status: { in: status } } }
    }
  );
  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"])
    }
  };
};

const getBulkCollaboratorBatchItem = async (
  bulkCollaboratorBatchId: string,
  bulkCollaboratorItemId: string
): Promise<IBulkCollaboratorItem> => {
  const response = await service.get(
    `/bulk/collaborator/batch/${bulkCollaboratorBatchId}/item/${bulkCollaboratorItemId}`
  );
  return response.data;
};

// bulk payable upload

const createBulkPayableBatch = async (
  data: IBulkPayableBatchCreate
): Promise<IBulkPayableBatch> => {
  const response = await service.post(`/bulk/payable/batch`, data);
  return response.data;
};

const updateBulkPayableBatch = async (
  id: string,
  data: IBulkPayableBatchUpdate
): Promise<IBulkPayableBatch> => {
  const response = await service.patch(`/bulk/payable/batch/${id}`, data);
  return response.data;
};

const getBulkPayableBatch = async (id: string): Promise<IBulkPayableBatch> => {
  const response = await service.get(`/bulk/payable/batch/${id}`);
  return response.data;
};

const createBulkPayableBatchItem = async (
  data: IBulkPayableItemCreate
): Promise<IBulkPayableItem> => {
  const response = await service.post(
    `/bulk/payable/batch/${data.bulkPayableBatchId}/item`,
    data
  );
  return response.data;
};

const updateBulkPayableBatchItem = async (
  id: string,
  data: IBulkPayableItemUpdate
): Promise<IBulkPayableItem> => {
  const response = await service.patch(
    `/bulk/payable/batch/${data.bulkPayableBatchId}/item/${id}`,
    data
  );
  return response.data;
};

const getBulkPayableBatchItems = async (
  batchId: string,
  status: BulkStatus[]
): Promise<{ data: IBulkPayableItem[]; summary: { listSize: number } }> => {
  const { headers, data } = await service.get(
    `/bulk/payable/batch/${batchId}/item`,
    {
      params: { filter: { status: { in: status } } }
    }
  );
  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"])
    }
  };
};

const getBulkPayableBatchItem = async (
  batchId: string,
  batchItemId: string
): Promise<IBulkPayableItem> => {
  const response = await service.get(
    `/bulk/payable/batch/${batchId}/item/${batchItemId}`
  );
  return response.data;
};

// 1099 NEC endpoints

// bulk calc batch
const createBulkCalculate1099Batch = async (
  data: IBulkBatchCreate
): Promise<IBulkCalculation1099Batch> => {
  const response = await service.post(`/bulk/calculation1099/batch`, data);
  return response.data;
};

const updateBulkCalculate1099Batch = async (
  id: string,
  data: IBulkBatchUpdate
): Promise<IBulkCalculation1099Batch> => {
  const response = await service.patch(
    `/bulk/calculation1099/batch/${id}`,
    data
  );
  return response.data;
};

const getBulkCalculate1099Batch = async (
  id: string
): Promise<IBulkCalculation1099Batch> => {
  const response = await service.get(`/bulk/calculation1099/batch/${id}`);
  return response.data;
};

// bulk calc batch item
const createBulkCalculate1099BatchItem = async (
  bulkCalculation1099BatchId: string,
  data: IBulkCalculation1099ItemCreate
): Promise<IBulkCalculation1099Item> => {
  const response = await service.post(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item`,
    data
  );
  return response.data;
};

const updateBulkCalculate1099BatchItem = async (
  bulkCalculation1099BatchId: string,
  bulkCalculation1099BatchItemId: string,
  data: IBulkCalculation1099ItemUpdate
): Promise<IBulkPayableItem> => {
  const response = await service.patch(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item/${bulkCalculation1099BatchItemId}`,
    data
  );
  return response.data;
};

const getBulkCalculate1099BatchItems = async (
  bulkCalculation1099BatchId: string
): Promise<IBulkPayableItem[]> => {
  const response = await service.get(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item`
  );
  return response.data;
};

const getBulkCalculate1099BatchItem = async (
  bulkCalculation1099BatchId: string,
  bulkCalculation1099BatchItemId: string
): Promise<IBulkPayableItem> => {
  const response = await service.get(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item/${bulkCalculation1099BatchItemId}`
  );
  return response.data;
};

const calculate1099NECTotal = async (
  data: ICalculate1099Request
): Promise<ICollaboratorSchema> => {
  const response = await service.post("/collaborator/1099/calculate", data);
  return response.data;
};

const do1099NECRemail = async (
  data: IRemail1099Request
): Promise<IRemail1099Response> => {
  const response = await service.post("/collaborator/1099/remail", data);
  return response.data;
};

const markDocUndelivered = async (
  data: IMark1099AsUndeliveredRequest
): Promise<IMark1099AsUndeliveredResponse> => {
  const response = await service.post(
    "/collaborator/1099/mark-undelivered",
    data
  );
  return response.data;
};

const getCollaboratorsReports = async (
  request: ICollaboratorsReportRequest,
  query?: ListRequestQuery
): Promise<{
  data: ICollaboratorsReportResponse[];
  summary: {
    listSize: number;
  };
}> => {
  const { data, headers } = await service.get("/reports/collaborators", {
    params: {
      ...request,
      ...query
    }
  });

  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"])
    }
  };
};

const getCollaboratorPayableSummaryReports = async (
  request: ICollaboratorsPayoutsSummaryReportRequest,
  query?: ListRequestQuery
): Promise<{
  data: ICollaboratorsPayoutsSummaryReportResponse[];
  summary: {
    listSize: number;
  };
}> => {
  const { data, headers } = await service.get(
    "/reports/collaborators/payables-summary",
    {
      params: {
        ...request,
        ...query
      }
    }
  );

  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"])
    }
  };
};

const payApproved = async (): Promise<IInvoice> => {
  const response = await service.post("/pay-approved");
  return response.data;
};

const getImmediatePayrollPayabelsList = async (
  query?: ListRequestQuery<
    undefined,
    {
      updatedAt?: "asc" | "desc";
    }
  >
): Promise<PayablesWithSummary> => {
  const { headers, data } = await service.get("/payroll/immediate/payable", {
    params: query
  });

  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"]),
      totalValue: Number(headers["x-ws-custom-invoice-amount-total"])
    }
  };
};

const getPayrollReports = async (
  query?: ListRequestQuery<{
    invoiceId?:
      | string
      | {
          in?: string[];
        };
  }>
): Promise<{
  data: IPayrollReportResponse;
  summary: {
    listSize: number;
  };
}> => {
  const { data, headers } = await service.get(`/reports/payroll`, {
    params: {
      ...query
    }
  });

  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"])
    }
  };
};

const getOpenPayableAgingReport = async (
  request: IPayableAgingReportRequest,
  query?: ListRequestQuery
): Promise<{
  data: IPayableAgingReportResponse[];
  summary: {
    listSize: number;
  };
}> => {
  const { data, headers } = await service.get(`reports/aging/payables`, {
    params: {
      ...request,
      ...query
    }
  });

  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"])
    }
  };
};

const getOpenLineItemAgingReport = async (
  request: ILineItemsAgingReportRequest,
  query?: ListRequestQuery
): Promise<{
  data: ILineItemsAgingReportResponse[];
  summary: {
    listSize: number;
  };
}> => {
  const { data, headers } = await service.get(`reports/aging/line-items`, {
    params: {
      ...request,
      ...query
    }
  });

  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"])
    }
  };
};

const createTaxForm = async (
  requestData: ITaxFormCreateRequest
): Promise<IPayerTaxFormResponse> => {
  const { data } = await service.post("/tax-form", requestData);
  return data;
};

const getTaxForm = async (taxFormId: string) => {
  const { data } = await service.get(`/tax-form/${taxFormId}`);

  // can be one IPayeeTaxFormResponse or IPayerTaxFormResponse;
  return data as any;
};

const updateTaxForm = async (
  taxFormId: string,
  requestData: ITaxFormUpdateRequest,
  params?: {
    skipEventHistory?: boolean;
  }
): Promise<IPayerTaxFormResponse> => {
  const { data } = await service.patch(`/tax-form/${taxFormId}`, requestData, {
    params: {
      skipEventHistory: params?.skipEventHistory ? "true" : undefined
    }
  });
  return data;
};

const downloadTaxFormDocument = async (
  requestData: {
    taxFormId: string;
    submissionId: string;
  } & ITaxFormSubmissionDownloadRequest
): Promise<Blob> => {
  const { taxFormId, submissionId, ...request } = requestData;

  const { data } = await service.post(
    `/tax-form/${requestData.taxFormId}/submission/${requestData.submissionId}/download`,
    request,
    {
      responseType: "blob"
    }
  );
  return data;
};

const taxFormVerifyIdentity = async (
  taxFormId: string,
  request: IRecipientVerificationRequest
) => {
  const { data } = await service.post(
    `/tax-form/${taxFormId}/verify-identity`,
    request
  );

  return data as IRecipientVerificationResponse;
};

const taxFormVerifyTin = async () => {
  const { data } = await service.post(`/tax-form/verify-tin`, {});

  return data as ITinVerificationResponse;
};

const taxFormSubmitW9 = async () => {
  const { data } = await service.post(`/tax-form/submit-w9`, {});

  return data; //TODO IVerifications;
};

const getPayeeTaxForms = async (year: number, params?: any) => {
  const { data } = await service.get(
    `/tax-form?year=${year}&type=Form1099Nec&viewer=payee`,
    {
      params
    }
  );

  return data as IPayeeTaxFormResponse[];
};

const getPayerTaxForms = async (
  year: number,
  xWingspanExpansion?: string,
  params?: any
): Promise<{
  data: IPayerTaxFormResponse[];
  summary: { listSize: number };
}> => {
  const { data, headers } = await service.get(
    `/tax-form?year=${year}&type=Form1099Nec&viewer=payer`,
    {
      params,
      headers: {
        "x-wingspan-expansion": xWingspanExpansion
      }
    }
  );

  return { data, summary: { listSize: Number(headers["x-ws-list-size"]) } };
};

const request1099Link = async (request: ITaxFormInviteRequest) => {
  const { data } = await service.post(`/tax-form/resend-invite`, request);

  return data as ITaxFormInviteResponse;
};

const resyncTaxForm = async (taxFormId: string) => {
  const { data } = await service.post(`/tax-form/${taxFormId}/resync`, {});

  return data as IPayerTaxFormResponse;
};

const resendEmailCopy = async (
  taxFormId: string,
  request: ITaxFormRedeliveryRequest
) => {
  const { data } = await service.post(
    `/tax-form/${taxFormId}/redeliver`,
    request
  );

  return data as ITaxFormResponse;
};

const createCorrection = async (
  taxFormId: string,
  request: ITaxFormCorrectionRequest
) => {
  const { data } = await service.post(
    `/tax-form/${taxFormId}/correction`,
    request
  );

  return data as ITaxFormCorrectionResponse;
};

const updateCorrection = async (
  taxFormId: string,
  correctionId: string,
  request: ITaxFormCorrectionRequest
) => {
  const { data } = await service.patch(
    `/tax-form/${taxFormId}/correction/${correctionId}`,
    request
  );

  return data as ITaxFormCorrectionResponse;
};

export interface ITaxFormCorrectionRejectRequest {
  payerOwnedData?: {
    comment?: string;
    commentInternal?: string;
  };
}

const rejectCorrection = async (
  taxFormId: string,
  correctionId: string,
  request: ITaxFormCorrectionRejectRequest
) => {
  const { data } = await service.post(
    `/tax-form/${taxFormId}/correction/${correctionId}/reject`,
    request
  );

  return data as ITaxFormCorrectionResponse;
};

const approveCorrection = async (
  taxFormId: string,
  correctionId: string,
  request: ITaxFormCorrectionRejectRequest
) => {
  const { data } = await service.post(
    `/tax-form/${taxFormId}/correction/${correctionId}/submit`,
    request
  );

  return data as ITaxFormCorrectionResponse;
};

const paymentCardInitiateCreate = async () => {
  const { data } = await service.post("/payment-card");

  return data as IPaymentCardCreateInitiateResponse;
};

export type IPaymentCardCompleteCreateRequest = { saveForFutureUse?: boolean };

const paymentCardCompleteCreate = async (
  paymentCardId: string,
  requestData?: IPaymentCardCompleteCreateRequest
) => {
  const { data } = await service.post(
    `/payment-card/${paymentCardId}`,
    requestData
  );

  return data as IPaymentCard;
};

const paymentCardOneTimeInitiateCreate = async (
  requestData: IPaymentCardOneTimeCreateRequest
) => {
  const { data } = await service.post("/payment-card/one-time", requestData);

  return data as IPaymentCardCreateInitiateResponse;
};

const paymentCardOneTimeCompleteCreate = async (
  paymentCardId: string,
  requestData: IPaymentCardOneTimeCreateRequest
) => {
  const { data } = await service.post(
    `/payment-card/one-time/${paymentCardId}`,
    requestData
  );

  return data as IPaymentCard;
};

export type PaymentCardListQuery = ListRequestQuery<{
  cartType: PaymentCardType;
}>;

const paymentCardList = async (query?: PaymentCardListQuery) => {
  const { data } = await service.get("/payment-card", {
    // TODO: uncomment when API is ready
    // params: query
  });

  return data as IPaymentCard[];
};

const paymentCardDelete = async (paymentCardId: string) => {
  const { data } = await service.delete(`/payment-card/${paymentCardId}`);

  return data as IPaymentCard;
};

export const paymentsService = {
  service: {
    get: getService,
    update: updateService,
    banking: {
      application: {
        get: getBankingApplication
      }
    }
  },
  mcc: {
    list: getMCCs
  },
  memberClient: {
    list: getMemberClients,
    listSize: getMemberClientsListSize,
    listV2: getClientsV2,
    listV2Size: getClientsV2ListSize,
    get: getMemberClient,
    getV2: getClientV2,
    create: createMemberClient,
    update: updateMemberClient,
    delete: deleteMemberClient
  },
  collaborator: {
    list: getCollaborators,
    listSize: getCollaboratorsListSize,
    listV2: getCollaboratorsV2,
    listV2Size: getCollaboratorsV2ListSize,
    get: getCollaborator,
    getV2: getCollaboratorV2,
    getEvents: getCollaboratorEvents,
    create: createCollaborator,
    update: updateCollaborator,
    delete: deleteCollaborator,
    addToGroup: addCollaboratorToGroup,
    removeFromGroup: removeCollaboratorFromGroup,
    download1099: download1099,
    downloadW9: downloadW9,
    nec1099: {
      calculate1099NECTotal,
      do1099NECRemail,
      markDocUndelivered,
      bulkCalculation1099Batch: {
        create: createBulkCalculate1099Batch,
        update: updateBulkCalculate1099Batch,
        get: getBulkCalculate1099Batch
      },
      bulkCalculation1099BatchItem: {
        create: createBulkCalculate1099BatchItem,
        update: updateBulkCalculate1099BatchItem,
        get: getBulkCalculate1099BatchItem,
        list: getBulkCalculate1099BatchItems
      }
    }
  },
  collaboratorGroup: {
    list: getCollaboratorGroups,
    get: getCollaboratorGroup,
    create: createCollaboratorGroup,
    update: updateCollaboratorGroup,
    delete: deleteCollaboratorGroup,
    eligibilityRequirement: {
      replace: replaceCollaboratorGroupRequirement,
      delete: deleteCollaboratorGroupRequirement
    }
  },
  invoice: {
    list: getInvoices,
    listSize: getInvoicesListSize,
    listStats: getInvoicesListStats,
    get: getInvoice,
    create: createInvoice,
    update: updateInvoice,
    delete: deleteInvoice,
    sendReminder: sendInvoiceReminder,
    generatePdf: generateInvoicePdf,
    refund: refundInvoice,
    pay: payInvoice
  },
  invoiceTemplate: {
    list: getInvoiceTemplates,
    listSize: getInvoiceTemplatesListSize,
    get: getInvoiceTemplate,
    create: createInvoiceTemplate,
    update: updateInvoiceTemplate,
    delete: deleteInvoiceTemplate
  },
  payable: {
    list: getPayables,
    get: getPayable,
    create: createPayable,
    update: updatePayable,
    delete: deletePayable
  },
  client: {
    invoice: {
      list: getClientInvoices,
      get: getClientInvoice,
      create: createClientInvoice,
      update: updateClientInvoice,
      getFees: getClientInvoiceFees,
      pay: payClientInvoice
    },
    invoiceTemplate: {
      list: getClientInvoiceTemplates,
      get: getClientInvoiceTemplate,
      create: createClientInvoiceTemplate,
      update: updateClientInvoiceTemplate
    }
  },
  banking: {
    statement: {
      list: listBankingStatements,
      get: getBankingStatement,
      download: downloadBankingStatement
    },
    card: {
      create: createCard,
      replace: replaceCard,
      get: getCard,
      list: getCards,
      update: updateCard,
      delete: deleteCard,
      token: {
        create: createCardToken,
        update: updateCardToken
      }
    },
    institution: {
      get: getInstitution
    }
  },
  collaboratorSettings: {
    eligibilityRequirements: {
      list: getEligibilityRequirements,
      get: getEligibilityRequirement,
      create: createEligibilityRequirement,
      delete: deleteEligibilityRequirement
    }
  },
  reports: {
    payroll: getPayrollReports,
    collaborators: getCollaboratorsReports,
    openPayableAging: getOpenPayableAgingReport,
    openLineItemAging: getOpenLineItemAgingReport,
    collaboratorPayable: getCollaboratorPayableSummaryReports
  },
  summary: {
    payables: getPayablesSummary
  },
  payrollSettings: {
    get: getPayrollSettings,
    update: updatePayrollSettings
  },
  payoutSettings: {
    get: getPayoutSettings,
    update: updatePayoutSettings,
    updateWalletDistribution,
    debitCard: {
      get: getPayoutSettingsDebitCard,
      list: getPayoutSettingsDebitCards,
      create: createPayoutSettingsDebitCard,
      delete: deletePayoutSettingsDebitCard
    }
  },
  collaboratorDeductions: {
    list: getCollaboratorDeductions,
    get: getCollaboratorDeduction,
    update: updateCollaboratorDeduction,
    create: createCollaboratorDeduction,
    delete: deleteCollaboratorDeduction
  },
  clientDeductions: {
    list: getClientDeductions,
    get: getClientDeduction,
    update: updateClientDeduction,
    create: createClientDeduction,
    delete: deleteClientDeduction
  },
  bulkCollaborator: {
    batch: {
      create: createBulkCollaboratorBatch,
      get: getBulkCollaboratorBatch,
      update: updateBulkCollaboratorBatch
    },
    batchItem: {
      create: createBulkCollaboratorBatchItem,
      get: getBulkCollaboratorBatchItem,
      list: getBulkCollaboratorBatchItems,
      update: updateBulkCollaboratorBatchItem
    }
  },
  bulkPayable: {
    batch: {
      create: createBulkPayableBatch,
      get: getBulkPayableBatch,
      update: updateBulkPayableBatch
    },
    batchItem: {
      create: createBulkPayableBatchItem,
      get: getBulkPayableBatchItem,
      list: getBulkPayableBatchItems,
      update: updateBulkPayableBatchItem
    }
  },
  payApproved,
  payroll: {
    immediate: {
      payable: {
        list: getImmediatePayrollPayabelsList
      }
    }
  },
  taxForm: {
    create: createTaxForm,
    get: getTaxForm,
    update: updateTaxForm,
    verifyIdentity: taxFormVerifyIdentity,
    verifyTin: taxFormVerifyTin,
    submitW9: taxFormSubmitW9,
    payeeList: getPayeeTaxForms,
    payerList: getPayerTaxForms,
    requestLink: request1099Link,
    resync: resyncTaxForm,
    downloadDocument: downloadTaxFormDocument,
    resendEmailCopy,
    createCorrection,
    updateCorrection,
    rejectCorrection,
    approveCorrection
  },
  paymentCard: {
    initiateCreate: paymentCardInitiateCreate,
    completeCreate: paymentCardCompleteCreate,
    list: paymentCardList,
    delete: paymentCardDelete,
    oneTime: {
      initiateCreate: paymentCardOneTimeInitiateCreate,
      completeCreate: paymentCardOneTimeCompleteCreate
    }
  }
};
