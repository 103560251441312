import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { RouteGettingStarted } from "./routes/RouteGettingStarted";
import { RouteDocumentsToSign } from "./routes/RouteDocumentsToSign";
import { RouteW9Consent } from "./routes/RouteW9Consent";

export const DashboardIndex: React.FC = () => {
  useBrowserPageTitle("Dashboard");

  return (
    <Switch>
      <Route
        path="/member/dashboard/collaborators-e-signatures"
        render={() => <RouteDocumentsToSign type="collaborator" />}
        exact
      />
      <Route
        path="/member/dashboard/clients-e-signatures"
        render={() => <RouteDocumentsToSign type="client" />}
        exact
      />
      <Route
        path="/member/dashboard/w9-consent"
        component={RouteW9Consent}
        exact
      />
      <Route path="/member/dashboard" component={RouteGettingStarted} />
      <Redirect from="*" to="/member/dashboard" />
    </Switch>
  );
};
