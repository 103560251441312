import {
  SelectOptionOld,
  useModalContext,
  useWSModal,
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  IMemberClient,
  IMemberClientCreateRequest,
  MemberClientStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { queryCache } from "react-query";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers,
  useIntegrationsQuickbooksItems
} from "../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../query/integrations/selectors";
import { QUERY_MEMBER_CLIENTS } from "../../../query/payments/keys";
import { useCreateMemberClient } from "../../../query/payments/mutations";
import { useMemberClientsQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import {
  contactCompanyValidator,
  contactNameValidator
} from "../../../utils/validators";
import { selectQuickbooksUserOptions } from "../../../modules/Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../modules/Integrations/selectors/selectQuickbooksAccountOrItemOptions";
import { AdditionalEmailsFieldOld } from "../AdditionalEmailsField/AdditionalEmailsFieldOld";

type Props = WSElementProps & {
  onSuccess?: (memberClient: IMemberClient) => void;
  onClose: () => void;
};

export const ModalAddClient: React.FC<Props> = ({
  onSuccess,
  onClose,
  ...elementProps
}) => {
  const { openModal } = useModalContext();

  const userId = useUserId();

  const memberClientsQuery = useMemberClientsQuery();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks({
    refetchOnMount: true
  });
  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers();
  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems();

  const [createMemberClient, createMemberClientMeta] = useCreateMemberClient();

  const { data: integrationState } = qIntegrationsQuickbooks;
  const { data: qboCustomers } = qIntegrationsQuickbooksCustomers;
  const { data: qboItems } = qIntegrationsQuickbooksItems;

  const qFeatureFlags = useFeatureFlags();

  const isQuickbooksActive =
    integrationState &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(integrationState));

  const quickbooksCustomerOptions: SelectOptionOld[] = selectQuickbooksUserOptions(
    qboCustomers
  );

  const quickbooksItemsOptions: SelectOptionOld[] = selectQuickbooksAccountOrItemOptions(
    qboItems
  );

  const defaultItem = (qIntegrationsQuickbooksItems.data || []).find(
    item => item.itemId === integrationState?.defaults?.itemId
  );

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ clients: memberClientsQuery }}>
        {({ clientsData }) => (
          <WSFormOld<{
            company: string;
            contact: string;
            email: string;
            qboCustomerId: string;
            qboItemId: string;
            additionalEmails?: { email: string }[];
          }>
            defaultValues={{
              company: "",
              contact: "",
              email: "",
              qboCustomerId: "",
              qboItemId: "",
              additionalEmails: []
            }}
            onSubmit={values => {
              const request: IMemberClientCreateRequest = {
                memberId: userId,
                company: values.company,
                name: values.contact,
                emailTo: values.email,
                emailCC: values.additionalEmails
                  ? values.additionalEmails.map(e => e.email)
                  : undefined
              };

              if (
                isQuickbooksActive &&
                (values.qboCustomerId !== "" || values.qboItemId !== "")
              ) {
                request.integration = {
                  quickbooks: {
                    ...(values.qboCustomerId
                      ? { customerId: values.qboCustomerId }
                      : {}),
                    ...(values.qboItemId ? { itemId: values.qboItemId } : {})
                  }
                };
              }

              createMemberClient(request, {
                onSuccess: memberClient => {
                  // Prepopulate the query cache with the new client
                  const previous: undefined | IMemberClient[] =
                    queryCache.getQueryData(QUERY_MEMBER_CLIENTS) || [];
                  const newData = [memberClient, ...previous];
                  queryCache.setQueryData(QUERY_MEMBER_CLIENTS, newData);

                  onSuccess?.(memberClient);
                  onClose();
                }
              });
            }}
            validationSchema={Yup.object().shape({
              company: contactCompanyValidator,
              contact: contactNameValidator.required("Required"),
              email: validatorEmail.required("Required").notOneOf(
                clientsData
                  .filter(c => c.status !== MemberClientStatus.Inactive)
                  .map(c => c.emailTo),
                "A client with this email address already exists"
              ),
              additionalEmails: Yup.array(
                Yup.object().shape({
                  email: validatorEmail
                })
              )
            })}
          >
            <WSFormOld.Field
              name="company"
              label="Client company (optional)"
              component={WSTextInput}
              mb="XL"
            />
            <WSFormOld.Field
              name="contact"
              label="Client contact"
              component={WSTextInput}
              mb="XL"
            />
            <WSFormOld.Field
              name="email"
              label="Contact email"
              component={WSTextInput}
              mb="XL"
            />
            <AdditionalEmailsFieldOld
              name="additionalEmails"
              inputLabel="Contact email CC"
              buttonLabel="Add email recipient"
              buttonDescription="Additional email addresses will be cc’d"
              mb="2XL"
            />
            {isQuickbooksActive && quickbooksItemsOptions.length > 0 && (
              <>
                <WSDivider mb="XL" label="Quickbooks Mapping" />
                <WSText mb="XL">
                  Because you have an active integration with QBO, please map
                  this new Client to an existing Customer, or leave blank and we
                  will create a new one.
                </WSText>
                <WSFormOld.Field
                  mb="XL"
                  name="qboCustomerId"
                  component={WSSelectOld}
                  componentProps={{
                    placeholder: "Not mapped, create new Customer",
                    options: quickbooksCustomerOptions,
                    searchable: true,
                    cleanable: true,
                    placeholderActions: [
                      {
                        label: "Resync QBO Customers",
                        icon: "refresh-v",
                        callback() {
                          openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                            entity: QUICKBOOKS_ENTITY.CUSTOMERS
                          });
                        }
                      }
                    ]
                  }}
                />
                <WSFormOld.Field
                  mb="2XL"
                  name="qboItemId"
                  component={WSSelectOld}
                  componentProps={{
                    options: quickbooksItemsOptions,
                    placeholder: `Use default "${defaultItem?.fullyQualifiedName}"`,
                    cleanable: true,
                    searchable: true,
                    placeholderActions: [
                      {
                        label: "Resync QBO Items",
                        icon: "refresh-v",
                        callback() {
                          openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                            entity: QUICKBOOKS_ENTITY.ITEMS
                          });
                        }
                      }
                    ]
                  }}
                  label="Default QBO Item"
                />
                <RefreshModal />
              </>
            )}
            <WSErrorMessage
              mb="XL"
              error={createMemberClientMeta.error}
              contextKey="CreateClient"
            />

            <WSButtons format="modal">
              <WSButton
                name="addClient"
                ml="M"
                loading={createMemberClientMeta.isLoading}
              >
                Add client
              </WSButton>
              <WSButton.Tertiary onClick={onClose}>Cancel</WSButton.Tertiary>
            </WSButtons>
          </WSFormOld>
        )}
      </WSQueries>
    </WSElement>
  );
};

export const useModalAddClient = () =>
  useWSModal(ModalAddClient, {
    title: "Add client",
    size: "S"
  });
