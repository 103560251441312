import FlatfileImporter from "@flatfile/adapter";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../query/hooks/helpers";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { getVisibleCollaborators } from "../../../query/payments/selectors";
import { useUserProfile } from "../../../query/users/queries";
import { IS_DEV_ENV } from "../../../shared/constants/environment";
import { getFloatFromString } from "../../../utils/amounts";
import { flatfileTheme } from "../../../utils/flatfileTheme";
import { BulkAdjust1099Collaborator } from "./BulkAdjust1099AmountStep1";

const apiKey = process.env.REACT_APP_FLATFILE_API_KEY || "";
const currentYear = new Date().getFullYear();
const prevYear = currentYear - 1;

export const use1099AmountBulkAdjustImporter = () => {
  const userId = useUserId();
  const user = useUserProfile(userId);
  const collaboratorsQuery = useCollaboratorsQuery();

  const getCollaboratorByEmail = (
    collaborators: ICollaboratorSchema[],
    email: string
  ): ICollaboratorSchema | null => {
    const collaborator = collaborators.filter(
      mc => mc.member.user.email === email
    );
    return collaborator.length > 0 ? collaborator[0] : null;
  };

  const importer = new FlatfileImporter(apiKey, {
    ...flatfileTheme,
    type: "Contractors",
    title: "Adjust 1099 amounts",
    autoDetectHeaders: true,
    devMode: IS_DEV_ENV,
    managed: true,
    disableManualInput: true,
    displayEncoding: false,
    fields: [
      {
        label: "Legal First Name",
        key: "legalFirstName"
      },
      {
        label: "Legal Last Name",
        key: "legalLastName"
      },
      {
        label: "Email",
        key: "email",
        validators: [
          { validate: "required" }
          // { validate: "unique" }
        ]
      },
      {
        label: `${prevYear} Wingspan payments`,
        key: "platformIncome"
      },
      {
        label: "Adjustment amount",
        key: "adjustmentAmount"
      },
      {
        label: "Send 1099",
        key: "send1099",
        type: "checkbox"
      }
    ]
  });

  importer.registerRecordHook(async (record: BulkAdjust1099Collaborator) => {
    const collaborators = getVisibleCollaborators(
      collaboratorsQuery.data || []
    );
    const collaborator = getCollaboratorByEmail(
      collaborators,
      record.email as string
    );
    const output: any = {};
    const platformIncomeFromCSV = getFloatFromString(
      record.platformIncome + ""
    );
    const adjustmentAmountFromCSV = getFloatFromString(
      record.adjustmentAmount + ""
    );
    if (!adjustmentAmountFromCSV) {
      output.adjustmentAmount = {
        value: record.adjustmentAmount || 0,
        info: [
          {
            message: "Set default value as 0",
            level: "info"
          }
        ]
      };
    }
    if (
      adjustmentAmountFromCSV &&
      platformIncomeFromCSV &&
      platformIncomeFromCSV + adjustmentAmountFromCSV < 0
    ) {
      output.adjustmentAmount = {
        value: record.adjustmentAmount || 0,
        info: [
          {
            message:
              "Sum of gross amount and adjustment amount is less than zero",
            level: "error"
          }
        ]
      };
    }
    if (collaborator) {
      const prevYearForm1099Balances =
        collaborator.form1099Balances[
          prevYear as keyof ICollaboratorSchema["form1099Balances"]
        ];
      const platformIncome = prevYearForm1099Balances?.platformIncome as number;
      if (
        platformIncomeFromCSV >= 0 &&
        platformIncomeFromCSV !== getFloatFromString(platformIncome + "")
      ) {
        output.platformIncome = {
          value: record.platformIncome,
          info: [
            {
              message: "This value is not matching with our database value",
              level: "error"
            }
          ]
        };
      }
    } else {
      output.email = {
        value: record.email,
        info: [
          {
            message:
              "New contractor will be created as there is no contractor exists with this email",
            level: "info"
          }
        ]
      };
    }
    return output;
  });

  importer.setCustomer({
    userId: userId,
    name: `${user.data?.profile.firstName} ${user.data?.profile.lastName}`
  });

  return async () => {
    try {
      const results = await importer.requestDataFromUser();
      await importer.close();
      return results.validData as Array<BulkAdjust1099Collaborator>;
    } catch (error) {
      console.info(error || "window close");
      throw error;
    }
  };
};
