import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSPill,
  WSSwitchInput,
  WSText
} from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import {
  MemberWithholdingStatus,
  TaxFilingCode
} from "@wingspanhq/users/dist/lib/interfaces";
import cn from "classnames";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useQueryVerifications } from "../../query/onboarding/queries/useQueryVerifications";
import { useUpdateMemberProfile } from "../../query/users/mutations";
import { useActivities, useMemberProfile } from "../../query/users/queries";
import { selectorIsOnboardingComplete } from "../../shared/selectors/selectorIsOnboardingComplete";
import { DefaultSettingItem } from "../components/DefaultSettingItem/DefaultSettingItem.component";
import { SettingsLoader } from "../components/SettingsLoader/SettingsLoader";
import { SettingsSections } from "../components/SettingsSections/SettingsSections.component";
import { EXPENSES_OPTIONS } from "../components/SettingsUpdateForms/TaxExpensesEstimateForYearForm";
import { US_STATES_OPTIONS } from "../components/SettingsUpdateForms/TaxFilingStateForm";
import { TAX_FILING_STATUS_OPTIONS } from "../components/SettingsUpdateForms/TaxFilingStatusForm";
import {
  HEAD_OF_HOUSEHOLD_OPTIONS,
  MARRIED_OPTIONS,
  SINGLE_OPTIONS
} from "../components/SettingsUpdateForms/TaxIncomeEstimateForYearForm";
import { getSelectedOptionByValue } from "../utils";
import mainStyles from "./SettingsMain.module.scss";

export interface TaxProfileTabProps {}

export const TaxProfileTab: React.FC<TaxProfileTabProps> = props => {
  const userId = useUserId();
  const history = useHistory();
  const qMemberProfile = useMemberProfile(userId);
  const [updateMemberProfile] = useUpdateMemberProfile();
  const [withholdingStatus, setWithholdingStatus] = useState<boolean>();
  const activityQuery = useActivities(userId);
  const queryVerifications = useQueryVerifications();

  const content = (
    <WSQueries
      queries={{ member: qMemberProfile, activityQuery }}
      renderLoader={() => <SettingsLoader />}
    >
      {({ member, activityQuery: { data: activity } }) => {
        const memberData = member.data;

        return (
          <WSElement className={mainStyles.contentPane} {...props}>
            <SettingsSections
              title="Tax Profile"
              parentPathName="/member/settings/tax-profile"
              sections={[
                {
                  title: "Filing Basics",
                  render() {
                    return (
                      <>
                        <DefaultSettingItem
                          label="Tax filing status"
                          value={memberData.profile.taxInfo?.filingCode || ""}
                          valueRenderer={(value: string) => {
                            return (
                              <WSText.ParagraphSm
                                className={cn({
                                  [mainStyles.emptyStateValue]: !value
                                })}
                              >
                                {value
                                  ? getSelectedOptionByValue(
                                      TAX_FILING_STATUS_OPTIONS,
                                      value
                                    )?.label
                                  : "Tax filing status is not added yet"}
                              </WSText.ParagraphSm>
                            );
                          }}
                          field="taxFilingStatus"
                          action={
                            memberData.profile.taxInfo?.filingCode
                              ? "Edit"
                              : "Add"
                          }
                        />
                        <DefaultSettingItem
                          label="Number of dependents"
                          value={memberData.profile.taxInfo?.numExemptions || 0}
                          valueRenderer={(value: string) => {
                            return (
                              <WSText.ParagraphSm
                                className={cn({
                                  [mainStyles.emptyStateValue]: !value
                                })}
                              >
                                {Number(value) === 0 || value
                                  ? value
                                  : "Number of dependents is not added yet"}
                              </WSText.ParagraphSm>
                            );
                          }}
                          field="noOfDependents"
                          action={
                            memberData.profile.taxInfo?.numExemptions
                              ? "Edit"
                              : "Add"
                          }
                        />
                        <DefaultSettingItem
                          label="Tax filing state"
                          value={
                            memberData.profile.taxInfo?.stateOfResidence || ""
                          }
                          valueRenderer={(value: string) => {
                            return (
                              <WSText.ParagraphSm
                                className={cn({
                                  [mainStyles.emptyStateValue]: !value
                                })}
                              >
                                {value
                                  ? getSelectedOptionByValue(
                                      US_STATES_OPTIONS,
                                      value
                                    )?.label
                                  : "Tax filing state is not added yet"}
                              </WSText.ParagraphSm>
                            );
                          }}
                          field="taxFilingState"
                          action={
                            memberData.profile.taxInfo?.stateOfResidence
                              ? "Edit"
                              : "Add"
                          }
                        />
                        {/* <DefaultSettingItem
                      label="IRS Industry"
                      value={memberData.profile.taxInfo?.occupations || ""}
                      valueRenderer={(value: string) => {
                        return (
                          <WSText.ParagraphSm
                            className={cn({
                              [mainStyles.emptyStateValue]: !value
                            })}
                          >
                            {value ? value : "IRS Industry is not added yet"}
                          </WSText.ParagraphSm>
                        );
                      }}
                      field="taxFilingState"
                      action={
                        memberData.profile.taxInfo?.stateOfResidence
                          ? "Edit"
                          : "Add"
                      }
                    /> */}
                      </>
                    );
                  }
                },
                {
                  title: "Tax savings estimates",
                  render() {
                    const incomeOptions = memberData.profile.taxInfo?.filingCode
                      ? [
                          TaxFilingCode.Single,
                          TaxFilingCode.MarriedFilingSeparately,
                          TaxFilingCode.QualifiedWidower
                        ].includes(memberData.profile.taxInfo?.filingCode)
                        ? SINGLE_OPTIONS
                        : [TaxFilingCode.Married].includes(
                            memberData.profile.taxInfo?.filingCode
                          )
                        ? MARRIED_OPTIONS
                        : HEAD_OF_HOUSEHOLD_OPTIONS
                      : [];
                    return (
                      <>
                        <DefaultSettingItem
                          label="Estimated annual income"
                          value={
                            memberData.profile.taxInfo?.incomeEstimateForYear
                          }
                          valueRenderer={(value: string) => {
                            return (
                              <WSText.ParagraphSm
                                className={cn({
                                  [mainStyles.emptyStateValue]: !value
                                })}
                              >
                                {value
                                  ? `$ ${
                                      getSelectedOptionByValue(
                                        incomeOptions,
                                        `${value}`
                                      )?.label
                                    }`
                                  : "Estimated annual income is not added yet"}
                              </WSText.ParagraphSm>
                            );
                          }}
                          field="incomeEstimateForYear"
                          action={
                            memberData.profile.taxInfo?.incomeEstimateForYear
                              ? "Edit"
                              : "Add"
                          }
                        />
                        <DefaultSettingItem
                          label="Estimated annual expenses"
                          value={
                            memberData.profile.taxInfo?.expensesEstimateForYear
                          }
                          valueRenderer={(value: string) => {
                            return (
                              <WSText.ParagraphSm
                                className={cn({
                                  [mainStyles.emptyStateValue]: !value
                                })}
                              >
                                {value
                                  ? `$ ${
                                      getSelectedOptionByValue(
                                        EXPENSES_OPTIONS,
                                        `${value}`
                                      )?.label
                                    }`
                                  : "Estimated annual expenses is not added yet"}
                              </WSText.ParagraphSm>
                            );
                          }}
                          field="expensesEstimateForYear"
                          action={
                            memberData.profile.taxInfo?.expensesEstimateForYear
                              ? "Edit"
                              : "Add"
                          }
                        />
                        {/*
                    Disable for now
                    <DefaultSettingItem
                      label="Projected tax rate"
                      helpInfoText="Unless manually customized, this number is based on the estimates above."
                      value={
                        memberData.profile.taxInfo?.stateOfResidence || ""
                      }
                      valueRenderer={(value: string) => {
                        return (
                          <WSText.ParagraphSm
                            className={cn({
                              [mainStyles.emptyStateValue]: !value
                            })}
                          >
                            {value
                              ? value
                              : "Projected tax rate is not added yet"}
                          </WSText.ParagraphSm>
                        );
                      }}
                      field="projectedTaxRate"
                      action={
                        memberData.profile.taxInfo?.stateOfResidence
                          ? "Edit"
                          : "Add"
                      }
                    /> */}
                      </>
                    );
                  }
                },
                {
                  title: "Tax withholdings",
                  render() {
                    const status =
                      memberData.profile?.withholdings?.tax?.status;
                    const isActive = status === MemberWithholdingStatus.Active;
                    const rate =
                      memberData.profile?.withholdings?.tax?.rate || 0;
                    const value = withholdingStatus ?? isActive;

                    return (
                      <>
                        <WSFlexBox.CenterY justify="space-between" mt="2XL">
                          {!selectorIsOnboardingComplete(
                            activity,
                            queryVerifications.data
                          ) ? (
                            <WSButton.Link
                              onClick={() => {
                                history.push("/member/cip");
                              }}
                            >
                              Enable payments to activate withholdings.
                            </WSButton.Link>
                          ) : null}

                          {status ? (
                            <WSElement>
                              {value ? (
                                <WSPill
                                  theme="success"
                                  text="Withholdings on"
                                  badge
                                />
                              ) : (
                                <WSPill
                                  theme="warning"
                                  text="Withholdings off"
                                  badge
                                />
                              )}

                              <WSText.ParagraphSm my="M">
                                Set aside {rate}% of each invoice paid through
                                Wingspan.
                              </WSText.ParagraphSm>
                            </WSElement>
                          ) : (
                            <WSText.ParagraphSm my="M">
                              Tax withholdings setup has not been completed yet.{" "}
                              <Link to="/member/taxes">Go to taxes.</Link>
                            </WSText.ParagraphSm>
                          )}
                          {status ? (
                            <WSSwitchInput
                              my="M"
                              name="withholdingsStatus"
                              value={value}
                              onChange={async () => {
                                const newValue = !value;

                                setWithholdingStatus(newValue);
                                const request: IMemberUpdateRequest = {
                                  memberId: userId,
                                  profile: {
                                    withholdings: {
                                      tax: {
                                        rate,
                                        status: newValue
                                          ? MemberWithholdingStatus.Active
                                          : MemberWithholdingStatus.Paused
                                      }
                                    }
                                  }
                                };
                                await updateMemberProfile(request);
                              }}
                            />
                          ) : null}
                        </WSFlexBox.CenterY>
                      </>
                    );
                  }
                }
              ]}
            />
          </WSElement>
        );
      }}
    </WSQueries>
  );
  return (
    <WSPage title="Tax Profile">
      <WSGrid>
        <WSGrid.Item span={{ m: "10" }}>{content}</WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
