import {
  WSElementProps,
  WSForm,
  WSInputCardNumber,
  WSInputMask,
  WSInputText,
  WSList,
  WSSelect,
  validatorCardNumber
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import {
  EXPIRATION_MONTH_OPTIONS,
  EXPIRATION_YEAR_OPTIONS
} from "./dropdownOptions";

type Props = {
  name: string;
  showCvv?: boolean;
} & WSElementProps;

export type FormDataBankCard = {
  name: string;
  cardNumber: string;
  expMM: string | null;
  expYYYY: string | null;
  cvv: string;
};

export const getValidationSchemaBankCard = (showCvv?: boolean) =>
  Yup.object().shape({
    name: Yup.string().required("Required"),
    cardNumber: validatorCardNumber.required("Required"),
    expMM: Yup.string().required("Required"),
    expYYYY: Yup.string().required("Required"),
    cvv: showCvv ? Yup.string().required("Required") : Yup.string()
  });

export const FormPartialBankCard: React.FC<Props> = ({
  name,
  showCvv,
  ...elementProps
}) => {
  return (
    <WSList gap="M" {...elementProps}>
      <WSForm.Field
        label="Name on card"
        name={name + ".name"}
        component={WSInputText}
        componentProps={{
          required: true
        }}
      />

      <WSForm.Field
        label="Card number"
        name={name + ".cardNumber"}
        component={WSInputCardNumber}
        componentProps={{
          required: true
        }}
      />

      <WSList gap="M" kind="Horizontal">
        <WSForm.Field
          label="Month"
          name={name + ".expMM"}
          component={WSSelect}
          componentProps={{
            required: true,
            options: EXPIRATION_MONTH_OPTIONS
          }}
        />

        <WSForm.Field
          label="Year"
          name={name + ".expYYYY"}
          component={WSSelect}
          componentProps={{
            required: true,
            options: EXPIRATION_YEAR_OPTIONS
          }}
        />

        {showCvv && (
          <WSForm.Field
            label="CVV"
            name={name + ".cvv"}
            component={WSInputMask}
            componentProps={{
              required: true,
              mask: "999?9",
              slotChar: ""
            }}
          />
        )}
      </WSList>
    </WSList>
  );
};

export const CARD_EXP_MONS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
].map(s => ({
  value: s,
  label: s,
  searchText: s
}));

// ref: https://stackoverflow.com/questions/1575271/range-of-years-in-javascript-for-a-select-box
const CARD_EXP_YEAR_NOW = new Date().getFullYear();
const range = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => `${start + i * step}`
  );
export const CARD_EXP_YEARS = range(
  CARD_EXP_YEAR_NOW,
  CARD_EXP_YEAR_NOW + 30,
  1
).map(s => ({
  value: s,
  label: s,
  searchText: s
}));
