import {
  WSInfiniteScroll,
  WSLoader,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { FrequencyAndScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { INVOICING_PATH } from "..";
import { useBrowserPageTitle } from "../../../shared/hooks/useBrowserPageTitle";
import { useUrlQueryFilters } from "../../../utils/router";
import { PageToolbar } from "../components/PageToolbar";
import { TableInvoiceTemplates } from "../components/TableInvoiceTemplates";
import { Filters, Toolbar } from "../components/Toolbar";
import { useQueryInvoiceTemplateRows } from "../queries/useQueryInvoiceTemplateRows";
import { useQueryInvoiceTemplateRowsSummary } from "../queries/useQueryInvoiceTemplateRowsSummary";

export const RouteScheduled: React.FC = () => {
  useBrowserPageTitle("Scheduled invoices");
  const history = useHistory();
  const { defaultFilters, filters, setFilters } = useUrlQueryFilters<Filters>({
    memberClientId: "all"
  });

  const requestFilters = useMemo(
    () => ({
      "invoiceData.memberClientId":
        filters.memberClientId === "all" ? undefined : filters.memberClientId,
      status: {
        in: [
          FrequencyAndScheduleStatus.Active,
          FrequencyAndScheduleStatus.Draft
        ]
      }
    }),
    [filters.memberClientId]
  );

  const queryRows = useQueryInvoiceTemplateRows({
    filter: requestFilters
  });
  const querySummary = useQueryInvoiceTemplateRowsSummary({
    filter: requestFilters
  });

  return (
    <WSPage>
      <PageToolbar
        breadcrumb="Scheduled"
        title="Scheduled invoices"
        buttons={[
          {
            label: "Create invoice",
            onClick: () => history.push(INVOICING_PATH + "/create")
          }
        ]}
        mb="2XL"
      />

      <Toolbar
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        count={querySummary.data?.listSize}
        mb="L"
      />

      {queryRows.isLoading && <WSLoader.Spinner size="S" />}

      {queryRows.data && queryRows.data.length === 0 && (
        <WSText.ParagraphSm color="gray500">
          No scheduled invoices found
        </WSText.ParagraphSm>
      )}

      <WSInfiniteScroll
        onLoad={() => {
          queryRows.fetchMore();
        }}
        loadMore={queryRows.data && queryRows.data.length > 0}
        endOfList={!queryRows.canFetchMore}
        loading={!!queryRows.isFetchingMore}
      >
        <TableInvoiceTemplates
          invoiceTemplates={queryRows.data || []}
          loading={queryRows.isLoading}
        />
      </WSInfiniteScroll>
    </WSPage>
  );
};
