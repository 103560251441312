import flatten from "lodash/flatten";
import { WSQueries } from "../../../../query/WSQuery";
import { useEngagementListQuery } from "../../../../query/engagements/queries/useEngagementListQuery";
import {
  WSEmptyState,
  WSFilters,
  WSInfiniteScroll
} from "@wingspanhq/fe-component-library";
import { EngagementType } from "@wingspanhq/payments/dist/interfaces";
import { useUrlQueryFilters } from "../../../../utils/router";
import { useHistory } from "react-router-dom";
import { PAYEES_ROOT_PATH } from "../../utils";
import { EngagementRowsTable } from "../../components/EngagementRowsTable";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";

export const RouteEngagements: React.FC = () => {
  useBrowserPageTitle("Engagements");
  const history = useHistory();
  const queryEngagementList = useEngagementListQuery();
  return (
    <WSQueries queries={{ queryEngagementList }}>
      {({ queryEngagementListData }) => {
        const list = flatten(queryEngagementListData.map(r => r.data) || []);
        const count = queryEngagementListData[0]?.summary.listSize;

        return (
          <>
            <WSFilters
              my="L"
              values={{}}
              onFilter={newValues => {}}
              primaryFilters={
                [
                  // {
                  //   title: "Type",
                  //   name: "type",
                  //   type: "checkbox",
                  //   options: [
                  //     {
                  //       label: "Independent contractor (IC)",
                  //       name: EngagementType.ContractorVendor
                  //     },
                  //     {
                  //       label: "Agency of record (AOR)",
                  //       name: EngagementType.AgencyOfRecord
                  //     },
                  //     {
                  //       label: "Employer of record (EOR)",
                  //       name: EngagementType.EmployeeOfRecord
                  //     }
                  //   ]
                  // },
                ]
              }
              info={{
                count
              }}
              quickFilters={[]}
            />
            {list.length === 0 ? (
              <WSEmptyState
                py="3XL"
                hasBorder
                fullWidth
                type="documents"
                title="No engagements yet"
                bgColor="gray"
                description="Once an engagement is added, they will appear here."
                buttons={[
                  {
                    label: "Add engagement",
                    kind: "Secondary",
                    onClick: async () => {
                      history.push(`${PAYEES_ROOT_PATH}/create-engagement`);
                    }
                  }
                ]}
              />
            ) : (
              <WSInfiniteScroll
                mt="M"
                onLoad={() => {
                  queryEngagementList.fetchMore();
                }}
                loadMore={list.length > 0}
                endOfList={!queryEngagementList.canFetchMore}
                loading={!!queryEngagementList.isFetchingMore}
              >
                <EngagementRowsTable engagements={list} />
              </WSInfiniteScroll>
            )}
          </>
        );
      }}
    </WSQueries>
  );
};
