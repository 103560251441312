import { toWSDateString, WSText } from "@wingspanhq/fe-component-library";
import {
  IBulkCollaboratorBatch,
  ICollaboratorGroupResponse
} from "@wingspanhq/payments/dist/interfaces";
import { isNil } from "lodash";
import { RowData, ListValueType } from "../../../../components/LabelValueList";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { ILabelInfoValueOptions } from "../../types";
import { getDisplayImportStatusValue } from "../../utils/getDisplayImportStatusValue";

export function getCollaboratorBatchDetailsItems(
  bulkBatch?: IBulkCollaboratorBatch,
  collaboratorGroup?: ICollaboratorGroupResponse,
  options?: ILabelInfoValueOptions
): RowData[] {
  if (isNil(bulkBatch)) {
    return [];
  }
  return [
    {
      name: "originalSpreadsheet",
      label: "Original spreadsheet",
      value: getUploadedFilename(bulkBatch, { length: 40 }) || "N/A"
    },
    {
      name: "importedToCollaborators",
      label: "Imported to contractors",
      value: getDisplayImportStatusValue(bulkBatch.status)
    },
    {
      name: "createdAt",
      label: "Created",
      value: toWSDateString(bulkBatch.createdAt)
    },
    {
      name: "createdBy",
      label: "Creator",
      value: getBatchCreatorName(bulkBatch)
    },
    {
      name: "initialImportStatus",
      label: "Contractor group",
      value: collaboratorGroup?.name || "N/A"
    }
  ].map(row => ({
    id: row.name,
    type: ListValueType.Text,
    name: row.name,
    value: (
      <WSText.ParagraphSm mr="XS" color={options?.valueColor || "gray500"}>
        {row.value}
      </WSText.ParagraphSm>
    ),
    label: (
      <WSText.ParagraphSm mr="XS" color={options?.labelColor || "gray500"}>
        {row.label}
      </WSText.ParagraphSm>
    )
  }));
}
