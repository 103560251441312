import {
  WSActions,
  WSControl,
  WSDivider,
  WSElement,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { FormData } from "../../types";
import { Preview } from "./Preview";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = {
  data: FormData;
  onEdit: () => void;
};

export const Confirmation: React.FC<Props> = ({ data, onEdit }) => {
  const [consent, setConsent] = useState(false);

  const [submit, meta] = useMutationSubmit();

  if (!data.country) {
    return null;
  }

  return (
    <WSElement>
      <WSSectionToolbar title="Confirm account information" mb="S" />
      <WSText.ParagraphSm color="gray500">
        Please review to ensure the information is accurate before submitting
      </WSText.ParagraphSm>
      <WSDivider my="L" />

      <Preview data={data} onEdit={onEdit} />

      <WSDivider my="2XL" />

      <WSText.ParagraphSm color="gray400" mb="XL">
        This information will be used to customize your Wingspan experience.
        Once you continue, you cannot return to this step and the information
        cannot be changed. Please verify all information provided is accurate.
      </WSText.ParagraphSm>

      <WSControl
        mb="XL"
        type="checkbox"
        value={consent}
        onChange={setConsent}
        size="S"
        label="By checking this box, I confirm that all information is accurate. I agree to the processing of my information for identity verification as detailed in the Privacy Policy."
      />

      <WSErrorMessage
        forceShowApiErrors
        contextKey="CIP"
        my="2XL"
        error={meta.error}
      />

      <WSActions
        orientation="horizontal"
        alignment="fill"
        buttons={[
          {
            kind: "Secondary",
            label: "Back",
            onClick: onEdit
          },
          {
            label: "Confirm & Continue",
            loading: meta.isLoading,
            disabled: !consent,
            onClick: () => {
              submit(data);
            }
          }
        ]}
      />
    </WSElement>
  );
};
