import classNames from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import styles from "./WSInfoBox.module.scss";

export type WSInforBoxSize = "S" | "M";

export type WSInfoBoxProps = {
  title?: string;
  size?: WSInforBoxSize;
  transparent?: boolean;
} & WSElementProps;

export const WSInfoBox: React.FC<WSInfoBoxProps> = ({
  title,
  children,
  className,
  size = "M",
  transparent,
  ...elementProps
}) => {
  return (
    <WSElement
      className={classNames(
        styles.infobox,
        styles[`size-${size}`],
        transparent && styles.transparent,
        className
      )}
      {...elementProps}
    >
      {title && <p className={styles.title}>{title}</p>}
      {children}
    </WSElement>
  );
};
