import { queryCache } from "react-query";
import {
  useWSSnackbar,
  WSMarginProps,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { PayerPayeeStatus } from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { buildPayeeDetailsPath } from "../../utils";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { getCountryName } from "../../../../query/users/selectors";
import { usePayeesDownloadCSV } from "../../../../query/payee/mutations/usePayeesDownloadCSV";
import {
  IPayeeRow,
  PayeeRowsQuery,
  PayerPayeeEngagementSearchableStatus
} from "../../../../services/search";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { QUERY_PAYEE_ROWS } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { WSTableRowMenuAction } from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { useResendPayeeInvite } from "../../../../query/payee/mutations/useResendPayeeInvite";
import { UserStatus } from "@wingspanhq/users/dist/lib/interfaces";

export interface PayeeRowsTableProps extends WSMarginProps {
  isArchivedOnly?: boolean;
  filters?: PayeeRowsQuery;
  payees: IPayeeRow[];
  loading?: boolean;
}

export const PayeeRowsTable: React.FC<PayeeRowsTableProps> = ({
  filters,
  payees,
  isArchivedOnly,
  loading,
  ...elementProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const history = useHistory();
  const [downloadCSV, downloadCSVMeta] = usePayeesDownloadCSV();
  const [updatePayee] = useUpdatePayee();
  const [resendInvite, resendInviteMeta] = useResendPayeeInvite();

  const tableData = payees.map(payee => {
    return {
      data: payee,
      id: payee.payeeId
    };
  });

  const handleRestoreContractor = async (payee: IPayeeRow) => {
    await updatePayee(
      {
        payeeId: payee.payeeId,
        payerOwnedData: {
          status: PayerPayeeStatus.Active
        }
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            queryCache.invalidateQueries(QUERY_PAYEE_ROWS);
          }, 1000);

          openSnackbar({
            duration: 5000,
            type: "success",
            message: "Contractor restored successfully"
          });
        },
        onError: () => {
          openSnackbar({
            duration: 5000,
            type: "warning",
            message: `Failed to restore contractor`
          });
        }
      }
    );
  };

  return (
    <WSTable<IPayeeRow>
      loading={loading}
      tableData={tableData}
      onRowClick={({ data }) => {
        history.push(buildPayeeDetailsPath(data.payeeId));
      }}
      headerAction={{
        icon: "download",
        onClick: async () => {
          await downloadCSV({
            fileName: isArchivedOnly ? "Archived contractors" : "Contractors",
            ...filters
          });
        },
        loading: downloadCSVMeta.isLoading
      }}
      columns={[
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1.5fr",
            header: {
              text: "Name"
            }
          },
          renderFunction: ({ data }) => {
            const names = wsName({
              user: data.user!,
              member: data.member,
              payerOwnedData: data.payerOwnedData
            });
            return (
              <WSTableCell
                avatar={{
                  type: "text",
                  text: names.getResolvedName(true) || "--"
                }}
                text={names.getResolvedName(true)}
                secondaryText={names.getResolvedSecondaryName()}
              />
            );
          }
        },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1fr",
            header: {
              text: "Contact"
            },
            hideOnScreens: ["XS"]
          },
          renderFunction: ({ data }) => <WSTableCell text={data.user?.email} />
        },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1fr",
            header: {
              text: "Location"
            },
            hideOnScreens: ["XS"]
          },
          renderFunction: ({ data }) => (
            <WSTableCell
              text={data.member?.profile?.address?.city}
              secondaryText={
                data.member?.profile?.address?.country
                  ? getCountryName(data.member?.profile?.address?.country)
                  : undefined
              }
            />
          )
        },
        // {
        //   config: {
        //     gridTemplateSizeMin: "0",
        //     gridTemplateSizeMax: "1fr",
        //     header: {
        //       text: "Engagements"
        //     }
        //   },
        //   renderFunction: ({ data }) => (
        //     <WSTableCell
        //       text={data.engagements?.[0]?.name}
        //       secondaryText={
        //         data.engagements?.length! > 1
        //           ? `${data.engagements?.length! - 1} more`
        //           : undefined
        //       }
        //     />
        //   )
        // },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1fr",
            header: {
              text: "Eligibility status"
            }
          },
          renderFunction: ({ data }) => {
            if (
              data.searchableStatus ===
              PayerPayeeEngagementSearchableStatus.Inactive
            ) {
              return (
                <WSTableCell
                  pill={{
                    icon: "archive",
                    theme: "neutral",
                    text: "Archived"
                  }}
                />
              );
            } else if (
              data.searchableStatus ===
              PayerPayeeEngagementSearchableStatus.Eligible
            ) {
              return (
                <WSTableCell
                  pill={{
                    icon: true,
                    theme: "success",
                    text: "Complete"
                  }}
                  secondaryText="Eligible for payment"
                />
              );
            } else if (
              data.searchableStatus ===
              PayerPayeeEngagementSearchableStatus.NotEligible
            ) {
              return (
                <WSTableCell
                  pill={{
                    icon: true,
                    theme: "warning",
                    text: "Incomplete"
                  }}
                  secondaryText="Not yet eligible for payment"
                  secondaryTextColor="amber400"
                />
              );
            } else if (
              data.searchableStatus ===
              PayerPayeeEngagementSearchableStatus.NotSignedUp
            ) {
              return (
                <WSTableCell
                  pill={{
                    icon: true,
                    theme: "warning",
                    text: "Incomplete"
                  }}
                  secondaryText="Not yet signed up"
                  secondaryTextColor="amber400"
                />
              );
            } else {
              return <WSTableCell text="--" />;
            }
          }
        }
      ]}
      rowActions={({ data }) =>
        data.user.status === UserStatus.Pending
          ? [
              {
                text: "Resend invite",
                onAsyncClick: async () => {
                  await resendInvite(data.payeeId);
                }
              }
            ]
          : []
      }
      rowMenuActions={({ data }) =>
        isArchivedOnly
          ? ([
              {
                label: "Restore contractor",
                icon: "repeat-circle",
                onAsyncClick: async () => {
                  await handleRestoreContractor(data);
                }
              }
            ] as WSTableRowMenuAction<IPayeeRow>[])
          : data.user.status === UserStatus.Pending
          ? ([
              {
                label: "Resend invite",
                icon: "send",

                onAsyncClick: async () => {
                  await resendInvite(data.payeeId);
                }
              }
            ] as WSTableRowMenuAction<IPayeeRow>[])
          : []
      }
      {...elementProps}
    />
  );
};
