import {
  useModalContext,
  WSButton,
  WSButtons,
  WSIcon,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { useAdvocate, useMemberProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";

export const BOOKKEEPING_NO_ACCOUNTS_CONNECTED =
  "BOOKKEEPING_NO_ACCOUNTS_CONNECTED";

export const NoAccountsConnected: React.FC = () => {
  const userId = useUserId();
  const { closeModal } = useModalContext();
  const qMember = useMemberProfile(userId);
  const qAdvocate = useAdvocate(qMember.data?.advocateId as string, {
    enabled: qMember.isFetched
  });

  return (
    <WSModal name={BOOKKEEPING_NO_ACCOUNTS_CONNECTED} size="XS">
      <WSQueries queries={{ qMember, qAdvocate }}>
        {({ qAdvocate }) => (
          <>
            <WSText.Heading5 mb="M">
              <WSIcon
                block
                name="alert-circle"
                size="M"
                mr="M"
                color="red500"
              />
              No accounts connected
            </WSText.Heading5>
            <WSText mb="M">
              We're looking into what happened. Your member advocate,{" "}
              {qAdvocate.data.firstName}, will reach out soon to help.
            </WSText>
            <WSButtons mt="2XL" format="modal">
              <WSButton.Primary
                data-testid="tryOtherImportMethod"
                onClick={() => {
                  closeModal(BOOKKEEPING_NO_ACCOUNTS_CONNECTED);
                }}
              >
                Try other import method
              </WSButton.Primary>
            </WSButtons>
          </>
        )}
      </WSQueries>
    </WSModal>
  );
};
