import cn from "classnames";
import React, { FunctionComponent } from "react";
import { toCamelCase } from "../../../../utils";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSIcon, WSIconName } from "../WSIcon/WSIcon.component";
import { WSText } from "../WSText/WSText.component";
import { WSCheckbox, WSCheckboxProps } from "./WSCheckbox.component";
import { WSFileInput, WSFileInputProps } from "./WSFileInput.component";
import styles from "./WSInput.module.scss";
import { WSBaseInputProps } from "./WSInput.types";
import { WSInputDateOld, WSInputDatePropsOld } from "./WSInputDate.component";
import { WSRadioInput, WSRadioInputProps } from "./WSRadioInput.component";

export interface WSInputProps extends WSBaseInputProps, WSElementProps {
  icon?: WSIconName;
  error?: string;
  inputClassName?: string;
}

interface WSInputType
  extends React.ForwardRefExoticComponent<
    WSInputProps & React.RefAttributes<HTMLInputElement>
  > {
  Checkbox: React.ForwardRefExoticComponent<
    WSCheckboxProps & React.RefAttributes<HTMLInputElement>
  >;
  File: FunctionComponent<WSFileInputProps>;
  RadioInput: React.ForwardRefExoticComponent<
    WSRadioInputProps & React.RefAttributes<HTMLInputElement>
  >;
  Date: FunctionComponent<WSInputDatePropsOld>;
}

export const WSInput: WSInputType = React.forwardRef<
  HTMLInputElement,
  WSInputProps
>((props, ref) => {
  const {
    label,
    icon,
    error = "",
    className = "",
    inputClassName = "",
    type = "text",
    layoutProps,
    systemProps,
    ...inputProps
  } = detachLayoutProps(props);
  const inputContainerClassNames = cn(styles.inputLabelContainer, className);
  const inputClassNames = cn(styles.input, inputClassName, {
    [styles.error]: error !== ""
  });
  return (
    <WSElement className={inputContainerClassNames} {...layoutProps}>
      {label && (
        <WSText.ParagraphSm className={styles.label}>
          {label}
        </WSText.ParagraphSm>
      )}
      <WSElement className={styles.inputContainer}>
        {icon && <WSIcon block name={icon} className={styles.icon} size="XS" />}
        <input
          className={inputClassNames}
          ref={ref}
          type={type}
          data-testid={toCamelCase(inputProps.name, "field")}
          {...inputProps}
        />
      </WSElement>
      {error && (
        <WSElement className={styles.error}>
          <WSIcon
            block
            name="alert-circle"
            size="XS"
            className={styles.errorIcon}
          />
          <WSText.ParagraphSm className={styles.errorText}>
            {error}
          </WSText.ParagraphSm>
        </WSElement>
      )}
    </WSElement>
  );
}) as any;

WSInput.Checkbox = WSCheckbox;
WSInput.File = WSFileInput;
WSInput.RadioInput = WSRadioInput;
WSInput.Date = WSInputDateOld;
