import {
  IPayeeEngagement,
  IPayerEngagement,
  IPayerPayeePayeeOwnedData,
  IPayerPayeePayerOwnedData,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  ISharedMember,
  ISharedUser
} from "@wingspanhq/users/dist/lib/interfaces";
import { ListRequestQuery } from "../../../utils/serviceHelper";
import { serviceSearch } from "../service";
import { PayerPayeeEngagementSearchableStatus } from "../types";

export interface ISearchablePayerPayeeEngagement {
  payerPayeeEngagementId: IPayerEngagement["payerPayeeEngagementId"];
  status: IPayerEngagement["status"];
  eligibilityRequirements: IPayerEngagement["eligibilityRequirements"];
  engagementName: IPayerEngagement["engagementName"];
  isDefault: IPayerEngagement["isDefault"];
  createdAt: Date;
  collaboratorGroupIds?: IPayeeEngagement["collaboratorGroupIds"];
  payeeOwnedData?: IPayerEngagement["payeeOwnedData"];
}

export interface IPayerRow {
  createdAt: Date;
  updatedAt: Date;
  payerId: string;

  // searchableStatus: PayerPayeeEngagementSearchableStatus;

  payeeOwnedData?: IPayerPayeePayeeOwnedData;
  payerOwnedData?: {
    status?: IPayerPayeePayerOwnedData["status"];
    autoPayStrategy?: IPayerPayeePayerOwnedData["autoPayStrategy"];
  };

  engagements: ISearchablePayerPayeeEngagement[];
  user: ISharedUser;
  member: ISharedMember;
}

export type PayerRowsQueryFilter = {
  searchString?: string;
  searchableStatus?: PayerPayeeEngagementSearchableStatus;
  "payeeOwnedData.status"?:
    | PayerPayeeStatus
    | {
        in?: PayerPayeeStatus[];
      };
};

export type PayerRowsQuerySort = {
  updatedAt?: "asc" | "desc";
};

export type PayerRowsQuery = ListRequestQuery<
  PayerRowsQueryFilter,
  PayerRowsQuerySort
>;

export const getPayerRows = async (
  query?: PayerRowsQuery
): Promise<IPayerRow[]> => {
  const response = await serviceSearch.get("/payer-row", {
    params: query
  });

  return response.data;
};

export type IPayerSearchRowsSummary = {
  listSize: number;
};

export const getPayerRowsSummary = async (
  params?: PayerRowsQuery
): Promise<IPayerSearchRowsSummary> => {
  const { headers } = await serviceSearch.get(`/payer-row`, {
    params,
    headers: {
      "X-WS-Get-List-Summary": "true"
    }
  });

  return {
    listSize: Number(headers["x-ws-list-size"])
  };
};
