import {
  WSAlert,
  WSDivider,
  WSElement,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useMemo, useState } from "react";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import {
  IPayeeResponse,
  PayerPayeeStatus,
  RequirementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { GeneralRequirementsTable } from "./GeneralRequirementsTable";
import { EngagementRequirementsTable } from "./EngagementRequirementsTable";
import uniqBy from "lodash/uniqBy";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";
import sortBy from "lodash/sortBy";
import { IMemberClientRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/memberClient";
import styles from "./index.module.scss";

export type EligibilityPanelProps = {
  engagements: IPayeeEngagementListResponse;
  payee: IPayeeResponse;
};

const TOOLTIP_TEXT = `• General requirements - include account setup and onboarding tasks that must be completed to be eligible for payment. \n
• Engagement requirements - if engagements are utilized, any engagement-specific requirements must also be completed to be eligible for payment on those engagements.`;

export const EligibilityRequirements: React.FC<EligibilityPanelProps> = ({
  engagements,
  payee
}) => {
  const engagementRequirements = useMemo(() => {
    const allRequirements = sortBy<IMemberClientRequirementResponse>(
      engagements
        .filter(e => e.status === PayerPayeeEngagementStatus.Active)
        .map(e => e.eligibilityRequirements)
        .flat(),
      "engagementName"
    );
    const uniqRequirements = uniqBy(
      allRequirements,
      "eligibilityRequirementId"
    );

    return uniqRequirements.map(requirement => {
      return {
        ...requirement,
        engagementId: engagements.find(eng =>
          eng.eligibilityRequirements.includes(requirement)
        )!.payerPayeeEngagementId,
        count: allRequirements.filter(
          r => r.collaboratorGroupId === requirement.collaboratorGroupId
        ).length
      };
    });
  }, [engagements]);

  const generalRequirements = useMemo(() => {
    return payee.requirements || [];
  }, [payee.requirements]);

  const allRequirements = useMemo(() => {
    return [...generalRequirements, ...engagementRequirements];
  }, [generalRequirements, engagementRequirements]);

  const completedLength = useMemo(() => {
    return allRequirements.filter(e => e.status === RequirementStatus.Complete)
      .length;
  }, [allRequirements]);

  const isComplete = useMemo(() => {
    return allRequirements.every(e => e.status === RequirementStatus.Complete);
  }, [allRequirements]);

  const [hideInfo, setHideInfo] = useState<boolean>(false);

  const isInactive = payee.payerOwnedData.status === PayerPayeeStatus.Inactive;

  return (
    <WSPanel p="NONE" colorBackground="gray50" className={styles.panel}>
      <WSElement py="M" px="XL" colorBackground="white">
        <WSSectionToolbar
          title="Eligibility"
          tooltip={{
            title: "Eligibility requirements",
            text: TOOLTIP_TEXT
          }}
          pill={
            payee.payerOwnedData.status === PayerPayeeStatus.Inactive
              ? {
                  icon: "archive",
                  text: "Archived",
                  theme: "neutral"
                }
              : {
                  icon: true,
                  text: `${completedLength} of ${allRequirements.length} completed`,
                  theme: isComplete ? "success" : "warning"
                }
          }
        />
      </WSElement>
      <WSDivider />

      <WSElement colorBackground="gray50" p="XL">
        {isInactive ? null : isComplete ? (
          <WSAlert
            mb="L"
            theme="success"
            icon="check-circle"
            title="Contractor is all set for now"
          >
            All eligibility requirements completed. Any new requirements will
            appear here.
          </WSAlert>
        ) : (
          <WSAlert
            mb="L"
            theme="warning"
            icon="alert"
            title="Eligibility requirements pending"
          >
            The contractor must complete all eligibility requirements to start
            receiving payments.
          </WSAlert>
        )}
        {isInactive && !hideInfo && (
          <WSAlert
            icon="archive"
            onDismiss={() => {
              setHideInfo(prev => !prev);
            }}
            size="M"
            mb="L"
            theme="info"
            title="Archived"
          >
            <WSText.ParagraphSm weight="book" color="gray700">
              This contractor is currently inactive and cannot be added to new
              engagements or invoices until they are restored. <br />
              Their eligibility will be recalculated if they are restored. Past
              requirement files for this contractor can be viewed here.
            </WSText.ParagraphSm>
          </WSAlert>
        )}
        <WSSectionToolbar title="General requirements" mb="S" />
        <GeneralRequirementsTable
          requirements={generalRequirements}
          payee={payee}
        />
        {engagementRequirements.length ? (
          <>
            <WSSectionToolbar title="Engagement requirements" mb="S" mt="L" />
            <EngagementRequirementsTable
              requirements={engagementRequirements}
              noCounts
            />
          </>
        ) : null}
      </WSElement>
    </WSPanel>
  );
};
