import { useModalContext, WSDivider } from "@wingspanhq/fe-component-library";
import { PayoutSplits } from "../PaymentMethods/components/PayoutSplits";
import { WingspanAccounts } from "../PaymentMethods/components/WingspanAccounts";
import {
  UPDATE_DEPOSIT_RATE_MODAL,
  UpdateDepositRateModal
} from "./modals/UpdateDepositRate";

export const Sidebar: React.FC = () => {
  const { closeModal, openModal } = useModalContext();

  return (
    <>
      <UpdateDepositRateModal />
      <PayoutSplits
        mb="L"
        onEditWallet={() => openModal(UPDATE_DEPOSIT_RATE_MODAL)}
      />
      <WSDivider my="3XL" />
      <WingspanAccounts mb="XL" />
      {/*<WSButton.Link rightIcon="chevron-right">Search for ATMs</WSButton.Link>*/}
    </>
  );
};
