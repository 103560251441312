import { WSElement, WSText } from "@wingspanhq/fe-component-library";
import { WingspanProducts } from "@wingspanhq/users/dist/lib/interfaces";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Fullscreen } from "../../components/Fullscreen/Fullscreen";
import { CURRENT_YEAR } from "../../modules/1099NECFiling/constants/currentYear";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import {
  getShouldForceToW9FlowByActivity,
  getShouldForceToW9FlowByTaxForms
} from "../../query/platform/selectors";
import { usePayeeTaxForms } from "../../query/taxForm/queries/usePayeeTaxForms";
import {
  useActivities,
  useMemberProfile,
  useUserProfile
} from "../../query/users/queries";
import { LogoApp } from "../../shared/components/LogoApp";
import { redirectTo1099Onboarding } from "../../shared/utils/redirectTo1099Onboarding";
import { useSetWSStore, useWSStore } from "../../store";
import { ProgressBar } from "../components/ProgressBar/ProgressBar";
import styles from "./OnboardingCustomizing.module.scss";

export const OnboardingCustomizing: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryUser = useUserProfile(userId);
  const queryActivity = useActivities(userId);
  const queryTaxForms = usePayeeTaxForms(CURRENT_YEAR);
  const setStore = useSetWSStore();
  const store = useWSStore();
  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSQueries
      queries={{
        queryMember,
        queryActivity,
        queryUser,
        queryTaxForms,
        queryFeatureFlags
      }}
    >
      {({
        queryMemberData: member,
        queryActivityData,
        queryTaxFormsData,
        queryFeatureFlagsData: featureFlags
      }) => (
        <Fullscreen>
          <WSElement className={styles.wrapper}>
            <WSElement className={styles.container}>
              <LogoApp className={styles.logo} mb="3XL" />
              <ProgressBar
                mb="3XL"
                ms={1000}
                onComplete={() => {
                  if (store.signUpAutopayDetails) {
                    history.push(
                      "/member/autopay/" +
                        (store.signUpAutopayDetails.memberClientId ??
                          store.signUpAutopayDetails?.payeeId)
                    );
                  } else if (store.signUpHash) {
                    const hash = store.signUpHash.slice(1);
                    setStore({ signUpHash: undefined });
                    history.push(hash);
                  } else if (
                    getShouldForceToW9FlowByActivity(
                      store,
                      queryActivityData
                    ) &&
                    getShouldForceToW9FlowByTaxForms(queryTaxFormsData)
                  ) {
                    redirectTo1099Onboarding(history);
                  } else if (
                    store.isEmployerSignUp ||
                    member.profile.productImportance?.[0] ===
                      WingspanProducts.Payments
                  ) {
                    history.push("/member/cip");
                  } else if (
                    member.profile.productImportance?.[0] ===
                    WingspanProducts.Bookkeeping
                  ) {
                    setStore({ productActivationAfterOnboarding: true });
                    history.push("/member/bookkeeping/setup");
                  } else if (
                    member.profile.productImportance?.[0] ===
                    WingspanProducts.Benefits
                  ) {
                    setStore({ productActivationAfterOnboarding: true });
                    history.push("/member/benefits");
                  } else {
                    history.push("/member/dashboard");
                  }
                }}
              />
              <WSText>Personalizing your Wingspan experience</WSText>
            </WSElement>
          </WSElement>
        </Fullscreen>
      )}
    </WSQueries>
  );
};
