import { Calendar, CalendarProps } from "primereact/calendar";
import { FormEvent, Nullable } from "primereact/ts-helpers";
import React from "react";
import { toWSDateString } from "../../../utils";
import { getInputTestIdByName } from "../../../utils/getInputTestIdByName";
import { WSInputBaseProps } from "../../types/forms";
import { detachFieldProps, WSField, WSFieldProps } from "../WSField/WSField";
import {
  WSInputContainer,
  WSInputContainerProps
} from "../WSInputContainer/WSInputContainer";
import { useIsMobile } from "../layout";
import styles from "./WSInputDate.module.scss";
import { fsExcludeCN } from "../../../utils/fsExcludeCN";

export type WSInputDateProps = Omit<
  WSInputBaseProps<any, any>,
  "value" | "onChange"
> &
  Omit<WSInputContainerProps, "size" | "align"> &
  WSFieldProps &
  Omit<
    CalendarProps,
    "pt" | "prefix" | "size" | "color" | "selectionMode" | "value" | "onChange"
  > & {
    value: Nullable<Date>;
    onChange(
      value: Nullable<Date>,
      event: FormEvent<Date> | React.ChangeEvent<HTMLInputElement>
    ): void;
  };

const defaultPlaceholder = "MM/DD/YYYY";

export const WSInputDate = React.forwardRef<HTMLInputElement, WSInputDateProps>(
  (props, ref) => {
    const {
      name,
      status,
      className,
      icon,
      iconRight,
      inputAction,
      disabled,
      value,
      onChange,
      inputClassName,
      placeholder,
      fsExclude,
      ...otherProps
    } = props;

    const isMobile = useIsMobile();

    const { fieldProps, ...inputProps } = detachFieldProps(otherProps);

    return (
      <WSField {...fieldProps} status={status}>
        <WSInputContainer
          status={status}
          icon={icon}
          iconRight={iconRight}
          inputAction={inputAction}
          disabled={disabled}
          className={className}
        >
          {isMobile ? (
            <input
              type="date"
              className={fsExcludeCN(inputClassName, fsExclude)}
              value={value ? toWSDateString(value, "mobileInput") : ""}
              onChange={(event) => {
                const newDate = event.target.valueAsDate;
                if (newDate) {
                  newDate.setHours(0, 0, 0, 0);
                  onChange?.(newDate, event);
                } else {
                  onChange?.(null, event);
                }
              }}
            />
          ) : (
            <Calendar
              inputRef={ref}
              className={fsExcludeCN(inputClassName, fsExclude)}
              placeholder={placeholder || defaultPlaceholder}
              value={value}
              onChange={(e) => onChange?.(e.value, e)}
              pt={{
                input: { root: { className: styles.input } }
              }}
              data-testid={getInputTestIdByName(name)}
              {...(inputProps as any)}
            />
          )}
        </WSInputContainer>
      </WSField>
    );
  }
);

WSInputDate.displayName = "WSInputDate";
