import {
  WSButton,
  WSButtons,
  WSCentered,
  WSContainer,
  WSDivider,
  WSElement,
  WSForm,
  WSGrid,
  WSInputText,
  WSInputTextarea,
  WSLayout,
  WSText,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { PAYEES_ENGAGEMENTS_LIST_PATH } from "../../utils";

import styles from "./styles.module.scss";
import { useCreateEngagement } from "../../../../query/engagements/mutations/useCreateEngagement";
import { useUserId } from "../../../../query/hooks/helpers";
import { EngagementType } from "@wingspanhq/payments/dist/interfaces";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";

export interface CreateEngagementFormValues {
  name: string;
  description?: string;
}

export const RouteCreateEngagement: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const { openSnackbar } = useWSSnackbar();
  const [createEngagement, createEngagementMeta] = useCreateEngagement();
  const onSubmit = async (data: CreateEngagementFormValues) => {
    await createEngagement(
      {
        ...data,
        type: EngagementType.ContractorVendor,
        userId
      },
      {
        onSuccess: () => {
          // FIXME: Redirect to the newly created engagement
          history.push(PAYEES_ENGAGEMENTS_LIST_PATH);
          openSnackbar({
            message: "Engagement created successfully",
            type: "success"
          });
        }
      }
    );
  };
  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">
          Create engagement
        </WSText.ParagraphSm>
      }
      onClose={() => {
        history.replace(PAYEES_ENGAGEMENTS_LIST_PATH);
      }}
    >
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "10", m: "8", l: "6" }}>
          <WSText.Paragraph weight="medium" mb="S">
            Engagement information
          </WSText.Paragraph>

          <WSForm<CreateEngagementFormValues>
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required")
            })}
            onSubmit={onSubmit}
          >
            <WSGrid gutter={{ m: "2XL" }}>
              <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                <WSForm.Field
                  name="name"
                  label="Engagement name"
                  placeholder="Engagement name"
                  component={WSInputText}
                  componentProps={{
                    required: true
                  }}
                />
              </WSGrid.Item>

              <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                <WSElement>
                  <WSText.ParagraphSm weight="book" color="gray500" mb="S">
                    Type
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm weight="book" color="gray700">
                    Independent Contractor (IC)
                  </WSText.ParagraphSm>
                </WSElement>
              </WSGrid.Item>

              <WSGrid.Item span={{ m: "12" }}>
                <WSForm.Field
                  name="description"
                  label="Description"
                  placeholder="Engagement description"
                  component={WSInputTextarea}
                />
              </WSGrid.Item>

              <WSErrorMessage
                mt="M"
                contextKey={ErrorContextKey.CreateEngagement}
                error={createEngagementMeta.error}
              />
            </WSGrid>

            <WSDivider my="XL" />

            <WSButtons mb="XL">
              <WSButton.Tertiary
                className={styles.formBtn}
                size="M"
                onClick={() => {
                  history.replace(PAYEES_ENGAGEMENTS_LIST_PATH);
                }}
              >
                Cancel
              </WSButton.Tertiary>

              <WSButton.Primary
                className={styles.formBtn}
                type="submit"
                size="M"
              >
                Create
              </WSButton.Primary>
            </WSButtons>

            <WSText.ParagraphXs weight="book" color="gray400">
              Once the engagement has been created, you can add requirements,
              contractors, and team members to the engagement.
            </WSText.ParagraphXs>
          </WSForm>
        </WSCentered>
      </WSContainer>
    </WSLayout>
  );
};
