import { NotificationTemplate } from "../index";

export const Collaborators: NotificationTemplate<never> = {
  renderTitle: props => "Add new contractors",
  renderBody: props => {
    return "Add new contractors so you can get started working together.";
  },
  buttonAction: (props, history) => () => {
    return history.push("/member/invoices/contacts/collaborators");
  },
  buttonText: () => "Add new contractors"
};
