import footprint, { FootprintComponentKind } from "@onefootprint/footprint-js";
import { format } from "date-fns";
import { queryCache } from "react-query";
import { useHistory } from "react-router-dom";
import { useWSMutation, WSMutationConfig } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";
import { QUERY_CUSTOMER_ENTITY } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { QUERY_VERIFICATION_MISSING_DATA } from "../../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { QUERY_VERIFICATIONS } from "../../../../query/onboarding/queries/useQueryVerifications";
import {
  QUERY_USERS_ACTIVITIES,
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "../../../../query/users/keys";
import {
  createCustomerEntity,
  getCustomerEntity,
  updateCustomerData
} from "../../../../services/api/onboarding/customer";
import { getVerificationMissingData } from "../../../../services/api/onboarding/missingFields";
import { operations } from "../../../../services/api/onboarding/types";
import {
  getVerifications,
  performVerification
} from "../../../../services/api/onboarding/verifications";
import { usersService } from "../../../../services/users";
import { footprintAppearanceConfig } from "../../../../shared/constants/footprint";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { FormData } from "../../types";

export const useMutationSubmit = (
  config?: WSMutationConfig<FormData, WSServiceError, FormData>
) => {
  const userId = useUserId();
  const history = useHistory();

  return useWSMutation<FormData, WSServiceError, FormData>(
    async data => {
      if (!data.type || !data.country) {
        throw new Error("Invalid form data");
      }

      try {
        // Check if there is customer entity
        await getCustomerEntity();
      } catch (error) {
        // Create it if it doesn't exist

        const customer = await createCustomerEntity({
          country: data.country,
          type: data.type
        });

        queryCache.setQueryData(QUERY_CUSTOMER_ENTITY, customer);
      }

      const member = await usersService.member.update(userId, {
        memberId: userId,
        profile: {
          address: {
            country: data.country
          }
        }
      });

      queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);

      const verificationLevel = data.type === "Business" ? "Tax" : "Banking";

      let missingData;
      try {
        missingData = await getVerificationMissingData(verificationLevel);
      } catch (error) {}

      const shouldSetEmail =
        missingData?.requiredFields?.includes("email") ||
        missingData?.requiredFields?.includes("Representative.email");

      const shouldSetPhoneNumber =
        missingData?.requiredFields?.includes("phoneNumber") ||
        missingData?.requiredFields?.includes("Representative.phoneNumber");

      const userData: { [key: string]: unknown } = {};
      if (shouldSetEmail || shouldSetPhoneNumber) {
        const user = await usersService.user.get(userId);
        if (shouldSetEmail) {
          userData.email = user.email;
        }

        if (shouldSetPhoneNumber) {
          userData.phoneNumber = user.phone.number || undefined;
        }
      }

      // Update customer data
      if (data.type === "Business") {
        await updateCustomerData("Entity", {
          customerData: {
            ...userData,
            numberOfEmployees: data.business.numberOfEmployees || undefined,
            legalBusinessName: data.business.legalBusinessName || undefined,
            doingBusinessAs: data.business.dba || undefined,
            federalTaxClassification:
              data.business.taxClassification || undefined,
            businessTaxId: data.business.taxId || undefined,
            industry: data.business.industry || undefined,
            website: data.business.noWebsite ? "None" : data.business.website,

            addressLine1: data.business.address.addressLine1 || undefined,
            city: data.business.address.city || undefined,
            region: data.business.address.state || undefined,
            postalCode: data.business.address.postalCode || undefined,
            country: data.business.address.country || undefined,

            canadaQuebecEnterpriseNumber:
              data.business.address.country === "CA" &&
              data.business.address.state === "QC"
                ? data.business.canadaQuebecEnterpriseNumber || undefined
                : undefined,
            regionOfFormation: data.business.stateOfIncorporation || undefined
          }
        });

        await updateCustomerData("Representative", {
          customerData: {
            ...userData,
            firstName: data.accountHolder.firstName || undefined,
            lastName: data.accountHolder.lastName || undefined,
            occupation: data.accountHolder.jobTitle || undefined,
            dateOfBirth: data.accountHolder.birthday
              ? format(data.accountHolder.birthday, "yyyy-MM-dd")
              : undefined,
            country: data.accountHolder.address.country || undefined,
            individualTaxId: data.accountHolder.taxId || undefined,
            addressLine1: data.accountHolder.address.addressLine1 || undefined,
            city: data.accountHolder.address.city || undefined,
            region: data.accountHolder.address.state || undefined,
            postalCode: data.accountHolder.address.postalCode || undefined
          },
          ownershipStake: data.accountHolder.ownership.stake ?? 0
        });
      } else {
        await updateCustomerData("Entity", {
          customerData: {
            ...userData,
            firstName: data.accountHolder.firstName || undefined,
            lastName: data.accountHolder.lastName || undefined,
            occupation: data.accountHolder.jobTitle || undefined,
            dateOfBirth: data.accountHolder.birthday
              ? format(data.accountHolder.birthday, "yyyy-MM-dd")
              : undefined,
            country: data.accountHolder.address.country || undefined,
            individualTaxId: data.accountHolder.taxId || undefined,
            addressLine1: data.accountHolder.address.addressLine1 || undefined,
            city: data.accountHolder.address.city || undefined,
            region: data.accountHolder.address.state || undefined,
            postalCode: data.accountHolder.address.postalCode || undefined,
            canadaQuebecEnterpriseNumber:
              data.business.address.country === "CA" &&
              data.business.address.state === "QC"
                ? data.business.canadaQuebecEnterpriseNumber || undefined
                : undefined
          }
        });
      }

      // Perform verification

      let verifications = await getVerifications();

      if (verifications.tax === "None") {
        try {
          await performVerification(verificationLevel);
        } catch (error) {
          await openFootprintFlow(userId, verificationLevel, history);
          return data;
        }

        // Check verificaiton status
        verifications = await getVerifications();
        const status =
          verifications[
            verificationLevel.toLowerCase() as keyof typeof verifications
          ];

        if (status === "UpdateRequired" || status === "Failed") {
          await openFootprintFlow(userId, verificationLevel, history);
          return data;
        }
      }

      await finish(userId, history);

      return data;
    },
    {
      dependencies: [
        QUERY_CUSTOMER_ENTITY,
        QUERY_VERIFICATIONS,
        QUERY_VERIFICATION_MISSING_DATA,
        QUERY_USERS_ACTIVITIES,
        QUERY_USERS_USER_PROFILE,
        QUERY_USERS_MEMBER_PROFILE,
        QUERY_NOTIFICATIONS_NOTIFICATIONS
      ],
      ...config
    }
  );
};

async function finish(userId: string, history: ReturnType<typeof useHistory>) {
  await usersService.activity.update(userId, {
    flows: {
      paymentsSetup: {
        currentStep: 3
      }
    }
  });
  await queryCache.refetchQueries(QUERY_USERS_ACTIVITIES);

  history.push("/member/invoices/set-up");
}

async function openFootprintFlow(
  userId: string,
  verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"],
  history: ReturnType<typeof useHistory>
) {
  const missingData = await getVerificationMissingData(verificationLevel);

  if (missingData.requiredFields && missingData.requiredFields.length > 0) {
    throw new Error(
      "Missing required fields: " +
        missingData.requiredFields.map(field => field).join(", ")
    );
  } else if (missingData.completeOnboardingToken) {
    const component = footprint.init({
      kind: FootprintComponentKind.Verify,
      variant: "modal",
      authToken: missingData.completeOnboardingToken,
      onComplete: () => {
        finish(userId, history);
      },
      options: {
        showLogo: false
      },
      appearance: footprintAppearanceConfig
    });
    component.render();
    return;
  }

  throw new Error("No missing data or token provided");
}
