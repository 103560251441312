import { Route, Switch } from "react-router-dom";
import { Invoicing } from "../modules/Invoicing";
import { useFeatureFlags } from "../query/hooks/useFeatureFlags";
import {
  NEC_1099_PAYMENTS_SLUG,
  NEC_1099_SUMMARY_SLUG
} from "../shared/constants/nec1099";
import { DeletePayableModal } from "./components/DeletePayableModal/DeletePayableModal";
import { InvoiceActions } from "./components/InvoiceActions/InvoiceActions";
import { Payments1099CollaboratorView } from "./components/Payments1099CollaboratorView";
import { PaymentsVerificationModal } from "./components/PaymentsVerificationModal/PaymentsVerificationModal";
import { RejectPayableModal } from "./components/RejectPayableModal/RejectPayableModal";
import { AddDefaultPaymentMethodWarningModal } from "./screens/AddDefaultPaymentMethodWarningModal";
import { Collaborators1099NECReview } from "./screens/Collaborators1099NECReview";
import { InvoicesClients } from "./screens/InvoicesClients";
import { InvoicesPaymentsSetup } from "./screens/InvoicesPaymentsSetup";
import { InvoicesSetupComplete } from "./screens/InvoicesSetupComplete";
import { BulkAdjust1099AmountStep1 } from "./screens/bulkAdjust1099Amount/BulkAdjust1099AmountStep1";
import { BulkAdjust1099AmountStep2 } from "./screens/bulkAdjust1099Amount/BulkAdjust1099AmountStep2";
import { BulkUpdate1099MissingInfoStep1 } from "./screens/bulkAdjust1099MissingInfo/BulkUpdate1099MissingInfoStep1";
import { BulkUpdate1099MissingInfoStep2 } from "./screens/bulkAdjust1099MissingInfo/BulkUpdate1099MissingInfoStep2";
import { BulkUploadCollaboratorsStep1 } from "./screens/bulkUploadCollaborators/BulkUploadCollaboratorsStep1";
import { BulkUploadCollaboratorsStep2 } from "./screens/bulkUploadCollaborators/BulkUploadCollaboratorsStep2";
import { InvoicesCollaboratorsIndex } from "./screens/collaborators/InvoicesCollaboratorsIndex";
import { DeductionsIndex } from "./screens/deductions/DeductionsIndex";
import { AddDeduction } from "./screens/deductions/modals/AddDeduction";
import { DeductionInfo } from "./screens/deductions/modals/DeductionsInfo";
import { DeleteDeduction } from "./screens/deductions/modals/DeleteDeduction";
import { Payables } from "./screens/payables";
import { BulkUploadPayablesInfo } from "./screens/payables/bulkUploadPayables/BulkUploadPayablesInfo";
import { BulkUploadPayablesStatus } from "./screens/payables/bulkUploadPayables/BulkUploadPayablesStatus";

export const Invoices = () => {
  const featureFlagsQuery = useFeatureFlags();

  if (!featureFlagsQuery.data) {
    return null;
  }

  return (
    <>
      <PaymentsVerificationModal />
      <AddDefaultPaymentMethodWarningModal />
      <InvoiceActions />
      <DeletePayableModal />
      <RejectPayableModal />
      <AddDeduction />
      <DeductionInfo />
      <DeleteDeduction />

      <Switch>
        <Route
          path="/member/invoices/set-up"
          component={InvoicesPaymentsSetup}
        />

        <Route
          path="/member/invoices/complete"
          exact
          component={InvoicesSetupComplete}
        />

        <Route
          path="/member/invoices/contacts/clients"
          component={InvoicesClients}
        />

        <Route
          path={`*/:memberClientId/${NEC_1099_SUMMARY_SLUG}/:year/${NEC_1099_PAYMENTS_SLUG}`}
          component={Payments1099CollaboratorView}
        />

        {/* TODO: These routes are out dated and should be removed */}
        <Route
          path="/member/invoices/contacts/collaborators/1099/adjust-compensation/download"
          component={BulkAdjust1099AmountStep1}
        />

        <Route
          path="/member/invoices/contacts/collaborators/1099/adjust-compensation/upload"
          component={BulkAdjust1099AmountStep2}
        />

        <Route
          path="/member/invoices/contacts/collaborators/1099/missing-information/download"
          component={BulkUpdate1099MissingInfoStep1}
        />

        <Route
          path="/member/invoices/contacts/collaborators/1099/missing-information/upload"
          component={BulkUpdate1099MissingInfoStep2}
        />

        <Route
          path="/member/invoices/contacts/collaborators/1099"
          component={Collaborators1099NECReview}
        />
        {/* The above should be deprecated */}

        <Route
          path="/member/invoices/contacts/collaborators/bulk-create/info"
          component={BulkUploadCollaboratorsStep1}
        />
        <Route
          path={
            featureFlagsQuery.data?.bulkImporterCollaborator
              ? "/member/invoices/contacts/collaborators/bulk-create/:bulkBatchId/upload"
              : "/member/invoices/contacts/collaborators/bulk-create/upload"
          }
          component={BulkUploadCollaboratorsStep2}
        />
        <Route
          path="/member/invoices/contacts/collaborators/:collaboratorId/deductions"
          component={DeductionsIndex}
        />

        <Route
          path="/member/invoices/contacts/collaborators"
          component={InvoicesCollaboratorsIndex}
        />

        <Route
          path="/member/invoices/payables/bulk-upload/info"
          component={BulkUploadPayablesInfo}
        />
        <Route
          path="/member/invoices/payables/bulk-upload/:bulkBatchId/status"
          component={BulkUploadPayablesStatus}
        />

        <Route path="/member/invoices/payables" component={Payables} />

        <Route path="/member/invoices" component={Invoicing} />
      </Switch>
    </>
  );
};
