import {
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSCentered,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSInputDateOld,
  WSLayout,
  WSLoader,
  WSModal,
  WSRadioInputGroup,
  WSScreen,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  ICustomField,
  InvoiceStatus,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { Overlay } from "../../../components/Overlay";
import { PreventLeave } from "../../../components/PreventLeave/PreventLeave";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { FormFieldSelectPayeeEngagement } from "../../../modules/Payees/components/SelectPayeeEngagement/FormField";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useSavePayable,
  useSendPayable
} from "../../../query/payments/mutations";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import {
  getCollaboratorCompany,
  getCollaboratorEmail,
  getCollaboratorName,
  getIsPayableCreator,
  getIsPayableDisputed,
  getVisibleCollaborators
} from "../../../query/payments/selectors";
import { useClientQuery } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import { isSameDate } from "../../../utils/dates";
import { getChangedData } from "../../../utils/getChangedData";
import { ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL } from "../../screens/AddDefaultPaymentMethodWarningModal";
import {
  attachmentsToFormData,
  convertToLineItemFormData
} from "../../utils/invoicesFormUtils";
import { AddCollaboratorModal } from "../AddCollaboratorModal/AddCollaboratorModal";
import { DeductionsBanner } from "../Deductions/DeductionsBanner";
import {
  CONFIRM_COLLABORATOR_MODAL,
  ConfirmCollaboratorModal,
  INVITE_COLLABORATOR_MODAL,
  InviteCollaboratorModal
} from "../InvoicesForm/CollaboratorsSection";
import {
  LineItemsSection,
  LineItemsSectionValues
} from "../InvoicesForm/LineItemsSection";
import { calculateTotalAmount } from "../InvoicesForm/LineItemsSection/utils";
import { getValidationSchemaLineItem } from "../InvoicesForm/LineItemsSection/validationSchema";
import { OtherSection, OtherSectionValues } from "../InvoicesForm/OtherSection";
import { NewCollaboratorIntegrations } from "../NewCollaboratorIntegrations/NewCollaboratorIntegrations";
import { QuickbooksWrapper } from "../QuickbooksWrapper/QuickbooksWrapper";
import { CollaboratorField } from "./CollaboratorField";
import { ExistingPayableBanner } from "./ExistingPaybleBanner";

const SAVE_CHANGES_MODAL = "saveChanges";
const RESOLVE_DISPUTE_MODAL = "RESOLVE_DISPUTE_MODAL";

export type SendPayableInvoiceAction = "open" | "approve" | "pay";

export type PayableFormValues = {
  payee: {
    payeeId: string;
    payerPayeeEngagementId: string;
  } | null;
  collaboratorEmail: string;
  collaboratorQBOVendor?: string;
  collaboratorQBOExpenseAccount?: string;
  action: SendPayableInvoiceAction;
  labels?: {
    [key: string]: string;
  };
  payDate: Date;
  purchaseOrderNumber?: string;
} & LineItemsSectionValues &
  OtherSectionValues;

export type PayableFormProps = {
  payable?: IPayableSchema;
  collaborator?: ICollaboratorSchema;
  backPath?: string;
  customFields?: ICustomField[];
  email?: string;
};

export const PayableForm: React.FC<PayableFormProps> = ({
  payable,
  collaborator,
  backPath = "/member/invoices/payables/dashoboard",
  customFields,
  email
}) => {
  const userId = useUserId();
  const clientQuery = useClientQuery(userId);
  const queryFeatureFlags = useFeatureFlags();
  const [isLoading, setIsLoading] = useState(false);
  const { openSnackbar } = useWSSnackbar();

  const collaboratorsQuery = useCollaboratorsQuery(
    {},
    queryFeatureFlags.data?.engagements
      ? {
          enabled: false,
          retry: false,
          initialData: [],
          refetchOnMount: false
        }
      : {}
  );

  const [defaultValues] = useState<PayableFormValues>({
    collaboratorEmail: collaborator ? getCollaboratorEmail(collaborator) : "",
    payee: payable
      ? {
          payeeId: payable.memberId,
          payerPayeeEngagementId: payable.collaboratorId
        }
      : null,
    lineItems: (payable?.lineItems || []).map(lineItem =>
      convertToLineItemFormData(lineItem, customFields)
    ),
    other: {
      projectName: String(payable?.labels?.projectName || ""),
      notes: payable?.invoiceNotes || "",
      attachments: attachmentsToFormData(
        payable?.attachments?.customAttachmentIds
      )
    },
    payDate: payable?.client?.payDate || payable?.dueDate || new Date(),
    action: "open",
    ...(queryFeatureFlags.data?.purchaseOrderNumber
      ? { purchaseOrderNumber: payable?.metadata?.purchaseOrderNumber || "" }
      : {})
  });

  const [savePayable, savePayableMeta] = useSavePayable();
  // Having second instanse of the same mutation to have separate meta for it
  const [savePayable2, savePayable2Meta] = useSavePayable();
  const [sendPayable, sendPayableMeta] = useSendPayable();
  const { openModal, closeModal } = useModalContext();
  const history = useHistory();

  const title = payable ? "Edit payable" : "Create payable";
  const isDraft = payable ? payable.status === InvoiceStatus.Draft : true;
  const isDisputed = payable ? getIsPayableDisputed(payable) : false;

  const canEdit = payable
    ? getIsPayableCreator(payable, userId) ||
      queryFeatureFlags.data?.editAllInvoices
    : true;

  return (
    <>
      <BrowserPageTitle title={title} />

      <WSModal
        name={SAVE_CHANGES_MODAL}
        size="XS"
        title="Do you want so save changes?"
      >
        {(values: PayableFormValues) => (
          <WSButtons forceFullWidth format="modal">
            <WSButton
              onClick={() => {
                if (isDisputed) {
                  closeModal(SAVE_CHANGES_MODAL);
                  openModal(RESOLVE_DISPUTE_MODAL, values);
                } else {
                  savePayable(
                    {
                      data: values,
                      payableId: payable?.payableId
                    },
                    {
                      onSuccess: () => {
                        closeModal(SAVE_CHANGES_MODAL);
                        history.push(backPath);
                      }
                    }
                  );
                }
              }}
              loading={savePayableMeta.isLoading}
            >
              Save changes
            </WSButton>
            <WSButton.Secondary
              onClick={() => {
                closeModal(SAVE_CHANGES_MODAL);
                history.push(backPath);
              }}
            >
              Discard
            </WSButton.Secondary>
          </WSButtons>
        )}
      </WSModal>

      <WSModal
        name={RESOLVE_DISPUTE_MODAL}
        size="S"
        title="Resolve the dispute"
      >
        {(values: PayableFormValues) => (
          <>
            <WSText mb="XL">
              This payable has been disputed by the collaborator, would you like
              to clear the dispute?
            </WSText>
            <WSButtons format="modal">
              <WSButton
                onClick={() => {
                  savePayable(
                    {
                      data: values,
                      payableId: payable?.payableId,
                      resubmit: true
                    },
                    {
                      onSuccess: () => {
                        closeModal(RESOLVE_DISPUTE_MODAL);
                        history.push(backPath);
                      },
                      onError: () => {
                        closeModal(RESOLVE_DISPUTE_MODAL);
                      }
                    }
                  );
                }}
              >
                Yes
              </WSButton>
              <WSButton.Secondary
                onClick={() => {
                  savePayable(
                    {
                      data: values,
                      payableId: payable?.payableId
                    },
                    {
                      onSuccess: () => {
                        closeModal(RESOLVE_DISPUTE_MODAL);
                        history.push(backPath);
                      },
                      onError: () => {
                        closeModal(RESOLVE_DISPUTE_MODAL);
                      }
                    }
                  );
                }}
              >
                No
              </WSButton.Secondary>
              {savePayableMeta.isLoading && <WSLoader.Spinner />}
            </WSButtons>
          </>
        )}
      </WSModal>

      <AddCollaboratorModal />

      <WSFormOld<PayableFormValues>
        defaultValues={defaultValues}
        validationSchema={Yup.object().shape({
          ...(queryFeatureFlags.data?.engagements
            ? {
                payee: Yup.object().shape({
                  payeeId: Yup.string().required("Required"),
                  payerPayeeEngagementId: Yup.string().required("Required")
                })
              }
            : { collaboratorEmail: validatorEmail.required("Required") }),
          lineItems: Yup.array(getValidationSchemaLineItem(customFields))
            .min(1, "At least one work item is required")
            .required("At least one work item is required"),
          payDate: Yup.lazy((value: Date) => {
            if (isSameDate(defaultValues.payDate, value)) {
              return Yup.date();
            } else {
              return Yup.date().isTodayAndInFuture();
            }
          }),
          ...(queryFeatureFlags.data?.purchaseOrderNumber
            ? { purchaseOrderNumber: Yup.string().max(24) }
            : {})
        })}
        onSubmit={async values => {
          setIsLoading(true);

          if (
            values.action === "approve" &&
            !(
              !!clientQuery.data?.profile.defaultPaymentMethod?.accountId ||
              !!clientQuery.data?.profile.defaultPaymentMethod?.paymentMethodId
            )
          ) {
            openModal(ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL, {
              nextUrl: "/member/invoices/payables/create"
            });
            return;
          }
          const handleSend = (dataWithAdditionalValues = values) => {
            if (isDisputed) {
              openModal(RESOLVE_DISPUTE_MODAL, values);
            } else {
              savePayable(
                {
                  data: dataWithAdditionalValues,
                  payableId: payable?.payableId
                },
                {
                  onSuccess: async ({ savedPayable }) => {
                    sendPayable(
                      {
                        payableId: savedPayable.payableId,
                        action: values.action
                      },
                      {
                        onSuccess: () => {
                          openSnackbar({
                            duration: 5000,
                            type: "success",
                            message: "Payable created successfully"
                          });

                          history.push(backPath);
                        }
                      }
                    );
                  }
                }
              );
            }
          };

          if (queryFeatureFlags.data?.engagements) {
            handleSend();
          } else {
            const collaborators = collaboratorsQuery.data || [];

            const matchingCollaborator = collaborators.find(
              memberClient =>
                memberClient.member.user.email === values.collaboratorEmail
            );

            if (matchingCollaborator) {
              let hasFirstInvoice = true;
              try {
                hasFirstInvoice =
                  (
                    await paymentsService.payable.list({
                      filter: {
                        memberClientId: {
                          in: [matchingCollaborator.collaboratorId]
                        }
                      }
                    })
                  ).summary.totalValue > 0;
              } catch (e) {
                console.error(e);
              }

              if (hasFirstInvoice) {
                handleSend();
              } else {
                openModal(CONFIRM_COLLABORATOR_MODAL, {
                  data: {
                    name: getCollaboratorName(matchingCollaborator),
                    company: getCollaboratorCompany(matchingCollaborator),
                    email: getCollaboratorEmail(matchingCollaborator)
                  },
                  onConfirm: () => {
                    closeModal(CONFIRM_COLLABORATOR_MODAL);
                    handleSend();
                  },
                  onCancel: () => {
                    closeModal(CONFIRM_COLLABORATOR_MODAL);
                  }
                });
              }
            } else {
              openModal(INVITE_COLLABORATOR_MODAL, {
                email: values.collaboratorEmail,
                onSubmit: (inviteFormData: any) => {
                  const {
                    email,
                    confirmEmail,
                    qboVendorId,
                    qboExpenseAccountId,
                    ...additionalData
                  } = inviteFormData;
                  Object.keys(additionalData).forEach(key => {
                    additionalData[key] = String(additionalData[key]);
                  });
                  const valuesWithAdditionalData = {
                    ...values,
                    labels: {
                      ...additionalData
                    },
                    ...(qboVendorId !== ""
                      ? {
                          collaboratorQBOVendor: qboVendorId,
                          collaboratorQBOExpenseAccount: qboExpenseAccountId
                        }
                      : {})
                  };
                  closeModal(INVITE_COLLABORATOR_MODAL);
                  handleSend(valuesWithAdditionalData);
                },
                onCancel: () => {
                  closeModal(INVITE_COLLABORATOR_MODAL);
                }
              });
            }
          }

          setIsLoading(false);
        }}
      >
        {formContext => {
          return (
            <WSLayout
              headerLeft={
                <WSButton.Link
                  icon="arrow-left"
                  onClick={() => {
                    const newValues = formContext.getValues();
                    const changedData = getChangedData(
                      defaultValues,
                      newValues,
                      {
                        dismissTimeInDates: true
                      }
                    );

                    if (Object.keys(changedData).length > 0) {
                      openModal(SAVE_CHANGES_MODAL, newValues);
                    } else {
                      history.push(backPath);
                    }
                  }}
                  type="button"
                >
                  <WSScreen.TabletAndDesktop>
                    Save & Exit
                  </WSScreen.TabletAndDesktop>
                </WSButton.Link>
              }
              headerCenter={
                <WSText.Heading5 data-testid="payableFormTitle">
                  {title}
                </WSText.Heading5>
              }
              headerRight={
                <WSText.ParagraphSm color="gray500">1/2</WSText.ParagraphSm>
              }
              line
            >
              <InviteCollaboratorModal />
              <ConfirmCollaboratorModal />

              <WSQueries queries={{ collaboratorsQuery, queryFeatureFlags }}>
                {({
                  collaboratorsQuery,
                  queryFeatureFlags: { data: featureFlags }
                }) => {
                  const collaborators = getVisibleCollaborators(
                    collaboratorsQuery.data
                  );

                  return (
                    <>
                      <WSFormOld.Value name={(undefined as any) as string}>
                        {values => {
                          const changedData = getChangedData(
                            defaultValues,
                            values
                          );
                          const isChanged = !!(
                            Object.keys(changedData).length > 0
                          );

                          return <PreventLeave isEnabled={isChanged} />;
                        }}
                      </WSFormOld.Value>
                      <WSContainer verticalPadding>
                        <WSCentered span={{ xl: "8" }}>
                          <WSElement mb="3XL">
                            {featureFlags.engagements ? (
                              <>
                                <WSText.Heading5 mb="XL">
                                  Contractor (Engagement)
                                </WSText.Heading5>
                                <FormFieldSelectPayeeEngagement
                                  name="payee"
                                  disabled={!!payable}
                                  preselectedEmail={email}
                                />
                              </>
                            ) : (
                              <WSGrid>
                                <WSGrid.Item span={{ m: "7" }}>
                                  <WSText.Heading5 mb="M">
                                    Contractor
                                  </WSText.Heading5>
                                  <WSElement>
                                    {!canEdit && <Overlay white overParent />}

                                    {payable ? (
                                      <WSFormOld.Field
                                        name="collaboratorEmail"
                                        label="Contractor email"
                                        component={WSTextInput}
                                        componentProps={{
                                          disabled: true
                                        }}
                                      />
                                    ) : (
                                      <CollaboratorField
                                        email={email}
                                        name="collaboratorEmail"
                                        label=""
                                        collaborators={collaborators}
                                      />
                                    )}

                                    <WSFormOld.Value name="collaboratorEmail">
                                      {email => {
                                        const selectedCollaborator = collaborators.find(
                                          collaborator =>
                                            collaborator.member.user.email ===
                                            email
                                        );

                                        return selectedCollaborator ? (
                                          <DeductionsBanner
                                            collaborator={selectedCollaborator}
                                          />
                                        ) : null;
                                      }}
                                    </WSFormOld.Value>

                                    <QuickbooksWrapper>
                                      <WSFormOld.Value name="collaboratorEmail">
                                        {email => {
                                          const isExistedCollaborator = collaborators.some(
                                            collaborator =>
                                              collaborator.member.user.email ===
                                              email
                                          );

                                          return isExistedCollaborator ||
                                            !canEdit ? null : (
                                            <NewCollaboratorIntegrations
                                              trigger
                                            />
                                          );
                                        }}
                                      </WSFormOld.Value>
                                    </QuickbooksWrapper>
                                  </WSElement>
                                </WSGrid.Item>
                              </WSGrid>
                            )}
                          </WSElement>
                          {!payable ? (
                            <WSFormOld.Value name="collaboratorEmail">
                              {collaboratorEmail => (
                                <ExistingPayableBanner
                                  collaboratorEmail={collaboratorEmail}
                                />
                              )}
                            </WSFormOld.Value>
                          ) : null}

                          <WSText.Heading5 mb="M">Work summary</WSText.Heading5>
                          <LineItemsSection
                            mb="3XL"
                            isIntegrationQuickbooksAccounts
                            disabled={!canEdit}
                            customFields={customFields}
                          />

                          <WSText.Heading5 mb="M">Due date</WSText.Heading5>
                          <WSGrid>
                            <WSGrid.Item span={{ s: "3" }}>
                              <WSFormOld.Field
                                name="payDate"
                                component={WSInputDateOld}
                                mb="3XL"
                              />
                            </WSGrid.Item>
                          </WSGrid>

                          <WSText.Heading5 mb="M">
                            Additional Info
                          </WSText.Heading5>
                          <OtherSection disabled={!canEdit} mb="3XL" />

                          {isDraft && (
                            <WSText.Heading5 mb="M">
                              Share and pay
                            </WSText.Heading5>
                          )}
                          <WSFormOld.Field
                            hidden={!isDraft}
                            mb="3XL"
                            name="action"
                            component={WSRadioInputGroup}
                            componentProps={{
                              options: [
                                {
                                  value: "open",
                                  label: "Open invoice (keep unpaid)"
                                },
                                {
                                  value: "approve",
                                  label: "Approve payment"
                                },
                                {
                                  value: "pay",
                                  label: "Pay now"
                                }
                              ]
                            }}
                          />

                          <WSErrorMessage
                            mb="XL"
                            contextKey="PayableForm"
                            error={
                              sendPayableMeta.error ||
                              savePayableMeta.error ||
                              savePayable2Meta.error
                            }
                          />

                          <WSFlexBox mb="XL">
                            <WSButton.Secondary
                              mr="M"
                              mb="M"
                              type="button"
                              onClick={() => {
                                const values = formContext.getValues();
                                if (values.payee?.payerPayeeEngagementId) {
                                  if (isDisputed) {
                                    openModal(RESOLVE_DISPUTE_MODAL, values);
                                  } else {
                                    savePayable2(
                                      {
                                        data: values,
                                        payableId: payable?.payableId
                                      },
                                      {
                                        onSuccess: () => {
                                          history.push(backPath);
                                        }
                                      }
                                    );
                                  }
                                } else {
                                  history.push(backPath);
                                }
                              }}
                              name="save"
                              loading={savePayable2Meta.isLoading}
                            >
                              Save & exit
                            </WSButton.Secondary>
                            <WSFormOld.Value name="lineItems">
                              {lineItems => {
                                const amount = calculateTotalAmount(lineItems);

                                return (
                                  <WSButton
                                    mr="M"
                                    type="submit"
                                    loading={
                                      isLoading ||
                                      savePayableMeta.isLoading ||
                                      sendPayableMeta.isLoading
                                    }
                                    name="submit"
                                    disabled={amount <= 0}
                                  >
                                    <WSFormOld.Value name="action">
                                      {action =>
                                        isDraft
                                          ? action === "approve"
                                            ? "Open & approve invoice"
                                            : action === "pay"
                                            ? "Open & pay invoice"
                                            : "Open invoice"
                                          : action === "approve"
                                          ? "Save & approve invoice"
                                          : action === "pay"
                                          ? "Save & pay invoice"
                                          : "Save invoice"
                                      }
                                    </WSFormOld.Value>
                                  </WSButton>
                                );
                              }}
                            </WSFormOld.Value>
                          </WSFlexBox>
                        </WSCentered>
                      </WSContainer>

                      {/* <WSFormOld.Value name={undefined as any}>
              {values => <pre>{JSON.stringify(values, null, 2)}</pre>}
            </WSFormOld.Value>

            <WSFormOld.Context>
              {({ errors }) => <pre>{JSON.stringify(errors, null, 2)}</pre>}
            </WSFormOld.Context> */}
                    </>
                  );
                }}
              </WSQueries>
            </WSLayout>
          );
        }}
      </WSFormOld>
    </>
  );
};
