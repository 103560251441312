import { RecordsChunk, RecordError, FlatfileRecord } from "@flatfile/sdk";
import {
  IBulkCollaboratorItem,
  IBulkCollaboratorItemCreate,
  ICustomField,
  IMemberClientFormW9Info
} from "@wingspanhq/payments/dist/interfaces";
import axios from "axios";
import { getRecordRejectionErrors } from "./getFlatfileRecordRejectionErrors";
import { bulkCollaboratorService } from "../services/bulkCollaborator";
import { prepareCollaboratorLabelsRequest } from "../../../utils/jsonFormSchema";
import isEmpty from "lodash/isEmpty";
import {
  CompanyStructure,
  INewUser
} from "@wingspanhq/users/dist/lib/interfaces";
import { pMap } from "../../../utils/pMap";
import { BULK_COLLABORATOR_ITEM_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { defaultCollaboratorGroupOption } from "../../../Invoices/screens/bulkUploadCollaborators/BulkUploadCollaboratorsStep2";

const canUpdateFormW9Data = (uploadCollaborator: any) => {
  if (
    uploadCollaborator.w9FirstName ||
    uploadCollaborator?.firstName ||
    uploadCollaborator.addressLine1 ||
    uploadCollaborator.ssn ||
    uploadCollaborator.ein
  ) {
    return true;
  }
  return false;
};

export const csvCollaboratorToBulkBatchItem = (
  csvCollaborator: any,
  selectedCollaboratorGroupId?: string,
  additionalData?: ICustomField[],
  organizationAccounts?: INewUser[],
  shouldCreateTaxForms?: boolean
): IBulkCollaboratorItemCreate => {
  let formW9Data: IMemberClientFormW9Info = {
    // NOTE: Regular Collaborator import is still using w9FirstName and w9LastName
    // so we need to check if they are present and use them if they are
    // otherwise use firstName and lastName from the CSV to handle it in recipients import
    // as we don't have w9FirstName and w9LastName there
    firstName:
      (csvCollaborator.w9FirstName as string) ??
      csvCollaborator?.firstName ??
      "",
    lastName:
      (csvCollaborator.w9LastName as string) ?? csvCollaborator?.lastName ?? "",
    dob: csvCollaborator.dob as Date,

    legalBusinessName: csvCollaborator.legalBusinessName as string,
    companyStructure: csvCollaborator.companyStructure as CompanyStructure,
    ...(csvCollaborator.ein && {
      ein: csvCollaborator.ein?.replace(/\D/g, "") as string
    }),
    ...(csvCollaborator.ssn && {
      ssn: csvCollaborator.ssn?.replace(/\D/g, "") as string
    }),

    addressLine1: (csvCollaborator.addressLine1 ?? "") as string,
    addressLine2: (csvCollaborator.addressLine2 ?? "") as string,
    city: (csvCollaborator.city ?? "") as string,
    state: (csvCollaborator.state ?? "") as string,
    postalCode: (csvCollaborator.postalCode ?? "") as string,
    country: (csvCollaborator.country ?? "") as string
  };
  // filter out null pr empty values
  formW9Data = Object.keys(formW9Data).reduce((acc, key) => {
    const value = (formW9Data as any)[key];
    if (value !== null && value !== undefined && value !== "") {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {}) as IMemberClientFormW9Info;

  const labels = prepareCollaboratorLabelsRequest(
    additionalData || [],
    csvCollaborator
  );

  if (csvCollaborator.phoneNumber) {
    labels.phoneNumber = csvCollaborator.phoneNumber;
  }

  if (shouldCreateTaxForms) {
    labels[BULK_COLLABORATOR_ITEM_LABELS_WS_KEYS.shouldCreateTaxForms] = "true";
  }

  let orgSubAccountId;
  if (csvCollaborator.organizationAccount) {
    orgSubAccountId = (organizationAccounts ?? []).find(
      orgAcc =>
        orgAcc.profile.preferredName === csvCollaborator.organizationAccount
    )?.userId;
  }

  return {
    firstLastName: [csvCollaborator?.firstName, csvCollaborator?.lastName]
      .join(" ")
      .trim(),
    email: csvCollaborator.email,
    externalId: csvCollaborator.contractorId || "",
    collaboratorId: csvCollaborator?.collaboratorId,
    ...(orgSubAccountId ? { orgSubAccountId } : {}),
    ...(selectedCollaboratorGroupId &&
    selectedCollaboratorGroupId !== defaultCollaboratorGroupOption.value
      ? { collaboratorGroupId: selectedCollaboratorGroupId }
      : {}),
    ...(canUpdateFormW9Data(csvCollaborator) ? { formW9Data: formW9Data } : {}),
    ...(!isEmpty(labels)
      ? {
          labels
        }
      : {})
  };
};

export async function processFlatfileChunk(
  chunk: RecordsChunk,
  bulkBatchId: string,
  collaboratorGroupId: string,
  additionalData?: ICustomField[],
  organizationAccounts?: INewUser[],
  shouldCreateTaxForms?: boolean
) {
  const validChunkRecords = chunk.records.filter(record => record.valid);
  const batchItemRequestsWithRecordId = validChunkRecords.map(record => {
    const batchItemRequest = csvCollaboratorToBulkBatchItem(
      record.data,
      collaboratorGroupId,
      additionalData,
      organizationAccounts,
      shouldCreateTaxForms
    );
    return {
      ...batchItemRequest,
      recordId: record.recordId
    };
  });

  async function createBatchItem({
    recordId: _,
    ...batchItemRequest
  }: IBulkCollaboratorItemCreate & {
    recordId: FlatfileRecord["recordId"];
  }): Promise<IBulkCollaboratorItem> {
    const response = await bulkCollaboratorService.batchItem.create(
      bulkBatchId,
      batchItemRequest
    );
    return response;
  }

  let rejections: RecordError[] = [];

  try {
    const { errors } = await pMap(
      batchItemRequestsWithRecordId,
      createBatchItem,
      {
        concurrency: 100,
        retryConfig: {
          // number of maximal retry attempts
          retries: 3,
          // wait time between retries in ms
          delay: 100,
          // increase delay with every retry
          backoff: "EXPONENTIAL"
        }
      }
    );
    console.log("Bulk Import Collaborators Chunk Errors", errors);
    for (let i = 0; i < errors.length; i++) {
      const { error } = errors[i];
      // NOTE: Sometimes, POST batch/item API throws 502 as FE creates batchItems in bulk,
      // so for some of the records which failed for this reason have a generic error message  i.e.
      // "Failed to import this item, try to edit it here and re-submit."
      const errorMessage =
        (error as any)?.response?.data?.error ||
        "Failed to import this item, try to edit it here and re-submit.";
      const csvRow = validChunkRecords.find(
        rec => rec.recordId === errors[i].item.recordId
      )?.data;
      const recordErrors = getRecordRejectionErrors(errorMessage, csvRow);
      rejections.push(new RecordError(errors[i].item.recordId, recordErrors));
    }
  } catch (err) {
    console.log("An error occurred:", err);
  }

  return rejections;
}

export async function handleFlatfileCSVChunk(
  chunk: RecordsChunk,
  bulkBatchId: string,
  collaboratorGroupId: string,
  additionalData?: ICustomField[]
) {
  const validChunkRecords = chunk.records.filter(record => record.valid);

  const bulkBatchItemPromises = validChunkRecords.map((record, index) => {
    const batchItemRequest = csvCollaboratorToBulkBatchItem(
      record.data,
      collaboratorGroupId,
      additionalData
    );
    let promise;
    try {
      promise = bulkCollaboratorService.batchItem.create(
        bulkBatchId,
        batchItemRequest
      );
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log(
          `Error while creating bulk payable item: ${err.response?.data?.error}`
        );
      } else if (err instanceof Error) {
        console.log(`Error while creating bulk payable item: ${err.message}`);
      } else {
        console.log(
          `Error while creating bulk payable item: ${JSON.stringify(err)}`
        );
      }
    }
    return {
      // Need this recordId to display an error in Flatfile during processing
      recordId: record.recordId,
      promise
    };
  });

  let rejections: RecordError[] = [];
  const settledPromises = await Promise.allSettled(
    bulkBatchItemPromises.map(
      bulkBatchItemPromise => bulkBatchItemPromise.promise
    )
  );
  for (let i = 0; i < settledPromises.length; i++) {
    const settledPromise = settledPromises[i];
    if (settledPromise.status === "rejected") {
      //NOTE: Sometimes, POST batch/item API throws 502 as FE creates batchItems in bulk,
      // so for some of the records which failed for this reason have a generic
      // error message  i.e. "Failed to import this item, try to edit it here and re-submit.";
      const errorMessage =
        settledPromise?.reason?.response?.data?.error ||
        "Failed to import this item, try to edit it here and re-submit.";
      const csvRow = validChunkRecords.find(
        rec => rec.recordId === bulkBatchItemPromises[i].recordId
      )?.data;
      const recordErrors = getRecordRejectionErrors(errorMessage, csvRow);
      rejections.push(
        new RecordError(bulkBatchItemPromises[i].recordId, recordErrors)
      );
    }
  }

  return rejections;
}
