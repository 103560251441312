import {
  WSButton,
  WSElementProps,
  WSFlexBox,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { IMemberClient } from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo, useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";

import { UrlIdKey } from "../../../constants/routing";
import {
  getClientCompany,
  getClientEmail,
  getClientName
} from "../../../query/payments/selectors";
import { openInNewTab } from "../../../utils/openInNewTab";
import { InvoicesClientDetails } from "../../screens/InvoicesClientDetails";

type Props = WSElementProps & {
  memberClients: IMemberClient[];
  listPath: string;
  pageLimit: number;
};

export const ClientsTable: React.FC<Props> = ({
  memberClients,
  listPath,
  pageLimit,
  ...elementProps
}) => {
  const history = useHistory();
  const location = useLocation();
  const [limit, setLimit] = useState(pageLimit);

  const tableData = useMemo(
    () =>
      memberClients.map(memberClient => ({
        id: memberClient.memberClientId,
        data: {
          memberClient
        }
      })),
    [memberClients]
  );

  const limitedData = tableData.slice(0, limit);

  return (
    <>
      <Route
        path={`${listPath}/:${UrlIdKey.Client}`}
        component={InvoicesClientDetails}
      />

      <WSTable
        tableData={limitedData}
        columns={[
          {
            config: {
              gridTemplateSizeMax: "1fr"
            },
            renderFunction: ({ data: { memberClient } }) => (
              <WSTableCell
                avatar={{ type: "text", text: memberClient?.name || "" }}
                text={
                  getClientName(memberClient) || getClientEmail(memberClient)
                }
                secondaryText={getClientCompany(memberClient)}
              />
            )
          },
          {
            config: {
              hideOnScreens: ["XS"],
              gridTemplateSizeMax: "0.5fr"
            },
            renderFunction: ({ data: { memberClient } }) => (
              <WSTableCell
                text={`${memberClient?.emailTo}${
                  memberClient?.emailCC && memberClient.emailCC.length > 0
                    ? ` + ${memberClient.emailCC.length} more`
                    : ""
                }`}
              />
            )
          }
        ]}
        rowActions={({ id }) => [
          {
            onClick: () => {
              history.push("/member/invoices/create", {
                backPath: location.pathname,
                memberClientId: id
              });
            },
            text: "Create invoice"
          }
        ]}
        onRowClick={item => {
          history.push(`${listPath}/${item.id}`, {
            backPath: listPath
          });
        }}
        onRowControlClick={item => {
          openInNewTab(`${listPath}/${item.id}`);
        }}
        onRowMousewheelClick={item => {
          openInNewTab(`${listPath}/${item.id}`);
        }}
        data-testid="clientsTable"
        {...elementProps}
      />

      {tableData.length > limit && (
        <WSFlexBox.CenterX mt="XL">
          <WSButton.Link
            onClick={() => {
              setLimit(limit + pageLimit);
            }}
          >
            Load more
          </WSButton.Link>
        </WSFlexBox.CenterX>
      )}
    </>
  );
};
