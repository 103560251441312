import {
  WSButton,
  WSEmptyState,
  WSText
} from "@wingspanhq/fe-component-library";

export interface RecentContractorsTableProps {
  contractors: any[];
}

export const RecentContractorsTable: React.FC<RecentContractorsTableProps> = ({
  contractors
}) => {
  return contractors.length > 0 ? (
    <>
      <div>RecentContractorsTable</div>
      <WSButton.Link
        mb="M"
        onClick={() => {
          alert("TODO");
        }}
      >
        View all in contractors page
      </WSButton.Link>
    </>
  ) : (
    <WSEmptyState
      type="people"
      title="No contractors yet"
      description="Once a contractor is added to this engagement, they will appear here"
      buttons={[
        {
          label: "Add contractor",
          kind: "Secondary",
          onClick: () => {
            alert("TODO");
          }
        }
      ]}
      hasBorder={true}
      bgColor="white"
    />
  );
};
