import { WSLoader } from "@wingspanhq/fe-component-library";
import { IActivity } from "@wingspanhq/users/dist/lib";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OverlaySpinner } from "../../components/OverlaySpinner";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useMemberClientsQuery } from "../../query/payments/queries";
import { useActivities, useMemberProfile } from "../../query/users/queries";
import { FlowSetup } from "../../shared/components/FlowSetup";
import { LayoutPaymentsSetup } from "../../shared/components/LayoutPaymentsSetup";
import { useIsOnlyOneBusinessOwner } from "../../shared/hooks/useIsOnlyOneBusinessOwner";
import { selectorIsDomesticMember } from "../../shared/selectors/selectorIsDomesticMember";
import { ACHConnectType, PayoutMethodType } from "../../shared/types/payments";
import { useMutationFinishSetup } from "./mutations/useMutationFinishSetup";
import { useMutationsGetUnitApplication } from "./mutations/useMutationsGetUnitApplication";
import { Route1099Consent } from "./routes/Route1099Consent";
import { RouteBusiness } from "./routes/RouteBusiness";
import { RouteConsent } from "./routes/RouteConsent";
import { RouteConsentInternational } from "./routes/RouteConsentInternational";
import { RouteEligibilityRequirements } from "./routes/RouteEligibilityRequirements";
import { RoutePayouts } from "./routes/RoutePayouts";
import { RoutePayoutsConnectBank } from "./routes/RoutePayoutsConnectBank";
import { RoutePayoutsConnectBankManual } from "./routes/RoutePayoutsConnectBankManual";
import { RoutePayoutsInstant } from "./routes/RoutePayoutsInstant";
import { RoutePayoutsInternational } from "./routes/RoutePayoutsInternational";
import { RoutePersonal } from "./routes/RoutePersonal";
import { selectorHasEligibilityRequirements } from "./selectors/selectorHasEligibilityRequirements";
import { SetupParams } from "./types";

const getInitialPath = (
  activity: IActivity,
  params?: SetupParams,
  isDomesticMember?: boolean
) => {
  if (!activity.flows.paymentsSetup?.currentStep) return undefined;

  switch (activity.flows.paymentsSetup.currentStep) {
    case 1:
      return "/business";

    case 2:
      return "/personal";

    case 3:
      if (params?.skipTaxVerification) {
        return "/payouts";
      } else if (isDomesticMember) {
        return "/consent";
      } else {
        return "/consent-international";
      }

    case 4:
      if (params?.skipTaxVerification) {
        return "/payouts";
      } else {
        return "/electronic-1099-consent";
      }

    case 5:
      if (isDomesticMember) {
        return "/payouts";
      } else {
        return "/payouts-international";
      }
  }
};

type Props = {
  basePath: string;
  onBack: () => Promise<void> | void;
  onFinish: () => Promise<void> | void;
  params?: SetupParams;
};

export const PaymentsSetup: React.FC<Props> = ({
  basePath,
  onBack,
  onFinish,
  params
}) => {
  const history = useHistory();
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryMemberClients = useMemberClientsQuery();
  const queryActivity = useActivities(userId);
  const [finishSetup, finishSetupMeta] = useMutationFinishSetup({
    onSuccess: onFinish
  });

  // Unit application for multiple buisness owners
  const [isOnlyOneBusinessOwner] = useIsOnlyOneBusinessOwner();
  const [unitApplicationLink, setUnitApplicationLink] = useState<
    string | undefined
  >();
  const [getUnitApplication] = useMutationsGetUnitApplication({
    onSuccess: setUnitApplicationLink
  });
  useEffect(() => {
    getUnitApplication();
  }, []);

  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSQueries
      queries={{ queryMember, queryActivity }}
      renderLoader={() => (
        <LayoutPaymentsSetup>
          <WSLoader.Spinner py="2XL" />
        </LayoutPaymentsSetup>
      )}
    >
      {({
        queryMember: { data: member },
        queryActivity: { data: activity }
      }) => {
        const isDomesticMember = selectorIsDomesticMember(member);
        const hasEligibilityRequirements = selectorHasEligibilityRequirements(
          queryMemberClients.data || []
        );

        return (
          <>
            {finishSetupMeta.isLoading && <OverlaySpinner />}

            <FlowSetup
              basePath={basePath}
              params={params}
              initialPath={getInitialPath(activity, params, isDomesticMember)}
              routes={[
                {
                  path: "/business",
                  component: RouteBusiness,
                  onBack: onBack,
                  onNext: () => {
                    history.push(basePath + "/personal");
                  }
                },
                {
                  path: "/personal",
                  component: RoutePersonal,
                  onBack: () => {
                    history.push(basePath + "/business");
                  },
                  onNext: context => {
                    if (
                      isOnlyOneBusinessOwner === false &&
                      unitApplicationLink
                    ) {
                      window.open(unitApplicationLink, "_self");
                    } else if (params?.skipTaxVerification) {
                      if (context?.isUSPerson || isDomesticMember) {
                        history.push(basePath + "/payouts");
                      } else {
                        history.push(basePath + "/payouts-international");
                      }
                    } else if (context?.isUSPerson || isDomesticMember) {
                      history.push(basePath + "/consent");
                    } else {
                      history.push(basePath + "/consent-international");
                    }
                  }
                },
                {
                  path: "/consent",
                  component: RouteConsent,
                  onBack: () => {
                    history.push("/member/cip");
                  },
                  onNext: () => {
                    history.push(basePath + "/electronic-1099-consent");
                  }
                },
                {
                  path: "/consent-international",
                  component: RouteConsentInternational,
                  onBack: () => {
                    history.push("/member/cip");
                  },
                  onNext: () => {
                    if (hasEligibilityRequirements) {
                      history.push(basePath + "/eligibility-requirements");
                    } else {
                      history.push(basePath + "/payouts-international");
                    }
                  }
                },
                {
                  path: "/electronic-1099-consent",
                  component: Route1099Consent,
                  onBack: () => {
                    history.push(basePath + "/consent");
                  },
                  onNext: () => {
                    if (hasEligibilityRequirements) {
                      history.push(basePath + "/eligibility-requirements");
                    } else if (isDomesticMember) {
                      history.push(basePath + "/payouts");
                    } else {
                      history.push(basePath + "/payouts-international");
                    }
                  }
                },
                {
                  path: "/eligibility-requirements",
                  component: RouteEligibilityRequirements,
                  onBack: () => {
                    if (isDomesticMember) {
                      history.push(basePath + "/electronic-1099-consent");
                    } else {
                      history.push(basePath + "/consent-international");
                    }
                  },
                  onNext: () => {
                    if (isDomesticMember) {
                      history.push(basePath + "/payouts");
                    } else {
                      history.push(basePath + "/payouts-international");
                    }
                  }
                },
                {
                  path: "/payouts",
                  component: RoutePayouts,
                  onBack: () => {
                    if (hasEligibilityRequirements) {
                      history.push(basePath + "/eligibility-requirements");
                    } else {
                      history.push(basePath + "/electronic-1099-consent");
                    }
                  },
                  onNext: (context?: {
                    payoutMethodType?: PayoutMethodType;
                  }) => {
                    if (
                      context?.payoutMethodType === PayoutMethodType.Instant
                    ) {
                      if (queryFeatureFlags.data?.paymentCards) {
                        finishSetup();
                      } else {
                        history.push(basePath + "/payouts-instant");
                      }
                    } else if (
                      context?.payoutMethodType === PayoutMethodType.ACH
                    ) {
                      history.push(basePath + "/payouts-connect-bank");
                    } else {
                      finishSetup();
                    }
                  }
                },
                {
                  path: "/payouts-instant",
                  component: RoutePayoutsInstant,
                  onBack: () => {
                    history.push(basePath + "/payouts");
                  },
                  onNext: () => {
                    finishSetup();
                  }
                },
                {
                  path: "/payouts-connect-bank",
                  component: RoutePayoutsConnectBank,
                  onBack: () => {
                    history.push(basePath + "/payouts");
                  },
                  onNext: (context?: { achConnectType?: ACHConnectType }) => {
                    if (context?.achConnectType === ACHConnectType.Manual) {
                      history.push(basePath + "/payouts-connect-bank-manually");
                    } else {
                      finishSetup();
                    }
                  }
                },
                {
                  path: "/payouts-connect-bank-manually",
                  component: RoutePayoutsConnectBankManual,
                  onBack: () => {
                    history.push(basePath + "/payouts-connect-bank");
                  },
                  onNext: () => {
                    finishSetup();
                  }
                },
                {
                  path: "/payouts-international",
                  component: RoutePayoutsInternational,
                  onBack: () => {
                    if (hasEligibilityRequirements) {
                      history.push(basePath + "/eligibility-requirements");
                    } else {
                      if (isDomesticMember) {
                        history.push(basePath + "/electronic-1099-consent");
                      } else {
                        history.push(basePath + "/consent-international");
                      }
                    }
                  },
                  onNext: () => {
                    finishSetup();
                  }
                }
              ]}
            />
          </>
        );
      }}
    </WSQueries>
  );
};
