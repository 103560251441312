import {
  CompanyStructure,
  IMemberProfile,
  IUserProfile
} from "@wingspanhq/users/dist/lib/interfaces";
import { DeepPartial } from "../../../utils";
import { FormData as FormDataBusinessInformation } from "../components/FormBusinessInformation";
import { FormDataAddress } from "../components/FormPartialAddress";
import { FormPartialDataPerson } from "../components/FormPartialPerson";
import { FederalTaxClassification } from "../../CIP/types";

const taxClassificationMap: Record<
  CompanyStructure,
  FederalTaxClassification
> = {
  [CompanyStructure.None]: "SoleProprietorship",
  [CompanyStructure.SoleProprietorship]: "SoleProprietorship",
  [CompanyStructure.LlcSingleMember]: "LlcSingleMember",
  [CompanyStructure.LlcMultiMember]: "LlcPartnership",
  [CompanyStructure.CorporationS]: "CorporationS",
  [CompanyStructure.LLCCorporationS]: "LlcCorporationS",
  [CompanyStructure.LLCCorporationC]: "LlcCorporationC",
  [CompanyStructure.LLCPartnership]: "LlcPartnership",
  [CompanyStructure.CorporationC]: "CorporationC",
  [CompanyStructure.Partnership]: "Partnership"
};

export const getBusinessData = (
  memberProfile?: IMemberProfile
): DeepPartial<FormDataBusinessInformation> => ({
  legalBusinessName: memberProfile?.company?.legalBusinessName,
  taxId: memberProfile?.company?.taxId,
  taxClassification: memberProfile?.company?.structure
    ? taxClassificationMap[memberProfile?.company?.structure]
    : undefined,
  address: getAddressData(memberProfile)
});

export const getAddressData = (
  memberProfile?: IMemberProfile
): DeepPartial<FormDataAddress> => ({
  addressLine1: memberProfile?.address?.addressLine1,
  addressLine2: memberProfile?.address?.addressLine2,
  city: memberProfile?.address?.city,
  state: memberProfile?.address?.state,
  postalCode: memberProfile?.address?.postalCode
});

export const getPersonalData = (
  userProfile?: IUserProfile
): DeepPartial<FormPartialDataPerson> => ({
  firstName: userProfile?.firstName,
  lastName: userProfile?.lastName,
  birthday: userProfile?.dob
});
