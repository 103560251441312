import { WSEmptyState, WSText } from "@wingspanhq/fe-component-library";
import React from "react";

export interface RequirementsTableProps {
  requirements: any[];
}

export const RequirementsTable: React.FC<RequirementsTableProps> = ({
  requirements
}) => {
  return requirements.length > 0 ? (
    <WSText.ParagraphSm>Requirements table</WSText.ParagraphSm>
  ) : (
    <WSEmptyState
      type="documents"
      title="No requirements yet"
      description="Once a requirement is added to this engagement, it will appear here. If requirements are not needed, turn off requirements for this engagement."
      buttons={[
        {
          label: "Add requirement",
          kind: "Secondary",
          onClick: () => {
            alert("TODO");
          }
        },
        {
          label: "Turn off requirements",
          kind: "Tertiary",
          onClick: () => {
            alert("TODO");
          }
        }
      ]}
      hasBorder={true}
      bgColor="gray"
      orientation="horizontal"
    />
  );
};
