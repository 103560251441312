import { WSGrid } from "@wingspanhq/fe-component-library";
import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import { FieldView, FieldViewProps } from "./FieldView";
import { getPayeeNames } from "../selectors/getPayeeNames";
import { useMemo } from "react";

type Props = {
  payee: IPayeeResponse;
};

export const ContractorInformation: React.FC<Props> = ({ payee }) => {
  const fields = useMemo(() => {
    const names = getPayeeNames(payee);
    const address = payee.member?.profile?.address;
    const { payeeName, payeeCompanyName } = payee.payerOwnedData;

    const result: FieldViewProps[] = [
      {
        label: "DBA (doing business as)",
        value: names.companyName
      },
      {
        label: "Business name",
        value: names.legalBusinessName || payeeCompanyName
      },
      {
        label: "Name",
        value: names.fullName || payeeName
      },
      {
        label: "Email",
        value: names.email
      },
      {
        label: "Location",
        value: [address?.city, address?.country].filter(Boolean).join(", ")
      },
      {
        label: "External ID",
        value: names.externalId
      }
    ].filter(i => i.value);

    return result;
  }, [payee]);

  return (
    <WSGrid gutter="2XL">
      {fields.map(({ label, value }) => (
        <WSGrid.Item key={label} span={{ xs: "12", s: "6", l: "4" }}>
          <FieldView label={label} value={value} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
