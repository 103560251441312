import {
  InternalAccountType,
  PendingTransactionSubType,
  PendingWithholdingSubType,
  WithholdingTransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  WSButton,
  WSDivider,
  WSGrid,
  WSInfoBox,
  WSList,
  WSLoader,
  WSPanel,
  WSSelect,
  WSSelectOption,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import { Nullable } from "primereact/ts-helpers";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { MIGRATION_BASE_PATH } from "..";
import { useBankingBalance } from "../../../query/bookkeeping/queries";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../query/payments/queries";
import { useWithholdingBalance } from "../../../query/taxes/queries";
import { useAccounts } from "../../../query/users/queries";
import bookkeepingService from "../../../services/bookkeeping";
import { selectorAccountFullName } from "../../../shared/selectors/selectorAccountFullName";
import { selectorDefaultDestination } from "../../../shared/selectors/selectorDefaultDestination";
import { useWSAnalytics } from "../../../utils/WSAnalytics";
import { selectorAccountWithdrawalReady } from "../../Transfer/components/FormTransfer/selectorAccountWithdrawalReady";
import { Layout } from "../../Onboarding/components/Layout";

export const RouteWithdraw = () => {
  const history = useHistory();

  const [accountId, setAccountId] = useState<Nullable<string>>(null);

  const userId = useUserId();
  const queryBankingBalance = useBankingBalance();
  const queryTaxWithholdingBalance = useWithholdingBalance();
  const queryAccounts = useAccounts();
  const queryPayoutSettings = usePayoutSettings(userId);

  const selectOptions: WSSelectOption[] = useMemo((): WSSelectOption[] => {
    return (queryAccounts.data || [])
      .filter(selectorAccountWithdrawalReady)
      .map(account => ({
        label: selectorAccountFullName(account),
        value: account.accountId
      }));
  }, [queryAccounts.data]);

  useEffect(() => {
    if (!accountId && queryAccounts.data && queryPayoutSettings.data) {
      const defaultPayoutMethodId = selectorDefaultDestination(
        queryPayoutSettings.data
      )?.destinationId;

      if (defaultPayoutMethodId) {
        selectOptions.find(option => option.value === defaultPayoutMethodId) &&
          setAccountId(defaultPayoutMethodId);
      }
    }
  }, [accountId, queryAccounts.data, queryPayoutSettings.data, selectOptions]);

  const walletBalance = queryBankingBalance.data?.balance || 0,
    taxWithholdingsBalance = queryTaxWithholdingBalance.data?.balance || 0;

  const canWithdraw = walletBalance > 0 || taxWithholdingsBalance > 0;

  const analytics = useWSAnalytics();

  const [submit, meta] = useWSMutation(
    async () => {
      if (!accountId) {
        throw new Error("No account specified");
      }

      if (walletBalance > 0) {
        await bookkeepingService.createPendingBankingTransaction({
          amount: walletBalance,
          type: InternalAccountType.Banking,
          subType: PendingTransactionSubType.Withdraw,
          currency: Currency.USD,
          accountId
        });
      }

      if (taxWithholdingsBalance > 0) {
        await bookkeepingService.createPendingWithholding({
          amount: taxWithholdingsBalance,
          type: WithholdingTransactionType.TaxWithholding,
          subType: PendingWithholdingSubType.Withdraw,
          currency: Currency.USD,
          accountId
        });
      }
    },
    {
      onSuccess: () => {
        analytics.track.other("Banking Migration Completed", {
          migrationType: "migrateAccountToLead"
        });
        history.push(MIGRATION_BASE_PATH + "/withdraw-success");
      }
    }
  );

  if (queryBankingBalance.isLoading || queryTaxWithholdingBalance.isLoading) {
    return (
      <Layout
        title="Withdraw funds"
        onClose={() => {
          history.push("/member");
        }}
      >
        <WSLoader.Spinner size="XS" />
      </Layout>
    );
  }

  return (
    <Layout
      title="Withdraw funds"
      onClose={() => {
        history.push("/member");
      }}
    >
      <WSList gap="L">
        <WSText.Paragraph weight="medium">
          Withdraw funds and close accounts
        </WSText.Paragraph>

        <WSText.ParagraphSm color="gray600">
          You've chosen to withdraw funds from your Wingspan Wallet and Tax
          Withholding accounts and close those accounts. Please select a bank
          account to transfer the full balance to:
        </WSText.ParagraphSm>

        <WSPanel>
          <WSText.Paragraph weight="medium">
            Withdraw funds from:
          </WSText.Paragraph>
          <WSDivider mt="XS" mb="2XL" />

          <WSGrid>
            <WSGrid.Item span={{ s: "6" }}>
              <WSText.ParagraphSm color="gray500">
                Wingspan Wallet remaining balance:
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mt="S">
                {toWSMoneyString(walletBalance)}
              </WSText.ParagraphSm>
            </WSGrid.Item>

            <WSGrid.Item span={{ s: "6" }}>
              <WSText.ParagraphSm color="gray500">
                Tax Withholding remaining balance:
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mt="S">
                {toWSMoneyString(taxWithholdingsBalance)}
              </WSText.ParagraphSm>
            </WSGrid.Item>
          </WSGrid>

          {!canWithdraw && (
            <WSInfoBox mt="L">
              There is no balance to transfer. Go back to migrate and keep using
              these features.
            </WSInfoBox>
          )}
        </WSPanel>

        {canWithdraw && (
          <WSPanel>
            <WSText.Paragraph weight="medium">Bank account</WSText.Paragraph>
            <WSDivider mt="XS" mb="2XL" />

            <WSSelect
              label="Select an account to withdraw the remaining balance to:"
              required
              value={accountId}
              onChange={setAccountId}
              options={selectOptions}
              status={queryAccounts.isLoading ? "loading" : undefined}
            />
          </WSPanel>
        )}

        {canWithdraw ? (
          <WSButton
            fullWidth
            onClick={submit}
            loading={meta.isLoading}
            disabled={!accountId}
          >
            Withdraw funds
          </WSButton>
        ) : (
          <WSButton.Secondary
            fullWidth
            onClick={() => {
              history.push("/member/banking-update");
            }}
          >
            Back
          </WSButton.Secondary>
        )}
      </WSList>
    </Layout>
  );
};
