import {
  useModalContext,
  WSButtons,
  WSInputDateOld,
  WSFormOld,
  WSInputNumberOld,
  WSModal,
  WSText,
  WSTextInput,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import { DeductionType } from "@wingspanhq/payments/dist/interfaces/deductions";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useCreateCollaboratorDeduction } from "../../../../query/payments/mutations";

type Props = {
  clientId: string;
  memberId: string;
};

export const ADD_DEDUCTION_MODAL = "ADD_DEDUCTION_MODAL";

export const Inner: React.FC<Props> = (props, context) => {
  const { closeModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();
  const [
    createDeduction,
    createDeductionMeta
  ] = useCreateCollaboratorDeduction();

  return (
    <WSFormOld<{
      name: string;
      amount: number;
      startDate: Date;
    }>
      defaultValues={{
        startDate: new Date()
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        amount: Yup.number().required("Amount is required"),
        startDate: Yup.date().required("StartDate is required")
      })}
      onSubmit={async formData => {
        await createDeduction(
          {
            name: formData.name,
            type: DeductionType.PrePayment,
            amount: formData.amount,
            startDate: formData.startDate,
            clientId: props.clientId,
            currency: Currency.USD,
            memberId: props.memberId
          },
          {
            onSuccess() {
              openSnackbar({
                message: "Deduction added",
                type: "success"
              });
              closeModal(ADD_DEDUCTION_MODAL);
            }
          }
        );
      }}
    >
      <WSFormOld.Field
        mb="XL"
        label="Deduction name"
        name="name"
        component={WSTextInput}
      />
      <WSFormOld.Field
        mb="XL"
        label="Deduction amount"
        name="amount"
        component={WSInputNumberOld}
        componentProps={{
          mode: "currency",
          currency: "USD"
        }}
      />
      <WSFormOld.Field
        mb="XL"
        label="Start date"
        name="startDate"
        component={WSInputDateOld}
      />

      <WSErrorMessage
        mt="XL"
        error={createDeductionMeta.error}
        contextKey="Other"
      />

      <WSText mt="2XL" color="gray500">
        Scheduled deductions are completed when you pay a contractor. If a
        deduction is larger than a payable (or your specified percentage of the
        payable) the remaining balance will be deducted from the next payable.
      </WSText>

      <WSButtons mt="3XL" forceFullWidth>
        <WSFormOld.SubmitButton data-testid="submit">
          Add new deduction
        </WSFormOld.SubmitButton>
      </WSButtons>
    </WSFormOld>
  );
};

export const AddDeduction: React.FC = () => {
  return (
    <WSModal
      name={ADD_DEDUCTION_MODAL}
      size="S"
      title="New pre-payment deduction"
    >
      {({ clientId, memberId }) => (
        <Inner clientId={clientId} memberId={memberId} />
      )}
    </WSModal>
  );
};
