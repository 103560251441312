import {
  toWSDateString,
  useIsMobile,
  useModalContext,
  WSButton,
  WSDivider,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSPage,
  wsSearch,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces/bulkUpload";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { sortKind } from "fast-fuzzy";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import { useBulkCollaboratorBatchesQuery } from "../../../modules/BulkImporter/query/bulkCollaborator/queries";
import { getUploadedFilename } from "../../../modules/BulkImporter/utils/getUploadedFilename";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  ICollaboratorsFilters,
  useCollaboratorsQuery,
  useCollaboratorsV2Query
} from "../../../query/payments/queries";
import {
  filterDuplicateCollaborators,
  getVisibleCollaborators,
  getVisibleCollaboratorsV2
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { paymentsService } from "../../../services/payments";
import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import { ButtonDownloadCsv } from "../../../shared/components/ButtonDownloadCsv";
import { useUrlQueryFilters } from "../../../utils/router";
import { getAllEntries } from "../../../utils/serviceHelper";
import {
  ADD_COLLABORATOR_MODAL,
  AddCollaboratorModal
} from "../../components/AddCollaboratorModal/AddCollaboratorModal";
import { CollaboratorsTable } from "../../components/CollaboratorsTable/CollaboratorsTable";
import { AddCollaboratorToGroupModal } from "./AddCollaboratorToGroupModal";
import { CollaboratorDataItem, mapCollaboratorsToCSV } from "./csvUtils";
import { Actions } from "../../../components/Actions";
import {
  customFieldsService,
  mapCustomFieldListFilters
} from "../../../services/customFields";

export const InvoicesCollaborators: React.FC = () => {
  const history = useHistory();
  const defaultFiltersPopulated: ICollaboratorsFilters = {};
  const { filters, setFilters } = useUrlQueryFilters<ICollaboratorsFilters>(
    defaultFiltersPopulated
  );

  const collaboratorsQuery = useCollaboratorsQuery(filters);
  const collaboratorsV2Query = useCollaboratorsV2Query();
  const featureFlagsQuery = useFeatureFlags();
  const qBulkPayableBatches = useBulkCollaboratorBatchesQuery();
  const { openModal } = useModalContext();
  const [searchText, setSearchText] = useState("");
  const isMobile = useIsMobile();

  return (
    <>
      <AddCollaboratorToGroupModal />
      <AddCollaboratorModal />
      <WSPage
        title="Contractors"
        sideContent={
          <WSFlexBox.CenterY>
            <WSButton
              mr="M"
              name="createNewCollaborator"
              onClick={() => {
                openModal(ADD_COLLABORATOR_MODAL);
              }}
            >
              Add contractor
            </WSButton>
            <Actions
              name="actions"
              items={[
                {
                  label: "Bulk upload contractors",
                  onClick() {
                    history.push(
                      "/member/invoices/contacts/collaborators/bulk-create/info"
                    );
                  }
                }
              ]}
            />
          </WSFlexBox.CenterY>
        }
      >
        <WSQueries
          queries={{
            collaboratorsQuery,
            collaboratorsV2Query,
            featureFlagsQuery,
            qBulkPayableBatches
          }}
        >
          {({
            collaboratorsQuery,
            collaboratorsV2Query,
            featureFlagsQuery: { data: featureFlags },
            qBulkPayableBatches: { data: bulkCollaboratorBatches }
          }) => {
            let collaborators = getVisibleCollaborators(
              collaboratorsQuery.data
            );
            let collaboratorsV2 = getVisibleCollaboratorsV2(
              collaboratorsV2Query.data
            );

            if (featureFlags.deduplicateCollaboratorList) {
              collaborators = filterDuplicateCollaborators(collaborators);
            }

            if (searchText) {
              collaborators = wsSearch(searchText, collaborators, {
                normalizeWhitespace: true,
                ignoreCase: true,
                keySelector: collaborator =>
                  [
                    collaborator.member.user.profile?.firstName,
                    collaborator.member.user.profile?.lastName,
                    collaborator.member.user.email,
                    collaborator.member.profile?.company?.name,
                    collaborator.collaboratorId,
                    collaborator.clientData?.externalId
                  ]
                    .filter(Boolean)
                    .join(" ")
                    .trim(),
                threshold: 0.7,
                sortBy: sortKind.bestMatch
              });

              collaboratorsV2 = wsSearch(searchText, collaboratorsV2, {
                normalizeWhitespace: true,
                ignoreCase: true,
                keySelector: collaborator =>
                  [
                    collaborator.member?.user.profile?.firstName,
                    collaborator.member?.user.profile?.lastName,
                    collaborator.member?.user.email,
                    collaborator.member?.profile?.company?.name,
                    collaborator.memberId,
                    collaborator.clientData?.externalId
                  ]
                    .filter(Boolean)
                    .join(" ")
                    .trim(),
                threshold: 0.7,
                sortBy: sortKind.bestMatch
              });
            }

            const filteredBulkBatches = bulkCollaboratorBatches.filter(
              bulkBatch =>
                [BulkStatus.Complete, BulkStatus.Failed].includes(
                  bulkBatch.status
                )
            );

            return (
              <>
                <WSFlexBox justify="space-between" alignItems="center" mb="M">
                  <WSFiltersOld
                    onSearch={newSearchText => {
                      setSearchText(newSearchText.toLowerCase());
                    }}
                    searchGridItemProps={{
                      span: { s: "6", m: "5", l: "4" }
                    }}
                    searchQuery={searchText}
                    filters={filters}
                    defaultFilters={defaultFiltersPopulated}
                    onFilter={newFilters => {
                      const updatedFilters = {
                        ...newFilters
                      };

                      setFilters(updatedFilters);
                    }}
                  >
                    <WSFormOld.Field
                      name="bulkBatchId"
                      label="Bulk Batch"
                      mb="2XL"
                      component={WSSelectOld}
                      componentProps={{
                        searchable: true,
                        placeholder: "All bulk imports",
                        options: filteredBulkBatches.map(bulkBatch => ({
                          label:
                            getUploadedFilename(bulkBatch) +
                            ` (created ${toWSDateString(bulkBatch.createdAt)})`,
                          value: bulkBatch.bulkCollaboratorBatchId,
                          searchText: [bulkBatch.labels.filename]
                            .filter(Boolean)
                            .join(" ")
                        }))
                      }}
                    />
                  </WSFiltersOld>
                  {collaborators.length > 0 && (
                    <ButtonDownloadCsv
                      getData={async () => {
                        let data: CollaboratorDataItem[] = [];

                        try {
                          const additionalData = await customFieldsService.customField.list(
                            {
                              filter: mapCustomFieldListFilters({
                                resourceType: [
                                  CustomFieldResourceType.Collaborator
                                ]
                              })
                            }
                          );
                          const groups = await getAllEntries(
                            paymentsService.collaboratorGroup.list
                          );

                          data = collaborators.map(c => {
                            return {
                              collaborator: c,
                              collaboratorV2: collaboratorsV2.find(
                                c2 =>
                                  c2.primaryCollaborationId === c.collaboratorId
                              ),
                              additionalData,
                              group: groups.find(g =>
                                c.collaboratorGroupIds?.includes(
                                  g.collaboratorGroupId
                                )
                              )
                            };
                          });
                        } catch (error) {
                          console.error(error);
                        }

                        return mapCollaboratorsToCSV(data);
                      }}
                      fileName="Collaborators"
                    />
                  )}
                </WSFlexBox>
                {collaborators.length > 0 ? (
                  <CollaboratorsTable
                    collaborators={collaborators}
                    listPath="/member/invoices/contacts/collaborators"
                  />
                ) : searchText ? (
                  <WSText>
                    No results found.{" "}
                    <WSButton.Link
                      onClick={() => {
                        setSearchText("");
                        setFilters(defaultFiltersPopulated);
                      }}
                    >
                      Clear search
                    </WSButton.Link>
                  </WSText>
                ) : !isEmpty(filters) ? (
                  <WSText>
                    No results found.{" "}
                    <WSButton.Link
                      onClick={() => {
                        setFilters(defaultFiltersPopulated);
                        setSearchText("");
                      }}
                    >
                      Clear filters
                    </WSButton.Link>
                  </WSText>
                ) : (
                  <WSText>
                    No collaborators yet.{" "}
                    <WSPersistentUpgradeButton
                      feature={WSFrontendFeature.InviteAndPayCollaborator}
                      kind="Link"
                      onClick={() => {
                        openModal(ADD_COLLABORATOR_MODAL);
                      }}
                    >
                      Add a contractor
                    </WSPersistentUpgradeButton>
                  </WSText>
                )}
              </>
            );
          }}
        </WSQueries>
      </WSPage>
    </>
  );
};
