import {
  toWSMoneyString,
  useWSSnackbar,
  WSButton,
  WSDivider,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useRedeemCashbackBalance } from "../../../query/bookkeeping/mutations";
import {
  useCashbackEligibility,
  usePostedCashbackBalance
} from "../../../query/bookkeeping/queries";
import { useUserId } from "../../../query/hooks/helpers";

export const CashbackBalance: React.FC = () => {
  const userId = useUserId();
  const queryCashbackEligibility = useCashbackEligibility(userId);
  const queryPostedCashbackBalance = usePostedCashbackBalance(userId, {
    enabled: queryCashbackEligibility.data?.isUserEligibleForCashback
  });
  const [redeemCashbackBalance] = useRedeemCashbackBalance();
  const cashbackBalance =
    queryPostedCashbackBalance.data?.postedCashbackBalance || 0;
  const { openSnackbar } = useWSSnackbar();

  return (
    <WSPanel>
      <WSText weight="medium" mb="XL">
        Cashback balance
      </WSText>
      <WSText.Heading1
        weight="book"
        color={cashbackBalance > 1 ? "green500" : "gray200"}
      >
        {toWSMoneyString(cashbackBalance)}
      </WSText.Heading1>
      <WSDivider type="expand" my="XL" />
      <WSButton.Tertiary
        size="S"
        data-testid="redeemCashback"
        fullWidth
        disabled={cashbackBalance < 1}
        onAsyncClick={async () => {
          await redeemCashbackBalance(
            {},
            {
              onSuccess() {
                openSnackbar({
                  type: "success",
                  message: "Cashback successfully sent!"
                });
              },
              onError() {
                openSnackbar({
                  type: "warning",
                  message: "Failed to send cashback!"
                });
              }
            }
          );
        }}
      >
        Redeem
      </WSButton.Tertiary>
    </WSPanel>
  );
};
