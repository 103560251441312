import { WSElement, WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Tabs } from "../../../components/Tabs";
import { InvoicesCollaborators } from "./InvoicesCollaborators";
import { InvoicesCollaboratorsGroups } from "./InvoicesCollaboratorsGroups";
import styles from "./InvoicesCollaboratorsIndex.module.scss";

export const InvoicesCollaboratorsIndex: React.FC = () => {
  const tabs = [
    {
      path: "/member/invoices/contacts/collaborators",
      label: "Collaborators"
    },
    {
      path: "/member/invoices/contacts/collaborators/groups",
      label: "Groups"
    }
  ];

  return (
    <WSPage>
      <Tabs tabs={tabs} />
      <WSElement className={styles.wrapper}>
        <Switch>
          <Route
            path="/member/invoices/contacts/collaborators/groups"
            component={InvoicesCollaboratorsGroups}
          />
          <Route
            path="/member/invoices/contacts/collaborators/custom-fields"
            render={() => <Redirect from="*" to="/member/data/custom-fields" />}
          />
          <Route
            path="/member/invoices/contacts/collaborators"
            component={InvoicesCollaborators}
          />
          <Redirect from="*" to="/member/invoices/contacts/collaborators" />
        </Switch>
      </WSElement>
    </WSPage>
  );
};
