import {
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";

type Props = {
  title: string;
  onEdit?: () => void;
  data: ({
    label: string;
    value?: string | null;
    fsExclude?: boolean;
  } | null)[];
};

export const Section: React.FC<Props> = ({ title, onEdit, data }) => {
  return (
    <WSPanel>
      <WSSectionToolbar
        title={title}
        button={
          onEdit
            ? {
                label: "Edit",
                onClick: onEdit
              }
            : undefined
        }
      />
      <WSDivider mt="S" mb="L" />
      <WSFlexBox
        direction="column"
        gap="2XL"
        alignItems="stretch"
        alignContent="stretch"
      >
        <WSGrid>
          {data.filter(Boolean).map(
            (item, colIndex) =>
              item && (
                <WSGrid.Item key={colIndex} span={{ m: "6" }}>
                  <WSText.ParagraphSm color="gray500" mb="S">
                    {item.label}
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm fsExclude={item.fsExclude}>
                    {item.value || "–"}
                  </WSText.ParagraphSm>
                </WSGrid.Item>
              )
          )}
        </WSGrid>
      </WSFlexBox>
    </WSPanel>
  );
};
