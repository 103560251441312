import {
  IAdditionalData,
  ICollaboratorGroupResponse,
  ICollaboratorSchema,
  ICollaboratorV2,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import isEmpty from "lodash/isEmpty";
import {
  getCollaboratorCompany,
  getCollaboratorName
} from "../../../query/payments/selectors";
import { cleanCSVData } from "../../../shared/utils/csv";

export type CollaboratorDataItem = {
  collaborator: ICollaboratorSchema;
  collaboratorV2?: ICollaboratorV2;
  group?: ICollaboratorGroupResponse;
  additionalData?: IAdditionalData[];
};

const mapCollaboratorToItemData = (item: CollaboratorDataItem) => {
  const { collaborator, collaboratorV2, additionalData, group } = item;

  if (!collaborator) {
    return {};
  }

  const { createdBy, ...currentAdditionalData } = collaborator?.labels || {};

  return {
    Name: getCollaboratorName(collaborator),
    "First Name": collaborator.member?.user?.profile?.firstName,
    "Last Name": collaborator.member?.user?.profile?.lastName,
    "Preferred Name": (collaborator.member?.user?.profile as any)
      ?.preferredName,
    "Company Name": getCollaboratorCompany(collaborator),
    "Company DBA": collaborator.member.profile?.company?.name,
    "Legal Business Name":
      collaborator.member.profile?.company?.legalBusinessName,
    "Contractor ID": collaborator.collaboratorId,
    "External Contractor ID": collaborator?.clientData?.externalId,
    Status: collaborator.status,
    Email: collaborator.member?.user?.email,
    "Created At": collaborator.createdAt,
    "Signed Up At": collaboratorV2?.memberEvents?.signedUpAt,
    // "Enablement Date": collaboratorV2?.int
    // invited_at	Invited At
    // enablement_date	Enablement Date
    // first_invoice_paid_at	First Invoice Paid At
    // first_invoice_deposited_at	First Invoice Deposited At
    "Contractor Group ID": collaborator.collaboratorGroupIds?.[0],
    "Contractor Group Name": group?.name,
    "Company Structure": collaborator.member?.profile?.company?.structure,
    "Tax Status": collaborator.taxStatus,
    "Contractor URL": `https://my.wingspan.app/member/invoices/contacts/collaborators/${collaborator.collaboratorId}`,
    // "W-9 Shared"
    ...(!additionalData || isEmpty(additionalData)
      ? {}
      : additionalData.reduce((acc, data) => {
          return {
            ...acc,
            [`Custom Field ${data.name}`]:
              data.type === WSDataTypes.Boolean
                ? currentAdditionalData[data.key] === "true"
                  ? "Yes"
                  : "No"
                : currentAdditionalData[data.key] || "-"
          };
        }, {}))
  };
};

export const mapCollaboratorsToCSV = (data: Array<CollaboratorDataItem>) => {
  let cleanResult = [];

  const dirtyResult = data
    .map(d => {
      try {
        return mapCollaboratorToItemData(d);
      } catch (error) {
        console.error(error);
      }
      return {};
    })
    .filter(d => !isEmpty(d));

  cleanResult = dirtyResult.map(d => cleanCSVData(d, dirtyResult)) as Array<
    any
  >;

  return cleanResult;
};
