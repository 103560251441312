import {
  SelectOptionOld,
  useModalContext,
  WSButton,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSSelectOld
} from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../query/integrations/queries";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { useFormContext } from "react-hook-form";
import { WSQueries } from "../../../query/WSQuery";
import { getSortedAndGroupedQuickbooksUserList } from "../../../modules/Integrations/utils/getSortedAndGroupedQuickbooksUserList";
import { selectQuickbooksUserOptions } from "../../../modules/Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../modules/Integrations/selectors/selectQuickbooksAccountOrItemOptions";

type Props = WSElementProps & {
  trigger?: boolean;
};

export const NewCollaboratorIntegrations: React.FC<Props> = ({ trigger }) => {
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const qIntegrationsQuickbooksExpenseAccounts = useIntegrationsQuickbooksAccountExpenses();
  const { openModal } = useModalContext();

  const [showIntegrations, setShowIntegrations] = useState(
    trigger ? false : true
  );

  const { setValue } = useFormContext();

  useEffect(() => {
    if (!showIntegrations) {
      setValue("qboExpenseAccountId", "");
      setValue("qboVendorId", "");
    }
  }, [showIntegrations, setValue]);

  return (
    <>
      {trigger ? (
        <WSButton.Link
          mt="M"
          type="button"
          name="integrationsAction"
          icon={showIntegrations ? "minus-circle" : "plus-circle"}
          onClick={() => setShowIntegrations(v => !v)}
        >
          {showIntegrations ? "Remove integrations" : "Add integrations"}
        </WSButton.Link>
      ) : null}

      <WSQueries
        queries={{
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksVendors,
          qIntegrationsQuickbooksExpenseAccounts
        }}
        renderErrors={() => null}
      >
        {({
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksVendors,
          qIntegrationsQuickbooksExpenseAccounts
        }) => {
          const integration = qIntegrationsQuickbooks.data;
          const integrationQuickbooksVendors =
            qIntegrationsQuickbooksVendors.data || [];
          const integrationQuickbooksExpenseAccounts =
            qIntegrationsQuickbooksExpenseAccounts.data || [];

          const vendors = getSortedAndGroupedQuickbooksUserList(
            integrationQuickbooksVendors
          );

          const quickbooksVendorOptions: SelectOptionOld[] = selectQuickbooksUserOptions(
            vendors
          );

          const quickbooksAccountsOptions: SelectOptionOld[] = selectQuickbooksAccountOrItemOptions(
            integrationQuickbooksExpenseAccounts
          );

          const defaultAccount = integrationQuickbooksExpenseAccounts.find(
            acct => acct.accountId === integration?.defaults?.expenseAccountId
          );

          return (
            <WSElement
              style={{ display: showIntegrations ? "block" : "none" }}
              mt="XS"
            >
              <WSFormOld.Field
                mb="XL"
                name="qboVendorId"
                component={WSSelectOld}
                componentProps={{
                  placeholder: "Not mapped, create new Vendor",
                  options: quickbooksVendorOptions,
                  searchable: true,
                  cleanable: true,
                  placeholderActions: [
                    {
                      label: "Resync QBO Vendors",
                      icon: "refresh-v",
                      callback() {
                        openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                          entity: QUICKBOOKS_ENTITY.VENDORS
                        });
                      }
                    }
                  ]
                }}
                label="QBO Vendor"
              />
              <WSFormOld.Field
                mb="2XL"
                name="qboExpenseAccountId"
                component={WSSelectOld}
                componentProps={{
                  options: quickbooksAccountsOptions,
                  placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                  cleanable: true,
                  searchable: true,
                  placeholderActions: [
                    {
                      label: "Resync QBO Expenses Accounts",
                      icon: "refresh-v",
                      callback() {
                        openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                          entity: QUICKBOOKS_ENTITY.EXPENSES
                        });
                      }
                    }
                  ]
                }}
                label="Default QBO Expense Account"
              />
              <RefreshModal />
            </WSElement>
          );
        }}
      </WSQueries>
    </>
  );
};
