import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdateCollaborator } from "../../../../query/payments/mutations";
import {
  ICollaboration,
  ICollaboratorSchema,
  ICollaboratorV2
} from "@wingspanhq/payments/dist/interfaces";
import { getCollaboratorName } from "../../../../query/payments/selectors";
import { useHistory } from "react-router-dom";
import { MemberClientStatus } from "@wingspanhq/payments/dist/interfaces/memberClient";

type Props = WSElementProps & {
  collaboratorId: string;
  collaboratorName: string;
  backPath: string;
};

export const DELETE_COLLABORATOR_MODAL = "DELETE_COLLABORATOR_MODAL";

export const DeleteCollaboratorModal: React.FC<Props> = ({
  collaboratorName,
  collaboratorId,
  backPath
}) => {
  const { closeModal } = useModalContext();
  const history = useHistory();

  const [updateCollaborator, updateCollaboratorMeta] = useUpdateCollaborator(
    collaboratorId
  );

  return (
    <WSModal
      name={DELETE_COLLABORATOR_MODAL}
      size="S"
      title="Delete contractor"
    >
      <WSText my="2XL">
        Are you sure you want to delete {collaboratorName}?
      </WSText>

      <WSErrorMessage
        mt="XL"
        error={updateCollaboratorMeta.error}
        contextKey="Other"
      />

      <WSButtons mt="3XL" forceFullWidth>
        <WSButton
          destructive
          onAsyncClick={async () => {
            await updateCollaborator(
              {
                status: MemberClientStatus.Inactive
              },
              {
                onSuccess: () => {
                  history.push(backPath);
                }
              }
            );

            closeModal(DELETE_COLLABORATOR_MODAL);
          }}
        >
          Delete contractor
        </WSButton>
        <WSButton.Tertiary
          onClick={() => {
            closeModal(DELETE_COLLABORATOR_MODAL);
          }}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSModal>
  );
};
