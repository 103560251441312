import React, { useEffect, useMemo, useRef, useState } from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSCalendlyWidget.module.scss";
import { WSIcon, WSPortal } from "../../core";

declare global {
  interface Window {
    Calendly: any;
  }
}

const isCalendlyEvent = (e: any) =>
  e.data.event && e.data.event.indexOf("calendly") === 0;

export interface WSCalendlyWidgetProps
  extends Omit<WSElementProps, "children"> {
  url: string;
  userName: string;
  userEmail: string;
  onEventTypeViewed?(event: any): void;
  onDateAndTimeSelected?(event: any): void;
  onEventScheduled?(event: any): void;
  onProfilePageViewed?(event: any): void;
  onCloseWithoutSchedule?(event: any): void;
  children(props: { onOpen(): void }): React.ReactNode;
}

const scriptId = "wingspan-calendly-script";

export const WSCalendlyWidget: (
  props: WSCalendlyWidgetProps
) => React.ReactElement = ({
  children,
  onEventTypeViewed = Function.prototype,
  onDateAndTimeSelected = Function.prototype,
  onEventScheduled = Function.prototype,
  onCloseWithoutSchedule = Function.prototype,
  onProfilePageViewed = Function.prototype,
  url,
  userEmail,
  userName,
  ...rest
}) => {
  const [isScheduled, setIsScheduled] = useState(false);
  const state = useRef({
    isMounted: false
  });

  useEffect(() => {
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.setAttribute("id", scriptId);

      script.async = true;
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      document.head.appendChild(script);
    }
  }, []);

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onMessage = useMemo(
    () => (event: MessageEvent) => {
      if (isCalendlyEvent(event)) {
        switch (event.data.event) {
          case "calendly.profile_page_viewed": {
            return onProfilePageViewed(event.data);
          }
          case "calendly.event_type_viewed": {
            return onEventTypeViewed(event.data);
          }
          case "calendly.date_and_time_selected": {
            return onDateAndTimeSelected(event.data);
          }
          case "calendly.event_scheduled": {
            setIsScheduled(true);
            return onEventScheduled(event.data);
          }
          default:
            return null;
        }
      }
      return null;
    },
    [
      onDateAndTimeSelected,
      onEventScheduled,
      onEventTypeViewed,
      onProfilePageViewed
    ]
  );

  return (
    <>
      {open ? (
        <WSPortal>
          <WSElement className={styles.wrapper}>
            <WSIcon
              block
              name="arrow-left"
              size="L"
              className={styles.iconBack}
              onClick={() => {
                setOpen(false);
                if (!isScheduled) {
                  onCloseWithoutSchedule();
                }
              }}
            />
            <WSIcon
              block
              name="exit"
              className={styles.iconClose}
              onClick={() => {
                setOpen(false);
                if (!isScheduled) {
                  onCloseWithoutSchedule();
                }
              }}
            />
            <WSElement
              className={styles.container}
              ref={(div) => {
                if (div) {
                  if (!state.current.isMounted) {
                    window.Calendly.initInlineWidget({
                      url,
                      parentElement: div,
                      prefill: {
                        name: userName,
                        email: userEmail
                      },
                      utm: {}
                    });
                    state.current.isMounted = true;
                    console.log("Calendly Mounted");
                    window.addEventListener("message", onMessage);
                  }
                } else {
                  state.current.isMounted = false;
                  console.log("Calendly Unmounted");
                  window.removeEventListener("message", onMessage);
                }
              }}
              {...rest}
            />
          </WSElement>
        </WSPortal>
      ) : null}
      {children({ onOpen })}
    </>
  );
};
