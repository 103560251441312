import React from "react";
import {
  WSButton,
  WSControl,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSForm,
  WSInputPassword,
  WSInputText,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { validatorPassword } from "../../../../shared/validators/validatorPassword";
import { InvitationAuthPrivacyPolicyInfoBlock } from "../InvitationAuthPrivacyPolicyInfoBlock";
import { useSubmitSignUpForm } from "../../mutations/useSubmitSignUpForm";
import { GoogleSignIn } from "../GoogleSignIn";
import { useHistory } from "react-router-dom";
import { useWSAnalytics } from "../../../../utils/WSAnalytics";
import { useSetWSStore } from "../../../../store";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { IInvitationResponse } from "@wingspanhq/users";
import { useNotYourEmailPayer } from "../../modals/useNotYourEmailPayer";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { useNotYourEmailPayee } from "../../modals/useNotYourEmailPayee";
import { INVITATION_AUTH_SUCCESS_PATH } from "../../index";
import { IInvitation } from "@wingspanhq/users/dist/lib/interfaces";

type Props = {
  organizationName: string;
  invitationData: IInvitationResponse;
  invitation: IInvitation;
};

export const InvitationAuthSignUpForm: React.FC<Props> = ({
  organizationName,
  invitationData,
  invitation
}) => {
  const [disabledEmail, setDisabledEmail] = React.useState(
    !!invitationData.invitee.email
  );
  const [submit, submitMeta] = useSubmitSignUpForm(invitationData, invitation);
  const notYourEmailPayerModal = useNotYourEmailPayer();
  const notYourEmailPayeeModal = useNotYourEmailPayee();
  const history = useHistory();
  const analytics = useWSAnalytics();
  const setStore = useSetWSStore();

  return (
    <>
      <WSList gap="XL" p="XL">
        <WSText.Paragraph weight="medium">Sign Up</WSText.Paragraph>

        <WSForm
          onSubmit={async values => {
            await submit(values, {
              onSuccess() {
                setStore({ signUpMethod: "Email" });
                analytics.track.signUp();
                history.replace(INVITATION_AUTH_SUCCESS_PATH);
              }
            });
          }}
          defaultValues={{
            email: invitationData.invitee.email || "",
            password: "",
            remember: true
          }}
          validationSchema={Yup.object().shape({
            email: validatorEmail.required("Email is required"),
            password: validatorPassword.required("Password is required"),
            remember: Yup.boolean()
          })}
        >
          <WSForm.Field
            mb="L"
            name="email"
            label="Email"
            description={`This email was provided by ${organizationName} and will be used to create your account.`}
            component={WSInputText}
            componentProps={{
              disabled: disabledEmail,
              required: true
            }}
          />
          <WSForm.Field
            mb="L"
            name="password"
            label="Create password"
            component={WSInputPassword}
            componentProps={{
              required: true
            }}
          />
          <WSForm.Field
            name="remember"
            component={WSControl}
            componentProps={{
              size: "S",
              type: "checkbox",
              label: "Stay signed in"
            }}
          />

          <InvitationAuthPrivacyPolicyInfoBlock mt="XL" mb="L" />

          {submitMeta.error?.message === "SingleSignOn" &&
          "url" in submitMeta.error &&
          submitMeta.error.url ? (
            <WSText.ParagraphSm color="red400" mb="XL">
              Your domain <b>{submitMeta.error.domain}</b> has SSO enabled.{" "}
              <WSButton.Link
                size="S"
                onClick={() => {
                  if (submitMeta.error && "url" in submitMeta.error) {
                    window.location.href = submitMeta.error.url;
                  }
                }}
                rightIcon="arrow-right"
              >
                Sign in with SSO
              </WSButton.Link>
            </WSText.ParagraphSm>
          ) : submitMeta.error?.message === "Captcha loading error" ? (
            <WSText color="garnet" my="XL">
              Sorry, something went wrong.{" "}
              <WSButton.Link
                type="button"
                size="M"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload and try again
              </WSButton.Link>
            </WSText>
          ) : (
            <WSErrorMessage
              my="XL"
              contextKey="Auth"
              forceShowApiErrors
              error={submitMeta.error}
            />
          )}

          <WSForm.SubmitButton fullWidth size="M">
            Get started with Wingspan
          </WSForm.SubmitButton>
        </WSForm>
      </WSList>
      <WSDivider label="or" />

      <WSElement p="XL">
        <GoogleSignIn
          invitation={invitation}
          email={invitationData.invitee.email}
          title="Sign-in with Google"
          onFailure={() => {}}
          onSuccess={response => {
            setStore({ signUpMethod: "Google" });
            analytics.track.signUp();
            history.replace(INVITATION_AUTH_SUCCESS_PATH);
          }}
        />
      </WSElement>

      <WSDivider />

      <WSFlexBox p="XL" justify="space-between">
        {/*<WSElement>
          <WSText.ParagraphXs inline color="gray500" mr="S">
            Already a member?
          </WSText.ParagraphXs>
          <WSButton.Link size="S" rightIcon="chevron-right">
            Sign in
          </WSButton.Link>
        </WSElement>*/}
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onClick={async () => {
            let result: boolean | null = false;
            if (invitationData.context === PlatformContextType.Payer) {
              result = await notYourEmailPayerModal.open({
                email: invitationData.invitee.email
              });
            } else {
              result = await notYourEmailPayeeModal.open({
                email: invitationData.invitee.email,
                userId: invitationData.invitee.userId,
                userName: invitationData.invitee.name,
                payerName: invitationData.inviter?.name
              });
            }

            if (result) {
              setDisabledEmail(false);
            }
          }}
        >
          Not your email?
        </WSButton.Link>
      </WSFlexBox>
    </>
  );
};
