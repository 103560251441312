import { Tooltip, TooltipProps } from "primereact/tooltip";
import React, { useCallback, useEffect, useState } from "react";
import { WSSidebar } from "../common";
import { WSText } from "../core/WSText/WSText.component";
import { useIsMobile } from "../layout";
import styles from "./WSTooltip.module.scss";

export type WSTooltipProps = {
  dark?: boolean;
  title?: string;
  position?: TooltipProps["position"];
  target?: React.RefObject<HTMLElement>;
};

export const WSTooltip: React.FC<WSTooltipProps> = ({
  dark,
  title,
  children,
  ...props
}) => {
  const isMobile = useIsMobile();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleMobileClick = useCallback(() => {
    setSidebarVisible(true);
  }, []);

  useEffect(() => {
    const node = props.target?.current;

    if (isMobile && node) {
      node.addEventListener("click", handleMobileClick);

      return () => {
        if (isMobile) {
          node.removeEventListener("click", handleMobileClick);
        }
      };
    }
  }, [handleMobileClick, isMobile, props.target]);

  if (isMobile) {
    return (
      <WSSidebar
        visible={sidebarVisible}
        position="bottom"
        size="AUTO"
        onClose={() => {
          setSidebarVisible(false);
        }}
        contentClassName={styles.sidebarContent}
      >
        {title && (
          <WSText.ParagraphSm
            color={dark ? "white" : "gray600"}
            weight="medium"
            mb="XS"
          >
            {title}
          </WSText.ParagraphSm>
        )}
        <WSText.ParagraphSm>{children}</WSText.ParagraphSm>
      </WSSidebar>
    );
  }

  return (
    <Tooltip
      {...props}
      pt={{
        text: {
          className: dark ? "p-tooltip-text-dark" : undefined
        }
      }}
    >
      {title && (
        <WSText.ParagraphSm
          color={dark ? "white" : "gray600"}
          weight="medium"
          mb="XS"
        >
          {title}
        </WSText.ParagraphSm>
      )}
      {children}
    </Tooltip>
  );
};
