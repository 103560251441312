export const currencies = [
  {
    name: "US Dollar",
    alphaCode: "USD",
    numericCode: 840,
    minorUnit: 2
  },
  {
    name: "Euro",
    alphaCode: "EUR",
    numericCode: 978,
    minorUnit: 2
  },
  {
    name: "Australian Dollar",
    alphaCode: "AUD",
    numericCode: 36,
    minorUnit: 2
  },
  {
    name: "Canadian Dollar",
    alphaCode: "CAD",
    numericCode: 124,
    minorUnit: 2
  },
  {
    name: "Bahraini Dinar",
    alphaCode: "BHD",
    numericCode: 48,
    minorUnit: 3
  },
  {
    name: "Bulgarian Lev",
    alphaCode: "BGN",
    numericCode: 975,
    minorUnit: 2
  },
  {
    name: "Czech Koruna",
    alphaCode: "CZK",
    numericCode: 203,
    minorUnit: 2
  },
  {
    name: "Danish Krone",
    alphaCode: "DKK",
    numericCode: 208,
    minorUnit: 2
  },
  {
    name: "Hong Kong Dollar",
    alphaCode: "HKD",
    numericCode: 344,
    minorUnit: 2
  },
  {
    name: "Hungarian Forint",
    alphaCode: "HUF",
    numericCode: 348,
    minorUnit: 2
  },
  {
    name: "Indonesian Rupiah",
    alphaCode: "IDR",
    numericCode: 360,
    minorUnit: 2
  },
  {
    name: "Israeli Shekel",
    alphaCode: "ILS",
    numericCode: 376,
    minorUnit: 2
  },
  {
    name: "Japanese Yen",
    alphaCode: "JPY",
    numericCode: 392,
    minorUnit: 0
  },
  {
    name: "Kenyan Shilling",
    alphaCode: "KES",
    numericCode: 404,
    minorUnit: 2
  },
  {
    name: "Kuwaiti Dinar",
    alphaCode: "KWD",
    numericCode: 414,
    minorUnit: 3
  },
  {
    name: "Malaysian Ringgit",
    alphaCode: "MYR",
    numericCode: 458,
    minorUnit: 2
  },
  {
    name: "Norwegian Krone",
    alphaCode: "NOK",
    numericCode: 578,
    minorUnit: 2
  },
  {
    name: "New Zealand Dollar",
    alphaCode: "NZD",
    numericCode: 554,
    minorUnit: 2
  },
  {
    name: "Omani Rial",
    alphaCode: "OMR",
    numericCode: 512,
    minorUnit: 3
  },
  {
    name: "Philippine Peso",
    alphaCode: "PHP",
    numericCode: 608,
    minorUnit: 2
  },
  {
    name: "Polish Zloty",
    alphaCode: "PLN",
    numericCode: 985,
    minorUnit: 2
  },
  {
    name: "Qatari Rial",
    alphaCode: "QAR",
    numericCode: 634,
    minorUnit: 2
  },
  {
    name: "Romanian Leu",
    alphaCode: "RON",
    numericCode: 946,
    minorUnit: 2
  },
  {
    name: "Saudi Riyal",
    alphaCode: "SAR",
    numericCode: 682,
    minorUnit: 2
  },
  {
    name: "Singapore Dollar",
    alphaCode: "SGD",
    numericCode: 702,
    minorUnit: 2
  },
  {
    name: "Swedish Krona",
    alphaCode: "SEK",
    numericCode: 752,
    minorUnit: 2
  },
  {
    name: "Swiss Franc",
    alphaCode: "CHF",
    numericCode: 756,
    minorUnit: 2
  },
  {
    name: "Thai Baht",
    alphaCode: "THB",
    numericCode: 764,
    minorUnit: 2
  },
  {
    name: "Turkish Lira",
    alphaCode: "TRY",
    numericCode: 949,
    minorUnit: 2
  },
  {
    name: "UAE Dirham",
    alphaCode: "AED",
    numericCode: 784,
    minorUnit: 2
  },
  {
    name: "UK Pound Sterling",
    alphaCode: "GBP",
    numericCode: 826,
    minorUnit: 2
  },
  {
    name: "Yuan Renminbi",
    alphaCode: "CNY",
    numericCode: 156,
    minorUnit: 2
  }
];
