import {
  useWSSnackbar,
  WSElement,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { NotificationType } from "@wingspanhq/notifications/dist/lib/interfaces";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NEC_1099_ONLY_PAYERS } from "../../../../components/SideNav/1099OnlyPayers";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useDismissNotification } from "../../../../query/notifications/mutations/useDismissNotification";
import { useViewNotification } from "../../../../query/notifications/mutations/useViewNotification";
import { useNotifications } from "../../../../query/notifications/queries/useNotifications";
import { useQueryVerificationMissingData } from "../../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { usePayeeTaxForms } from "../../../../query/taxForm/queries/usePayeeTaxForms";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { selectorIsEnterpriseUser } from "../../../../shared/selectors/selectorIsEnterpriseUser";
import { selectorMemberName } from "../../../../shared/selectors/selectorMemberName";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { CURRENT_YEAR } from "../../../1099NECFiling/constants/currentYear";
import { useModalPromptDismiss } from "../../components/ModalPromptDismiss/useModalPromptDismiss";
import {
  NotificationHandleType,
  NotificationProps,
  NotificationTemplates
} from "../../components/Notifications";
import { TodoItem } from "../../components/TodoItem";
import { selectorHasPayerContextUser } from "../../../../shared/selectors/selectorHasPayerContextUser";
import { selectorGroupedVisibleNotifications } from "../../selectors/selectorGroupedVisibleNotifications";
import { useTrackClickNotification } from "../../utils/useTrackClickNotification";
import { GettingStartedSidePanel } from "./GettingStartedSidePanel";
import { useCustomization } from "../../../customization";

export const RouteGettingStarted: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const trackClickNotification = useTrackClickNotification(userId);
  const queryMember = useMemberProfile(userId);
  const modalDismiss = useModalPromptDismiss();
  const queryTasks = useNotifications({
    forceFetchOnMount: true,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });
  const [dismissNotification, metaDismissTask] = useDismissNotification();
  const [readNotification, metaReadTask] = useViewNotification();
  const { openSnackbar } = useWSSnackbar();
  const { branding } = useCustomization();

  const isPayerView =
    queryMember.data &&
    (selectorIsEnterpriseUser(queryMember.data.user) ||
      selectorHasPayerContextUser(queryMember.data.user));
  const userName = queryMember.data && selectorMemberName(queryMember.data);

  const featureFlagsQuery = useFeatureFlags();
  const queryPayerTaxForms = usePayeeTaxForms(CURRENT_YEAR);
  const queryMissingDataTax = useQueryVerificationMissingData("Tax");
  const queryMissingDataBanking = useQueryVerificationMissingData("Banking");
  const { name: platformName } = branding(true);

  useEffect(() => {
    if (
      featureFlagsQuery.data?.nec1099Only &&
      queryPayerTaxForms.data?.length &&
      queryPayerTaxForms.data?.length >= 0 &&
      queryPayerTaxForms.data.every(taxForm =>
        NEC_1099_ONLY_PAYERS.includes(taxForm.clientId)
      )
    ) {
      history.push("/member/tax-documents");
    }
  }, [queryPayerTaxForms.data, featureFlagsQuery.data]);

  return (
    <WSPage title={`Welcome to ${platformName}, ${userName}.`}>
      <WSElement shimmer={queryMember.isLoading}>
        {isPayerView ? (
          <WSText mb="XL" data-testid="gettingStartedTitle">
            Here are some things you need to do to make sure {userName} is up
            and running with {platformName}.
          </WSText>
        ) : (
          <WSText mb="XL" data-testid="gettingStartedTitle">
            Here are things you need to do to ensure you’re paid on time.
          </WSText>
        )}
      </WSElement>
      <WSGrid gutter="2XL">
        <WSGrid.Item span={{ s: "8", m: "9" }}>
          <WSText.Heading4 mb="XL">To-Do</WSText.Heading4>
          <WSQueries queries={{ queryTasks }}>
            {({ queryTasksData }) => {
              const groupedTasks = selectorGroupedVisibleNotifications(
                queryTasksData
              );

              return (
                <>
                  {groupedTasks.length === 0 ? (
                    <EmptyState
                      title={
                        isPayerView
                          ? `You’re all set for now.`
                          : `You’re ready to get paid.`
                      }
                      description="We’ll add tasks here as they arise."
                    />
                  ) : (
                    groupedTasks.map(({ group, ...notification }) => {
                      // TODO: remove ASAP once verification statuses are syncronized correctly
                      if (
                        (notification.handle ===
                          NotificationHandleType.TaxUpdateRequired ||
                          notification.handle ===
                            NotificationHandleType.TaxFailed) &&
                        !queryMissingDataTax.data?.completeOnboardingToken
                      ) {
                        notification.handle = NotificationHandleType.TaxPending;
                      }

                      // TODO: remove ASAP once verification statuses are syncronized correctly
                      if (
                        (notification.handle ===
                          NotificationHandleType.BankingUpdateRequired ||
                          notification.handle ===
                            NotificationHandleType.BankingFailed) &&
                        !queryMissingDataBanking.data?.completeOnboardingToken
                      ) {
                        notification.handle =
                          NotificationHandleType.BankingPending;
                      }

                      const taskPreset = notification.handle
                        ? NotificationTemplates[
                            notification.handle as keyof typeof NotificationTemplates
                          ]
                        : undefined;

                      const Button = taskPreset?.Footer;
                      const taskProps = {
                        ...notification,
                        group
                      } as NotificationProps<never>;
                      const hasGroups = group.length > 1;

                      return (
                        <TodoItem
                          key={notification.channelMessageId}
                          isPending={taskPreset?.isPending}
                          onClick={() => {
                            if (group.length === 1) {
                              trackClickNotification(notification);
                            }
                          }}
                          viewed={group.every(data => data.isViewed)}
                          body={
                            taskPreset
                              ? taskPreset.renderBody(taskProps)
                              : notification.message
                          }
                          buttonAction={
                            taskPreset
                              ? taskPreset.buttonAction?.(taskProps, history)
                              : notification.actionURL
                              ? () => {
                                  const {
                                    hostname,
                                    pathname,
                                    search,
                                    hash
                                  } = new URL(notification.actionURL!);

                                  if (hostname !== window.location.hostname) {
                                    openInNewTab(notification.actionURL!);
                                    return;
                                  }

                                  history.push(pathname + search + hash);
                                }
                              : undefined
                          }
                          buttonActionAsync={
                            taskPreset
                              ? taskPreset.buttonActionAsync?.(
                                  taskProps,
                                  history
                                )
                              : undefined
                          }
                          title={
                            taskPreset
                              ? taskPreset.renderTitle(taskProps)
                              : notification.subject
                          }
                          status={notification.status}
                          footer={Button ? <Button {...taskProps} /> : null}
                          buttonText={
                            taskPreset
                              ? taskPreset.buttonText?.(taskProps)
                              : notification.actionTitle
                          }
                          numberOfTasks={hasGroups ? group.length : undefined}
                          company={
                            notification.context?.client?.logoFileId &&
                            !hasGroups
                              ? {
                                  logoFileId:
                                    notification.context.client.logoFileId,
                                  name: notification.context.client.companyName
                                }
                              : undefined
                          }
                          onRead={
                            group.some(data => !data.isViewed)
                              ? () => {
                                  group.forEach(data => {
                                    if (!data.isViewed) {
                                      readNotification(data.channelMessageId);
                                    }
                                  });
                                }
                              : undefined
                          }
                          onDismiss={
                            notification.type === NotificationType.Dismissable
                              ? async () => {
                                  const result = await modalDismiss.open({});

                                  if (result) {
                                    await dismissNotification(
                                      notification.channelMessageId,
                                      {
                                        onSuccess() {
                                          openSnackbar({
                                            type: "success",
                                            duration: 3000,
                                            message: `${taskPreset?.renderTitle(
                                              taskProps
                                            ) ||
                                              notification.subject} marked as completed`
                                          });
                                        },
                                        onError() {
                                          openSnackbar({
                                            type: "warning",
                                            duration: 3000,
                                            message: `Sorry, something went wrong`
                                          });
                                        }
                                      }
                                    );
                                  }
                                }
                              : undefined
                          }
                        />
                      );
                    })
                  )}
                </>
              );
            }}
          </WSQueries>
        </WSGrid.Item>
        {queryMember.data && !isPayerView ? (
          <WSGrid.Item span={{ s: "4", m: "3" }}>
            <GettingStartedSidePanel />
          </WSGrid.Item>
        ) : null}
      </WSGrid>
    </WSPage>
  );
};
