import {
  useIsDesktop,
  WSBreadcrumbs,
  WSButton,
  WSContainer,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSIconList,
  WSImage,
  WSPanel,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import paymentsImage from "../../assets/images/personal-pay-link/payments.svg";
import previewImage from "../../assets/images/personal-pay-link/preview.png";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSPersistentUpgradeButton } from "../../components/Membership/WSPersistentUpgradeButton";
import { PAYERS_PATH } from "../../modules/Payers/routes/RouteClients";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useQueryVerifications } from "../../query/onboarding/queries/useQueryVerifications";
import { useActivities } from "../../query/users/queries";
import { WSFrontendFeature } from "../../Settings/utils/subscriptionUtils";
import { selectorIsOnboardingComplete } from "../../shared/selectors/selectorIsOnboardingComplete";
import styles from "./PersonalPayLinkOverview.module.scss";

export const PersonalPayLinkOverview: React.FC<{ isMore?: boolean }> = ({
  isMore
}) => {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  useBrowserPageTitle("Personal Pay Link");
  const [show, setShow] = useState<{ [key: string]: boolean }>({});
  const userId = useUserId();
  const activityQuery = useActivities(userId);
  const queryVerifications = useQueryVerifications();
  const featureFlagsQuery = useFeatureFlags();

  return (
    <>
      {isMore ? null : (
        <WSScreen.Mobile>
          <WSImage src={previewImage} placeholder={"Preview"} />
        </WSScreen.Mobile>
      )}
      <WSContainer>
        {!featureFlagsQuery.data?.engagements && (
          <WSBreadcrumbs
            my="XL"
            items={[
              {
                label: "Clients",
                onClick: () => {
                  history.push(PAYERS_PATH);
                }
              },
              {
                label: "Create pay link"
              }
            ]}
          />
        )}
        {isMore ? null : (
          <WSText mt={isDesktop ? "XL" : "M"} color="gray500">
            GET PAID
          </WSText>
        )}
        <WSGrid>
          <WSGrid.Item span={{ s: "6" }}>
            <WSText.Heading1>
              Accept online payments directly to your bank account for free
            </WSText.Heading1>
            <WSIconList.Checks
              mt="M"
              items={[
                "Share your custom link easily",
                "No invoice necessary",
                "In a SMS, email, chat, or website",
                "Reccurring payments welcome"
              ]}
            />

            {isMore ? null : (
              <WSPersistentUpgradeButton
                name="enablePPL"
                feature={WSFrontendFeature.PPL}
                mt="2XL"
                fullWidth={!isDesktop}
                onClick={() => {
                  if (
                    selectorIsOnboardingComplete(
                      activityQuery.data,
                      queryVerifications.data
                    )
                  ) {
                    history.push("/member/personal-pay-link/set-up/create");
                  } else {
                    history.push("/member/personal-pay-link/set-up/payments");
                  }
                }}
              >
                Create your wingspan.me link
              </WSPersistentUpgradeButton>
            )}

            <WSFlexBox
              alignItems={isDesktop ? "flex-start" : "center"}
              direction="column"
              wrap="nowrap"
            >
              <WSText.Heading5 mt="XL" color="gray500">
                <WSIcon name="lock" color="gray500" size="S" mr="XS" />
                Certified Secure Digital Payments
              </WSText.Heading5>
              <WSText.ParagraphSm mt="M" color="gray500">
                ACH, bank transfer, credit card, or debit card.
              </WSText.ParagraphSm>
              <WSImage
                mt="M"
                width={200}
                src={paymentsImage}
                placeholder={"Payments"}
                height={20}
              />
            </WSFlexBox>
          </WSGrid.Item>

          <WSScreen.TabletAndDesktop>
            <WSGrid.Item span={{ s: "6" }}>
              <WSImage mt="XS" src={previewImage} placeholder={"Preview"} />
            </WSGrid.Item>
          </WSScreen.TabletAndDesktop>
        </WSGrid>

        <WSButton.Link
          mb="M"
          onClick={() => setShow(prev => ({ ...prev, noFee: !prev.noFee }))}
        >
          <WSText.Heading5 mt="2XL">
            No fees <WSIcon name={show.noFee ? "caret-up" : "caret-down"} />
          </WSText.Heading5>
        </WSButton.Link>
        {show.noFee ? (
          <WSGrid>
            <WSGrid.Item
              span={{ s: "6", m: "4", l: "3" }}
              className={styles.cardGridItem}
            >
              <WSPanel py="XL" className={styles.card}>
                <WSText color="gray500">FOR YOU</WSText>
                <WSText.Heading5 mt="XL">Free</WSText.Heading5>
                <WSText mt="XS" color="gray500">
                  Every invoice, always <br />
                  <br />
                </WSText>
              </WSPanel>
            </WSGrid.Item>
            <WSGrid.Item
              span={{ s: "6", m: "4", l: "3" }}
              className={styles.cardGridItem}
            >
              <WSPanel py="XL" className={styles.card}>
                <WSText color="gray500">FOR YOUR CLIENTS</WSText>
                <WSText.Heading5 mt="XL">Free</WSText.Heading5>
                <WSText mt="XS" color="gray500">
                  ACH & Wires <br />
                  2.9% with credit cards
                </WSText>
              </WSPanel>
            </WSGrid.Item>
          </WSGrid>
        ) : null}

        <WSButton.Link
          mt="2XL"
          mb="M"
          onClick={() =>
            setShow(prev => ({ ...prev, howItWorks: !prev.howItWorks }))
          }
        >
          <WSText.Heading5>
            How it works{" "}
            <WSIcon name={show.noFee ? "caret-up" : "caret-down"} />
          </WSText.Heading5>
        </WSButton.Link>
        {show.howItWorks ? (
          <WSGrid>
            <WSGrid.Item
              span={{ s: "6", m: "4", l: "3" }}
              className={styles.cardGridItem}
            >
              <WSPanel py="XL" className={styles.card}>
                <WSText className={styles.circle} color="gray500">
                  1
                </WSText>
                <WSText.Heading5 mt="XL">
                  Share your wingspan.me link.
                </WSText.Heading5>
                <WSText mt="XS" color="gray500">
                  Send it or post it.
                </WSText>
              </WSPanel>
            </WSGrid.Item>
            <WSGrid.Item
              span={{ s: "6", m: "4", l: "3" }}
              className={styles.cardGridItem}
            >
              <WSPanel py="XL" className={styles.card}>
                <WSText className={styles.circle} color="gray500">
                  2
                </WSText>
                <WSText.Heading5 mt="XL">
                  You receive a bank deposit within days.
                </WSText.Heading5>
                <WSText mt="XS" color="gray500">
                  We don’t hold it.
                </WSText>
              </WSPanel>
            </WSGrid.Item>
            <WSGrid.Item
              span={{ s: "6", m: "4", l: "3" }}
              className={styles.cardGridItem}
            >
              <WSPanel py="XL" className={styles.card}>
                <WSText className={styles.circle} color="gray500">
                  3
                </WSText>
                <WSText.Heading5 mt="XL">
                  It’s actually that simple. <br /> <br />
                </WSText.Heading5>
                <WSText mt="XS" color="gray500">
                  Like Venmo, but better.
                </WSText>
              </WSPanel>
            </WSGrid.Item>
          </WSGrid>
        ) : null}
      </WSContainer>
    </>
  );
};
