import {
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSPage,
  WSPanel,
  WSPill,
  WSText,
  WSTodoList
} from "@wingspanhq/fe-component-library";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import {
  ADD_TO_CALENDAR_LINK,
  getCurrentIRSQuarter
} from "../../../shared/utils/taxes";
import { openInNewTab } from "../../../utils/openInNewTab";
import { useModalTaxesQuarterInfo } from "../modals/TaxesModalQuarterInfo";
import { NextPaymentInfo } from "../components/NextPaymentInfo";

export const TaxesPreview: React.FC = () => {
  const history = useHistory();
  const quarter = getCurrentIRSQuarter();
  const modalTaxInfo = useModalTaxesQuarterInfo();

  useBrowserPageTitle("Taxes Overview");

  const currentDueDate = moment(quarter.dueDate, "MMM DD, YYYY");
  const deadlineFromNow = currentDueDate.fromNow(true);

  const startTaxesOnboarding = async () => {
    history.push("/member/taxes/onboarding/filing-status");
  };

  return (
    <WSPage
      title="Taxes"
      primaryButtonComponent={props => (
        <WSPersistentUpgradeButton
          feature={WSFrontendFeature.TaxWitholdings}
          onClick={startTaxesOnboarding}
          {...props}
          data-testid="startOnboarding"
        >
          Set up tax withholdings
        </WSPersistentUpgradeButton>
      )}
    >
      <WSGrid>
        <WSGrid.Item span={{ s: "8" }}>
          <WSPanel>
            <WSText.ParagraphSm color="gray500">
              Current balance set aside for taxes.
            </WSText.ParagraphSm>
            <WSText.Display2 my="XS">$—</WSText.Display2>
            <WSFlexBox.CenterY>
              <WSText.ParagraphXs>
                <NextPaymentInfo
                  deadlineFromNow={deadlineFromNow}
                  currentDueDate={currentDueDate}
                />
              </WSText.ParagraphXs>
              <WSIcon
                block
                name="help-circle"
                size="S"
                ml="XS"
                color="gray500"
                onClick={() => modalTaxInfo.open()}
              />
              <WSButton.Link
                ml="M"
                size="S"
                onClick={() => {
                  openInNewTab(ADD_TO_CALENDAR_LINK);
                }}
              >
                Add to calendar
              </WSButton.Link>
            </WSFlexBox.CenterY>
          </WSPanel>
          <WSPanel mt="XL">
            <WSElement mb="XL">
              <WSIcon block name="calculator" size="M" color="gray600" />
            </WSElement>

            <WSText.Heading5>Set aside money for taxes</WSText.Heading5>
            <WSText my="M" color="gray500">
              Automate tax withholdings with Wingspan
            </WSText>

            <WSTodoList
              items={[
                {
                  mark: "1.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Estimate your tax rate
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Find our recommendation specific to you
                      </WSText.ParagraphSm>
                    </>
                  )
                },
                {
                  mark: "2.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Start setting aside
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Automatically set aside your tax rate from all Wingspan
                        payments
                      </WSText.ParagraphSm>
                    </>
                  )
                },
                {
                  mark: "3.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Watch your withholdings grow
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Wingspan holds your tax withholdings, withdraw when you
                        need!
                      </WSText.ParagraphSm>
                    </>
                  )
                },
                {
                  mark: "4.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Pay the IRS
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Send 1-click payments on time each quarter
                      </WSText.ParagraphSm>
                    </>
                  )
                }
              ]}
            />

            <WSDivider my="XL" />
            <WSButton.Link
              rightIcon="chevron-right"
              onClick={startTaxesOnboarding}
            >
              Continue to Step 1: Find your tax rate
            </WSButton.Link>
          </WSPanel>
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "4" }}>
          <WSPanel>
            <WSFlexBox alignItems="center">
              <WSPill theme="warning" text="Withholdings off" badge />
              <WSButton.Link
                ml="S"
                size="S"
                data-testid="withholdingsSwitcher"
                onClick={startTaxesOnboarding}
              >
                Turn on
              </WSButton.Link>
            </WSFlexBox>
            <WSText.ParagraphSm color="gray500" mt="M">
              My withholding rate is
            </WSText.ParagraphSm>
            <WSText.Display2 my="XS">—%</WSText.Display2>
            <WSText>
              The proportion of 1099 income set aside so you are financially
              prepared for taxes.
            </WSText>
            <WSButton.Link mt="XL" onClick={startTaxesOnboarding}>
              Find my tax rate
            </WSButton.Link>
          </WSPanel>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
