import { WSElementProps } from "@wingspanhq/fe-component-library";
import { Tabs } from "../../../../components/Tabs";
import {
  PAYEES_ENGAGEMENTS_LIST_PATH,
  PAYEES_GROUPS_LIST_PATH,
  PAYEES_LIST_PATH,
  PAYEES_ROOT_PATH
} from "../../utils";
import { useCollaboratorGroupListSizeQuery } from "../../../../query/collaboratorGroups/queries/useCollaboratorGroupsListSize";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";
import { PayerPayeeEngagementSearchableStatus } from "../../../../services/search";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useEngagementListSizeQuery } from "../../../../query/engagements/queries/useEngagementListSizeQuery";

export const DashboardTabs: React.FC<WSElementProps> = props => {
  const queryFeatureFlags = useFeatureFlags();
  const queryPayeeRowsSummary = usePayeeRowsSummaryQuery({
    filter: {
      searchableStatus: {
        "!=": PayerPayeeEngagementSearchableStatus.Inactive
      }
    }
  });
  const groupsListSizeQuery = useCollaboratorGroupListSizeQuery(
    {},
    {
      enabled: !queryFeatureFlags.data?.engagementsList
    }
  );
  const engagementsListSizeQuery = useEngagementListSizeQuery(
    {},
    {
      enabled: queryFeatureFlags.data?.engagementsList
    }
  );

  return (
    <Tabs
      {...props}
      tabs={[
        {
          label: "Contractors",
          path: PAYEES_LIST_PATH,
          badge: queryPayeeRowsSummary.data?.listSize
        },
        ...(queryFeatureFlags.data?.engagementsList
          ? [
              {
                label: "Engagements",
                path: PAYEES_ENGAGEMENTS_LIST_PATH,
                badge: engagementsListSizeQuery.data
              }
            ]
          : [
              {
                label: "Groups",
                path: PAYEES_GROUPS_LIST_PATH,
                badge: groupsListSizeQuery.data
              }
            ])
      ]}
    />
  );
};
