import * as React from "react";
import { useEffect, useState } from "react";
import {
  WSElement,
  WSElementProps,
  detachLayoutProps
} from "../../WSElement/WSElement.component";
import { WSPanel, WSText } from "../../core";
import styles from "./WSHorizontalScrollCards.module.scss";

export type WSHorizontalScrollCardsEndCardConfig = {
  onClick: () => void;
  label: string;
};

export interface WSHorizontalScrollCardsProps extends WSElementProps {
  cardWidth?: number;
  endCard?: WSHorizontalScrollCardsEndCardConfig;
}

export const WSHorizontalScrollCards: React.FC<WSHorizontalScrollCardsProps> = ({
  children,
  cardWidth = 200,
  endCard,
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const gapWidth = 24;
    let itemsCount = React.Children.count(children);
    if (endCard) {
      itemsCount += 1;
    }

    setContainerWidth(cardWidth * itemsCount + gapWidth * (itemsCount - 1));
  }, [endCard, children, cardWidth]);

  const itemStyles: React.CSSProperties = {
    width: cardWidth
  };

  return (
    <WSElement className={styles.cardsContainer} {...layoutProps}>
      <div style={{ width: containerWidth }} className={styles.gridContainer}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return (
              <div className={styles.item} style={itemStyles}>
                {child}
              </div>
            );
          }
          return null;
        })}
        {endCard && (
          <div className={styles.item} style={itemStyles}>
            <WSPanel className={styles.endCard} onClick={endCard.onClick}>
              <WSText weight="medium" color="blue400">
                {endCard.label}
              </WSText>
            </WSPanel>
          </div>
        )}
      </div>
    </WSElement>
  );
};
