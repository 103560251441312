/* eslint-disable react/no-array-index-key */

import cn from "classnames";
import React from "react";
import { WSDivider } from "../../WSDivider/WSDivider";
import {
  WSElement,
  WSElementColorsType,
  WSElementProps,
  WSElementSpacingsType
} from "../../WSElement/WSElement.component";
import { WSButton, WSButtonKind } from "../../core/WSButton/WSButton.component";
import { WSIcon, WSIconName } from "../../core/WSIcon/WSIcon.component";
import {
  WSImage,
  WSImageEmbeddedProps
} from "../../core/WSImage/WSImage.component";
import { WSPanel, WSPanelKindMap } from "../../core/WSPanel/WSPanel.component";
import { WSText, WSTextKindsType } from "../../core/WSText/WSText.component";
import { WSAvatar, WSAvatarSize } from "../WSAvatar/WSAvatar.component";
import { WSBadge, WSBadgeProps } from "../WSBadge/WSBadge.component";
import { WSMenuTrigger } from "../WSMenu/WSMenu.component";
import styles from "./WSCard.component.module.scss";

const WSCardSizesMembers = ["XS", "S", "M"] as const;
export type WSCardSizes = typeof WSCardSizesMembers[number];

export interface CardMenuItemProps {
  label: string;
  icon?: WSIconName;
  onClick: () => void;
}

export type WSCardActionOther = CardMenuItemProps & {
  buttonKind?: WSButtonKind;
};
export type WSCardActionPrimary = WSCardActionOther & {
  rightIcon?: WSIconName;
};

type WSCardHeaderLeftBase = {
  color?: WSElementColorsType;
  colorBackground?: WSElementColorsType;
};

export type WSCardHeaderLeftIcon = WSCardHeaderLeftBase & {
  icon: WSIconName;
};
export type WSCardHeaderLeftText = WSCardHeaderLeftBase & {
  text: string;
};

export type WSCardHeaderRight = WSBadgeProps;

export interface WSCardProps extends Omit<WSElementProps, "content"> {
  panelKind?: WSPanelKindMap;
  size?: WSCardSizes;
  image?: WSImageEmbeddedProps;
  headerLeft?: WSCardHeaderLeftIcon | WSCardHeaderLeftText;
  headerRight?: WSCardHeaderRight;
  title?: string;
  subtitle?: string;
  content?: React.ReactNode;
  primaryAction?: WSCardActionPrimary;
  otherAction?: WSCardActionOther | Array<WSCardActionOther>;
}

export const WSCard: React.FC<WSCardProps> = ({
  panelKind,
  size = "M",
  image,
  headerLeft,
  headerRight,
  title,
  subtitle,
  content,
  primaryAction,
  otherAction,
  children,
  className,
  ...elementProps
}) => {
  const isXS = size === "XS";
  const isS = size === "S";
  const isM = size === "M";

  const contentSpacing: WSElementSpacingsType = isXS ? "L" : "XL";

  const hasHeader = !!(headerLeft || headerRight || otherAction);
  const hasBody = !!(title || subtitle || content);
  const hasSecondaryBody = !!children;

  const headerBottomMargin: WSElementSpacingsType =
    hasBody || hasSecondaryBody ? contentSpacing : "NONE";
  const bodyBottomMargin: WSElementSpacingsType = hasSecondaryBody
    ? contentSpacing
    : "NONE";

  let titleTextKind: WSTextKindsType;
  let subtitleTextKind: WSTextKindsType;
  let contentTextKind: WSTextKindsType;
  if (isM) {
    titleTextKind = "Heading3";
    subtitleTextKind = "Heading4";
    contentTextKind = "Paragraph";
  } else {
    if (isS) {
      titleTextKind = "Heading4";
      subtitleTextKind = "ParagraphSm";
    } else {
      titleTextKind = "Heading5";
      subtitleTextKind = "ParagraphSm";
    }
    contentTextKind = "ParagraphSm";
  }

  const contentInternal = Array.isArray(content) ? content : [content];

  let otherActionInternal: Array<WSCardActionOther> | undefined;
  if (otherAction) {
    if (Array.isArray(otherAction)) {
      if (otherAction.length > 0) {
        otherActionInternal = otherAction;
      } else {
        // not valid, don't set up an internal
      }
    } else {
      otherActionInternal = [otherAction];
    }
    // all cards with otherActions must have an icon, sorry but them's the rules!
    if (!headerLeft && otherActionInternal) {
      if (title) {
        headerLeft = {
          text: title.substring(0, 1)
        };
      } else {
        headerLeft = {
          icon: "file"
        };
      }
    }
  }

  let headerLeftTemplate: React.ReactNode;
  if (headerLeft) {
    let headerLeftCircleSize: WSAvatarSize = "L";
    if (isS) {
      headerLeftCircleSize = "M";
    } else if (isXS) {
      headerLeftCircleSize = "S";
    }
    if ("icon" in headerLeft) {
      headerLeftTemplate = (
        <WSAvatar.Icon size={headerLeftCircleSize} {...headerLeft} />
      );
    } else if ("text" in headerLeft) {
      headerLeftTemplate = (
        <WSAvatar.Text size={headerLeftCircleSize} {...headerLeft} />
      );
    }
  }

  let headerRightTemplate: React.ReactNode;
  if (headerRight) {
    headerRightTemplate = <WSBadge {...headerRight} />;
  }

  const isWholeCardClickable = primaryAction && !otherAction;

  return (
    <WSPanel
      kind={panelKind}
      onClick={isWholeCardClickable ? primaryAction?.onClick : undefined}
      className={cn(
        styles.card,
        {
          [styles.clickable]: isWholeCardClickable
        },
        className
      )}
      {...elementProps}
    >
      {image && <WSImage className={styles.image} {...image} />}
      <WSElement p={contentSpacing} className={styles.content}>
        {hasHeader && (
          <WSElement mb={headerBottomMargin} className={styles.header}>
            <WSElement className={styles.left}>{headerLeftTemplate}</WSElement>
            <WSElement className={styles.right}>
              <WSElement>{headerRightTemplate}</WSElement>
              <WSElement ml={headerRightTemplate ? "XS" : "NONE"}>
                {otherActionInternal &&
                  (otherActionInternal.length > 1 || isXS ? (
                    <WSMenuTrigger
                      name="card-actions"
                      renderTrigger={({ onToggle }) => (
                        <WSIcon
                          onClick={onToggle}
                          block
                          name="dots-v"
                          className={styles.menuButton}
                          color="gray500"
                          size={isXS ? "S" : "M"}
                        />
                      )}
                      items={otherActionInternal}
                    />
                  ) : (
                    <WSButton
                      kind={
                        otherActionInternal[0].buttonKind
                          ? otherActionInternal[0].buttonKind
                          : "Secondary"
                      }
                      size="S"
                      icon={otherActionInternal[0].icon}
                      onClick={otherActionInternal[0].onClick}
                    >
                      {otherActionInternal[0].label}
                    </WSButton>
                  ))}
              </WSElement>
            </WSElement>
          </WSElement>
        )}
        {hasBody && (
          <WSElement mb={bodyBottomMargin} className={styles.body}>
            {title && (
              <WSText
                mb={subtitle || content ? "XS" : "NONE"}
                kind={titleTextKind}
              >
                {title}
              </WSText>
            )}
            {subtitle && (
              <WSText
                mb={content ? "M" : "NONE"}
                color="gray500"
                kind={subtitleTextKind}
              >
                {subtitle}
              </WSText>
            )}
            {contentInternal && (
              <WSElement>
                {contentInternal.map((contentSection, index) => (
                  <WSText
                    mb={
                      contentInternal.length > 1 &&
                      index !== contentInternal.length - 1
                        ? "XS"
                        : "NONE"
                    }
                    key={`card-content-${index}`}
                    kind={contentTextKind}
                  >
                    {contentSection}
                  </WSText>
                ))}
              </WSElement>
            )}
          </WSElement>
        )}
        {hasSecondaryBody && (
          <WSElement className={styles.secondaryBody}>
            <WSDivider mb={contentSpacing} />
            {children}
          </WSElement>
        )}
      </WSElement>
      {primaryAction && (
        <WSElement
          px={contentSpacing}
          py="L"
          className={styles.primaryAction}
          onClick={!isWholeCardClickable ? primaryAction.onClick : undefined}
        >
          <WSButton.Link
            size={isXS ? "S" : "L"}
            icon={primaryAction.icon}
            type="button"
          >
            {primaryAction.label}
          </WSButton.Link>
        </WSElement>
      )}
    </WSPanel>
  );
};
