import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useMutationPerformVerification } from "../../../query/onboarding/mutations/useMutationPerformVerification";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { NotificationBanner } from "../../PaymentMethods/components/NotificationBanner";

export const PendingState: React.FC = () => {
  const queryVerifications = useQueryVerifications();
  const [
    performVerification,
    performVerificationMeta
  ] = useMutationPerformVerification();

  return (
    <>
      {queryVerifications.data?.banking === "Failed" ||
      queryVerifications.data?.banking === "UpdateRequired" ||
      queryVerifications.data?.banking === "Pending" ? (
        <NotificationBanner mb="2XL" />
      ) : (
        <WSElement colorBackground="gray50" p="XL" mb="2XL">
          <WSFlexBox wrap="nowrap" mb="XL">
            <WSElement>
              <WSText weight="medium" color="gray600" mb="S">
                Wingspan Wallet
              </WSText>
              <WSText.ParagraphSm color="gray500" mb="S">
                Add funds to a business bank account with Wingspan. Use virtual
                cards to perform transactions, make payments and so much more.
              </WSText.ParagraphSm>
              <WSText.ParagraphSm color="gray500">
                To get started, provide verification documents to ensure
                compliance and the secure handling of your funds.
              </WSText.ParagraphSm>
            </WSElement>
          </WSFlexBox>

          <WSButton
            loading={performVerificationMeta.isLoading}
            fullWidth
            size="M"
            onClick={() => {
              performVerification({
                verificationLevel: "Banking"
              });
            }}
          >
            Get started
          </WSButton>
        </WSElement>
      )}

      <WSElement colorBackground="gray50" p="XL">
        <WSText weight="medium" color="gray600" mb="XL">
          Reasons people love Wingspan Wallet:
        </WSText>

        <WSElement colorBackground="white" p="XL" mb="M">
          <WSText weight="medium" color="gray600" mb="M">
            Business banking made better
          </WSText>
          <WSText.ParagraphSm color="gray500">
            Receive your payouts 1-2 days sooner. No account, transaction,
            minimum balance or hidden fees. Virtual debit card via Apple Pay and
            Google Pay
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement colorBackground="white" p="XL" mb="M">
          <WSText weight="medium" color="gray600" mb="M">
            Save big on your taxes with write-offs
          </WSText>
          <WSText.ParagraphSm color="gray500">
            Automatic write-offs of business expenses, fully integrated with
            Wingspan Income & Expenses
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement colorBackground="white" p="XL">
          <WSText weight="medium" color="gray600" mb="M">
            Over 40,000 ATMs
          </WSText>
          <WSText.ParagraphSm color="gray500">
            No-fee cash deposits and withdrawals at 40,000 ATMs nationwide.
            Unlimited ACH and wire transfers for free.
          </WSText.ParagraphSm>
        </WSElement>
      </WSElement>
    </>
  );
};
