import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { IPayerRow } from "../../../services/search";
import { AllEngagementsByPayerIds } from "../queries/useQueryAllPayersWithEngagements";
import {
  IPayerEngagementListResponse,
  IPayerEngagementResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

const getActiveRequirements = (engagement: IPayerEngagementResponse) => {
  if (engagement.status !== PayerPayeeEngagementStatus.Active) {
    return [];
  }

  return engagement.eligibilityRequirements.filter(
    requirement =>
      requirement.status !== RequirementStatus.Complete &&
      !requirement.document?.events?.memberSignedAt
  );
};
const getActiveEngagements = (engagements: IPayerEngagementListResponse) => {
  return engagements.filter(
    engagement => getActiveRequirements(engagement).length > 0
  );
};

export const getActivePayersWithEngagement = (
  payersEngagements: AllEngagementsByPayerIds
) => {
  return payersEngagements
    .filter(({ payer, engagements }) => {
      return getActiveEngagements(engagements).length > 0;
    })
    .map(({ payer, engagements }) => {
      return {
        payer,
        engagements: getActiveEngagements(engagements)
      };
    });
};

export const getPayerRowsWithRequirements = (payers?: IPayerRow[]) => {
  return (
    payers?.filter(payer =>
      payer.engagements.some(
        engagement => engagement.eligibilityRequirements.length > 0
      )
    ) ?? []
  );
};
