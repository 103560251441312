import {
  WSControl,
  WSControlGroup,
  WSDivider,
  WSElement,
  WSElementProps,
  WSForm,
  WSInfoBox,
  WSInputMask,
  WSInputText,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { US_STATES_OPTIONS } from "../../../../constants/user";
import { SELECT_OPTIONS_CANADIAN_PROVINCES } from "../../../../shared/constants/user";
import { WS_LINKS } from "../../../../types/wsLinks";
import { selectorNo1099 } from "../../../1099NECFiling/components/FormTaxpayerInformation/selectorNo1099";
import { canadaQuebecEnterpriseNumberProps } from "../../helpers/canadaQuebecEnterpriseNumber";
import { industrySelectOptions } from "../../helpers/industry";
import {
  businessTaxClassificationSelectOptions,
  taxClassificationLabelMap
} from "../../helpers/taxClassification";
import { getTaxIdLabel, getTaxIdMask } from "../../helpers/taxId";
import { FederalTaxClassification } from "../../types";
import { useModalWhyWeCollectInfo } from "../ModalWhyWeCollectInfo";
import { AddressFields } from "./AddressFields";

const groupName = "business";

type Props = WSElementProps;

export const BusinessFields: React.FC<Props> = props => {
  const modal = useModalWhyWeCollectInfo();

  return (
    <WSElement {...props}>
      <WSPanel>
        <WSSectionToolbar
          title="Business information"
          button={{
            label: "Why do we collect this info?",
            onClick: modal.open
          }}
        />
        <WSText.ParagraphSm color="gray500">
          Wingspan is required to collect this information for compliance
          purposes. Your information is{" "}
          <a href={WS_LINKS.security} target="_blank" rel="noreferrer">
            certified secure
          </a>
          .
        </WSText.ParagraphSm>

        <WSDivider my="L" />

        <WSList gap="XL">
          <WSForm.Field
            name={groupName + ".legalBusinessName"}
            label="Legal business name"
            component={WSInputText}
            componentProps={{
              required: true,
              placeholder: "Enter business name",
              fsExclude: true
            }}
          />

          <WSForm.Field
            name={groupName + ".dba"}
            label="Doing business as (DBA) (optional)"
            component={WSInputText}
            componentProps={{
              placeholder: "Enter DBA",
              fsExclude: true
            }}
          />

          <WSForm.Value name={groupName + ".address.country"}>
            {country => (
              <WSForm.Field
                hidden={country !== "US"}
                name={groupName + ".taxClassification"}
                label="Federal tax classification"
                component={WSSelect}
                componentProps={{
                  required: true,
                  helperText: "Business type",
                  options: businessTaxClassificationSelectOptions
                }}
              />
            )}
          </WSForm.Value>

          <WSForm.Value name={groupName + ".taxClassification"}>
            {taxClassification =>
              selectorNo1099(taxClassification) ? (
                <WSInfoBox title="No 1099-NEC form will be issued">
                  As a{" "}
                  {
                    taxClassificationLabelMap[
                      taxClassification as FederalTaxClassification
                    ]
                  }
                  , you're not eligible for a 1099 according to IRS guidelines.
                  Instead, Wingspan will provide a summary of your 2023 payments
                  to assist you during tax season. If this doesn't align with
                  your business structure, update your details promptly.
                </WSInfoBox>
              ) : null
            }
          </WSForm.Value>

          <WSForm.Value name={groupName + ".address.country"}>
            {country =>
              country === "US" || country === "CA" ? (
                <WSForm.Field
                  key={country + "-tax-id"}
                  name={groupName + ".taxId"}
                  label={getTaxIdLabel("Business", country)}
                  component={WSInputMask}
                  componentProps={{
                    required: true,
                    placeholder: getTaxIdMask("Business", country),
                    mask: getTaxIdMask("Business", country),
                    unmask: true,
                    fsExclude: true
                  }}
                />
              ) : (
                <WSForm.Field
                  key={country + "-tax-id"}
                  name={groupName + ".taxId"}
                  label={getTaxIdLabel("Business", country)}
                  component={WSInputText}
                  componentProps={{
                    required: true,
                    fsExclude: true
                  }}
                />
              )
            }
          </WSForm.Value>

          <WSForm.Field
            name={groupName + ".industry"}
            label="Industry"
            component={WSSelect}
            componentProps={{
              required: true,
              helperText:
                "If you don't see your industry, pick the one that matches best",
              options: industrySelectOptions
            }}
          />

          <WSElement>
            <WSForm.Value name={groupName + ".noWebsite"}>
              {noWebsite => (
                <WSForm.Field
                  mb="S"
                  name={groupName + ".website"}
                  label="Business website"
                  component={WSInputText}
                  componentProps={{
                    disabled: noWebsite,
                    placeholder: "http://"
                  }}
                />
              )}
            </WSForm.Value>

            <WSForm.Field
              name={groupName + ".noWebsite"}
              label="Business website"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                size: "S",
                label: "The business does not have a website"
              }}
            />
          </WSElement>

          <WSForm.Field
            name={groupName + ".numberOfEmployees"}
            component={WSControlGroup}
            label="How many employees do you have?"
            componentProps={{
              required: true,
              type: "radio",
              options: [
                {
                  label: "None",
                  value: "None"
                },
                {
                  label: "1-10",
                  value: "1To10"
                },
                {
                  label: "11-50",
                  value: "11To50"
                },
                {
                  label: "51-250",
                  value: "51To250"
                },
                {
                  label: "Over 250",
                  value: "Over250"
                }
              ]
            }}
          />
        </WSList>

        <WSDivider my="2XL" />

        <WSList gap="XL">
          <AddressFields
            name={groupName + ".address"}
            label="Business address"
          />

          <WSForm.Values
            names={[
              groupName + ".address.state",
              groupName + ".address.country"
            ]}
          >
            {values => {
              const country = values[groupName + ".address.country"];
              const state = values[groupName + ".address.state"];

              const isNeqVisible = country === "CA" && state === "QC";

              return (
                <WSForm.Field
                  hidden={!isNeqVisible}
                  name={groupName + ".canadaQuebecEnterpriseNumber"}
                  label="Québec Enterprise Number (NEQ)"
                  component={WSInputMask}
                  componentProps={{
                    ...canadaQuebecEnterpriseNumberProps,
                    required: true
                  }}
                />
              );
            }}
          </WSForm.Values>

          <WSForm.Value name={groupName + ".address.country"}>
            {country =>
              country === "US" ? (
                <WSForm.Field
                  key="US-state-of-incorporation"
                  name={groupName + ".stateOfIncorporation"}
                  label="State/Territory of incorporation"
                  component={WSSelect}
                  componentProps={{
                    required: true,
                    internalSearch: true,
                    options: US_STATES_OPTIONS
                  }}
                />
              ) : country === "CA" ? (
                <WSForm.Field
                  key="CA-state-of-incorporation"
                  name={groupName + ".stateOfIncorporation"}
                  label="Province of incorporation"
                  component={WSSelect}
                  componentProps={{
                    required: true,
                    internalSearch: true,
                    options: SELECT_OPTIONS_CANADIAN_PROVINCES
                  }}
                />
              ) : null
            }
          </WSForm.Value>
        </WSList>
      </WSPanel>
    </WSElement>
  );
};
