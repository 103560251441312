import {
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import {
  RouteComponentProps,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { helloSignClient, helloSignPromise } from "../../../utils/helloSign";
import { DetailsLayout } from "../../../components/DetailsLayout/DetailsLayout";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useTemplateQuery } from "../../../query/files/queries";
import { useWSMutation } from "../../../query/helpers";
import { QUERY_COLLABORATOR_GROUP } from "../../../query/payments/keys";
import { useEligibilityRequirementQuery } from "../../../query/payments/queries";
import { paymentsService } from "../../../services/payments";
import { RequirementDetails } from "../../components/RequirementDetails/RequirementDetails";

export const CollaboratorGroupRequirementDetails: React.FC<RouteComponentProps<{
  collaboratorGroupId: string;
  eligibilityRequirementId: string;
}>> = ({
  match: {
    params: { collaboratorGroupId, eligibilityRequirementId }
  }
}) => {
  const history = useHistory();
  const { openModal } = useModalContext();
  const eligibilityRequirementQuery = useEligibilityRequirementQuery(
    eligibilityRequirementId
  );
  const templateQuery = useTemplateQuery(
    eligibilityRequirementQuery.data?.templateId as string,
    { enabled: !!eligibilityRequirementQuery.data?.templateId }
  );
  const openPreview = async () => {
    let template = templateQuery.data;
    if (!templateQuery.isSuccess) return;
    if (!template?.viewUrl) {
      console.error("No HS view URL provided");
      return;
    }

    helloSignClient.open(template.viewUrl);

    await helloSignPromise(); // wait for close
    await templateQuery.refetch(); // refetching to enable re-opening the window. view URLs seems to be one-time
  };

  return (
    <DetailsLayout
      onBack={() => {
        history.push(
          "/member/invoices/contacts/collaborators/groups/" +
            collaboratorGroupId
        );
      }}
      title={templateQuery.data?.title || ""}
    >
      <DetailsLayout.Card>
        <RequirementDetails
          mb="5XL"
          eligibilityRequirementId={eligibilityRequirementId}
        />
        <WSButtons forceFullWidth mb="XL">
          <WSButton.Primary onClick={openPreview}>
            Preview requirement
          </WSButton.Primary>
          <WSButton
            destructive
            onClick={() => {
              openModal(REMOVE_REQUIREMENT_MODAL);
            }}
          >
            Remove requirement
          </WSButton>
          <WSButton.Secondary
            onClick={() => {
              openModal(REPLACE_REQUIREMENT_MODAL);
            }}
          >
            Replace requirement
          </WSButton.Secondary>
        </WSButtons>

        <WSText.ParagraphSm color="gray400">
          Documents contents cannot be edited once uploaded as a requirement. If
          you need to change a document, upload a new version.
        </WSText.ParagraphSm>
      </DetailsLayout.Card>
      <RemoveRequirementModal />
      <ReplaceRequirementModal />
    </DetailsLayout>
  );
};

const REMOVE_REQUIREMENT_MODAL = "REMOVE_REQUIREMENT_MODAL";

const RemoveRequirementModal = () => {
  const history = useHistory();
  const {
    params: { collaboratorGroupId, eligibilityRequirementId }
  } = useRouteMatch<{
    collaboratorGroupId: string;
    eligibilityRequirementId: string;
  }>();
  const { closeModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();
  const [remove, removeMeta] = useWSMutation(
    async () => {
      await paymentsService.collaboratorGroup.eligibilityRequirement.delete(
        collaboratorGroupId,
        eligibilityRequirementId
      );
    },
    {
      onSuccess: () => {
        closeModal(REMOVE_REQUIREMENT_MODAL);
        history.push(
          `/member/invoices/contacts/collaborators/groups/${collaboratorGroupId}`
        );
        openSnackbar({ message: "eSignature requirement removed." });
      },
      dependencies: [[QUERY_COLLABORATOR_GROUP, collaboratorGroupId]]
    }
  );

  return (
    <WSModal name={REMOVE_REQUIREMENT_MODAL} size="XS">
      <WSText mb="XL">
        If you remove this requirement, collaborators who have not yet completed
        it may become eligible for payments.
      </WSText>

      <WSErrorMessage
        mb="XL"
        contextKey="RemoveEligibilityRequirement"
        error={removeMeta.error}
      />

      <WSButtons>
        <WSButton.Tertiary
          onClick={() => {
            closeModal(REMOVE_REQUIREMENT_MODAL);
          }}
        >
          Cancel
        </WSButton.Tertiary>
        <WSButton destructive loading={removeMeta.isLoading} onClick={remove}>
          Remove
        </WSButton>
      </WSButtons>
    </WSModal>
  );
};

const REPLACE_REQUIREMENT_MODAL = "REPLACE_REQUIREMENT_MODAL";

const ReplaceRequirementModal = () => {
  const history = useHistory();
  const {
    params: { collaboratorGroupId, eligibilityRequirementId }
  } = useRouteMatch<{
    collaboratorGroupId: string;
    eligibilityRequirementId: string;
  }>();
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={REPLACE_REQUIREMENT_MODAL}
      title="Are you sure you want to replace  this requirement? All groups requiring it will be affected."
      size="XS"
    >
      <WSText mb="XL">
        Collaborators who have already completed this requirement will need to
        sign the new version.
      </WSText>
      <WSButtons forceFullWidth>
        <WSButton.Tertiary
          onClick={() => {
            closeModal(REPLACE_REQUIREMENT_MODAL);
          }}
        >
          Cancel
        </WSButton.Tertiary>
        <WSButton
          onClick={() => {
            closeModal(REPLACE_REQUIREMENT_MODAL);
            history.push(
              `/member/invoices/contacts/collaborators/groups/${collaboratorGroupId}/add-requirement`,
              {
                eligibilityRequirementId
              }
            );
          }}
        >
          Continue
        </WSButton>
      </WSButtons>
    </WSModal>
  );
};
