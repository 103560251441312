import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { IEngagement } from "@wingspanhq/payments/dist/interfaces";
import { getEngagement } from "../../../services/engagements";
import { QUERY_ENGAGEMENT } from "../keys";

export const useEngagementQuery = (
  id: string,
  config?: QueryConfig<IEngagement, WSServiceError>
) => {
  return useWSQuery<IEngagement, WSServiceError>(
    [QUERY_ENGAGEMENT, id],
    async (query, queryParams) => {
      return await getEngagement(id);
    },
    {
      ...config
    }
  );
};
