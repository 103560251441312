import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";
import { IMemberClientRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/memberClient";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { PayerRequirementsTable } from "../../../Payers/components/PayerRequirementsTable";
import { IPayerEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

export const PayerRequirements: React.FC<{
  engagements: IPayerEngagementListResponse;
}> = ({ engagements }) => {
  const allRequirements = sortBy<IMemberClientRequirementResponse>(
    engagements
      .filter(e => e.status === PayerPayeeEngagementStatus.Active)
      .map(e => e.eligibilityRequirements)
      .flat(),
    "engagementName"
  );
  const uniqRequirements = uniqBy(allRequirements, "eligibilityRequirementId");

  const engagementRequirements = uniqRequirements.map(requirement => {
    return {
      ...requirement,
      engagementId: engagements.find(eng =>
        eng.eligibilityRequirements.includes(requirement)
      )!.payerPayeeEngagementId,
      count: allRequirements.filter(
        r => r.collaboratorGroupId === requirement.collaboratorGroupId
      ).length
    };
  });

  return (
    <PayerRequirementsTable eligibilityRequirements={engagementRequirements} />
  );
};
