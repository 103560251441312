import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSGrid,
  WSLoader,
  WSMessageBox,
  WSPill,
  WSSearch,
  WSSelectOption,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import sortBy from "lodash/sortBy";
import { Nullable } from "primereact/ts-helpers";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SelectPayerEngagement } from "../../../modules/Payers/components/SelectPayerEngagement";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useMemberClientsQuery } from "../../../query/payments/queries";
import { getClients } from "../../../query/payments/selectors";
import { useModalAddClient } from "../ModalAddClient";
import { useInvoicesFormContext } from "./InvoicesForm";
import { useQueryPayerRows } from "../../../query/search/payer/queries/useQueryPayerRows";
import { selectorPayerEngagements } from "../../../modules/Payees/selectors/selectorPayerEngagements";
import { AdditionalEmailsFieldOld } from "../AdditionalEmailsField/AdditionalEmailsFieldOld";

export type ClientSectionProps = WSElementProps;

export type ClientSectionValues = {
  client?: {
    payerId: string | null;
    payerPayeeEngagementId: string | null;
    memberClientId: string;
    emailsCC: { email: string }[];
  };
};

export const ClientSection: React.FC<ClientSectionProps> = props => {
  const { register, setValue, clearErrors, errors } = useFormContext();
  const {
    defaultValues,
    invoicingConfigForPayee,
    payer,
    payerEngagement,
    invoice,
    invoiceTemplate
  } = useInvoicesFormContext();
  const queryFeatureFlags = useFeatureFlags();

  useEffect(() => {
    if (payer) {
      setValue(
        "client.emailsCC",
        (payer?.payeeOwnedData.payerEmailCC || []).map(email => ({ email }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payer?.payerId]);

  const [selectValue, setSelectValue] = useState<Nullable<WSSelectOption>>(
    null
  );

  const [autoFocus, setAutoFocus] = useState(false);
  const preselectedEmail = defaultValues?.email;

  useQueryPayerRows(
    {
      filter: {
        searchString: preselectedEmail
      }
    },
    {
      enabled: !!preselectedEmail,
      onSuccess: result => {
        if (!preselectedEmail) {
          return;
        }

        const payerRows = result.flat();
        const payer = payerRows.find(
          payer => payer.user.email === preselectedEmail
        );

        const filteredEngagements = selectorPayerEngagements(
          payer?.engagements || [],
          false,
          false
        );

        if (payer && filteredEngagements.length === 1) {
          setAutoFocus(false);
          setValue("client.payerId", payer.payerId);
          setValue(
            "client.payerPayeeEngagementId",
            filteredEngagements[0].payerPayeeEngagementId
          );
        } else {
          setAutoFocus(true);
        }
      }
    }
  );

  useEffect(() => {
    if (!payer || !payerEngagement || !payer.user) {
      setSelectValue(null);
      return;
    }

    const payerName = wsName({
      user: payer.user,
      member: payer.member,
      payeeOwnedData: payer.payeeOwnedData
    }).getResolvedName();

    setSelectValue({
      value: payer.payerId + "/" + payerEngagement.payerPayeeEngagementId,
      label: `${payerName} • ${payerEngagement.engagementName}`,
      labels: [payer.user.email],
      avatar: {
        type: "text",
        text: payerName
      }
    });
  }, [payer, payerEngagement]);

  const isDisabled = !!invoice || !!invoiceTemplate;

  return (
    <WSElement {...props}>
      <WSText.Heading5 mb="XL">Client</WSText.Heading5>

      <input type="hidden" name="client.memberClientId" ref={register()} />

      <input type="hidden" name="client.payerId" ref={register()} />
      <input
        type="hidden"
        name="client.payerPayeeEngagementId"
        ref={register()}
      />

      {queryFeatureFlags.data?.engagements ? (
        <>
          <SelectPayerEngagement
            defaultSearchText={preselectedEmail}
            onAddNewPayer={(payer, engagements) => {
              if (engagements.length) {
                const payerEngagement = engagements[0];
                const payerNames = wsName({
                  user: payer.user!,
                  member: payer.member,
                  payeeOwnedData: payer.payeeOwnedData
                });

                const payerName = payerNames.getResolvedName();

                clearErrors("client");
                setValue("client.payerId", payer.payerId);
                setValue(
                  "client.payerPayeeEngagementId",
                  payerEngagement.payerPayeeEngagementId
                );

                setSelectValue({
                  value:
                    payer.payerId +
                    "/" +
                    payerEngagement.payerPayeeEngagementId,
                  label: `${payerName} • ${payerEngagement.engagementName}`,
                  labels: [payerNames.email],
                  avatar: {
                    type: "text",
                    text: payerName
                  }
                });
              }
            }}
            autoFocus={autoFocus}
            disabled={isDisabled}
            value={selectValue}
            onChange={newValue => {
              if (newValue) {
                clearErrors("client");
                const [payerId, payerPayeeEngagementId] = newValue.value.split(
                  "/"
                );
                setValue("client.payerId", payerId);
                setValue(
                  "client.payerPayeeEngagementId",
                  payerPayeeEngagementId
                );
                setSelectValue(newValue);
              } else {
                setValue("client.payerId", null);
                setValue("client.payerPayeeEngagementId", null);
                setSelectValue(null);
              }
            }}
            status={
              errors?.client?.payerPayeeEngagementId ? "error" : undefined
            }
            message={errors?.client?.payerPayeeEngagementId?.message}
          />
        </>
      ) : (
        <Dropdown />
      )}

      <PreviewMemberClient />
      <PreviewPayerEngagement />

      {invoicingConfigForPayee?.additionalGuidelines.enabled &&
      invoicingConfigForPayee?.additionalGuidelines?.value ? (
        <WSMessageBox.Info
          data-testid="additionalGuidelines"
          my="M"
          noBorder
          // title={`Guidelines from Client (${memberClient?.name || "-"}):`}
        >
          <WSText.ParagraphSm color="gray500" weight="book">
            {invoicingConfigForPayee?.additionalGuidelines.value}
          </WSText.ParagraphSm>
        </WSMessageBox.Info>
      ) : null}
    </WSElement>
  );
};

const Dropdown: React.FC = () => {
  const memberClientsQuery = useMemberClientsQuery();
  const { watch } = useFormContext();
  const memberClientId = watch("client.memberClientId");
  const memberClients = getClients(memberClientsQuery.data || []);
  const modalAddClient = useModalAddClient();
  const { setValue, errors } = useFormContext();
  const formContext = useInvoicesFormContext();
  const isDisabled = !!formContext.invoice || !!formContext.invoiceTemplate;

  const items = useMemo(() => {
    return sortBy(memberClients, "updateAt").map(memberClient => ({
      value: memberClient.memberClientId,
      searchText: [
        memberClient.memberClientId,
        memberClient.emailTo,
        memberClient.name,
        memberClient.company
      ]
        .filter(Boolean)
        .join(" "),
      data: memberClient
    }));
  }, [memberClients]);

  const addNewHandler = () => {
    modalAddClient.open({
      onSuccess: memberClient => {
        setValue("client.memberClientId", memberClient.memberClientId);
        setValue(
          "client.emailsCC",
          (memberClient.emailCC || []).map(email => ({ email }))
        );
      }
    });
  };

  const itemsHeader = () => (
    <WSButton.Link
      icon="plus-circle"
      type="button"
      data-testid="addCollaboratorButton"
      onClick={addNewHandler}
    >
      Add new client
    </WSButton.Link>
  );

  if (memberClientsQuery.isLoading) {
    return <WSLoader.Spinner />;
  }

  if (items.length === 0) {
    return (
      <>
        <WSButton.Link
          icon="plus-circle"
          type="button"
          data-testid="addCollaboratorButton"
          onClick={addNewHandler}
        >
          Add client
        </WSButton.Link>
        <WSText.ParagraphSm color="gray500">
          Adding a client will add them to your client list for future use
        </WSText.ParagraphSm>
      </>
    );
  }

  return (
    <>
      <WSSearch
        disabled={isDisabled}
        autoFocus={!!formContext.defaultValues?.email}
        defaultInputValue={formContext.defaultValues?.email}
        name="memberClient"
        onChange={value => {
          const memberClient = memberClients.find(
            memberClient => memberClient.memberClientId === value
          );

          if (memberClient) {
            setValue("client.memberClientId", value);
            setValue(
              "client.emailsCC",
              (memberClient.emailCC || []).map(email => ({ email }))
            );
          } else {
            setValue("client.memberClientId", null);
          }
        }}
        value={memberClientId}
        placeholder="Select contact name or email"
        threshold={0.8}
        itemsHeader={itemsHeader}
        defaultItemsHeader={itemsHeader}
        defaultItems={items}
        items={items}
        itemToString={item =>
          item?.data.name || item?.data.company || item?.data.emailTo || ""
        }
        getTitle={memberClient =>
          memberClient.name || memberClient.company || memberClient.emailTo
        }
        getDetails={memberClient => memberClient.emailTo}
        getSideDetails={memberClient =>
          memberClient.company ? (
            <WSPill theme="blue" text={memberClient.company} />
          ) : null
        }
        error={!!errors?.client?.memberClientId}
      />
      <WSFormOld.Error name="client.memberClientId" />
    </>
  );
};

const PreviewMemberClient: React.FC = () => {
  const { memberClient } = useInvoicesFormContext();
  return (
    <>
      {memberClient ? (
        <WSGrid mt="M" mb="L" gutter="M">
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Contact name
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>{memberClient.name || "–"}</WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Client company
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {memberClient.company || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Contact email
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {memberClient.emailTo || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
        </WSGrid>
      ) : null}

      <AdditionalEmailsFieldOld
        hidden={!memberClient}
        name="client.emailsCC"
        inputLabel="Contact email CC"
        buttonLabel="Add email recipient"
        buttonDescription="Additional email addresses will be cc’d"
      />
    </>
  );
};

const PreviewPayerEngagement: React.FC = () => {
  const { payer, payerEngagement } = useInvoicesFormContext();

  return (
    <>
      {payer && payerEngagement ? (
        <WSGrid mt="M" mb="L" gutter="M">
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Contact name
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {payer.payeeOwnedData.payerName || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Client company
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {payer.payeeOwnedData.payerCompanyName || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Contact email
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>{payer.user?.email}</WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Engagement
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {payerEngagement.engagementName || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
        </WSGrid>
      ) : null}

      <AdditionalEmailsFieldOld
        hidden={!payer}
        name="client.emailsCC"
        inputLabel="Contact email CC"
        buttonLabel="Add email recipient"
        buttonDescription="Additional email addresses will be cc’d"
      />
    </>
  );
};
