import { Route, Switch } from "react-router-dom";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteVerify } from "./routes/RouteVerify";
import { RouteVerifySuccess } from "./routes/RouteVerifySuccess";
import { RouteWithdraw } from "./routes/RouteWithdraw";
import { RouteWithdrawSuccess } from "./routes/RouteWithdrawSuccess";

export const MIGRATION_BASE_PATH = "/member/banking-update";

export const Migration = () => {
  return (
    <Switch>
      <Route
        path={MIGRATION_BASE_PATH + "/verify"}
        exact
        component={RouteVerify}
      />

      <Route
        path={MIGRATION_BASE_PATH + "/verify-success"}
        exact
        component={RouteVerifySuccess}
      />

      <Route
        path={MIGRATION_BASE_PATH + "/withdraw-success"}
        exact
        component={RouteWithdrawSuccess}
      />
      <Route
        path={MIGRATION_BASE_PATH + "/withdraw"}
        exact
        component={RouteWithdraw}
      />
      <Route path={MIGRATION_BASE_PATH} component={RouteIndex} />
    </Switch>
  );
};
