import { ICardResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  useModalContext,
  WSFormOld,
  WSInputMaskOld,
  WSLoader,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICardTokenResponse } from "@wingspanhq/payments/dist/interfaces/api/card";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import {
  useRequestCardToken,
  useRequestCardVerificationToken
} from "../../../../query/payments/mutations";
import { ErrorContextKey } from "../../../../services/platform";
import { useSetWSStore, useWSStore } from "../../../../store";
import { censorPhone } from "../../../../utils/stringHelper";
import { validatorOTP } from "../../../../shared/validators/validatorOTP";

export const BANK_CARD_OTP_MODAL = "BANK_CARD_OTP_MODAL";

export type OTPModalInnerProps = {
  cardId: string;
  onSuccess: (response: ICardTokenResponse) => void;
};

export const BankCardOTPModal: React.FC = () => (
  <WSModal name={BANK_CARD_OTP_MODAL} size="S">
    {(props: OTPModalInnerProps) => <ModalInner {...props} />}
  </WSModal>
);

export const useOTPProtector = () => {
  const setStore = useSetWSStore();
  const {
    bankCardTokens: { token, ids }
  } = useWSStore();
  const { openModal } = useModalContext();

  return (card: ICardResponse, callback: (card: ICardResponse) => void) => {
    if (token && ids[card.cardId]) {
      callback(card);
    } else {
      openModal(BANK_CARD_OTP_MODAL, {
        cardId: card.cardId,
        onSuccess: ({ token, cardId }) => {
          setStore({
            bankCardTokens: {
              token,
              ids: {
                ...ids,
                [card.cardId]: cardId
              }
            }
          });
          callback(card);
        }
      } as OTPModalInnerProps);
    }
  };
};

const ModalInner: React.FC<OTPModalInnerProps> = ({ cardId, onSuccess }) => {
  const { closeModal } = useModalContext();

  const [
    requestCardVerificationToken,
    requestCardVerificationTokenMeta
  ] = useRequestCardVerificationToken();
  const [requestCardToken, requestCardTokenMeta] = useRequestCardToken();

  useEffect(() => {
    requestCardVerificationToken({ id: cardId, channel: "sms" });
  }, []);

  return (
    <>
      <WSText.Heading5 mb="XL">
        Additional verification required for your security
      </WSText.Heading5>

      {requestCardVerificationTokenMeta.isLoading ? (
        <WSLoader.Spinner />
      ) : requestCardVerificationTokenMeta.isSuccess ? (
        <>
          <WSText color="gray600" mb="XL">
            A 6 digit verification code has been sent <br />
            via SMS to{" "}
            {requestCardVerificationTokenMeta.data?.phoneNumber
              ? censorPhone(requestCardVerificationTokenMeta.data.phoneNumber)
              : "your registered mobile number"}
            .
          </WSText>

          <WSFormOld
            onSubmit={values => {
              requestCardToken(
                {
                  id: cardId,
                  verificationToken:
                    requestCardVerificationTokenMeta.data?.verificationToken ||
                    "",
                  verificationCode: values.otp
                },
                {
                  onSuccess: response => {
                    onSuccess(response);
                    closeModal(BANK_CARD_OTP_MODAL);
                  }
                }
              );
            }}
            validationSchema={Yup.object().shape({
              otp: validatorOTP
            })}
            defaultValues={{ otp: "" }}
          >
            <WSFormOld.Field
              mb="XL"
              name="otp"
              component={WSInputMaskOld}
              componentProps={{
                autoComplete: "one-time-code",
                mask: "999999"
              }}
            />

            <WSErrorMessage
              mb="XL"
              error={requestCardTokenMeta.error}
              contextKey={ErrorContextKey.RevealCardDataOPT}
            />

            <WSFormOld.SubmitButton
              name="continue"
              fullWidth
              loading={requestCardTokenMeta.isLoading}
            >
              Continue
            </WSFormOld.SubmitButton>
          </WSFormOld>
        </>
      ) : (
        <WSErrorMessage
          error={requestCardVerificationTokenMeta.error}
          contextKey={ErrorContextKey.Other}
        />
      )}
    </>
  );
};
