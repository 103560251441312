import { WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { DashboardTabs } from "./DashboardTabs";
import { DashboardHeader } from "./DashboardHeader";
import { RoutePayees } from "../RoutePayees";
import {
  PAYEES_ENGAGEMENTS_LIST_PATH,
  PAYEES_GROUPS_LIST_PATH,
  PAYEES_LIST_PATH,
  PAYEES_ROOT_PATH
} from "../../utils";
import { RouteGroups } from "../RouteGroups";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { RouteEngagements } from "../RouteEngagements";

export const RoutePayeesDashboard: React.FC = () => {
  const queryFeatureFlags = useFeatureFlags();
  return (
    <WSPage>
      <DashboardHeader />
      <DashboardTabs />

      <Switch>
        {queryFeatureFlags.data?.engagementsList ? (
          <Route
            path={PAYEES_ENGAGEMENTS_LIST_PATH}
            component={RouteEngagements}
          />
        ) : (
          <Route path={PAYEES_GROUPS_LIST_PATH} component={RouteGroups} />
        )}

        <Route path={PAYEES_LIST_PATH} component={RoutePayees} />

        <Redirect to={PAYEES_LIST_PATH} />
      </Switch>
    </WSPage>
  );
};
