import format from "date-fns/format";
import { WSMutationConfig, useWSMutation } from "../../../../query/helpers";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";
import { QUERY_CUSTOMER_ENTITY } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { QUERY_VERIFICATION_MISSING_DATA } from "../../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { QUERY_VERIFICATIONS } from "../../../../query/onboarding/queries/useQueryVerifications";
import { updateCustomerData } from "../../../../services/api/onboarding/customer";
import { WSServiceError } from "../../../../utils/serviceHelper";

export const useMutationSubmit = (
  config?: WSMutationConfig<void, WSServiceError, any>
) => {
  return useWSMutation<void, WSServiceError, any>(
    async data => {
      const formattedData = { ...data };

      if (formattedData.Entity && formattedData.Entity.website === "") {
        formattedData.Entity.website = "None";
      }

      if (formattedData.Entity?.dateOfBirth) {
        formattedData.Entity.dateOfBirth = format(
          formattedData.Entity.dateOfBirth,
          "yyyy-MM-dd"
        );
      }

      if (formattedData.Representative?.dateOfBirth) {
        formattedData.Representative.dateOfBirth = format(
          formattedData.Representative.dateOfBirth,
          "yyyy-MM-dd"
        );
      }

      if (formattedData.Owner1?.dateOfBirth) {
        formattedData.Owner1.dateOfBirth = format(
          formattedData.Owner1.dateOfBirth,
          "yyyy-MM-dd"
        );
      }

      if (formattedData.Owner2?.dateOfBirth) {
        formattedData.Owner2.dateOfBirth = format(
          formattedData.Owner2.dateOfBirth,
          "yyyy-MM-dd"
        );
      }

      if (formattedData.Owner3?.dateOfBirth) {
        formattedData.Owner3.dateOfBirth = format(
          formattedData.Owner3.dateOfBirth,
          "yyyy-MM-dd"
        );
      }

      if (formattedData.Owner4?.dateOfBirth) {
        formattedData.Owner4.dateOfBirth = format(
          formattedData.Owner4.dateOfBirth,
          "yyyy-MM-dd"
        );
      }

      if (formattedData.Entity) {
        await updateCustomerData("Entity", {
          customerData: formattedData.Entity
        });
      }

      if (formattedData.Representative) {
        await updateCustomerData("Representative", {
          customerData: data.Representative
        });
      }

      if (formattedData.Owner1) {
        await updateCustomerData("Owner1", {
          customerData: formattedData.Owner1
        });
      }

      if (formattedData.Owner2) {
        await updateCustomerData("Owner2", {
          customerData: formattedData.Owner2
        });
      }

      if (formattedData.Owner3) {
        await updateCustomerData("Owner3", {
          customerData: formattedData.Owner3
        });
      }

      if (formattedData.Owner4) {
        await updateCustomerData("Owner4", {
          customerData: formattedData.Owner4
        });
      }

      return data;
    },
    {
      dependencies: [
        QUERY_CUSTOMER_ENTITY,
        QUERY_VERIFICATIONS,
        QUERY_VERIFICATION_MISSING_DATA,
        QUERY_NOTIFICATIONS_NOTIFICATIONS
      ],
      ...config
    }
  );
};
