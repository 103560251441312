import {
  InputNumber,
  InputNumberChangeEvent,
  InputNumberProps
} from "primereact/inputnumber";
import React from "react";
import { getInputTestIdByName } from "../../../utils/getInputTestIdByName";
import { WSInputBaseProps } from "../../types/forms";
import { detachFieldProps, WSField, WSFieldProps } from "../WSField/WSField";
import {
  WSInputContainer,
  WSInputContainerProps
} from "../WSInputContainer/WSInputContainer";
import { fsExcludeCN } from "../../../utils/fsExcludeCN";

export type WSInputNumberProps = WSInputBaseProps<
  number | null,
  InputNumberChangeEvent
> &
  WSFieldProps &
  WSInputContainerProps &
  Omit<
    InputNumberProps,
    | "pt"
    | "prefix"
    | "size"
    | "color"
    | "onChange"
    | "onBlur"
    | "onFocus"
    | "mode"
  >;

export const WSInputNumber = React.forwardRef<InputNumber, WSInputNumberProps>(
  (props, ref) => {
    const {
      name,
      status,
      align,
      size = "M",
      className,
      icon,
      placeholder = "0",
      iconRight,
      inputAction,
      disabled,
      onChange,
      fsExclude,
      inputClassName,
      ...otherProps
    } = props;

    const { fieldProps, ...inputProps } = detachFieldProps(otherProps);

    return (
      <WSField {...fieldProps} status={status}>
        <WSInputContainer
          status={status}
          align={align}
          size={size}
          icon={icon}
          iconRight={iconRight}
          inputAction={inputAction}
          disabled={disabled}
          className={className}
        >
          <InputNumber
            ref={ref}
            disabled={disabled}
            onChange={(e) => onChange(e.value, e)}
            className={fsExcludeCN(inputClassName, fsExclude)}
            data-testid={getInputTestIdByName(name)}
            placeholder={placeholder}
            {...inputProps}
          />
        </WSInputContainer>
      </WSField>
    );
  }
);

WSInputNumber.displayName = "WSInputNumber";
