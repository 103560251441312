import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { parseInvitationData } from "./utils/parseInvitationData";
import { usePublicCustomizationQuery } from "../../query/customization/queries/usePublicCustomizationQuery";
import { WSQueries } from "../../query/WSQuery";
import { RootInvitation } from "./components/RootInvitation/RootInvitation";
import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";
import { InviteLinkNotFound } from "./components/InviteLinkNotFound";

interface InvitationAuthProps extends RouteComponentProps<{ data: string }> {}

export const INVITATION_AUTH_PATH = "/member/invitation/onboarding";
export const INVITATION_AUTH_ERROR_PATH = "/member/sign-up";
export const INVITATION_AUTH_SUCCESS_PATH = "/member/onboarding/customizing";

export const InvitationAuthIndex: React.FC<InvitationAuthProps> = props => {
  const data = parseInvitationData(props.location.search);
  // Init customization so we can use "useCustomization" hook
  const queryCustomization = usePublicCustomizationQuery(
    data?.inviterUserId as string,
    {
      enabled: !!data?.inviterUserId
    }
  );

  if (data?.invitationToken) {
    return (
      <WSQueries
        queries={{ queryCustomization }}
        renderErrors={() => <InviteLinkNotFound />}
        renderLoader={() => <LoadingPlaceholder blank />}
      >
        {({ queryCustomizationData }) => {
          return (
            <RootInvitation
              invitation={data}
              customization={queryCustomizationData}
            />
          );
        }}
      </WSQueries>
    );
  } else {
    return <InviteLinkNotFound />;
  }
};
