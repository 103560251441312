import {
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";

export interface InfoItemProps extends WSElementProps {
  label: string;
  value?: string;
  singleLine?: boolean;
}

export const InfoItem: React.FC<InfoItemProps> = ({
  label,
  value,
  singleLine,
  ...elementProps
}) => {
  return (
    <WSFlexBox direction="column" {...elementProps}>
      <WSText.ParagraphSm weight="book" mb="M" color="gray500">
        {label}
      </WSText.ParagraphSm>
      <WSText.ParagraphSm weight="book" color="gray700" singleLine={singleLine}>
        {value}
      </WSText.ParagraphSm>
    </WSFlexBox>
  );
};
