import {
  useIsDesktop,
  useIsMobile,
  WSBreadcrumbs,
  WSButton,
  WSButtons,
  WSContainer,
  WSCopyButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useGetMemberPublicFile } from "../../query/files/queries";
import { useUserId } from "../../query/hooks/helpers";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { WSQueries } from "../../query/WSQuery";
import { copyText } from "../../utils/copyText";
import { createPPL } from "../utils";
import { PersonalPayLinkOverview } from "./PersonalPayLinkOverview";
import { PAYERS_PATH } from "../../modules/Payers/routes/RouteClients";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";

type CompanyLogoProps = {
  id: string;
} & WSElementProps;

export const CompanyLogo: React.FC<CompanyLogoProps> = ({
  id,
  ...elementProps
}) => {
  const qFile = useGetMemberPublicFile(id);

  if (qFile.data?.url) {
    return (
      <WSElement {...elementProps}>
        <img
          style={{
            maxHeight: 50,
            maxWidth: 80,
            display: "block"
          }}
          src={qFile.data.url}
        />
      </WSElement>
    );
  }

  return null;
};

export const PersonalPayLinkDashboard: React.FC = () => {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const history = useHistory();

  const memberId = useUserId();
  const qFeatureFlags = useFeatureFlags();
  const qUserProfile = useUserProfile(memberId);
  const qMemberProfile = useMemberProfile(memberId);

  useBrowserPageTitle("Personal Pay Link - Dashboard");

  return (
    <WSQueries
      queries={{
        userData: qUserProfile,
        memberData: qMemberProfile,
        qFeatureFlags
      }}
    >
      {({ userData, memberData, qFeatureFlagsData: featureFlags }) => {
        const name = memberData.data.profile.company?.name || "";

        return (
          <>
            {/* TODO: What should be top alignment for this page? */}
            <WSContainer verticalPadding>
              <WSElement mt={isDesktop ? "XL" : undefined} />
              <WSGrid>
                <WSGrid.Item span={{ m: "6" }}>
                  {featureFlags.engagements ? (
                    <WSBreadcrumbs
                      items={[
                        {
                          label: "Clients",
                          onClick: () => {
                            history.push(PAYERS_PATH);
                          }
                        },
                        {
                          label: "Pay link"
                        }
                      ]}
                    />
                  ) : (
                    <WSFlexBox
                      direction={isDesktop ? "row" : "column"}
                      alignContent="stretch"
                      alignItems="stretch"
                    >
                      {memberData.data.profile.logoFileId ? (
                        <WSFlexBox.Center
                          mr={isMobile ? undefined : "M"}
                          mt={isMobile ? "S" : undefined}
                        >
                          <CompanyLogo
                            id={memberData.data.profile.logoFileId}
                          />
                        </WSFlexBox.Center>
                      ) : null}
                      <WSElement>
                        <WSText.Heading4
                          mt={isMobile ? "M" : undefined}
                          align={isMobile ? "center" : "left"}
                        >
                          {name}
                        </WSText.Heading4>
                        <WSText
                          mt="XS"
                          align={isMobile ? "center" : "left"}
                          color="gray500"
                          onClick={async () => {
                            await copyText(
                              createPPL(userData.data.tag),
                              "Copied!"
                            );
                          }}
                        >
                          {createPPL(userData.data.tag)}
                        </WSText>
                      </WSElement>
                    </WSFlexBox>
                  )}
                </WSGrid.Item>
                <WSGrid.Item span={{ m: "6" }}>
                  <WSButtons mb="S">
                    <WSButton.Secondary
                      size="M"
                      mt={isDesktop ? undefined : "S"}
                      fullWidth
                      onClick={() => history.push("/member/invoices")}
                    >
                      View&nbsp;payments
                    </WSButton.Secondary>

                    <WSCopyButton
                      size="M"
                      isURL
                      data={createPPL(userData.data.tag)}
                      copyLabel={"Copy\xA0my\xA0wingspan.me\xA0link"}
                      shareLabel={"Share\xA0my\xA0wingspan.me\xA0link"}
                      shareTitle="My wingspan.me link"
                    />
                  </WSButtons>
                  {featureFlags.engagements && (
                    <WSText.Paragraph
                      weight="book"
                      mt="XS"
                      align={isDesktop ? "left" : "center"}
                      color="gray400"
                      onClick={async () => {
                        await copyText(createPPL(userData.data.tag), "Copied!");
                      }}
                    >
                      {createPPL(userData.data.tag)}
                    </WSText.Paragraph>
                  )}
                </WSGrid.Item>
              </WSGrid>
            </WSContainer>
            <PersonalPayLinkOverview isMore={true} />
          </>
        );
      }}
    </WSQueries>
  );
};
