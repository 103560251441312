import {
  WSButton,
  WSButtons,
  WSContainer,
  WSCentered,
  WSPanel,
  WSText,
  WSTodoList
} from "@wingspanhq/fe-component-library";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import {
  getMissingInformationCollaborators,
  getVisibleCollaborators
} from "../../../query/payments/selectors";
import { WSQuery } from "../../../query/WSQuery";

export interface BulkUpdate1099MissingInfoStep1Props {}
export interface Download1099MissingInfoCollaborator {
  legalFirstName: string;
  legalLastName: string;
  email: string;
  country: string;
}

export interface Upload1099MissingInfoCollaborator {
  legalFirstName: string;
  legalLastName: string;
  email: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  ssn: string;
  businessName?: string;
}

export const BulkUpdate1099MissingInfoStep1: React.FC<BulkUpdate1099MissingInfoStep1Props> = () => {
  useBrowserPageTitle("Wingspan - 1099 Missing Information");
  const history = useHistory();
  const collaboratorQuery = useCollaboratorsQuery();
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;

  const formatMemberClients = (
    collaborators: ICollaboratorSchema[]
  ): Download1099MissingInfoCollaborator[] => {
    return collaborators.map(collaborator => {
      const legalFirstName = collaborator.member.user.profile
        ?.firstName as string;
      const legalLastName = collaborator.member.user.profile
        ?.lastName as string;
      const email = collaborator.member.user.email as string;

      return {
        legalFirstName,
        legalLastName,
        email,
        country: "US"
      };
    });
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 2) * 1}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
            1 of 2
          </WSText.ParagraphSm>
          <WSText.Heading4 mb="M">
            Bulk update missing information
          </WSText.Heading4>
          <WSQuery query={collaboratorQuery}>
            {({ data }) => {
              const collaborators = getVisibleCollaborators(data);
              const missingInfoCollaborators = getMissingInformationCollaborators(
                collaborators
              );
              const formattedCollaborators = formatMemberClients(
                missingInfoCollaborators
              );
              const csvProps = {
                filename: `Wingspan_Collaborators_Missing_Info_${prevYear}.csv`,
                headers: [
                  { label: "Legal First Name", key: "legalFirstName" },
                  { label: "Legal Last Name", key: "legalLastName" },
                  { label: "Email", key: "email" },
                  { label: "Business Name", key: "businessName" },
                  { label: "Address Line1", key: "addressLine1" },
                  { label: "Address Line2", key: "addressLine2" },
                  { label: "City", key: "city" },
                  { label: "State", key: "state" },
                  { label: "Postal Code", key: "postalCode" },
                  { label: "Country", key: "country" },
                  { label: "Tax ID", key: "ssn" }
                ],
                data: formattedCollaborators
              };
              return (
                <WSPanel>
                  <WSText mb="M">
                    You have {missingInfoCollaborators.length} collaborators
                    with income for {prevYear} who are missing information
                    required to file the 1099.
                  </WSText>
                  <WSTodoList
                    mb="XL"
                    items={[
                      {
                        mark: "1.",
                        content: (
                          <>
                            <WSText.ParagraphSm weight="medium">
                              Download CSV of collaborators with missing
                              information
                            </WSText.ParagraphSm>
                            <WSText.ParagraphSm mt="XS">
                              We'll provide a list of your collaborators
                              including their name, email, address and Taxpayer
                              Identification Number.
                            </WSText.ParagraphSm>
                          </>
                        )
                      },
                      {
                        mark: "2.",
                        content: (
                          <>
                            <WSText.ParagraphSm weight="medium">
                              Add missing information on CSV
                            </WSText.ParagraphSm>
                            <WSText.ParagraphSm mt="XS">
                              Each collaborator must have all fields complete in
                              order for Wingspan to file a 1099.
                            </WSText.ParagraphSm>
                          </>
                        )
                      },
                      {
                        mark: "3.",
                        content: (
                          <>
                            <WSText.ParagraphSm weight="medium">
                              Upload and import the missing information
                            </WSText.ParagraphSm>
                            <WSText.ParagraphSm mt="XS">
                              The missing information will be added to the
                              collaborators records.
                            </WSText.ParagraphSm>
                          </>
                        )
                      }
                    ]}
                  />
                  <WSButtons>
                    <CSVLink
                      {...csvProps}
                      onClick={() =>
                        history.push(
                          "/member/invoices/contacts/collaborators/1099/missing-information/upload"
                        )
                      }
                    >
                      <WSButton fullWidth icon="download">
                        Download CSV
                      </WSButton>
                    </CSVLink>
                    <WSButton.Secondary
                      fullWidth
                      onClick={() =>
                        history.push(
                          "/member/invoices/contacts/collaborators/1099/missing-information/upload"
                        )
                      }
                    >
                      I've updated the spreadsheet
                    </WSButton.Secondary>
                  </WSButtons>
                </WSPanel>
              );
            }}
          </WSQuery>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
