import {
  useWSModal,
  useWSSnackbar,
  WSAlert,
  WSButton,
  WSButtons,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces";
import { ClientInfoPanel } from "../../components/ClientInfoPanel";
import { useHistory } from "react-router-dom";
import { PAYERS_PATH } from "../RouteClients";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useDeletePayer } from "../../../../query/payers/mutations/useDeletePayer";

export interface RemoveClientModalProps {
  client: IPayerResponse;
  onSuccess?: (client: IPayerResponse) => void;
  onClose: () => void;
}

export const RemoveClientModal: React.FC<RemoveClientModalProps> = ({
  client,
  onClose
}) => {
  const history = useHistory();

  const { openSnackbar } = useWSSnackbar();
  const [removeClient, removeClientMeta] = useDeletePayer();

  const handleRemoveClient = async () => {
    await removeClient(client.payerId, {
      onSuccess: () => {
        history.replace(PAYERS_PATH);
        openSnackbar({
          duration: 5000,
          type: "success",
          message: "Client removed successfully"
        });
      },
      onError: () => {
        openSnackbar({
          duration: 5000,
          type: "warning",
          message: `Failed to remove client`
        });
      }
    });
  };

  return (
    <>
      <WSText.ParagraphSm mb="L" weight="book">
        Are you sure you want to remove this client permanently?
      </WSText.ParagraphSm>

      <ClientInfoPanel client={client} mb="XL" />

      <WSAlert
        mb="XL"
        theme="warning"
        icon="alert"
        size="M"
        title="Removing client"
      >
        After a client is removed permanently, they cannot be restored. All
        information associated with the client will be removed from the account.
        No additional invoices can be created for this client. Existing invoices
        will remain on the Invoicing page until you delete them.
      </WSAlert>

      <WSErrorMessage
        error={removeClientMeta.error}
        mb="XL"
        contextKey="RemoveClient"
      />

      <WSButtons format="modal">
        <WSButton
          destructive
          size="M"
          name="removeClient"
          ml="M"
          loading={removeClientMeta.isLoading}
          onClick={handleRemoveClient}
        >
          Remove client
        </WSButton>
        <WSButton.Tertiary size="M" onClick={onClose}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </>
  );
};

export const useRemoveClientModal = () =>
  useWSModal(RemoveClientModal, {
    title: "Remove client",
    size: "M"
  });
