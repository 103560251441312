import {
  WSButton,
  WSButtons,
  WSCentered,
  WSContainer,
  WSPanel,
  WSText,
  WSTodoList
} from "@wingspanhq/fe-component-library";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import { isEmpty } from "lodash";
import React from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import {
  getEligibleCollaboratorsFor1099Filing,
  getVisibleCollaborators
} from "../../../query/payments/selectors";
import { WSQuery } from "../../../query/WSQuery";
import { openInNewTab } from "../../../utils/openInNewTab";
import { openIntercom } from "../../../shared/utils/intercom";

export interface BulkAdjust1099AmountStep1Props {}

export interface BulkAdjust1099Collaborator {
  legalFirstName: string;
  legalLastName: string;
  email: string;
  platformIncome: number;
  adjustmentAmount: string;
  send1099: boolean;
}

export const BulkAdjust1099AmountStep1: React.FC<BulkAdjust1099AmountStep1Props> = () => {
  useBrowserPageTitle("Wingspan - 1099 Compensation Amounts");
  const history = useHistory();
  const collaboratorQuery = useCollaboratorsQuery();
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;

  const formatMemberClients = (
    collaborators: ICollaboratorSchema[]
  ): BulkAdjust1099Collaborator[] => {
    const collaboratorsWith1099 = collaborators.filter(
      collaborator =>
        !isEmpty(
          collaborator.form1099Balances[
            prevYear as keyof ICollaboratorSchema["form1099Balances"]
          ]
        )
    );
    return collaboratorsWith1099.map(collaborator => {
      const legalFirstName = collaborator.member.user.profile
        ?.firstName as string;
      const legalLastName = collaborator.member.user.profile
        ?.lastName as string;
      const email = collaborator.member.user.email as string;
      const prevYearForm1099Balances =
        collaborator.form1099Balances[
          prevYear as keyof ICollaboratorSchema["form1099Balances"]
        ];
      const platformIncome = prevYearForm1099Balances?.platformIncome as number;
      const adjustmentAmount = prevYearForm1099Balances?.adjustments as number;
      const send1099 = (prevYearForm1099Balances as any)?.issue1099 as boolean;

      return {
        legalFirstName,
        legalLastName,
        email,
        platformIncome,
        adjustmentAmount: adjustmentAmount ? `${adjustmentAmount}` : "",
        send1099
      };
    });
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 2) * 1}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
            1 of 2
          </WSText.ParagraphSm>
          <WSText.Heading4 mb="M">
            Bulk review & adjust 1099 compensation amounts
          </WSText.Heading4>
          <WSQuery query={collaboratorQuery}>
            {({ data }) => {
              const collaborators = getVisibleCollaborators(data);
              const eligibleCollaboratorsForFiling = getEligibleCollaboratorsFor1099Filing(
                collaborators
              );
              const formattedCollaborators = formatMemberClients(
                eligibleCollaboratorsForFiling
              );
              const csvProps = {
                filename: `Wingspan_Contractors_Adjustments_${prevYear}.csv`,
                headers: [
                  { label: "Legal First Name", key: "legalFirstName" },
                  { label: "Legal Last Name", key: "legalLastName" },
                  { label: "Email", key: "email" },
                  {
                    label: `${prevYear} Wingspan payments`,
                    key: "platformIncome"
                  },
                  { label: "Adjustment amount", key: "adjustmentAmount" },
                  { label: "Send 1099", key: "send1099", type: "checkbox" }
                ],
                data: formattedCollaborators
              };
              return (
                <WSPanel>
                  <WSTodoList
                    mb="XL"
                    items={[
                      {
                        mark: "1.",
                        content: (
                          <>
                            <WSText.ParagraphSm weight="medium">
                              Download CSV of contractors
                            </WSText.ParagraphSm>
                            <WSText.ParagraphSm mt="XS">
                              We'll provide a list of your contractors including
                              their name, email address, {prevYear} Wingspan
                              payments, and adjustment amount.
                            </WSText.ParagraphSm>
                          </>
                        )
                      },
                      {
                        mark: "2.",
                        content: (
                          <>
                            <WSText.ParagraphSm weight="medium">
                              Make adjustments to non-employee compensation on
                              CSV
                            </WSText.ParagraphSm>
                            <WSText.ParagraphSm mt="XS">
                              Ensure the sum of the {prevYear} Wingspan payments
                              and adjustment amount fields is equal to the total{" "}
                              {prevYear} compensation.
                            </WSText.ParagraphSm>
                          </>
                        )
                      },
                      {
                        mark: "3.",
                        content: (
                          <>
                            <WSText.ParagraphSm weight="medium">
                              Upload and import the adjustments
                            </WSText.ParagraphSm>
                            <WSText.ParagraphSm mt="XS">
                              Wingspan will import the adjustments and invite
                              any new contractors.
                            </WSText.ParagraphSm>
                          </>
                        )
                      }
                    ]}
                  />
                  <WSButtons>
                    <CSVLink
                      {...csvProps}
                      onClick={() =>
                        history.push(
                          "/member/invoices/contacts/collaborators/1099/adjust-compensation/upload"
                        )
                      }
                    >
                      <WSButton fullWidth icon="download">
                        Download CSV
                      </WSButton>
                    </CSVLink>
                    <WSButton.Secondary
                      fullWidth
                      onClick={() =>
                        history.push(
                          "/member/invoices/contacts/collaborators/1099/adjust-compensation/upload"
                        )
                      }
                    >
                      I've updated the spreadsheet
                    </WSButton.Secondary>
                  </WSButtons>
                </WSPanel>
              );
            }}
          </WSQuery>

          <BulkAdjust1099AmountFaqs />
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};

export const BulkAdjust1099AmountFaqs: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;
  return (
    <>
      <WSText.ParagraphSm weight="medium" mt="XL">
        How do I calculate adjustment amount?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm>
        Wingspan will report the non-employer compensation as the sum of the{" "}
        {prevYear} Wingspan payments and adjustment amount fields for the
        contractor. For example, if you paid a contractor{" "}
        <WSText.ParagraphSm inline formatMoney>
          1000
        </WSText.ParagraphSm>{" "}
        in {prevYear}, but only paid{" "}
        <WSText.ParagraphSm inline formatMoney>
          200
        </WSText.ParagraphSm>{" "}
        through Wingspan, the adjustment amount field would be +
        <WSText.ParagraphSm inline formatMoney>
          800
        </WSText.ParagraphSm>
        . The adjustment amount can be negative if you'd like to report less
        income than calculated by Wingspan.
      </WSText.ParagraphSm>

      <WSText.ParagraphSm weight="medium" mt="XL">
        How can I file a 1099 for contractor that isn't in Wingspan?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm>
        Add an additional row to the spreadsheet with the contractor's name,
        email address and adjustment amount. The contractor will be invited to
        Wingspan so we can collect the personal information required to file.
      </WSText.ParagraphSm>

      <WSText.ParagraphSm weight="medium" mt="XL">
        Can I update the adjustment amounts again?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm>
        Yes, you can edit adjustment amounts as many times as you need before
        the{" "}
        <WSText.ParagraphSm inline formatDate="monthDayYear">
          {new Date(currentYear, 0, 26)}
        </WSText.ParagraphSm>{" "}
        deadline.
      </WSText.ParagraphSm>

      <WSText.ParagraphSm weight="medium" mt="XL">
        Need more help?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm>
        Review the 1099{" "}
        <WSButton.Link
          onClick={() => openInNewTab("https://help.wingspan.app/en/")}
          mx="XS"
        >
          filling help docs
        </WSButton.Link>{" "}
        or{" "}
        <WSButton.Link mx="XS" onClick={openIntercom}>
          contact support
        </WSButton.Link>
      </WSText.ParagraphSm>
    </>
  );
};
