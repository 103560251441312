import * as Yup from "yup";
import { COUNTRY_OPTIONS } from "../../../../constants/user";
import { industrySelectOptions } from "../../helpers/industry";
import { businessTaxClassificationSelectOptions } from "../../helpers/taxClassification";
import { FieldConfig } from "./helpers";

export const fieldsConfig: Record<string, FieldConfig> = {
  email: {
    type: "text",
    label: "Email",
    requred: true
  },
  addressLine1: {
    type: "text",
    label: "Address line 1",
    requred: true
  },
  addressLine2: {
    type: "text",
    label: "Address line 2"
  },
  city: {
    type: "text",
    label: "City",
    requred: true
  },
  region: {
    type: "region",
    label: "State/Region",
    requred: true
  },
  postalCode: {
    type: "text",
    label: "Postal code",
    requred: true
  },
  country: {
    type: "select",
    label: "Country",
    options: COUNTRY_OPTIONS,
    internalSearch: true,
    requred: true
  },
  federalTaxClassification: {
    type: "select",
    label: "Federal tax classification",
    options: businessTaxClassificationSelectOptions,
    requred: true
  },
  website: {
    type: "text",
    label: "Website",
    validation: Yup.string().url("Invalid URL")
  },
  firstName: {
    type: "text",
    label: "First name",
    requred: true
  },
  middleName: {
    type: "text",
    label: "Middle name"
  },
  lastName: {
    type: "text",
    label: "Last name",
    requred: true
  },
  dateOfBirth: {
    type: "date",
    label: "Date of birth",
    requred: true
  },
  individualTaxId: {
    type: "taxId",
    label: "Individual tax ID",
    requred: true
  },
  occupation: {
    type: "text",
    label: "Job title / occupation",
    requred: true
  },
  sourceOfFunds: {
    type: "text",
    label: "Source of funds",
    requred: true
  },
  purposeOfAccount: {
    type: "text",
    label: "Purpose of account",
    requred: true
  },
  ownershipPercent: {
    type: "number",
    label: "Ownership percent",
    requred: true
  },
  legalBusinessName: {
    type: "text",
    label: "Legal business name",
    requred: true
  },
  doingBusinessAs: {
    type: "text",
    label: "Doing business as",
    requred: true
  },
  regionOfFormation: {
    type: "region",
    label: "State of incorporation",
    requred: true
  },
  stockTickerSymbol: {
    type: "text",
    label: "Stock ticker symbol",
    requred: true
  },
  indirectTaxRegistrationNumber: {
    type: "text",
    label: "Indirect tax registration number",
    requred: true
  },
  businessTaxId: {
    type: "taxId",
    label: "Business tax ID",
    requred: true,
    isBusiness: true
  },
  industry: {
    type: "select",
    label: "Industry",
    options: industrySelectOptions,
    requred: true
  }
};
