import {
  WSActions,
  WSElement,
  WSEmptyState,
  WSInfoBox,
  WSList,
  WSLoader,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { FundingSourceType } from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { useUserOrganizationName } from "../../../../userFeatures/useUserOrganizationName";
import { BankAccount } from "../../../PaymentMethods/components/BankAccount";
import { ContainerAccount } from "../../../PaymentMethods/components/ContainerAccount";

type Props = {
  onContinue?: () => void;
};

export const Managed: React.FC<Props> = ({ onContinue }) => {
  const userId = useUserId();
  const organizationName = useUserOrganizationName();
  const queryPayrollSettings = usePayrollSettings(userId);

  const fundingSourceId =
    queryPayrollSettings.data?.fundingSource?.fundingSourceId;
  const fundingSourceType =
    queryPayrollSettings.data?.fundingSource?.fundingSourceType ||
    FundingSourceType.Account;

  return (
    <WSList gap="2XL">
      <WSPanel>
        <WSList gap="2XL">
          <WSElement>
            <WSSectionToolbar title="Add payroll funding method" />
            <WSText.ParagraphSm color="gray500">
              Add a payroll funding method to fund your payrolls and start
              sending payments
            </WSText.ParagraphSm>
          </WSElement>

          {queryPayrollSettings.isLoading ? (
            <WSLoader.Spinner size="XS" />
          ) : fundingSourceType === FundingSourceType.Account &&
            fundingSourceId ? (
            <WSPanel>
              <ContainerAccount accountId={fundingSourceId}>
                {account => <BankAccount account={account} />}
              </ContainerAccount>
            </WSPanel>
          ) : fundingSourceType === FundingSourceType.InternalAccount &&
            fundingSourceId ? (
            <WSPanel>
              <ContainerAccount accountId={fundingSourceId}>
                {account => <BankAccount account={account} />}
              </ContainerAccount>
            </WSPanel>
          ) : (
            <WSEmptyState
              hasBorder
              orientation="horizontal"
              type="card"
              title="No payroll funding method yet"
              description="Once a payroll funding method is added, it will appear here."
            />
          )}

          <WSInfoBox>
            Payroll funding method is managed by {organizationName} - contact{" "}
            {organizationName} for support.
          </WSInfoBox>
        </WSList>
      </WSPanel>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: onContinue
          }
        ]}
      />
    </WSList>
  );
};
