import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useRemoveCollaboratorFromGroup } from "../../../query/payments/mutations";

export type RemoveCollaboratorFromGroupModalProps = {
  collaboratorName: string;
  collaboratorId: string;
  groupName: string;
  groupId: string;
};

export const REMOVE_COLLABORATOR_FROM_GROUP = "REMOVE_COLLABORATOR_FROM_GROUP";

export const RemoveCollaboratorFromGroupModal: React.FC = () => {
  const { closeModal } = useModalContext();

  const [
    removeCollaboratorFromGroup,
    removeCollaboratorFromGroupMeta
  ] = useRemoveCollaboratorFromGroup();

  return (
    <WSModal name={REMOVE_COLLABORATOR_FROM_GROUP} size="XS" title="">
      {(props: RemoveCollaboratorFromGroupModalProps) => (
        <>
          <WSText weight="medium" mb="XL">
            Are you sure you want to remove {props.collaboratorName} from{" "}
            {props.groupName}?
          </WSText>

          <WSErrorMessage
            mt="XL"
            error={removeCollaboratorFromGroupMeta.error}
            contextKey="DeleteCollaboratorsGroup"
          />

          <WSButtons mt="3XL">
            <WSButton.Tertiary
              onClick={() => {
                closeModal(REMOVE_COLLABORATOR_FROM_GROUP);
              }}
            >
              Cancel
            </WSButton.Tertiary>
            <WSButton
              destructive
              onAsyncClick={async () => {
                await removeCollaboratorFromGroup({
                  collaboratorId: props.collaboratorId,
                  groupId: props.groupId
                });

                closeModal(REMOVE_COLLABORATOR_FROM_GROUP);
              }}
            >
              Remove
            </WSButton>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
