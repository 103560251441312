import React from "react";
import { useHistory } from "react-router-dom";
import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useCollaboratorDeductions } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  DeductionStatus,
  ICollaboratorSchema
} from "@wingspanhq/payments/dist/interfaces";
import {
  getCollaboratorCompany,
  getCollaboratorName
} from "../../../query/payments/selectors";

type Props = {
  collaborator: ICollaboratorSchema;
};

export const DeductionsBanner: React.FC<Props> = props => {
  const history = useHistory();

  const qDeductions = useCollaboratorDeductions({
    memberId: props.collaborator.memberId,
    clientId: props.collaborator.clientId,
    status: DeductionStatus.Pending
  });

  return (
    <WSElement>
      <WSQueries
        queries={{ qDeductions }}
        renderLoader={() => null}
        renderErrors={() => null}
      >
        {({ qDeductions }) => {
          if (!qDeductions.data.length) {
            return null;
          }

          return (
            <WSMessageBox.Warning my="XL">
              <WSText inline>{`${getCollaboratorCompany(props.collaborator) ||
                getCollaboratorName(props.collaborator)} has ${
                qDeductions.data.length
              } scheduled deductions.`}</WSText>
              <WSButton.Link
                ml="XS"
                onClick={() =>
                  history.push(
                    `/member/invoices/contacts/collaborators/${props.collaborator.collaboratorId}/deductions/scheduled`
                  )
                }
              >
                See all
              </WSButton.Link>
            </WSMessageBox.Warning>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
