import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICheckbookCard,
  IPaymentCard,
  IPayoutSettingsResponse,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { Tooltip } from "../../../../components/Tooltip";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { selectorInstantAccounts } from "../../selectors/selectorInstantAccounts";
import { PayoutMethod } from "../PayoutMethod";

type Props = {
  payoutSettings: IPayoutSettingsResponse;
  accounts: IAccount[];
  debitCards: ICheckbookCard[];
  paymentCards: IPaymentCard[];
} & WSElementProps;

export const InstantMethods: React.FC<Props> = ({
  payoutSettings,
  accounts,
  debitCards,
  paymentCards,
  ...elementProps
}) => {
  const queryFeatureFlags = useFeatureFlags();
  const instantDestination = payoutSettings.payoutDestinations.find(
    destination => destination.payoutMethod === PayoutPreferences.Instant
  );
  const accountsRtp = selectorInstantAccounts(accounts);
  const isVisible =
    accountsRtp.length > 0 || debitCards.length > 0 || paymentCards.length > 0;

  return isVisible ? (
    <WSElement {...elementProps}>
      <WSFlexBox.CenterY mb="XL">
        <WSText weight="medium" mr="M">
          Instant payout methods
        </WSText>
        <WSText mr="M">(1% fee, up to one hour)</WSText>
        <Tooltip>
          <WSText mb="XS" color="white">
            <b>Instant payout (1% fee)</b>
          </WSText>
          <WSText color="white">
            Instant payouts only work with U.S. based bank accounts or debit
            cards that participate in instant transfer services. Find out if
            your bank account or card is eligible by adding it to your Wingspan
            account. Once added, eligible accounts will appear here under
            instant payout methods.
          </WSText>
        </Tooltip>
      </WSFlexBox.CenterY>

      {accountsRtp.map(account => (
        <PayoutMethod
          mb="M"
          account={account}
          type={PayoutPreferences.Instant}
          isDefault={
            payoutSettings.payoutPreferences === PayoutPreferences.Instant &&
            instantDestination &&
            instantDestination.destinationId === account.accountId
          }
        />
      ))}
      {queryFeatureFlags.data?.paymentCards
        ? paymentCards.map(paymentCard => (
            <PayoutMethod
              mb="M"
              paymentCard={paymentCard}
              type={PayoutPreferences.Instant}
              isDefault={
                payoutSettings.payoutPreferences ===
                  PayoutPreferences.Instant &&
                instantDestination &&
                instantDestination.destinationId === paymentCard.paymentCardId
              }
            />
          ))
        : debitCards.map(debitCard => (
            <PayoutMethod
              mb="M"
              debitCard={debitCard}
              type={PayoutPreferences.Instant}
              isDefault={
                payoutSettings.payoutPreferences ===
                  PayoutPreferences.Instant &&
                instantDestination &&
                instantDestination.destinationId === debitCard.cardId
              }
            />
          ))}
    </WSElement>
  ) : null;
};
