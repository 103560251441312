import {
  useIsMobile,
  WSAvatar,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IAuthorizedAccount,
  IMember,
  INewUser,
  IRedactedMember,
  IRedactedUser
} from "@wingspanhq/users";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useUserId } from "../../../../query/hooks/helpers";
import { useRequestPrincipalUser } from "../../../../query/users/mutations";
import {
  useAuthorizedAccounts,
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { ISessionCreateRequest } from "../../../../services/users";
import { getAuthorizedAccountName } from "../../../../shared/utils/teamUtils";
import { useResetWSStore } from "../../../../store";
import { CatchClickOutside } from "../../../../utils/useDidClickOutside";
import { Tooltip } from "../../../../components/Tooltip";
import styles from "../../../../components/SideNav/other.module.scss";
import { redirectByScope } from "../../utils/redirectByScope";
import { useModalPassword } from "../ModalPassword/useModalPassword";
import { useSwitchingAccount } from "../../utils/useSwitchingAccount";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";

export interface SwitchAccountsProps {
  isSideNavOpened: boolean;
  setIsSideNavOpened: (isDrawerOpened: boolean) => void;
}

interface ISelectedAccount
  extends Omit<IAuthorizedAccount, "accessibleMember" | "accessibleUser"> {
  accessibleMember: IMember | IRedactedMember;
  accessibleUser: INewUser | IRedactedUser;
}

export const SwitchAccounts: React.FC<SwitchAccountsProps> = ({
  isSideNavOpened,
  setIsSideNavOpened
}) => {
  const history = useHistory();
  const userId = useUserId();
  const qUser = useUserProfile(userId);
  const qMember = useMemberProfile(userId);
  const qAuthorizedAccounts = useAuthorizedAccounts(userId);
  const resetWSStore = useResetWSStore();
  const queryFeatureFlags = useFeatureFlags();

  const modalPassword = useModalPassword();

  const switchingAccount = useSwitchingAccount();

  const loggedInUserAccount = {
    userId,
    accessibleMember: qMember.data as IMember,
    accessibleUser: qUser.data as INewUser,
    allowedScopeGroupIds: []
  };
  const [
    requestPrincipalUser,
    requestPrincipalUserMeta
  ] = useRequestPrincipalUser();
  const [selectedAccount, setSelectedAccount] = useState<ISelectedAccount>(
    loggedInUserAccount
  );

  const onSwitchAccount = (account: ISelectedAccount) => {
    setSelectedAccount(account);
    const payload: ISessionCreateRequest = {
      email: account.accessibleMember.user.email
    };
    requestPrincipalUser(payload, {
      onSuccess: () => {
        setIsSideNavOpened(false);
        redirectByScope(account, history, resetWSStore);
      },
      onError: async err => {
        if (err.response?.status === 401) {
          const result = await modalPassword.open({
            account
          });

          if (result) {
            redirectByScope(account, history, resetWSStore);
          }
        }
      }
    });
  };

  return (
    <>
      <WSQueries
        queries={{ qAuthorizedAccounts, qMember, qUser, queryFeatureFlags }}
        onSuccess={({ queryFeatureFlags, qMember, qAuthorizedAccounts }) => {
          if (queryFeatureFlags.data.organizationAccounts) {
            switchingAccount({
              userId,
              accounts: qAuthorizedAccounts.data,
              member: qMember.data
            });
          }
        }}
        renderLoader={() => null}
        renderErrors={() => null}
      >
        {({ qAuthorizedAccounts, qMember, qUser, queryFeatureFlags }) => {
          if (qAuthorizedAccounts.data.length > 0) {
            const name = getAuthorizedAccountName(qMember.data, qUser.data);

            return (
              <Dropdown
                label={getAuthorizedAccountName(
                  selectedAccount.accessibleMember,
                  selectedAccount.accessibleUser
                )}
                setIsSideNavOpened={setIsSideNavOpened}
                isSideNavOpened={isSideNavOpened}
              >
                <WSFlexBox.CenterY
                  className={styles.mainDropdownItem}
                  p="S"
                  wrap="nowrap"
                  title={name}
                >
                  <WSAvatar.Text size="S" text={name.charAt(0)} mr="M" />
                  <WSText.ParagraphSm
                    color="gray600"
                    className={styles.ellipsis}
                  >
                    {name}
                  </WSText.ParagraphSm>
                </WSFlexBox.CenterY>
                <WSDivider />
                {qAuthorizedAccounts.data.map((acc: IAuthorizedAccount) => {
                  const accountName = getAuthorizedAccountName(
                    acc.accessibleMember,
                    acc.accessibleUser
                  );
                  return (
                    <WSFlexBox.CenterY
                      key={acc.userId + acc.accessibleMember.user.email}
                      onClick={() => onSwitchAccount(acc)}
                      className={styles.dropdownItem}
                      px="M"
                      wrap="nowrap"
                      title={accountName}
                      data-testid={accountName}
                    >
                      <WSAvatar.Text
                        size="S"
                        text={accountName.charAt(0)}
                        mr="M"
                      />
                      <WSText.ParagraphSm
                        color="gray600"
                        className={styles.ellipsis}
                      >
                        {accountName}
                      </WSText.ParagraphSm>
                    </WSFlexBox.CenterY>
                  );
                })}
              </Dropdown>
            );
          }
          return null;
        }}
      </WSQueries>
    </>
  );
};

interface DropdownProps extends WSElementProps {
  logo?: string;
  label: string;
  isSideNavOpened: boolean;
  setIsSideNavOpened: (isDrawerOpened: boolean) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  children,
  isSideNavOpened,
  setIsSideNavOpened
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isSideNavOpened) {
      setIsExpanded(false);
    }
  }, [isSideNavOpened]);

  return (
    <>
      {isSideNavOpened || isMobile ? (
        <WSElement className={styles.dropdownWrapper} px="XL" mb="XL">
          <WSFlexBox
            className={styles.trigger}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            data-testid="switchAccountMenu"
          >
            <WSFlexBox.CenterY wrap="nowrap" title={label}>
              <WSAvatar.Text size="S" text={label.charAt(0)} />
              <WSText.ParagraphSm
                ml="M"
                color="gray600"
                className={styles.ellipsis}
              >
                {label}
              </WSText.ParagraphSm>
            </WSFlexBox.CenterY>
            <WSIcon
              block
              name={isExpanded ? "caret-up" : "caret-down"}
              size="XS"
              color="gray700"
              data-testid="menu-arrow-indicator"
            />
            {isExpanded ? (
              <CatchClickOutside onClickOutside={() => setIsExpanded(false)}>
                {ref => (
                  <div ref={ref} className={styles.dropdownContent}>
                    {children}
                  </div>
                )}
              </CatchClickOutside>
            ) : null}
          </WSFlexBox>
        </WSElement>
      ) : (
        <Tooltip
          className={styles.tooltip}
          onTriggerClick={() => {
            setIsSideNavOpened(true);
            setIsExpanded(true);
          }}
          trigger={
            <WSFlexBox.Center>
              <WSAvatar.Text size="S" text={label.charAt(0)} />
            </WSFlexBox.Center>
          }
          placement="right-start"
        >
          <WSText color="white">Switch account</WSText>
        </Tooltip>
      )}
    </>
  );
};
