import {
  InputMask,
  InputMaskChangeEvent,
  InputMaskProps
} from "primereact/inputmask";
import React from "react";
import { getInputTestIdByName } from "../../../utils/getInputTestIdByName";
import { WSInputBaseProps } from "../../types/forms";
import { detachFieldProps, WSField, WSFieldProps } from "../WSField/WSField";
import {
  WSInputContainer,
  WSInputContainerProps
} from "../WSInputContainer/WSInputContainer";
import { fsExcludeCN } from "../../../utils/fsExcludeCN";

export type WSInputMaskProps = WSInputBaseProps<string, InputMaskChangeEvent> &
  Omit<WSInputContainerProps, "align" | "size"> &
  WSFieldProps & {
    mask?: InputMaskProps["mask"];
    slotChar?: InputMaskProps["mask"];
    autoClear?: InputMaskProps["autoClear"];
    unmask?: InputMaskProps["unmask"];
    readOnly?: InputMaskProps["readOnly"];
    onComplete?: InputMaskProps["onComplete"];
  };

export const WSInputMask = React.forwardRef<InputMask, WSInputMaskProps>(
  (props, ref) => {
    const {
      name,
      status,
      className,
      icon,
      iconRight,
      inputAction,
      disabled,
      onChange,
      inputClassName,
      fsExclude,
      ...otherProps
    } = props;

    const { fieldProps, ...inputProps } = detachFieldProps(otherProps);

    return (
      <WSField {...fieldProps} status={status}>
        <WSInputContainer
          status={status}
          icon={icon}
          iconRight={iconRight}
          inputAction={inputAction}
          disabled={disabled}
          className={className}
        >
          <InputMask
            ref={ref}
            disabled={disabled}
            onChange={(event) => {
              const value =
                event.value ||
                event.target.value ||
                (event.originalEvent?.target as HTMLInputElement)?.value;
              onChange(value, event);
            }}
            type="text"
            className={fsExcludeCN(inputClassName, fsExclude)}
            data-testid={getInputTestIdByName(name)}
            {...inputProps}
          />
        </WSInputContainer>
      </WSField>
    );
  }
);

WSInputMask.displayName = "WSInputMask";
