import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { WSQueries } from "../../../query/WSQuery";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";
import { useCollaboratorQuery } from "../../../query/payments/queries";
import { PayableForm } from "../../components/PayableForm/PayableForm";
import { usePayableQuery } from "./PayableDetails";

export const EditPayable: React.FC<RouteComponentProps<
  {
    payableId: string;
  },
  any,
  {
    backPath?: string;
  }
>> = ({ match, location }) => {
  const payableId = match.params.payableId;
  const payableQuery = usePayableQuery(payableId);
  const queryCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const collaboratorQuery = useCollaboratorQuery(
    payableQuery.data?.collaboratorId as string,
    {
      retry: false,
      enabled: !!payableQuery.data?.collaboratorId
    }
  );
  const backPath = location.state?.backPath;

  return (
    <WSQueries queries={{ payableQuery, collaboratorQuery, queryCustomFields }}>
      {({
        payableQuery: { data: payable },
        collaboratorQuery: { data: collaborator },
        queryCustomFields: { data: customFields }
      }) => (
        <PayableForm
          backPath={backPath}
          payable={payable}
          collaborator={collaborator}
          customFields={customFields}
        />
      )}
    </WSQueries>
  );
};
