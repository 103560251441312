import React from "react";
import {
  useModalContext,
  WSButton,
  WSButtons,
  WSFormOld,
  WSModal,
  WSSelectOld
} from "@wingspanhq/fe-component-library";
import { useCollaboratorGroupsQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { useAddCollaboratorToGroup } from "../../../query/payments/mutations";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";

export const ADD_COLLABORATOR_TO_GROUP_MODAL =
  "ADD_COLLABORATOR_TO_GROUP_MODAL";
interface AddCollaboratorToGroupModalProps {
  collaboratorId: string;
  collaboratorV2Id?: string;
}

export const AddCollaboratorToGroupModal: React.FC = () => {
  const { closeModal } = useModalContext();
  const qCollaboratorGroups = useCollaboratorGroupsQuery();

  const [
    addCollaboratorToGroup,
    addCollaboratorToGroupMeta
  ] = useAddCollaboratorToGroup();

  return (
    <WSModal
      name={ADD_COLLABORATOR_TO_GROUP_MODAL}
      size="M"
      title="Select Contractor Group"
    >
      {({
        collaboratorId,
        collaboratorV2Id
      }: AddCollaboratorToGroupModalProps) => (
        <WSQueries queries={{ qCollaboratorGroups }}>
          {({ qCollaboratorGroups: { data: collaboratorGroups } }) => (
            <WSFormOld<{
              collaboratorGroupId: string;
            }>
              onSubmit={async values => {
                const { collaboratorGroupId } = values;
                await addCollaboratorToGroup(
                  {
                    collaboratorId,
                    groupId: collaboratorGroupId
                  },
                  {
                    onSuccess: () => {
                      closeModal(ADD_COLLABORATOR_TO_GROUP_MODAL);
                    }
                  }
                );
              }}
            >
              {collaboratorGroups.length > 0 && (
                <>
                  <WSFormOld.Field
                    name="collaboratorGroupId"
                    label="Contractor group"
                    component={WSSelectOld}
                    componentProps={{
                      options: collaboratorGroups.map(collaboratorGroup => ({
                        label: collaboratorGroup.name,
                        value: (collaboratorGroup as any).collaboratorGroupId
                      }))
                    }}
                    mb="XL"
                  />
                  <WSErrorMessage
                    mb="XL"
                    error={addCollaboratorToGroupMeta.error}
                    contextKey="UpdateCollaborator"
                  />
                  <WSButtons mt="XL" format="modal">
                    <WSButton
                      name="addCollaborator"
                      ml="M"
                      loading={addCollaboratorToGroupMeta.isLoading}
                    >
                      Add to group
                    </WSButton>
                    <WSButton.Tertiary
                      onClick={() =>
                        closeModal(ADD_COLLABORATOR_TO_GROUP_MODAL)
                      }
                    >
                      Cancel
                    </WSButton.Tertiary>
                  </WSButtons>
                </>
              )}
            </WSFormOld>
          )}
        </WSQueries>
      )}
    </WSModal>
  );
};
