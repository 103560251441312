import {
  WSElement,
  WSFlexBox,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeResponse,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../../query/hooks/helpers";
import { useAccount, useClientQuery } from "../../../../query/users/queries";
import { useConfirmAutopayModal } from "./ConfirmAutopayModal";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { getPayeeNames } from "../../selectors/getPayeeNames";
import { BankAccount } from "../../../../shared/components/BankAccount";
import { BankCard } from "../../../../shared/components/BankCard";
import { FieldView } from "../FieldView";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorPaymentMethod } from "../../../../shared/components/BankCard/selectorPaymentMethod";

export const AutopayWidget: React.FC<{
  payee: IPayeeResponse;
  isInactive: boolean;
}> = ({ payee, isInactive }) => {
  const confirmAutopayModal = useConfirmAutopayModal();
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const userId = useUserId();
  const qClient = useClientQuery(userId);
  const _accountId = qClient.data?.profile?.defaultPaymentMethod?.accountId;

  const qAccount = useAccount(_accountId as string, {
    enabled: !!_accountId
  });

  const names = getPayeeNames(payee);
  const companyName = names.getResolvedName();

  return (
    <WSQueries
      queries={{ qClient }}
      renderLoader={() => null}
      renderErrors={() => null}
    >
      {({ qClientData }) => {
        const isEnabled =
          payee.payerOwnedData.autoPayStrategy ===
          PaymentRequirementStrategy.All;
        const clientProfile = qClientData.profile;
        const accountId = clientProfile.defaultPaymentMethod?.accountId;
        const cardId = clientProfile.defaultPaymentMethod?.paymentMethodId;
        const isAutopayPaymentMethodAvailable =
          !!qAccount.data ||
          !!selectorPaymentMethod(qClientData, cardId as string);

        if (!isAutopayPaymentMethodAvailable) {
          return null;
        }

        return (
          <WSElement>
            <WSSectionToolbar
              mb="S"
              title="Autopay"
              pill={
                isEnabled
                  ? {
                      text: "On",
                      theme: "success",
                      badge: true
                    }
                  : {
                      text: "Off",
                      theme: "neutral",
                      badge: true
                    }
              }
              button={
                isEnabled
                  ? {
                      label: "Turn off",
                      onAsyncClick: async () => {
                        await updatePayee({
                          payeeId: payee.payeeId,
                          payerOwnedData: {
                            autoPayStrategy: PaymentRequirementStrategy.None
                          }
                        });
                      },
                      disabled: isInactive
                    }
                  : {
                      label: "Turn on",
                      onClick: async () => {
                        confirmAutopayModal.open(
                          {
                            clientProfile,
                            payee
                          },
                          {
                            title: `Consent to paying ${companyName} automatically`
                          }
                        );
                      },
                      disabled: isInactive
                    }
              }
            />
            <WSPanel>
              <FieldView
                label="Payment Method"
                value={
                  accountId || cardId ? (
                    <WSFlexBox direction="column" gap="M" my="M">
                      {accountId && qAccount.data ? (
                        <BankAccount accountId={accountId} skipError />
                      ) : null}
                      {cardId && <BankCard type="credit" cardId={cardId} />}
                    </WSFlexBox>
                  ) : (
                    "None"
                  )
                }
              />

              <WSText.ParagraphSm mt="M" color="gray500">
                Turning autopay on will charge your chosen payment method on the
                due date of each invoice this contractor sends.
              </WSText.ParagraphSm>
            </WSPanel>
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
