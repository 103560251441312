export const QUERY_USERS_ACCOUNTS = "QUERY_USERS_ACCOUNTS";
export const QUERY_USERS_ACCOUNT = "QUERY_USERS_ACCOUNT";
export const QUERY_USERS_ACTIVITIES = "QUERY_USERS_ACTIVITIES";
export const QUERY_USERS_USER_PROFILE = "QUERY_USERS_USER_PROFILE";
export const QUERY_USERS_MEMBER_PROFILE = "QUERY_USERS_MEMBER_PROFILE";
export const QUERY_USERS_CLIENT = "QUERY_USERS_CLIENT";
export const QUERY_USERS_USER_TAG = "QUERY_USERS_USER_TAG";
export const QUERY_USERS_PUBLIC_USER_ID = "QUERY_USERS_PUBLIC_USER_ID";
export const QUERY_ADVOCATE = "QUERY_ADVOCATE";
export const QUERY_OCCUPATIONS = "QUERY_OCCUPATIONS";
export const QUERY_AUTHORIZED_ACCOUNTS = "QUERY_AUTHORIZED_ACCOUNTS";
export const QUERY_AUTHORIZATIONS = "QUERY_AUTHORIZATIONS";
export const QUERY_AUTHORIZATION = "QUERY_AUTHORIZATION";
export const QUERY_AUTHORIZATIONS_INFINITE = "QUERY_AUTHORIZATIONS_INFINITE";
export const QUERY_AUTHORIZATION_SCOPE_GROUPS =
  "QUERY_AUTHORIZATION_SCOPE_GROUPS";
export const QUERY_API_SESSION_LIST = "QUERY_API_SESSION_LIST";
export const QUERY_API_SESSION = "QUERY_API_SESSION";
export const QUERY_USERS_ACCOUNT_LINK_TOKEN = "QUERY_USERS_ACCOUNT_LINK_TOKEN";
export const QUERY_USERS_ORGANIZATION_USERS = "QUERY_USERS_ORGANIZATION_USERS";
export const QUERY_USERS_ALL_ORGANIZATION_USERS =
  "QUERY_USERS_ALL_ORGANIZATION_USERS";

export const QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE =
  "QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE";
export const QUERY_USERS_GET_ORGANIZATION_USER =
  "QUERY_USERS_GET_ORGANIZATION_USER";
export const QUERY_USERS_ORGANIZATION_USER = "QUERY_USERS_ORGANIZATION_USER";
export const QUERY_ACCOUNT_REQUIREMENTS = "QUERY_ACCOUNT_REQUIREMENTS";
