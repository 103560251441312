import { useHistory } from "react-router-dom";

export const redirectTo1099Onboarding = (
  history: ReturnType<typeof useHistory>
) => {
  if (history.location.pathname.startsWith("/member/1099-filing")) {
    return;
  } else {
    history.replace("/member/1099-filing/tax-information/verify-identity");
  }
};