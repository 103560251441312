import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSAlert,
  WSElement,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeEngagementResponse,
  IPayeeResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdatePayeeEngagement } from "../../../query/payeeEngagements/mutations/useUpdatePayeeEngagement";
import { FieldView } from "./FieldView";
import React from "react";

export interface ArchiveEngagementModalProps {
  payee: IPayeeResponse;
  engagement: IPayeeEngagementResponse;
  onClose: () => void;
}

export const ArchiveEngagementModal: React.FC<ArchiveEngagementModalProps> = ({
  payee,
  engagement,
  onClose
}) => {
  const history = useHistory();

  const { openSnackbar } = useWSSnackbar();
  const [updateEngagement, updateEngagementMeta] = useUpdatePayeeEngagement();

  return (
    <>
      <WSText.ParagraphSm mb="L" weight="book">
        Are you sure you want to archive this engagement?
      </WSText.ParagraphSm>
      <WSPanel>
        <WSFlexBox gap="XL">
          <FieldView label="Engagement" value={engagement.engagementName} />
          {/*
            <FieldView
              label="Type"
              value={getEngagementLabelByType(engagement.type, {
                descriptive: true
              })}
            />
          */}
        </WSFlexBox>
        {/* <FieldView
          mt="XL"
          label="Description"
          value={"TODO:description"}
        /> */}
      </WSPanel>

      <WSAlert
        my="2XL"
        theme="info"
        icon="info-circle"
        size="M"
        title="Archiving engagement"
      >
        After the engagement is archived, all contractors are made inactive
        within the engagement and no changes can be made to this engagement. No
        additional invoices can be created for this engagement.
        <WSElement mt="M" />
        Existing invoices will remain on the Invoicing page until you delete
        them. You can always restore the engagement when needed.
      </WSAlert>

      <WSErrorMessage
        error={updateEngagementMeta.error}
        mb="XL"
        contextKey="ArchiveClient"
      />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          },
          {
            label: "Archive engagement",
            loading: updateEngagementMeta.isLoading,
            onAsyncClick: async () => {
              await updateEngagement({
                payeeId: payee.payeeId,
                engagementId: engagement.payerPayeeEngagementId,
                status: PayerPayeeEngagementStatus.Inactive
              });
              onClose();
            }
          }
        ]}
      />
    </>
  );
};

export const useArchiveEngagementModal = () =>
  useWSModal(ArchiveEngagementModal, {
    title: "Archive engagement",
    size: "M"
  });
