import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFormOld,
  WSModal,
  WSTextArea,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useCreateCollaboratorsGroup } from "../../../../query/payments/mutations";

type Props = WSElementProps;

export const ADD_COLLABORATOR_GROUP_MODAL = "ADD_COLLABORATOR_GROUP_MODAL";

export const AddCollaboratorGroupModal: React.FC<Props> = () => {
  const { closeModal } = useModalContext();

  const [createGroup, createGroupMeta] = useCreateCollaboratorsGroup();

  return (
    <WSModal name={ADD_COLLABORATOR_GROUP_MODAL} size="S" title="Add group">
      <WSFormOld
        defaultValues={{
          name: "",
          description: ""
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          description: Yup.string().required("Description is required")
        })}
        onSubmit={async formData => {
          await createGroup(
            {
              name: formData.name,
              description: formData.description
            },
            {
              onSuccess() {
                closeModal(ADD_COLLABORATOR_GROUP_MODAL);
              }
            }
          );
        }}
      >
        <WSFormOld.Field
          mb="XL"
          label="Name"
          name="name"
          component={WSTextInput}
        />

        <WSFormOld.Field
          mb="XL"
          label="Description"
          name="description"
          component={WSTextArea}
        />

        <WSErrorMessage
          mt="XL"
          error={createGroupMeta.error}
          contextKey="CreateCollaboratorsGroup"
        />

        <WSButtons mt="3XL" forceFullWidth>
          <WSFormOld.SubmitButton data-testid="submit">
            Add group
          </WSFormOld.SubmitButton>
          <WSButton.Tertiary
            onClick={() => closeModal(ADD_COLLABORATOR_GROUP_MODAL)}
          >
            Cancel
          </WSButton.Tertiary>
        </WSButtons>
      </WSFormOld>
    </WSModal>
  );
};
