import {
  WSCopyText,
  WSElement,
  WSElementProps,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerResponse,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";

import styles from "./styles.module.scss";
import { getAutopayLink } from "../../../../Invoices/utils";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";

export interface AutoPayProps extends WSElementProps {
  client: IPayerResponse;
}

export const AutoPay: React.FC<AutoPayProps> = ({
  client,
  ...elementProps
}) => {
  const userId = useUserId();
  const qUserProfile = useUserProfile(userId);
  const clientInfo = wsName({
    user: {
      email: client.user?.email as string,
      profile: client.user?.profile
    },
    member: client.member,
    payeeOwnedData: client.payeeOwnedData
  });
  const isAutopayOn =
    client.payerOwnedData?.autoPayStrategy === PaymentRequirementStrategy.All;

  return (
    <WSQueries queries={{ qUserProfile }}>
      {({ qUserProfileData: userProfile }) => (
        <WSElement {...elementProps}>
          <WSSectionToolbar
            title="Autopay"
            pill={{
              theme: isAutopayOn ? "success" : "neutral",
              text: isAutopayOn ? "On" : "Off",
              badge: true
            }}
          />

          <WSPanel className={styles.panel}>
            <WSText.ParagraphSm weight="book" mb="XL">
              {isAutopayOn
                ? "Each invoice you send will be automatically paid on the due date unless this client disables Autopay."
                : "To set up Autopay, share this link with your client and they can set up automatic payments."}
            </WSText.ParagraphSm>

            <WSCopyText
              mt="M"
              isURL
              shareTitle="Autopay link"
              data={getAutopayLink({
                tag: userProfile.tag,
                companyName:
                  clientInfo.companyName ?? clientInfo.legalBusinessName ?? "",
                payeeId: userId,
                email: clientInfo.email
              })}
            />
          </WSPanel>
        </WSElement>
      )}
    </WSQueries>
  );
};
