import React, { ReactNode } from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSIcon, WSIconName } from "../../core";
import { WSText } from "../../core/WSText/WSText.component";
import styles from "./WSTimeline.module.scss";

export type WSTimelineItem = {
  date: Date;
  content: ReactNode;
  badges?: string[];
  icon?: WSIconName;
};

export type WSTimelineProps = {
  items: WSTimelineItem[];
} & WSElementProps;

export const WSTimeline: React.FC<WSTimelineProps> = ({
  items,
  ...elementProps
}) => (
  <WSElement {...elementProps} className={styles.timeline}>
    {items.map((item, index) => {
      const badges = item.badges || [];

      return (
        <WSElement key={String(index)} className={styles.item} mb="XL">
          {item.icon && (
            <WSIcon
              block
              name={item.icon}
              color="gray500"
              className={styles.icon}
            />
          )}

          <WSElement className={styles.badges}>
            {badges.map((badge) => (
              <WSText.ParagraphSm
                key={badge}
                className={styles.badge}
                color="gray400"
              >
                {badge}
              </WSText.ParagraphSm>
            ))}
            <WSText.ParagraphSm
              className={styles.badge}
              formatDate="monthDayYear"
              color="gray400"
            >
              {item.date}
            </WSText.ParagraphSm>
          </WSElement>

          <WSElement className={styles.content}>
            {typeof item.content === "string" ? (
              <WSText>{item.content}</WSText>
            ) : (
              item.content
            )}
          </WSElement>
        </WSElement>
      );
    })}
  </WSElement>
);
