import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSEmptyState } from "../WSEmptyState/WSEmptyState";
import { WSSidebar } from "../common";
import { WSButton, WSLoader, WSText } from "../core";
import { useIsMobile } from "../layout";
import { Option } from "./Option";
import { Search } from "./Search";
import styles from "./WSSelect.module.scss";
import { WSSelectOption, WSSelectProps } from "./types";

const getOptionKey = (option: WSSelectOption, index: number) =>
  option.value + option.label + index;

type Props = {
  options: WSSelectProps["options"];
  value: WSSelectProps["value"];
  onChange: WSSelectProps["onChange"];
  isListLoading?: WSSelectProps["isListLoading"];
  onReachEnd?: WSSelectProps["onReachMenuEnd"];
  footerText?: WSSelectProps["menuFooterText"];
  footerAction?: WSSelectProps["menuFooterAction"];

  searchText?: string;
  onSearchChange?: (searchText: string) => void;

  targetRef?: React.RefObject<HTMLDivElement>;
  onClose: () => void;
};

const Inner: React.FC<Props> = ({
  options,
  value,
  onChange,
  onClose,
  searchText,
  onSearchChange,
  isListLoading,
  onReachEnd,
  footerText,
  footerAction
}) => {
  const [cursor, setCursor] = useState(value ? 0 : -1);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCursor(
      value ? options.findIndex((option) => option.value === value) : -1
    );
  }, [options, value]);

  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    if (!optionsContainerRef.current) {
      return;
    }
    if (
      optionsContainerRef.current.scrollHeight ===
      optionsContainerRef.current.scrollTop +
        optionsContainerRef.current.offsetHeight
    ) {
      onReachEnd && onReachEnd();
    }
  }, [onReachEnd]);

  useEffect(() => {
    onScroll();

    const node = optionsContainerRef.current;
    node?.addEventListener("scroll", onScroll);

    return () => {
      node?.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowUp": {
          event.stopPropagation();
          event.preventDefault();

          setCursor((prev) => {
            if (prev === -1 || prev === 0) {
              return options.length - 1;
            }

            return prev - 1;
          });

          break;
        }
        case "ArrowDown": {
          event.stopPropagation();
          event.preventDefault();

          setCursor((prev) => {
            if (prev === -1 || prev === options.length - 1) {
              return 0;
            }

            return prev + 1;
          });
          break;
        }
        case "Enter": {
          event.stopPropagation();
          event.preventDefault();

          if (cursor === -1) {
            break;
          }

          onChange(options[cursor].value);
          onClose();
          break;
        }
        default:
          break;
      }
    },
    [cursor, onChange, onClose, options]
  );

  useEffect(() => {
    const wrapper = menuContainerRef.current;
    wrapper?.addEventListener("keydown", handleKeyDown);

    return () => {
      wrapper?.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, menuContainerRef]);

  return (
    <WSElement as="div" ref={menuContainerRef} className={styles.wrapper}>
      {onSearchChange && <Search text={searchText} onChange={onSearchChange} />}

      {!!searchText && options.length === 0 && !isListLoading ? (
        <WSEmptyState
          className={styles.emptyState}
          type="search"
          bgColor="gray"
          title={`No results for “${searchText}”`}
          description="Try adjusting your search to find what you’re looking for."
        />
      ) : (
        <WSElement className={styles.options} ref={optionsContainerRef}>
          {isListLoading ? (
            <WSLoader.Spinner size="S" my="M" />
          ) : (
            options.map((option, index) => (
              <Option
                key={getOptionKey(option, index)}
                {...option}
                onClick={() => {
                  onChange(option.value);
                  onClose();
                }}
                isActive={cursor === index}
              />
            ))
          )}
        </WSElement>
      )}

      {(footerText || footerAction) && (
        <WSElement className={styles.footer}>
          <WSText.ParagraphXs color="gray400">{footerText}</WSText.ParagraphXs>
          {footerAction && (
            <WSButton.Link
              size="S"
              type="button"
              {...footerAction}
              onClick={(event) => {
                footerAction.onClick?.(event);
                onClose();
              }}
            />
          )}
        </WSElement>
      )}
    </WSElement>
  );
};

type DropdownProps = {
  children: ReactNode;
  onClose: () => void;
  targetRef?: React.RefObject<HTMLDivElement>;
};

const Dropdown: React.FC<DropdownProps> = ({
  children,
  onClose,
  targetRef
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (
        !targetRef?.current?.contains(event.target as any) &&
        !ref.current?.contains(event.target as any)
      ) {
        onClose();
      }
    };

    window.addEventListener("mousedown", handleOutSideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [onClose, ref, targetRef]);

  return (
    <WSElement ref={ref} className={styles.dropdown}>
      {children}
    </WSElement>
  );
};

export const Menu: React.FC<Props> = (props) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <WSSidebar
        noCloseIcon
        closeOnEscape
        position="bottom"
        size="AUTO"
        contentElementProps={{
          colorBackground: "white",
          p: "NONE",
          className: styles.sidebarContent
        }}
        onClose={props.onClose}
      >
        <Inner {...props} />
      </WSSidebar>
    );
  }

  return (
    <Dropdown onClose={props.onClose} targetRef={props.targetRef}>
      <Inner {...props} />
    </Dropdown>
  );
};
