import { performVerification } from "../../../services/api/onboarding/verifications";
import { WSServiceError } from "../../../utils/serviceHelper";
import { WSMutationConfig, useWSMutation } from "../../helpers";
import { QUERY_VERIFICATION_MISSING_DATA } from "../queries/useQueryVerificationMissingFields";
import { QUERY_VERIFICATIONS } from "../queries/useQueryVerifications";

export const useMutationPerformVerification = (
  config?: WSMutationConfig<
    Awaited<ReturnType<typeof performVerification>>,
    WSServiceError,
    {
      verificationLevel: Parameters<typeof performVerification>[0];
    }
  >
) =>
  useWSMutation<
    Awaited<ReturnType<typeof performVerification>>,
    WSServiceError,
    {
      verificationLevel: Parameters<typeof performVerification>[0];
    }
  >(({ verificationLevel }) => performVerification(verificationLevel), {
    ...config,
    dependencies: [QUERY_VERIFICATIONS, QUERY_VERIFICATION_MISSING_DATA]
  });
