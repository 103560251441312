import {
  WSElement,
  WSGrid,
  WSLoader,
  WSPage,
  WSPageToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { Route, Switch, useHistory } from "react-router-dom";
import { Tabs } from "../../components/Tabs";
import { useBankingAccount } from "../../query/bookkeeping/queries";
import { Sidebar } from "./Sidebar";
import { PendingState } from "./components/PendingState";
import { RouteDashboard } from "./routes/RouteDashboard";
import { RouteTransactions } from "./routes/RouteTransactions";

export const Wallet = () => {
  const history = useHistory();
  const queryBankingAccount = useBankingAccount();

  if (queryBankingAccount.isLoading) {
    return (
      <WSPage>
        <WSText.Heading2 mb="2XL">Wingspan Wallet</WSText.Heading2>
        <WSLoader.Spinner />
      </WSPage>
    );
  }

  const isAvailable = !!queryBankingAccount.data?.internalAccountId;

  if (!isAvailable) {
    return (
      <WSPage>
        <WSText.Heading2 mb="2XL">Wingspan Wallet</WSText.Heading2>

        <WSGrid gutter="3XL">
          <WSGrid.Item span={{ m: "8" }}>
            <PendingState />
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "4" }}>
            <Sidebar />
          </WSGrid.Item>
        </WSGrid>
      </WSPage>
    );
  }

  return (
    <WSPage>
      <WSPageToolbar
        title="Wingspan Wallet"
        actions={[
          {
            buttonKind: "Primary",
            label: "Transfer",
            onClick: () => {
              history.push("/member/transfer?balance=wallet");
            }
          }
        ]}
      />

      <WSElement mb="2XL">
        <Tabs
          tabs={[
            {
              label: "Overview",
              path: "/member/wallet"
            },
            {
              label: "Transactions",
              path: "/member/wallet/transactions"
            }
          ]}
        />
      </WSElement>

      <Switch>
        <Route
          path="/member/wallet/transactions"
          component={RouteTransactions}
        />

        <Route path="/member/wallet" component={RouteDashboard} />
      </Switch>
    </WSPage>
  );
};
