import React from "react";
import { IMemberClientRequirementResponse } from "@wingspanhq/payments/dist/interfaces";
import {
  toWSDateString,
  WSElementProps,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import {
  useCountersignDocument,
  useDownloadClientDocument
} from "../../../../query/files/mutations";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import {
  RequirementStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export type EngagementRequirementsTableItem = IMemberClientRequirementResponse & {
  count?: number;
  engagementId: string;
};

export const EngagementRequirementsTable: React.FC<{
  requirements?: EngagementRequirementsTableItem[];
  noCounts?: boolean;
} & WSElementProps> = ({ requirements = [], noCounts, ...elementProps }) => {
  const [countersign, countersignMeta] = useCountersignDocument();
  const [downloadPdf, downloadPdfMeta] = useDownloadClientDocument();

  return (
    <>
      {(downloadPdfMeta.isLoading || countersignMeta.isLoading) && (
        <OverlaySpinner />
      )}

      <WSTable<EngagementRequirementsTableItem>
        tableData={requirements.map((requirement, index) => ({
          id: `engagement-${requirement.requirementType}${index}`,
          data: requirement
        }))}
        columns={[
          {
            config: {
              header: {
                text: "Requirement"
              },
              gridTemplateSizeMax: "1.5fr"
            },
            renderFunction: ({ data }) => (
              <WSTableCell
                text={data.document?.title}
                secondaryText={
                  data.status === RequirementStatus.Pending &&
                  data.document?.events?.memberSignedAt
                    ? "Needs your signature"
                    : data.status === RequirementStatus.Complete &&
                      data.document?.events?.clientSignedAt
                    ? "Completed " +
                      toWSDateString(
                        data.document.events.clientSignedAt,
                        "monthDayYear"
                      )
                    : data.status === RequirementStatus.Complete &&
                      data.document?.events?.memberSignedAt
                    ? "Completed " +
                      toWSDateString(
                        data.document.events?.memberSignedAt,
                        "monthDayYear"
                      )
                    : data.status === RequirementStatus.Complete
                    ? "Completed " + toWSDateString(new Date(), "monthDayYear")
                    : "Incomplete"
                }
              />
            )
          },
          {
            config: {
              gridTemplateSizeMax: "1fr",
              hideOnScreens: ["XS"],
              header: {
                text: "Type"
              }
            },
            renderFunction: ({ data }) => (
              <WSTableCell
                icon="pen"
                text={
                  data.requirementType === RequirementType.Signature
                    ? "e-Signature"
                    : "-"
                }
              />
            )
          },
          ...(noCounts
            ? []
            : ([
                {
                  config: {
                    hideOnScreens: ["XS"],
                    header: {
                      text: "Engagements"
                    }
                  },
                  renderFunction: ({ data }) => (
                    <WSTableCell text={`${data.count}`} />
                  )
                }
              ] as WSTableColumn<EngagementRequirementsTableItem>[])),
          {
            config: {
              header: {
                text: "Status"
              }
            },
            renderFunction: ({ data }) => (
              <WSTableCell
                pill={
                  data.status === RequirementStatus.Complete
                    ? {
                        theme: "success",
                        text: "Complete",
                        icon: true
                      }
                    : {
                        theme: "warning",
                        text: "Pending",
                        icon: true
                      }
                }
                secondaryText={
                  data.status === RequirementStatus.Pending
                    ? data.document?.events?.memberSignedAt &&
                      !data.document?.events?.clientSignedAt
                      ? "Your signature required"
                      : data.document?.events?.clientSignedAt &&
                        !data.document?.events?.memberSignedAt
                      ? "Awaiting contractor signature"
                      : ""
                    : ""
                }
              />
            )
          }
        ]}
        rowMenuActions={({ data }) => {
          const actions: WSMenuItem[] = [];

          if (
            data.document?.documentId &&
            data.status === RequirementStatus.Complete
          ) {
            actions.push({
              onClick: () => {
                downloadPdf({
                  documentId: data.document?.documentId!
                });
              },
              label: "Download PDF",
              icon: "download"
            });
          }

          if (
            data.status === RequirementStatus.Pending &&
            data.document?.events?.memberSignedAt
          ) {
            actions.push({
              onClick: () => {
                countersign({
                  documentId: data.document?.documentId,
                  collaboratorId: data.engagementId
                });
              },
              label: "Countersign",
              icon: "file"
            });
          }

          return actions;
        }}
        {...elementProps}
      />
    </>
  );
};
