import {
  toWSDateString,
  WSElement,
  WSElementProps,
  WSTable,
  WSTableCell,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  CalculationSettings1099Toggle,
  ICollaboratorSchema,
  IInvoice,
  IMemberClient
} from "@wingspanhq/payments/dist/interfaces";
import React, { useCallback } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";

import {
  useMemberClientsQuery,
  usePayrollSettings
} from "../../../query/payments/queries";
import {
  getClientName,
  getCurrentYearForm1099Balance
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { openInNewTab } from "../../../utils/openInNewTab";
import { InvoicesInvoiceDetails } from "../../screens/InvoicesInvoiceDetails";
import { calculateLineItemsTotal } from "../../utils";

type PayablesTableCollaboratorItemData = {
  client?: IMemberClient;
  invoice?: IInvoice;
};

type PayablesTableCollaboratorItem = WSTableItem<
  PayablesTableCollaboratorItemData
>;

interface Props extends WSElementProps {
  basePath: string;
  invoices: IInvoice[];
  year: keyof ICollaboratorSchema["form1099Balances"];
}
export const Payments1099CollaboratorTable: React.FC<Props> = ({
  basePath,
  invoices,
  year,
  ...elementProps
}) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const userId = useUserId();
  const qPayrollSettings = usePayrollSettings(userId);

  const memberClientsQuery = useMemberClientsQuery();

  const onView = useCallback(
    (item: PayablesTableCollaboratorItem) => {
      if (item.data.invoice) {
        history.push({
          pathname: `${history.location.pathname}/${item.id}`,
          search: location.search,
          state: {
            backPath: `${history.location.pathname}`
          }
        });
      }
    },
    [location.search]
  );

  const onViewInNewTab = useCallback(
    (item: PayablesTableCollaboratorItem) => {
      if (item.data.invoice) {
        openInNewTab(`/member/invoices/paid/${item.id}${location.search}`);
      }
    },
    [location.search]
  );

  return (
    <WSElement {...elementProps}>
      <Route
        path={`${match.path}/:invoiceId`}
        component={InvoicesInvoiceDetails}
        exact
      />
      <WSQueries queries={{ memberClientsQuery, qPayrollSettings }}>
        {({
          memberClientsQuery: { data: memberClients },
          qPayrollSettings: { data: payrollSettings }
        }) => {
          let data: PayablesTableCollaboratorItem[] = [
            ...invoices.map(invoice => ({
              id: invoice.invoiceId,
              data: {
                invoice,
                client: memberClients.find(
                  memberClient => memberClient.clientId === invoice.clientId
                )
              }
            }))
          ];
          return (
            <>
              <WSTable
                tableData={data}
                onRowClick={onView}
                onRowControlClick={onViewInNewTab}
                onRowMousewheelClick={onViewInNewTab}
                columns={[
                  {
                    config: {
                      gridTemplateSizeMax: "5fr",
                      header: {
                        text: "Deposited invoices"
                      }
                    },
                    renderFunction: ({ data: { invoice, client } }) =>
                      client && (
                        <WSTableCell
                          avatar={{
                            type: "icon",
                            icon: "check-double",
                            colorBackground: "green50",
                            color: "green500"
                          }}
                          text={`Invoice #${invoice?.invoiceNumber}`}
                          secondaryText={getClientName(client)}
                        />
                      )
                  },

                  {
                    config: {
                      gridTemplateSizeMax: "3fr",
                      header: {
                        text: "Non-Employment Compensation"
                      },
                      justify: "end"
                    },
                    renderFunction: ({ data: { invoice, client } }) => {
                      if (client) {
                        const lineItemsTotal = calculateLineItemsTotal(
                          invoice?.lineItems || []
                        );
                        let total = lineItemsTotal;
                        if (
                          payrollSettings.calculationSettings1099
                            ?.cardProcessingFees ===
                          CalculationSettings1099Toggle.Include
                        ) {
                          const currentForm1099Balance = getCurrentYearForm1099Balance(
                            client,
                            year
                          );
                          total =
                            lineItemsTotal +
                            (currentForm1099Balance?.paymentProcessingFees ??
                              0);
                        }
                        return (
                          <WSTableCell
                            text={String(total)}
                            secondaryText={`${
                              invoice?.events.estimatedDepositAt
                                ? "Paid on"
                                : "Paid outside of Wingspan on"
                            } ${toWSDateString(
                              invoice?.events.paidAt,
                              "monthDayYear"
                            )}`}
                          />
                        );
                      }
                      return null;
                    }
                  }
                ]}
              />
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
