type QueryParams = Record<
  string,
  | string
  | {
      in?: string[];
    }
>;

type FiltersValues = Record<string, string | string[]>;

export const queryParamsToFiltersValues: (
  params: QueryParams
) => FiltersValues = query => {
  const values: FiltersValues = {};

  Object.entries(query).forEach(([key, value]) => {
    if (typeof value === "string") {
      values[key] = value;
    } else if (Array.isArray(value["in"])) {
      values[key] = value["in"];
    }
  });

  return values;
};

export const filtersValuesToQueryParams: (
  params: FiltersValues
) => QueryParams = params => {
  const query: QueryParams = {};

  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === "string") {
      query[key] = value;
    } else if (Array.isArray(params[key])) {
      query[key] = { in: value };
    }
  });

  return query;
};
