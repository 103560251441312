import {
  WSElement,
  WSElementProps,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React from "react";
import styles from "./CollaboratorOnboardingStatusTimeline.module.scss";

export enum CollaboratorOnboardingTimeline {
  Invited = "Invited",
  SignedUp = "SignedUp",
  PayoutMethod = "PayoutMethod",
  Requirements = "Requirements"
}

const statuses = [
  {
    status: CollaboratorOnboardingTimeline.Invited,
    label: "Invited"
  },
  {
    status: CollaboratorOnboardingTimeline.SignedUp,
    label: "Signed Up"
  },
  {
    status: CollaboratorOnboardingTimeline.PayoutMethod,
    label: "Payout Method"
  },
  {
    status: CollaboratorOnboardingTimeline.Requirements,
    label: "Requirements"
  }
];

type Props = WSElementProps & {
  currentStatus?: keyof typeof CollaboratorOnboardingTimeline;
};

export const CollaboratorOnboardingStatusTimeline: React.FC<Props> = ({
  className,
  currentStatus,
  ...elementProps
}) => {
  return (
    <WSElement className={cn(styles.container, className)} {...elementProps}>
      {statuses.map(({ status, label }, index) => {
        const isActive = currentStatus
          ? statuses.findIndex(i => i.status === currentStatus) >= index
          : false;
        return (
          <WSElement className={styles.status} key={status}>
            {isActive ? (
              <WSIcon block name="check" color="green500" mb="XS" />
            ) : (
              <WSElement className={styles.dot} mb="XS" />
            )}

            <WSText.ParagraphXs color={isActive ? "green500" : "gray500"}>
              {label}
            </WSText.ParagraphXs>
          </WSElement>
        );
      })}
    </WSElement>
  );
};
