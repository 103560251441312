import {
  WSElement,
  WSElementProps,
  WSIcon,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSText,
  toWSDateString
} from "@wingspanhq/fe-component-library";
import { DocumentStatus } from "@wingspanhq/files/dist/lib/interfaces";
import {
  ICollaboratorSchema,
  IMemberClientRequirementResponse,
  MemberClientStatus,
  MemberTaxDocumentSharePermission
} from "@wingspanhq/payments/dist/interfaces";
import {
  RequirementStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import React from "react";
import { OverlaySpinner } from "../../../components/OverlaySpinner";
import { WSQueries } from "../../../query/WSQuery";
import {
  useCountersignDocument,
  useDownloadClientDocument
} from "../../../query/files/mutations";
import { useCollaboratorDownloadW9 } from "../../../query/payments/mutations";
import {
  useCollaboratorEventsQuery,
  useCollaboratorQuery
} from "../../../query/payments/queries";
import { getIsDomesticRedactedMember } from "../../../query/users/selectors";
import { getIsTaxDataVerified } from "../../../utils/collaborator";
import {
  CollaboratorOnboardingStatusTimeline,
  CollaboratorOnboardingTimeline
} from "../CollaboratorOnboardingStatusTimeline/CollaboratorOnboardingStatusTimeline";
import styles from "./CollaboratorOnboardingRequirements.module.scss";

type Props = { collaboratorId: string } & WSElementProps;

interface Requirement
  extends Omit<
    IMemberClientRequirementResponse,
    "requirementType" | "collaboratorGroupId"
  > {
  requirementType: "PayoutMethod" | "TaxForm" | RequirementType;
  events?: {
    completedAt?: Date;
  };
}

export const CollaboratorOnboardingRequirements: React.FC<Props> = ({
  collaboratorId,
  ...elementProps
}) => {
  const collaboratorQuery = useCollaboratorQuery(collaboratorId);
  const collaboratorEventsQuery = useCollaboratorEventsQuery(collaboratorId);

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ collaboratorQuery, collaboratorEventsQuery }}>
        {({
          collaboratorQuery: { data: collaborator },
          collaboratorEventsQuery: { data: collaboratorEvents }
        }) => {
          const requirements: Requirement[] = [
            {
              eligibilityRequirementId: "payoutMethod",
              requirementType: "PayoutMethod",
              status: collaboratorEvents.payoutMethodFirstAddedAt
                ? RequirementStatus.Complete
                : RequirementStatus.Pending,
              validFor: -1,
              clientId: collaborator.clientId,
              events: {
                completedAt: collaboratorEvents.payoutMethodFirstAddedAt
              }
            },
            {
              eligibilityRequirementId: "taxForm",
              requirementType: "TaxForm",
              status: getIsTaxDataVerified(collaboratorEvents)
                ? RequirementStatus.Complete
                : RequirementStatus.Pending,
              validFor: -1,
              clientId: collaborator.clientId,
              events: {
                completedAt: collaboratorEvents.taxDocumentationVerifiedAt
              }
            },
            ...((collaborator as any)
              .eligibilityRequirements as IMemberClientRequirementResponse[])
          ];

          const incompletedByClientRequirements = requirements.filter(
            requirement =>
              requirement.status === RequirementStatus.Complete &&
              (requirement.document
                ? requirement.document.status !== DocumentStatus.Complete
                : false)
          );

          const incompletedByMemberRequirements = requirements.filter(
            requirement => requirement.status !== RequirementStatus.Complete
          );

          const incompletedRequirements = [
            ...incompletedByClientRequirements,
            ...incompletedByMemberRequirements
          ];

          const completedRequirements = requirements.filter(
            requirement =>
              requirement.status === RequirementStatus.Complete &&
              (requirement.document
                ? requirement.document.status === DocumentStatus.Complete
                : true)
          );

          const eligibilityRequirementsComplete = requirements.every(
            requirement => requirement.status === RequirementStatus.Complete
          );

          return (
            <>
              <WSText.Heading5 mb="XL">Requirements</WSText.Heading5>

              {collaborator.status === MemberClientStatus.Active ? (
                <WSElement mb="XL" className={styles.completeStatus}>
                  <WSText.ParagraphSm>Complete</WSText.ParagraphSm>
                </WSElement>
              ) : (
                <WSElement mb="XL" className={styles.inProgressStatus}>
                  <WSText.ParagraphSm>In progress</WSText.ParagraphSm>
                </WSElement>
              )}

              <CollaboratorOnboardingStatusTimeline
                mb="XL"
                currentStatus={
                  eligibilityRequirementsComplete
                    ? CollaboratorOnboardingTimeline.Requirements
                    : collaboratorEvents.payoutMethodFirstAddedAt
                    ? CollaboratorOnboardingTimeline.PayoutMethod
                    : collaboratorEvents.signedUpAt
                    ? CollaboratorOnboardingTimeline.SignedUp
                    : CollaboratorOnboardingTimeline.Invited
                }
              />
              <RequirementsTable
                collaborator={collaborator}
                requirements={incompletedRequirements}
              />
              {completedRequirements.length > 0 && (
                <>
                  <WSText weight="medium" mt="XL" mb="M">
                    Completed requirements
                  </WSText>
                  <RequirementsTable
                    collaborator={collaborator}
                    requirements={completedRequirements}
                  />
                </>
              )}
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};

const RequirementsTable: React.FC<{
  collaborator: ICollaboratorSchema;
  requirements: Requirement[];
} & WSElementProps> = ({ collaborator, requirements, ...elementProps }) => {
  const [countersign, countersignMeta] = useCountersignDocument();
  const [downloadPdf, downloadPdfMeta] = useDownloadClientDocument();
  const [downloadW9, downloadW9Meta] = useCollaboratorDownloadW9();

  const isDomesticCollaborator = getIsDomesticRedactedMember(
    collaborator.member
  );

  return (
    <>
      {(downloadPdfMeta.isLoading ||
        countersignMeta.isLoading ||
        downloadW9Meta.isLoading) && <OverlaySpinner />}

      <WSTable
        tableData={requirements.map(i => ({
          id: i.eligibilityRequirementId,
          data: i
        }))}
        columns={[
          {
            config: {
              gridTemplateSizeMax: "5fr"
            },
            renderFunction: ({ data }) => (
              <WSTableCell
                avatar={{
                  type: "icon",
                  ...(data.requirementType === "PayoutMethod"
                    ? {
                        icon: "bank",
                        colorBackground: "gray50",
                        color: "gray500"
                      }
                    : data.requirementType === "TaxForm"
                    ? {
                        icon: "clipboard",
                        colorBackground: "gray50",
                        color: "gray500"
                      }
                    : {
                        icon: "paperclip",
                        colorBackground: "gray50",
                        color: "gray500"
                      })
                }}
                text={
                  data.requirementType === "PayoutMethod"
                    ? "Payout Method"
                    : data.requirementType === "TaxForm"
                    ? isDomesticCollaborator
                      ? "W-9 Tax Form"
                      : "W-8 Tax Form"
                    : data.document?.title
                }
                secondaryText={
                  data.status === RequirementStatus.Pending &&
                  data.document?.events?.memberSignedAt
                    ? "Needs your signature"
                    : data.status === RequirementStatus.Complete &&
                      data.document?.events?.clientSignedAt
                    ? "Completed " +
                      toWSDateString(
                        data.document.events.clientSignedAt,
                        "monthDayYear"
                      )
                    : data.status === RequirementStatus.Complete &&
                      data.document?.events?.memberSignedAt
                    ? "Completed " +
                      toWSDateString(
                        data.document.events?.memberSignedAt,
                        "monthDayYear"
                      )
                    : data.status === RequirementStatus.Complete &&
                      data.events?.completedAt
                    ? "Completed " +
                      toWSDateString(data.events?.completedAt, "monthDayYear")
                    : data.status === RequirementStatus.Complete
                    ? "Completed " + toWSDateString(new Date(), "monthDayYear")
                    : "Incomplete"
                }
              />
            )
          },
          {
            config: {
              gridTemplateSizeMax: "1fr",
              justify: "end"
            },
            renderFunction: ({ data }) =>
              data.status === RequirementStatus.Complete ? (
                data.document?.status === DocumentStatus.Pending ? (
                  <WSIcon block name="alert-circle" color="amber400" />
                ) : (
                  <WSIcon block name="check" color="green500" />
                )
              ) : (
                <WSIcon block name="alarm" color="gray400" />
              )
          }
        ]}
        rowMenuActions={({ data }) => {
          const actions: WSMenuItem[] = [];

          if (
            collaborator.memberData?.shareTaxDocument ===
              MemberTaxDocumentSharePermission.Allow &&
            isDomesticCollaborator &&
            data.requirementType === "TaxForm" &&
            data.status === RequirementStatus.Complete
          ) {
            actions.push({
              onClick: () => {
                downloadW9({
                  collaboratorId: collaborator.collaboratorId
                });
              },
              label: "Download PDF",
              icon: "download"
            });
          }

          if (
            data.document?.documentId &&
            data.status === RequirementStatus.Complete
          ) {
            actions.push({
              onClick: () => {
                downloadPdf({
                  documentId: data.document?.documentId || ""
                });
              },
              label: "Download PDF",
              icon: "download"
            });
          }

          if (
            data.status === RequirementStatus.Pending &&
            data.document?.events?.memberSignedAt
          ) {
            actions.push({
              onClick: () => {
                countersign({
                  documentId: data.document?.documentId,
                  collaboratorId: collaborator.collaboratorId
                });
              },
              label: "Countersign",
              icon: "file"
            });
          }

          return actions;
        }}
        {...elementProps}
      />
    </>
  );
};
