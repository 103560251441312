import {
  useModalContext,
  useWSModal,
  WSButton,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { PayoutMethodType } from "../../types/payments";
import {
  FormInputOnboardingOption,
  FormInputOnboardingOptionProps
} from "../FormInputOnboardingOption";
import { ModalInstantPayoutInfo } from "./ModalInstantPayoutInfo";
import { MODAL_WALLET_INFO, ModalWalletInfo } from "./ModalWalletInfo";
import { useShouldPauseAccount } from "../../hooks/useShouldPauseAccount";

type Props = {
  type: PayoutMethodType;
} & Omit<FormInputOnboardingOptionProps<PayoutMethodType>, "value" | "name">;

export const FormInputOnboardingPayoutMethod: React.FC<Props> = ({
  type,
  ...inputProps
}) => {
  const { openModal } = useModalContext();
  const modalInstantPayoutInfo = useWSModal(ModalInstantPayoutInfo, {
    size: "S"
  });
  const queryFeatureFlags = useFeatureFlags();
  const shouldPauseAccount = useShouldPauseAccount("wallet");

  if (type === PayoutMethodType.Instant) {
    return (
      <FormInputOnboardingOption
        mb="M"
        name="payoutMethodType"
        value={PayoutMethodType.Instant}
        title={
          <>
            Instant to Debit Card <WSText inline>(1% fee)</WSText>
          </>
        }
        {...inputProps}
      >
        <WSText>
          Fastest. Receive your payments immediately when sent.
          {queryFeatureFlags.data?.paymentCards && (
            <WSButton.Link
              onClick={() => {
                modalInstantPayoutInfo.open();
              }}
            >
              Learn more
            </WSButton.Link>
          )}
        </WSText>
      </FormInputOnboardingOption>
    );
  }

  if (type === PayoutMethodType.Wallet && !shouldPauseAccount) {
    return (
      <>
        <FormInputOnboardingOption
          mb="M"
          name="payoutMethodType"
          value={PayoutMethodType.Wallet}
          title={
            <>
              Wingspan Wallet <WSText inline>(free)</WSText>
            </>
          }
          {...inputProps}
        >
          <WSText>
            Faster. Free business bank & debit card.{" "}
            <WSButton.Link
              onClick={() => {
                openModal(MODAL_WALLET_INFO);
              }}
            >
              Learn more
            </WSButton.Link>
          </WSText>
        </FormInputOnboardingOption>

        <ModalWalletInfo />
      </>
    );
  }

  if (type === PayoutMethodType.ACH) {
    return (
      <FormInputOnboardingOption
        mb="XL"
        name="payoutMethodType"
        value={PayoutMethodType.ACH}
        title={
          <>
            Bank Account <WSText inline>(free)</WSText>
          </>
        }
        {...inputProps}
      >
        <WSText>Standard. Connect your existing bank account.</WSText>
      </FormInputOnboardingOption>
    );
  }

  if (type === PayoutMethodType.GiftCard) {
    return (
      <FormInputOnboardingOption
        mb="XL"
        name="payoutMethodType"
        value={PayoutMethodType.GiftCard}
        title={
          <>
            Gift card <WSText inline>(free)</WSText>
          </>
        }
        {...inputProps}
      >
        <WSText>Standard. 30+ available brands.</WSText>
      </FormInputOnboardingOption>
    );
  }

  return null;
};
