import * as Yup from "yup";

const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const validatorWebsite = Yup.string().test(
  "urlValidator",
  "Invalid URL",
  value => {
    if (value === undefined || value === null || value === "") {
      return true;
    }

    return urlRegex.test(value);
  }
);
