import { components } from "../../services/api/onboarding/types";

export const acknowledgementVersions: Record<
  components["schemas"]["AcknowledgementName"],
  string
> = {
  W9Certification: "2024-03-01",
  W8BenCertification: "2021-10-01",
  CanadianTaxPayerCertification: "2024-08-01",
  LocalTaxPayerCertification: "2024-08-01",
  WingspanTosAcceptance: "2024-07-22",
  WingspanPrivacyPolicyAcceptance: "2024-07-22",
  DepositAccountHolderAgreement: "2024-07-22",
  CashBackPromotionAgreement: "2022-10-04",
  DebitCardHolderAgreement: "2022-07-22",
  MoovTermsOfService: "2024-02-05",
  LeadBankTerms: "2022-08-09",
  ElectronicTaxFormConsent: "2024-08-01",
  ElectronicDisclosureAndConsent: "2024-08-01",
  CurrencyCloudTerms: "2021-07-19"
};
