import React, { useMemo } from "react";
import {
  ICollaboratorGroupResponse,
  IPayeeEngagementResponse,
  IPayeeResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  useWSSnackbar,
  WSElementProps,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { buildPayeeDetailsEngagementDetailsPath } from "../../utils";
import { useHistory } from "react-router-dom";
import {
  PayerPayeeStatus,
  PaymentsEligibility
} from "@wingspanhq/payments/dist/interfaces/payerPayee";
import { useUpdatePayeeEngagement } from "../../../../query/payeeEngagements/mutations/useUpdatePayeeEngagement";
import { useArchiveEngagementModal } from "../../components/ArchiveEngagementModal";
import { selectorPayeeEngagementName } from "../../selectors/selectorPayeeEngagements";

type TableItem = IPayeeEngagementResponse & {
  groupNames: string[];
};

export const EngagementsTable: React.FC<{
  payee: IPayeeResponse;
  engagements: IPayeeEngagementResponse[];
  groups: ICollaboratorGroupResponse[];
} & WSElementProps> = ({ payee, engagements, groups, ...elementProps }) => {
  const history = useHistory();
  const [updateEngagement, updateEngagementMeta] = useUpdatePayeeEngagement();
  const archiveEngagementModal = useArchiveEngagementModal();
  const { openSnackbar } = useWSSnackbar();

  const tableData = useMemo(() => {
    return engagements.map((engagement, index) => ({
      id: engagement.payerPayeeEngagementId,
      data: engagement
    }));
  }, [engagements]);

  return (
    <>
      <WSTable<IPayeeEngagementResponse>
        tableData={tableData}
        columns={[
          {
            config: {
              header: {
                text: "Name"
              },
              gridTemplateSizeMax: "1fr"
            },
            renderFunction: ({ data }) => {
              return (
                <WSTableCell
                  text={selectorPayeeEngagementName(data, groups, true)}
                />
              );
            }
          },
          // {
          //   config: {
          //     gridTemplateSizeMax: "1fr",
          //     hideOnScreens: ["XS"],
          //     header: {
          //       text: "Type"
          //     }
          //   },
          //   renderFunction: ({ data }) => (
          //     <WSTableCell text={getEngagementLabelByType(data.type)} />
          //   )
          // },
          {
            config: {
              hideOnScreens: ["XS"],
              header: {
                text: "Requirements"
              },
              gridTemplateSizeMax: "1fr"
            },
            renderFunction: ({ data }) => {
              const allLength = data.eligibilityRequirements?.length || 0;
              const completedLength =
                data.eligibilityRequirements?.filter(
                  r => r.status === RequirementStatus.Complete
                )?.length || 0;
              const isComplete = completedLength === allLength && allLength > 0;

              const secondaryText =
                data.paymentsEligibility === PaymentsEligibility.NotEligible
                  ? "Not eligible for payments"
                  : "Eligible for payments";
              const secondaryTextColor =
                data.paymentsEligibility === PaymentsEligibility.NotEligible
                  ? "amber400"
                  : undefined;

              if (data.status === PayerPayeeEngagementStatus.Inactive) {
                return (
                  <WSTableCell
                    pill={{
                      theme: "neutral",
                      text: "Archived",
                      icon: "archive"
                    }}
                  />
                );
              }

              if (allLength === 0) {
                return (
                  <WSTableCell
                    pill={{
                      theme: "neutral",
                      text: "None required",
                      icon: "info-circle"
                    }}
                    secondaryText={secondaryText}
                    secondaryTextColor={secondaryTextColor}
                  />
                );
              }

              return (
                <WSTableCell
                  secondaryText={secondaryText}
                  secondaryTextColor={secondaryTextColor}
                  pill={
                    isComplete
                      ? {
                          theme: "success",
                          text: `${completedLength} of ${allLength} Complete`,
                          icon: true
                        }
                      : {
                          theme: "warning",
                          text: `${completedLength} of ${allLength} Complete`,
                          icon: true
                        }
                  }
                />
              );
            }
          }
          // {
          //   config: {
          //     // gridTemplateSizeMax: "1fr",
          //     header: {
          //       text: "Status"
          //     }
          //   },
          //   renderFunction: ({ data }) => (
          //     <WSTableCell
          //       pill={
          //         data.status === PayerPayeeEngagementStatus.Active
          //           ? {
          //               theme: "success",
          //               text: "Active",
          //               icon: true
          //             }
          //           : {
          //               theme: "neutral",
          //               text: "Inactive",
          //               icon: "archive"
          //             }
          //       }
          //     />
          //   )
          // }
        ]}
        onRowClick={({ data }) => {
          history.push(
            buildPayeeDetailsEngagementDetailsPath(
              payee.payeeId,
              data.payerPayeeEngagementId
            )
          );
        }}
        rowMenuActions={({ data }) => {
          const actions: any = [];

          if (payee.payerOwnedData.status === PayerPayeeStatus.Active) {
            if (data.status !== PayerPayeeEngagementStatus.Inactive) {
              actions.push({
                onClick: async () => {
                  await archiveEngagementModal.open({
                    payee,
                    engagement: data
                  });
                },
                label: "Archive",
                icon: "archive"
              });
            } else {
              actions.push({
                onAsyncClick: async () => {
                  await updateEngagement(
                    {
                      payeeId: payee.payeeId,
                      engagementId: data.payerPayeeEngagementId,
                      status: PayerPayeeEngagementStatus.Active
                    },
                    {
                      onSuccess: () => {
                        openSnackbar({
                          message: "Engagement successfully restored",
                          type: "success"
                        });
                      },
                      onError: () => {
                        openSnackbar({
                          message: "Failed to restore engagement",
                          type: "error"
                        });
                      }
                    }
                  );
                },
                label: "Restore",
                icon: "repeat-circle"
              });
            }
          }
          return actions;
        }}
        // rowMenuActions={({ data }) => {
        //   const actions: WSMenuItem[] = [];

        //   if (
        //     payee.payeeOwnedData.shareTaxDocument ===
        //       PayeeTaxDocumentSharePermission.Allow &&
        //     isDomesticPayee &&
        //     data.requirementType === GeneralRequirementType.TaxVerification &&
        //     data.status === RequirementStatus.Complete
        //   ) {
        //     actions.push({
        //       onClick: () => {
        //         downloadW9({
        //           // TODO;
        //           collaboratorId: payee.payeeId
        //         });
        //       },
        //       label: "Download PDF",
        //       icon: "download"
        //     });
        //   }

        //   return actions;
        // }}
        {...elementProps}
      />
    </>
  );
};
