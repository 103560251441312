import * as React from "react";
import { useCallback, useContext, useState } from "react";
import { WSPortal } from "../core/WSPortal/WSPortal.component";
import {
  WSElement,
  WSElementColorsType
} from "../WSElement/WSElement.component";
import styles from "./WSSnackbar.module.scss";
import { WSContainer, WSFlexBox } from "../layout";
import { WSIcon, WSIconProps, WSText } from "../core";
import classNames from "classnames";

const ANIMATION_DELAY = 100;
const DURATION = 5000;

type WSSnackbarTheme = "light" | "dark";
type WSSnackbarType = "success" | "warning" | "error";

export type WSSnackbarProps = {
  id?: any;
  theme?: WSSnackbarTheme;
  type?: WSSnackbarType;
  icon?: WSIconProps;
  message: React.ReactNode;
  renderAction?(onClose: () => void): React.ReactNode;
  duration?: number;
};

export type WSSnackbarInnerProps = WSSnackbarProps & {
  onClose(id: any): void;
  isMounted: boolean;
};

export const WSSnackbarContext = React.createContext<{
  openSnackbar(snackbar: WSSnackbarProps): void;
  closeSnackbar(id: string): void;
}>({
  openSnackbar: () => {},
  closeSnackbar: () => {}
});

export const useWSSnackbar = () => useContext(WSSnackbarContext);

const ICON_COLOR_MAP: Record<
  WSSnackbarTheme,
  Record<WSSnackbarType, WSElementColorsType>
> = {
  dark: {
    success: "green200",
    error: "red200",
    warning: "amber200"
  },
  light: {
    success: "green400",
    error: "red400",
    warning: "amber400"
  }
};

export const WSSnackbar: React.FC<WSSnackbarInnerProps> = ({
  id,
  onClose,
  type,
  theme = "dark",
  message,
  icon,
  renderAction,
  isMounted = true
}) => {
  const defaultIconColor =
    type && theme
      ? ICON_COLOR_MAP[theme][type]
      : theme === "dark"
      ? "white"
      : "gray600";

  const defaultIcon =
    type === "success"
      ? "check-circle-fill"
      : type === "error"
      ? "alert-circle-fill"
      : "alert-fill";

  return (
    <WSFlexBox.CenterY
      alignItems="stretch"
      className={classNames(styles.snackbar, styles[theme], {
        [styles.snackbarMounted]: isMounted
      })}
      shadow="S"
      mt="M"
    >
      {icon || type ? (
        <WSElement className={styles.iconWrapper}>
          {icon ? (
            <WSIcon
              block
              size="M"
              className={styles.icon}
              color={defaultIconColor}
              {...icon}
            />
          ) : (
            <WSIcon
              className={styles.icon}
              block
              color={defaultIconColor}
              name={defaultIcon}
              size="M"
            />
          )}
        </WSElement>
      ) : null}
      <WSElement className={styles.textWrapper}>
        <WSText.Paragraph className={styles.text}>{message}</WSText.Paragraph>
      </WSElement>
      {renderAction ? (
        <WSFlexBox.CenterY gap="M" className={styles.actionWrapper}>
          {renderAction(() => onClose(id))}
        </WSFlexBox.CenterY>
      ) : null}
      {/*
    <WSElement className={styles.closeIconWrapper}>
      <WSIcon
        block
        className={styles.closeIcon}
        name="exit"
        size="M"
        onClick={() => onClose(id)}
        color={theme === "dark" ? "white" : "gray600"}
      />
    </WSElement>
    */}
    </WSFlexBox.CenterY>
  );
};

export const WSSnackbarProvider: React.FC = ({ children }) => {
  const [snackbars, setSnackbars] = useState<WSSnackbarInnerProps[]>([]);

  const openSnackbar = useCallback(
    (snackbar: WSSnackbarInnerProps) => {
      const id = snackbar.id ? snackbar.id : `${Date.now()}_${Math.random()}`;
      const duration = snackbar.duration ? snackbar.duration : DURATION;

      const delayTimeout = setTimeout(() => {
        setSnackbars((prev) =>
          prev.map((prevSnackbar) => {
            if (prevSnackbar.id === id) {
              return { ...prevSnackbar, isMounted: true };
            }
            return prevSnackbar;
          })
        );
      }, ANIMATION_DELAY);

      const durationTimeout = setTimeout(() => {
        setSnackbars((prev) => prev.filter((s) => s.id !== id));
      }, ANIMATION_DELAY + duration);

      setSnackbars((prev) => [...prev, { ...snackbar, isMounted: false, id }]);

      return () => {
        window.clearTimeout(delayTimeout);
        window.clearTimeout(durationTimeout);
      };
    },
    [setSnackbars]
  );

  const closeSnackbar = useCallback(
    (id: string) => {
      setSnackbars((prev) => prev.filter((s) => s.id !== id));
    },
    [setSnackbars]
  );

  return (
    <>
      <WSSnackbarContext.Provider
        value={{
          openSnackbar,
          closeSnackbar
        }}
      >
        {children}
      </WSSnackbarContext.Provider>
      {snackbars.length ? (
        <WSPortal>
          <WSContainer className={styles.snackbarContainer}>
            <WSElement className={styles.snackbarList} pb="XL">
              {snackbars.map((snackbar, index) => (
                <WSSnackbar
                  key={`${snackbar.id}+${index}`}
                  {...snackbar}
                  onClose={closeSnackbar}
                />
              ))}
            </WSElement>
          </WSContainer>
        </WSPortal>
      ) : null}
    </>
  );
};
