import {
  useModalContext,
  WSAvatar,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSModal,
  WSPill,
  WSTable,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import { DocumentRole, ITemplate } from "@wingspanhq/files/dist/lib/interfaces";
import { IEligibilityRequirement } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import React from "react";
import {
  Route,
  RouteComponentProps,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { DetailsLayout } from "../../../components/DetailsLayout/DetailsLayout";
import { useTemplatesQuery } from "../../../query/files/queries";
import {
  useCollaboratorGroupQuery,
  useEligibilityRequirementsQuery
} from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { openInNewTab } from "../../../utils/openInNewTab";
import { CollaboratorGroupAddRequirement } from "./CollaboratorGroupAddRequirement";
import { CollaboratorGroupRequirementDetails } from "./CollaboratorGroupRequirementDetails";
import { UPDATE_COLLABORATOR_GROUP_MODAL } from "./modals/UpdateCollaboratorGroupModal";

export const CollaboratorGroupDetails: React.FC<RouteComponentProps<{
  collaboratorGroupId: string;
}>> = ({
  match: {
    params: { collaboratorGroupId }
  }
}) => {
  const history = useHistory();
  const collaboratorGroupQuery = useCollaboratorGroupQuery(collaboratorGroupId);

  const { openModal } = useModalContext();

  return (
    <DetailsLayout
      onBack={() => {
        history.push("/member/invoices/contacts/collaborators/groups");
      }}
      title={collaboratorGroupQuery.data?.name || ""}
    >
      <Route
        path="/member/invoices/contacts/collaborators/groups/:collaboratorGroupId/add-requirement"
        component={CollaboratorGroupAddRequirement}
      />
      <Route
        path="/member/invoices/contacts/collaborators/groups/:collaboratorGroupId/requirements/:eligibilityRequirementId"
        component={CollaboratorGroupRequirementDetails}
      />
      <WSQueries queries={{ collaboratorGroupQuery }}>
        {({ collaboratorGroupQuery: { data: collaboratorGroup } }) => (
          <>
            <DetailsLayout.Card mb="XL">
              <WSFlexBox wrap="nowrap" justify="space-between">
                <WSElement>
                  <WSText weight="medium" mb="M">
                    {collaboratorGroup.name}
                  </WSText>
                  <WSText>{collaboratorGroup.description}</WSText>
                </WSElement>
                <WSButton.Link
                  onClick={() => {
                    openModal(UPDATE_COLLABORATOR_GROUP_MODAL, {
                      id: collaboratorGroupId,
                      name: collaboratorGroup.name,
                      description: collaboratorGroup.description
                    });
                  }}
                >
                  Edit
                </WSButton.Link>
              </WSFlexBox>
            </DetailsLayout.Card>

            <DetailsLayout.Card mb="XL">
              <WSText weight="medium" mb="M">
                Requirements
              </WSText>

              <RequirementsTable
                ids={(collaboratorGroup.eligibilityRequirements || []).map(
                  e => e.eligibilityRequirementId
                )}
              />
            </DetailsLayout.Card>

            <DetailsLayout.Card>
              <WSButton
                onClick={() => {
                  history.push(
                    `/member/invoices/contacts/collaborators/groups/${collaboratorGroupId}/add-requirement`
                  );
                }}
                fullWidth
                mb="M"
              >
                Add requirement
              </WSButton>

              <WSText.ParagraphSm color="gray500">
                Collaborators in this group will be required to meet these
                requirements before they can receive payments. You can still pay
                these collaborators, but the funds will be held until their
                requirements are complete. Collaborators only need to complete a
                requirement once, even if they are in multiple groups that
                require it.
              </WSText.ParagraphSm>
            </DetailsLayout.Card>
          </>
        )}
      </WSQueries>
    </DetailsLayout>
  );
};

const RequirementsTable: React.FC<{ ids: string[] }> = ({ ids }) => {
  const eligibilityRequirementsQuery = useEligibilityRequirementsQuery();
  const templatesQuery = useTemplatesQuery();
  const { openModal } = useModalContext();
  const history = useHistory();
  const {
    params: { collaboratorGroupId }
  } = useRouteMatch<{ collaboratorGroupId: string }>();

  return (
    <>
      <WSModal name="TAX_FORM_INFO_MODAL" title="Tax Forms" size="S">
        <WSText weight="medium" mb="M">
          W-9
        </WSText>
        <WSText mb="M">
          For U.S. citizens or other U.S. persons, Wingspan collects W-9
          information from your contractors before they are eligible for
          payment. Your collaborators are required to certify, under penalty of
          perjury, that the information provided for Form W-9 is accurate.
        </WSText>
        <WSButton.Link
          onClick={() => {
            openInNewTab(
              "https://www.irs.gov/businesses/small-businesses-self-employed/forms-and-associated-taxes-for-independent-contractors"
            );
          }}
          mb="XL"
        >
          Learn more about W-9
        </WSButton.Link>
        <WSText weight="medium" mb="M">
          W-8 BEN
        </WSText>
        <WSText mb="M">
          For foreign persons, Wingspan collects W-8 BEN information from your
          contractors before they are eligible for payment.
        </WSText>
        <WSButton.Link
          onClick={() => {
            openInNewTab("https://www.irs.gov/forms-pubs/about-form-w-8-ben");
          }}
        >
          Learn more about W-8
        </WSButton.Link>
      </WSModal>
      <WSModal name="PAYOUT_METHOD_INFO_MODAL" title="Payout Methods" size="S">
        <WSText mb="M">
          Wingspan supports two types of payouts: standard and instant.
        </WSText>
        <WSText mb="M">
          <b>Standard payouts</b> are processed over the ACH network. Usually,
          these payouts take 1-2 business days.
        </WSText>
        <WSText>
          <b>Instant payouts</b> are sent to an eligible debit card, and arrive
          in a few minutes.
        </WSText>
      </WSModal>

      <WSQueries queries={{ eligibilityRequirementsQuery, templatesQuery }}>
        {({
          eligibilityRequirementsQuery: { data: eligibilityRequirements },
          templatesQuery: { data: templates }
        }) => {
          const visibleEligibilityRequirements = eligibilityRequirements.filter(
            e => ids.includes(e.eligibilityRequirementId)
          );

          return (
            <WSTable<{
              eligibilityRequirement: IEligibilityRequirement;
              template?: ITemplate;
            }>
              tableData={[
                {
                  id: "payoutMethod",
                  data: {} as any
                },
                {
                  id: "taxForm",
                  data: {} as any
                },
                ...visibleEligibilityRequirements.map(
                  eligibilityRequirement => {
                    const template = templates.find(
                      t => t.templateId === eligibilityRequirement.templateId
                    );
                    return {
                      id: eligibilityRequirement.eligibilityRequirementId,
                      data: {
                        eligibilityRequirement,
                        template
                      }
                    } as WSTableItem;
                  }
                )
              ]}
              onRowClick={({ id, data }) => {
                if (id === "payoutMethod") {
                  openModal("PAYOUT_METHOD_INFO_MODAL");
                } else if (id === "taxForm") {
                  openModal("TAX_FORM_INFO_MODAL");
                } else {
                  history.push(
                    `/member/invoices/contacts/collaborators/groups/${collaboratorGroupId}/requirements/${data.eligibilityRequirement.eligibilityRequirementId}`
                  );
                }
              }}
              columns={[
                {
                  config: {
                    gridTemplateSizeMax: "3fr"
                  },
                  renderFunction: ({ id, data }) => (
                    <WSFlexBox alignItems="center">
                      <WSAvatar
                        mr="S"
                        type="icon"
                        {...((data.template?.roles || []).includes(
                          DocumentRole.Client
                        )
                          ? {
                              icon: "group",
                              color: "green500",
                              colorBackground: "green50"
                            }
                          : {
                              icon: "user",
                              color: "blue500",
                              colorBackground: "blue50"
                            })}
                      />
                      <WSElement>
                        <WSFlexBox.CenterY wrap="nowrap">
                          <WSText>
                            {id === "payoutMethod"
                              ? "Payout Method"
                              : id === "taxForm"
                              ? "Tax Form"
                              : data.template?.title}
                          </WSText>
                          {id === "payoutMethod" ? (
                            <WSIcon
                              block
                              ml="M"
                              name="info-circle"
                              color="gray500"
                              onClick={() => {
                                openModal("PAYOUT_METHOD_INFO_MODAL");
                              }}
                            />
                          ) : id === "taxForm" ? (
                            <WSIcon
                              block
                              ml="M"
                              name="info-circle"
                              color="gray500"
                              onClick={() => {
                                openModal("TAX_FORM_INFO_MODAL");
                              }}
                            />
                          ) : null}
                        </WSFlexBox.CenterY>
                        {id === "taxForm" && (
                          <WSText.ParagraphXs color="gray400" mt="XS">
                            W-9 or W-8 BEN
                          </WSText.ParagraphXs>
                        )}
                      </WSElement>
                    </WSFlexBox>
                  )
                },
                {
                  config: {
                    gridTemplateSizeMax: "1fr",
                    justify: "end"
                  },
                  renderFunction: ({ id }) =>
                    id === "payoutMethod" || id === "taxForm" ? (
                      <WSPill text="Default" />
                    ) : null
                }
              ]}
            />
          );
        }}
      </WSQueries>
    </>
  );
};
