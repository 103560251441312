import {
  WSButton,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { openIntercom } from "../../../shared/utils/intercom";
import { Layout } from "../../Onboarding/components/Layout";

export const RouteVerifySuccess = () => {
  const history = useHistory();

  return (
    <Layout
      title="Banking services update"
      onClose={() => {
        history.push("/member");
      }}
    >
      <WSList gap="L">
        <WSPanel>
          <WSText weight="medium">Migration complete</WSText>
          <WSText.ParagraphSm>
            Your consent to migrate your Wingspan account to Lead Bank has been
            recorded.
          </WSText.ParagraphSm>
        </WSPanel>

        <WSPanel>
          <WSInfoBox title="What to expect:" transparent>
            <ul>
              <li>
                Your account will be migrated after 2pm ET on the next available
                migration day (if you completed before 1pm ET today, that will
                be today; otherwise, it will be tomorrow)
              </li>
              <li>
                You will receive your new account details immediately after
                migration
              </li>
              <li>
                Your old account and debit card will be deactivated at the time
                of migration
              </li>
              <li>
                A new debit card will be available to order by the end of August
              </li>
            </ul>
          </WSInfoBox>
        </WSPanel>

        <WSPanel>
          <WSInfoBox title="Remember:" transparent>
            <ul>
              <li>Your funds remain FDIC insured throughout this process</li>
              <li>All account features will continue to function</li>
              <li>
                Your complete transaction history and statements will be fully
                transferred
              </li>
            </ul>
          </WSInfoBox>
        </WSPanel>

        <WSButton
          fullWidth
          onClick={() => {
            history.push("/member");
          }}
        >
          Close
        </WSButton>

        <WSText.ParagraphSm color="gray600">
          If you have any questions or concerns, you can find more information
          and connect with our <a onClick={openIntercom}>support team here</a>.
        </WSText.ParagraphSm>
      </WSList>
    </Layout>
  );
};
