import flatten from "lodash/flatten";
import { InfiniteQueryConfig } from "react-query";

import { getPayeeRows, IPayeeRow } from "../../../../services/search";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../../helpers";
import { mapPayeeRowsQueryFilter, RedactedPayeeRowsQuery } from "./utils";

const PAGE_SIZE = 20;

export const QUERY_PAYEE_ROWS = "QUERY_PAYEE_ROWS";

export const usePayeeRowsQuery = (
  params?: RedactedPayeeRowsQuery,
  config?: InfiniteQueryConfig<IPayeeRow[], WSServiceError>
) => {
  const query = useWSInfiniteQuery<IPayeeRow[], WSServiceError>(
    [QUERY_PAYEE_ROWS, params],
    (_, __, pageNumber = 1) => {
      return getPayeeRows({
        ...params,
        filter: mapPayeeRowsQueryFilter(params?.filter || {}),
        page: {
          size: PAGE_SIZE,
          number: pageNumber
        }
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data) : undefined
  };
};
