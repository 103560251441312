import {
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { useMemo } from "react";
import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import {
  IMemberClientRequirementResponse,
  PayerPayeeEngagementStatus,
  PaymentsEligibility,
  RequirementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { selectorPayerEngagementName } from "../../../Payees/selectors/selectorPayerEngagements";

export interface EngagementsTableProps {
  engagements: IPayerEngagementResponse[];
}

export const EngagementsTable: React.FC<EngagementsTableProps> = ({
  engagements = []
}) => {
  const history = useHistory();
  const match = useRouteMatch();

  const tableData = useMemo(() => {
    return engagements.map((engagement, index) => ({
      id: engagement.payerPayeeEngagementId,
      data: {
        engagement,
        index
      }
    }));
  }, [engagements]);

  const columns: Array<WSTableColumn> = [
    {
      config: {
        header: {
          text: "Name"
        }
      },
      renderFunction: rowItem => (
        <WSTableCell
          text={selectorPayerEngagementName(rowItem.data.engagement, true)}
        />
      )
    },
    /*
    {
      config: {
        header: {
          text: "Type"
        },
        hideOnScreens: ["XS"]
      },
      renderFunction: rowItem => (
        <WSTableCell
          text={getEngagementLabelByType(rowItem.data.engagement.type)}
        />
      )
    },
    */
    {
      config: {
        header: {
          text: "Requirements"
        }
      },
      renderFunction: rowItem => {
        const data = rowItem.data.engagement;
        if (data.status === PayerPayeeEngagementStatus.Inactive) {
          return (
            <WSTableCell
              pill={{
                theme: "neutral",
                text: "Archived",
                icon: "archive"
              }}
            />
          );
        }

        const allLength = data.eligibilityRequirements?.length || 0;
        const completedLength =
          data.eligibilityRequirements?.filter(
            (r: IMemberClientRequirementResponse) =>
              r.status === RequirementStatus.Complete
          )?.length || 0;
        const isComplete = completedLength === allLength && allLength > 0;

        const secondaryText =
          data.paymentsEligibility === PaymentsEligibility.NotEligible
            ? "Not eligible for payments"
            : "Eligible for payments";
        const secondaryTextColor =
          data.paymentsEligibility === PaymentsEligibility.NotEligible
            ? "amber400"
            : undefined;

        if (allLength === 0) {
          return (
            <WSTableCell
              pill={{
                theme: "neutral",
                text: "None required",
                icon: "info-circle"
              }}
              secondaryText={secondaryText}
              secondaryTextColor={secondaryTextColor}
            />
          );
        }

        return (
          <WSTableCell
            secondaryText={secondaryText}
            secondaryTextColor={secondaryTextColor}
            pill={
              isComplete
                ? {
                    theme: "success",
                    text: `${completedLength} of ${allLength} Complete`,
                    icon: true
                  }
                : {
                    theme: "warning",
                    text: `${completedLength} of ${allLength} Complete`,
                    icon: true
                  }
            }
          />
        );
        // const { eligibilityRequirements } = rowItem.data.engagement;
        // const completedCount = eligibilityRequirements.filter(
        //   (r: IMemberClientRequirementResponse) =>
        //     r.status === RequirementStatus.Complete
        // ).length;
        // if (eligibilityRequirements?.length === 0) {
        //   return <WSTableCell text="None" />;
        // }
        // return completedCount === eligibilityRequirements.length ? (
        //   <WSTableCell
        //     pill={{
        //       icon: "check-circle",
        //       text: `${completedCount} of ${eligibilityRequirements.length} complete`,
        //       theme: "success"
        //     }}
        //   />
        // ) : (
        //   <WSTableCell
        //     pill={{
        //       icon: "alert",
        //       text: `${completedCount} of ${eligibilityRequirements.length} complete`,
        //       theme: "warning"
        //     }}
        //   />
        // );
      }
    }
  ];

  return (
    <WSTable
      columns={columns}
      tableData={tableData}
      onRowClick={item => {
        history.push(`${match.url}/engagements/${item.id}`, {
          backPath: match.url
        });
      }}
      onRowControlClick={item => {
        openInNewTab(`${match.url}/engagements/${item.id}`);
      }}
      onRowMousewheelClick={item => {
        openInNewTab(`${match.url}/engagements/${item.id}`);
      }}
      data-testid="engagementsTable"
    />
  );
};
