import {
  WSAvatar,
  WSButton,
  WSButtons,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { PayoutDestinationType } from "@wingspanhq/payments/dist/interfaces";
import { IGrowthSourceNames } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { ContainerAccount } from "../../modules/PaymentMethods/components/ContainerAccount";
import { ContainerDebitCard } from "../../modules/PaymentMethods/components/ContainerDebitCard";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { usePayoutSettings } from "../../query/payments/queries";
import { useEnterpriseQuery } from "../../query/platform/queries";
import { useActivities } from "../../query/users/queries";
import { SetupRouteComponentProps } from "../../shared/components/FlowSetup";
import { LayoutPaymentsSetup } from "../../shared/components/LayoutPaymentsSetup";
import { selectorAccountFullName } from "../../shared/selectors/selectorAccountFullName";
import { selectorDebitCardFullName } from "../../shared/selectors/selectorDebitCardFullName";
import { selectorDefaultDestination } from "../../shared/selectors/selectorDefaultDestination";
import { useShouldPauseAccount } from "../../shared/hooks/useShouldPauseAccount";

export const InvoicesSetupComplete: React.FC<SetupRouteComponentProps> = () => {
  const userId = useUserId();
  const activityQuery = useActivities(userId);
  const payoutSettingsQuery = usePayoutSettings(userId);
  const enterpriseQuery = useEnterpriseQuery();
  const history = useHistory();
  const enterprise = enterpriseQuery.data;
  const shouldPauseAccount = useShouldPauseAccount("taxes");

  return (
    <LayoutPaymentsSetup>
      <WSQueries
        queries={{
          activityQuery,
          payoutSettingsQuery
        }}
      >
        {({ activityQuery: { data: activity } }) => {
          const isEnterprise =
            activity.context?.growthSource === IGrowthSourceNames.Enterprise;

          return (
            <>
              {isEnterprise && enterprise ? (
                <>
                  <WSText.Heading4 mb="XL" data-testid="heading">
                    You’re set up to receive payments from {enterprise.name}
                  </WSText.Heading4>

                  <DepositText />

                  <WSText mb="2XL">
                    {enterprise.name}'s partnership with Wingspan provides you
                    with free and optional benefits like tax withholding, income
                    tracking, identifying tax write-offs, and more.
                  </WSText>
                </>
              ) : (
                <>
                  <WSText.Heading4 mb="XS" data-testid="heading">
                    You’re ready to receive payments
                  </WSText.Heading4>

                  <DepositText />

                  <WSText mb="2XL">
                    Wingspan provides you with free benefits like tax
                    withholding, income tracking, identifying tax write-offs,
                    and more.
                  </WSText>
                </>
              )}

              {!shouldPauseAccount && <TaxesText mb="2XL" />}

              <WSButtons forceFullWidth>
                {!shouldPauseAccount && (
                  <WSButton
                    name="calculateTaxRate"
                    onClick={() => {
                      history.push("/member/taxes/onboarding/filing-status");
                    }}
                  >
                    Calculate estimated tax rate
                  </WSButton>
                )}
                <WSButton.Secondary
                  name="exploreWingspan"
                  onClick={() => {
                    history.push("/member/dashboard");
                  }}
                >
                  Explore Wingspan’s benefits
                </WSButton.Secondary>
              </WSButtons>
            </>
          );
        }}
      </WSQueries>
    </LayoutPaymentsSetup>
  );
};

const TaxesText: React.FC<WSElementProps> = props => (
  <WSElement {...props}>
    <WSAvatar.Icon icon="calculator" mb="XS" />
    <WSText weight="medium" mb="XS">
      Wingspan can set aside for taxes for free.
    </WSText>
    <WSText>
      Automatically set aside for taxes, every time you get paid. Pay your
      estimated taxes in one click. Withdraw or stop withholding at anytime.
    </WSText>
  </WSElement>
);

const DepositText: React.FC = () => {
  const userId = useUserId();
  const payoutSettingsQuery = usePayoutSettings(userId);
  if (!payoutSettingsQuery.data) {
    return null;
  }
  const destination = selectorDefaultDestination(payoutSettingsQuery.data);

  let text: React.ReactNode =
    "Your payments will be deposited to your Wingspan Wallet.";

  if (destination?.destinationType === PayoutDestinationType.Account) {
    text = (
      <ContainerAccount accountId={destination.destinationId}>
        {account => (
          <>{`Your payments will be deposited into your ${selectorAccountFullName(
            account
          )} account via direct deposit (ACH).`}</>
        )}
      </ContainerAccount>
    );
  } else if (destination?.destinationType === PayoutDestinationType.Card) {
    text = (
      <ContainerDebitCard cardId={destination.destinationId}>
        {debitCard => (
          <>{`Your payments will be instantly deposited to your ${selectorDebitCardFullName(
            debitCard
          )}`}</>
        )}
      </ContainerDebitCard>
    );
  } else if (destination?.destinationType === PayoutDestinationType.WeGift) {
    text =
      "You will receive payments as a gift card which will be delivered to your email address.";
  }

  return <WSText mb="M">{text}</WSText>;
};
