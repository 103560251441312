import { queryCache, QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import { IPayerEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { getPayerEngagements } from "../../../services/payerEngagements";
import { QUERY_PAYER_ENGAGEMENTS_LIST } from "../../../query/payerEngagements/keys";
import { useQueryPayerRowsAll } from "../../../query/search/payer/queries/useQueryPayerRowsAll";
import { IPayerRow } from "../../../services/search";
import { getPayerRowsWithRequirements } from "../selectors/getActivePayersWithEngagement";

export const QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS =
  "QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS";

export type AllEngagementsByPayerIds = Array<{
  engagements: IPayerEngagementListResponse;
  payer: IPayerRow;
}>;

export const useQueryAllPayersWithEngagements = (
  config?: QueryConfig<AllEngagementsByPayerIds, WSServiceError>
) => {
  const queryPayerRowsAll = useQueryPayerRowsAll(
    {},
    {
      onSuccess() {
        if (query.isIdle) query.refetch();
      }
    }
  );
  const payersWithRequirements = getPayerRowsWithRequirements(
    queryPayerRowsAll.data
  );

  const payerIds = payersWithRequirements.map(payer => payer.payerId);

  const query = useWSQuery<AllEngagementsByPayerIds, WSServiceError>(
    [QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS, payerIds],
    async () => {
      if (payerIds.length === 0) {
        return [];
      }

      const actions = payerIds.map((payerId, i) => () =>
        getPayerEngagements(payerId, {
          page: { size: 100, number: 1 }
        })
      );

      const allPages = await concurrentActions<IPayerEngagementListResponse>(
        actions,
        {
          concurrentLimit: 5
        }
      );

      return allPages.map((engagements, index) => {
        const payer = payersWithRequirements[index] as IPayerRow;

        queryCache.setQueryData(
          [QUERY_PAYER_ENGAGEMENTS_LIST, payer.payerId],
          engagements
        );

        return {
          payer,
          engagements
        };
      });
    },
    {
      ...config,
      enabled: payersWithRequirements.length > 0
    }
  );

  return query;
};
