import {
  detachLayoutProps,
  WSElement,
  WSFlexBox,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { OnboardingStep } from "../../types";
import styles from "./Stepper.module.scss";
import { StepPoint } from "./StepPoint";

interface StepperProps extends WSMarginProps {
  navigation: OnboardingStep[];
}

export function Stepper(props: StepperProps) {
  const location = useLocation();
  const { navigation, ...otherProps } = props;
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(navigation[0]);
  const [currentSubStep, setCurrentSubStep] = useState<OnboardingStep>();

  const { layoutProps } = detachLayoutProps(otherProps);

  useEffect(() => {
    const step = navigation.find(step => location.pathname.includes(step.slug));
    if (step) {
      setCurrentStep(step);
      let subStep;
      if (step.steps) {
        subStep = step.steps.find(
          stepL2 =>
            location.pathname.includes(`${step.slug}/${stepL2.slug}`) ||
            location.pathname.includes(`${step.slug}/complete`)
        );
      }
      setCurrentSubStep(subStep);
    }
  }, [location.pathname, navigation]);

  const activeSubIndex = useMemo(() => {
    if (location.pathname.includes("/complete")) {
      return (currentStep.steps?.length || 0) - 1;
    }
    return (
      currentStep.steps?.findIndex(subStep =>
        location.pathname.includes(`${currentStep.slug}/${subStep.slug}`)
      ) || 0
    );
  }, [location.pathname, currentStep]);

  return currentStep.steps ? (
    <WSPanel className={styles.stepper} {...layoutProps}>
      <WSFlexBox direction="column" mb="M" className={styles.stepTitles}>
        <WSText.ParagraphSm
          align="center"
          mb="XS"
          weight="medium"
          color="gray600"
        >
          {currentStep.title}
        </WSText.ParagraphSm>
        {currentSubStep ? (
          <WSText.ParagraphXs align="center" color="gray400" weight="medium">
            {`${activeSubIndex + 1}/${currentStep.steps?.length}`}:{" "}
            {currentSubStep?.title}
          </WSText.ParagraphXs>
        ) : null}
      </WSFlexBox>

      <WSElement className={styles.stepperContainer}>
        {currentStep.steps?.map((step, index) => {
          const isStepComplete = location.pathname.includes("/complete");
          const isActive = index === activeSubIndex && !isStepComplete;
          const isSubStepComplete = index < activeSubIndex || isStepComplete;

          return (
            <StepPoint
              key={String(index)}
              isActive={isActive}
              isComplete={isSubStepComplete}
            />
          );
        })}
      </WSElement>
    </WSPanel>
  ) : null;
}
