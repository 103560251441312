import { PayrollTableItem } from "./columns";
import { WSTableColumn } from "@wingspanhq/fe-component-library";
import { ReportsColumn } from "../../types";
import { formatTableValue } from "../../utils/formatTableValue";

export const selectorTableColumnsFromLabels = (
  columns: ReportsColumn<PayrollTableItem>[],
  data: PayrollTableItem[]
) => {
  const labelsColumnValue = "Line Item Labels";

  const filteredColumns = columns.filter(c => c.value !== labelsColumnValue);
  const isLabelsSelected = columns.length !== filteredColumns.length;

  const allLabelsKeys = isLabelsSelected
    ? data.reduce<string[]>((acc, item) => {
        Object.keys(item.lineItem.labels || {}).forEach(key => {
          if (!acc.includes(key)) {
            acc = [...acc, key];
          }
        });

        return acc;
      }, [])
    : [];

  return [
    ...filteredColumns.map(
      column =>
        ({
          config: {
            header: { text: column?.label || "-" }
          },
          renderFunction: rowData =>
            formatTableValue(column?.getTableCell(rowData.data))
        } as WSTableColumn<PayrollTableItem>)
    ),
    ...allLabelsKeys.map(
      key =>
        ({
          config: {
            header: { text: `Custom Field ${key}` }
          },
          renderFunction: rowData =>
            formatTableValue(rowData.data.lineItem.labels?.[key])
        } as WSTableColumn<PayrollTableItem>)
    )
  ];
};
