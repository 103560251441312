import * as Yup from "yup";
import { validatorCanadaQuebecEnterpriseNumber } from "../../../../shared/validators/validatorCanadaQuebecEnterpriseNumber";
import { validatorCompanyName } from "../../../../shared/validators/validatorCompanyName";
import { validatorName } from "../../../../shared/validators/validatorName";
import { validatorStringTextNumbersSymbols } from "../../../../shared/validators/validatorStringTextNumbersSymbols";
import { validatorStringTextOnly } from "../../../../shared/validators/validatorStringTextOnly";

const validatorPostalCode = Yup.string()
  .required("Required")
  .test("valid-postal-code", "Not valid", (value, context) => {
    if (!value) {
      return false;
    }

    const country = context.parent.country || "US";

    switch (country) {
      case "US":
        return /^\d{5}$/.test(value);
      case "CA":
        return /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(
          value
        );
      default:
        return true;
    }
  });

const addressSchema = Yup.object().shape({
  country: Yup.string()
    .nullable()
    .required("Required"),
  addressLine1: validatorStringTextNumbersSymbols.required("Required"),
  addressLine2: validatorStringTextNumbersSymbols,
  city: validatorStringTextOnly.required("Required"),
  state: Yup.string()
    .nullable()
    .when("country", {
      is: (country: string | null) => country === "US" || country === "CA",
      then: Yup.string()
        .nullable()
        .required("Required"),
      otherwise: Yup.string().nullable()
    }),
  postalCode: validatorPostalCode
});

const ownershipSchema = Yup.object().shape({
  stake: Yup.number()
    .nullable()
    .required("Required"),
  isRepresentative: Yup.boolean().when("stake", {
    is: (stake: number | null) => !!stake && stake < 25,
    then: Yup.boolean()
      .nullable()
      .isTrue("Required")
  })
});

const getAccountHolderSchema = (options?: {
  ssnProvided?: boolean;
  type?: "Individual" | "Business";
}) =>
  Yup.object().shape({
    firstName: validatorName.required("Required"),
    lastName: validatorName.required("Required"),
    jobTitle: validatorStringTextNumbersSymbols.required("Required"),
    taxId: options?.ssnProvided
      ? Yup.mixed()
      : Yup.string().required("Required"),
    birthday: Yup.date()
      .nullable()
      .required("Required"),
    address: addressSchema,
    isRepresentative: Yup.boolean().when("ownershipStake", {
      is: "less25",
      then: Yup.boolean().oneOf([true], "Required")
    }),
    ownership: ownershipSchema,
    canadaQuebecEnterpriseNumber:
      options?.type === "Individual"
        ? Yup.string().when("address", {
            is: (address: any) =>
              address
                ? address.country === "CA" && address.state === "QC"
                : false,
            then: validatorCanadaQuebecEnterpriseNumber,
            otherwise: Yup.string().nullable()
          })
        : Yup.string().nullable()
  });

const businessSchema = Yup.object().shape({
  legalBusinessName: validatorCompanyName.required("Required"),
  dba: validatorStringTextNumbersSymbols,
  taxClassification: Yup.string().when("address.country", {
    is: "US",
    then: Yup.string()
      .nullable()
      .required("Required"),
    otherwise: Yup.string().nullable()
  }),
  taxId: Yup.string().required("Required"),
  industry: Yup.string()
    .nullable()
    .required("Required"),
  website: Yup.string().when("noWebsite", {
    is: false,
    then: Yup.string()
      .url("Invalid URL")
      .required("Required"),
    otherwise: Yup.string()
  }),
  address: addressSchema,
  stateOfIncorporation: Yup.string().when("address.country", {
    is: (country: string | null) => country === "US" || country === "CA",
    then: Yup.string()
      .nullable()
      .required("Required"),
    otherwise: Yup.string().nullable()
  }),
  canadaQuebecEnterpriseNumber: Yup.string().when("address", {
    is: (address: any) =>
      address ? address.country === "CA" && address.state === "QC" : false,
    then: validatorCanadaQuebecEnterpriseNumber.required("Required"),
    otherwise: Yup.string().nullable()
  })
});

export const getValidationSchema = (options?: { ssnProvided?: boolean }) =>
  Yup.object().shape({
    country: Yup.string()
      .nullable()
      .required("Required"),
    type: Yup.string()
      .nullable()
      .required("Required"),
    business: Yup.object().when("type", {
      is: "Business",
      then: businessSchema
    }),
    accountHolder: Yup.object().when("type", {
      is: "Business",
      then: getAccountHolderSchema({ ...options, type: "Business" }),
      otherwise: getAccountHolderSchema({ ...options, type: "Individual" })
    })
  });
