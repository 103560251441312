import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { getEngagements } from "../../../services/engagements";
import { mapEngagementsFilters } from "./useEngagementListQuery";
import { QUERY_ENGAGEMENT_LIST_SIZE } from "../keys";

export const useEngagementListSizeQuery = (
  params?: any,
  config?: QueryConfig<number, WSServiceError>
) => {
  const { sort, ...filter } = params || {};

  return useWSQuery<number, WSServiceError>(
    [QUERY_ENGAGEMENT_LIST_SIZE, params],
    async (query, queryParams) => {
      const { summary } = await getEngagements({
        filter: mapEngagementsFilters(filter),
        page: { size: 1, number: 1 },
        sort
      });

      return summary.listSize;
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
