import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFormOld,
  WSModal,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateCollaborator } from "../../../query/payments/mutations";
import { useCollaboratorQuery } from "../../../query/payments/queries";
import { useUserProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { getChangedData } from "../../../utils/getChangedData";
import {
  getDefaultValuesForAdditionalData,
  getYupValidationSchema,
  prepareCollaboratorLabelsRequest
} from "../../../utils/jsonFormSchema";
import { prepareDynamicFormFields } from "../AddCollaboratorForm/AddCollaboratorForm";
import { selectorIsEnterpriseUser } from "../../../shared/selectors/selectorIsEnterpriseUser";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";

type Props = WSElementProps & {
  collaboratorId: string;
  collaboratorV2Id?: string;
};

export const EDIT_COLLABORATOR_ADDITIONAL_INFO_MODAL =
  "EDIT_COLLABORATOR_ADDITIONAL_INFO_MODAL";

export const EditCollaboratorAdditionalInfoModal: React.FC<Props> = ({
  collaboratorId,
  collaboratorV2Id
}) => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const collaboratorQuery = useCollaboratorQuery(collaboratorId);
  const [updateCollaborator, updateCollaboratorMeta] = useUpdateCollaborator(
    collaboratorId,
    collaboratorV2Id
  );
  const { closeModal } = useModalContext();
  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  return (
    <WSModal
      name={EDIT_COLLABORATOR_ADDITIONAL_INFO_MODAL}
      size="S"
      title="Custom fields"
    >
      <WSQueries
        queries={{
          collaboratorQuery,
          qCollaboratorCustomFields,
          userQuery
        }}
      >
        {({
          collaboratorQuery: { data: collaborator },
          qCollaboratorCustomFields: { data: collaboratorCustomFields },
          userQuery: { data: user }
        }) => {
          const additionalDataValidationSchema = getYupValidationSchema(
            collaboratorCustomFields
          );
          const isEnterpriseUser = selectorIsEnterpriseUser(user);

          return (
            <WSFormOld
              defaultValues={{
                contractorId: collaborator.clientData?.externalId,
                ...getDefaultValuesForAdditionalData(
                  collaboratorCustomFields,
                  collaborator.labels as any
                )
              }}
              onSubmit={values => {
                updateCollaborator(
                  getChangedData(collaborator, {
                    ...(isEnterpriseUser
                      ? {
                          clientData: {
                            externalId: values.contractorId
                          }
                        }
                      : {}),
                    labels: {
                      ...prepareCollaboratorLabelsRequest(
                        collaboratorCustomFields,
                        values
                      )
                    }
                  }),
                  {
                    onSuccess: () => {
                      closeModal(EDIT_COLLABORATOR_ADDITIONAL_INFO_MODAL);
                    }
                  }
                );
              }}
              validationSchema={Yup.object().shape({
                ...additionalDataValidationSchema
              })}
            >
              {isEnterpriseUser && (
                <>
                  <WSFormOld.Field
                    name="contractorId"
                    label="External Contractor ID (optional)"
                    component={WSTextInput}
                    mb="XL"
                  />
                </>
              )}
              {prepareDynamicFormFields(collaboratorCustomFields)}
              <WSErrorMessage
                mb="XL"
                error={updateCollaboratorMeta.error}
                contextKey="EditContact"
              />
              <WSButtons format="modal">
                <WSButton loading={updateCollaboratorMeta.isLoading}>
                  Update
                </WSButton>
                <WSButton.Tertiary
                  onClick={() => {
                    closeModal(EDIT_COLLABORATOR_ADDITIONAL_INFO_MODAL);
                  }}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </WSFormOld>
          );
        }}
      </WSQueries>
    </WSModal>
  );
};
