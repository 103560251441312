import {
  ICheckbookCard,
  IPayoutSettingsResponse
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorAccountFullName } from "../../../shared/selectors/selectorAccountFullName";
import { selectorDebitCardFullName } from "../../../shared/selectors/selectorDebitCardFullName";
import { selectorDefaultDestination } from "../../../shared/selectors/selectorDefaultDestination";
import { PayoutMethod } from "../steps/PayoutMethod";
import {
  OnboardingContext,
  OnboardingModulePayoutMethod,
  OnboardingStep
} from "../types";

const getStatus = (payoutSettings?: IPayoutSettingsResponse) => {
  if (payoutSettings && payoutSettings?.payoutDestinations.length > 0) {
    return "Complete";
  }

  return "None";
};

export const getPayoutMethodStepConfig = (
  _: OnboardingModulePayoutMethod,
  __: OnboardingContext,
  payoutSettings?: IPayoutSettingsResponse,
  accounts?: IAccount[],
  debitCards?: ICheckbookCard[]
): OnboardingStep => {
  const slug = "payout";

  const status = getStatus(payoutSettings);

  const accountText = getMethodText(payoutSettings, accounts, debitCards);

  return {
    title: "Add payout method",
    slug,
    component: PayoutMethod,

    status,
    result: {
      Complete: {
        title: "Your payout method was added",
        description: `The payout method was added and your payments will be deposited into your ${accountText} via direct deposit (ACH). Click continue for the next step.`
      }
    }
  };
};

const getMethodText = (
  payoutSettings?: IPayoutSettingsResponse,
  accounts?: IAccount[],
  debitCards?: ICheckbookCard[]
) => {
  const defaultDestination = payoutSettings
    ? selectorDefaultDestination(payoutSettings)
    : undefined;

  const matchingAccount = accounts?.find(
    account => account.accountId === defaultDestination?.destinationId
  );

  if (matchingAccount) {
    return selectorAccountFullName(matchingAccount) + " account";
  }

  const matchingDebitCard = debitCards?.find(
    debitCard => debitCard.cardId === defaultDestination?.destinationId
  );

  if (matchingDebitCard) {
    return selectorDebitCardFullName(matchingDebitCard);
  }

  return "account";
};
