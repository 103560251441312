import {
  WSButton,
  WSFlexBox,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { CURRENT_YEAR } from "../../modules/1099NECFiling/constants/currentYear";
import { useUserId } from "../../query/hooks/helpers";
import {
  getShouldForceToEnablePayments,
  getShouldForceToW9FlowByActivity,
  getShouldForceToW9FlowByTaxForms
} from "../../query/platform/selectors";
import { useCompleteEmailVerification } from "../../query/users/mutations";
import { paymentsService } from "../../services/payments";
import { usersService } from "../../services/users";
import { redirectTo1099Onboarding } from "../../shared/utils/redirectTo1099Onboarding";
import { useWSStore } from "../../store";

export const CompleteEmailVerification: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const userId = useUserId();
  const [
    completeEmailVerification,
    completeEmailVerificationMeta
  ] = useCompleteEmailVerification(userId);
  const queries = queryString.parse(location.search);
  const callbackUrl = queries.callbackUrl as string | null;
  const store = useWSStore();

  useEffect(() => {
    const verifyEmail = async () => {
      const request = {
        emailVerificationId: queries.emailVerificationId as string
      };
      await completeEmailVerification(request, {
        onSuccess: async () => {
          try {
            if (callbackUrl) {
              history.push(callbackUrl);
            } else {
              const activityPromise = usersService.activity.get(userId);
              const taxFormsPromise = paymentsService.taxForm.payeeList(
                CURRENT_YEAR
              );
              const [taxForms, activity] = await Promise.all([
                taxFormsPromise,
                activityPromise
              ]);

              if (
                getShouldForceToW9FlowByActivity(store, activity) &&
                getShouldForceToW9FlowByTaxForms(taxForms)
              ) {
                redirectTo1099Onboarding(history);
                return;
              } else if (getShouldForceToEnablePayments(activity)) {
                history.push("/member/cip");
              } else {
                history.replace("/member/dashboard");
              }
            }
          } catch (e) {
            history.replace("/member/dashboard");
            window.location.reload();
          }
        }
      });
    };
    verifyEmail();
  }, []);

  return (
    <OnboardingLayout>
      <WSFlexBox alignContent="center" alignItems="stretch" direction="column">
        {completeEmailVerificationMeta.isError ? (
          <>
            <WSText color="garnet">Email verification has failed.</WSText>
            <WSButton
              mt="M"
              onClick={() => history.push("/member/verify/email")}
            >
              Try again
            </WSButton>
          </>
        ) : (
          <>
            <WSLoader.Spinner size="L" mb="2XL" />
            <WSText color="gray700">
              Please wait a moment until we verify your email.
            </WSText>
          </>
        )}
      </WSFlexBox>
    </OnboardingLayout>
  );
};
