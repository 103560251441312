import React from "react";
import { WSModalProvider } from "../components/common/WSModal/WSModal.context";
import { WSSnackbarProvider } from "../components/WSSnackbar/WSSnackbar.component";
import { UseWSModalProvider } from "../components/common/useWSModal/WSModal.context";

export const WSProvider: React.FC = ({ children }) => (
  <WSSnackbarProvider>
    <WSModalProvider>
      <UseWSModalProvider>{children}</UseWSModalProvider>
    </WSModalProvider>
  </WSSnackbarProvider>
);
