import React from "react";

import styles from "./styles.module.scss";
import { WSQueries } from "../../../../query/WSQuery";
import { WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import { BulkBatchImportStatus } from "../BulkBatchImportStatus";
import { BulkResource } from "../../types";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { BulkBatchImportProgress } from "../BulkBatchImportProgress";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";

interface DefaultHeaderProps {
  bulkBatchId: string;
  bulkResource: BulkResource;
}

export function DefaultHeader({
  bulkBatchId,
  bulkResource
}: DefaultHeaderProps) {
  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId);

  return (
    <WSQueries queries={{ qBulkBatch }}>
      {({ qBulkBatch: { data: bulkBatch } }) => {
        return (
          <WSFlexBox direction="column">
            <WSFlexBox.CenterY
              className={styles.header}
              justify="space-between"
              mb="XL"
            >
              <WSText weight="medium" title={getUploadedFilename(bulkBatch)}>
                {getUploadedFilename(bulkBatch, { length: 35 })}
              </WSText>
              <BulkBatchImportStatus
                batchId={bulkBatchId}
                bulkResource={bulkResource}
              />
            </WSFlexBox.CenterY>

            <BulkBatchImportProgress
              batchId={bulkBatchId}
              bulkResource={bulkResource}
            />
          </WSFlexBox>
        );
      }}
    </WSQueries>
  );
}
