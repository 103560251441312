import {
  useIsMobile,
  WSField,
  WSFlexBox,
  WSForm,
  WSInputMask,
  WSInputText,
  WSList,
  WSSelect
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { US_STATES_OPTIONS } from "../../../constants/user";
import { SELECT_OPTIONS_CANADIAN_PROVINCES } from "../../../shared/constants/user";
import { validatorStringTextNumbersSymbols } from "../../../shared/validators/validatorStringTextNumbersSymbols";
import { validatorStringTextOnly } from "../../../shared/validators/validatorStringTextOnly";

export const getValidationSchemaAddress = (country: string) =>
  Yup.object().shape({
    addressLine1: validatorStringTextNumbersSymbols.required("Required"),
    addressLine2: validatorStringTextNumbersSymbols,
    city: validatorStringTextOnly.required("Required"),
    state:
      country === "US" || country === "CA"
        ? Yup.string()
            .nullable()
            .required("Required")
        : Yup.string().nullable(),
    postalCode: Yup.string()
      .required("Required")
      .test("valid-postal-code", "Not valid", value => {
        if (!value) {
          return false;
        }

        switch (country) {
          case "US":
            return /^\d{5}$/.test(value);
          case "CA":
            return /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(
              value
            );
          default:
            return true;
        }
      })
  });

export type FormDataAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string | null;
  postalCode: string;
};

type Props = {
  country: string;
  name: string;
  label?: string;
};

export const FormPartialAddress: React.FC<Props> = ({
  country,
  label,
  name
}) => {
  const isMobile = useIsMobile();
  return (
    <WSField as="div" label={label} required>
      <WSList gap="M">
        <WSForm.Field
          label="Street address"
          name={name + ".addressLine1"}
          component={WSInputText}
          componentProps={{
            placeholder: "Street address",
            fsExclude: true,
            required: true,
            autoComplete: "address-line1"
          }}
        />
        <WSForm.Field
          label="Street address line 2 (optional)"
          name={name + ".addressLine2"}
          component={WSInputText}
          componentProps={{
            placeholder: "Street address line 2",
            fsExclude: true,
            autoComplete: "address-line2"
          }}
        />

        {country === "US" ? (
          <WSFlexBox
            wrap="nowrap"
            gap="M"
            key={country + "-city-state-postal"}
            direction={isMobile ? "column" : "row"}
          >
            <WSForm.Field
              label="City"
              name={name + ".city"}
              component={WSInputText}
              componentProps={{
                placeholder: "City",
                required: true,
                autoComplete: "address-level2"
              }}
            />

            <WSForm.Field
              label="State"
              name={name + ".state"}
              component={WSSelect}
              componentProps={{
                placeholder: "State",
                internalSearch: true,
                options: US_STATES_OPTIONS,
                required: true
              }}
            />

            <WSForm.Field
              label="Zip code"
              name={name + ".postalCode"}
              component={WSInputMask}
              componentProps={{
                placeholder: "Zip code",
                mask: "99999",
                required: true,
                autoComplete: "postal-code"
              }}
            />
          </WSFlexBox>
        ) : country === "CA" ? (
          <WSFlexBox
            wrap="nowrap"
            gap="M"
            key={country + "-city-state-postal"}
            direction={isMobile ? "column" : "row"}
          >
            <WSForm.Field
              label="City"
              name={name + ".city"}
              component={WSInputText}
              componentProps={{
                placeholder: "City",
                required: true,
                autoComplete: "address-level2"
              }}
            />

            <WSForm.Field
              label="Province"
              name={name + ".state"}
              component={WSSelect}
              componentProps={{
                placeholder: "Province",
                internalSearch: true,
                options: SELECT_OPTIONS_CANADIAN_PROVINCES,
                required: true
              }}
            />

            <WSForm.Field
              label="Postal code"
              name={name + ".postalCode"}
              component={WSInputText}
              componentProps={{
                placeholder: "Postal code",
                required: true,
                autoComplete: "postal-code"
              }}
            />
          </WSFlexBox>
        ) : (
          <WSFlexBox
            wrap="nowrap"
            gap="M"
            key={country + "-city-state-postal"}
            direction={isMobile ? "column" : "row"}
          >
            <WSForm.Field
              label="City"
              name={name + ".city"}
              component={WSInputText}
              componentProps={{
                placeholder: "City / Town",
                required: true,
                autoComplete: "address-level2"
              }}
            />

            <WSForm.Field
              label="Region/state (optional)"
              name={name + ".state"}
              component={WSInputText}
              componentProps={{
                placeholder: "Region/state (optional)",
                autoComplete: "address-level1"
              }}
            />

            <WSForm.Field
              label="Postal code"
              name={name + ".postalCode"}
              component={WSInputText}
              componentProps={{
                placeholder: "Postal code",
                required: true,
                autoComplete: "postal-code"
              }}
            />
          </WSFlexBox>
        )}
      </WSList>
    </WSField>
  );
};
