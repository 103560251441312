import {
  WSElement,
  WSLayout,
  WSLayoutProps,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  headTitle?: string;
  description?: string;
  narrow?: boolean;
  whiteBackground?: boolean;
} & WSLayoutProps;

export const LayoutFullscreen: React.FC<Props> = ({
  children,
  headTitle,
  title,
  description,
  narrow,
  whiteBackground,
  ...props
}) => (
  <WSLayout
    line
    title={headTitle}
    {...props}
    contentClassName={classNames(styles.content, {
      [styles.whiteBackground]: whiteBackground
    })}
  >
    <WSElement
      className={classNames(styles.container, {
        [styles.narrow]: narrow
      })}
    >
      {title || description ? (
        <WSElement mb="2XL">
          {title && <WSText.Heading5>{title}</WSText.Heading5>}
          {description && (
            <WSText mt="M" color="gray500">
              {description}
            </WSText>
          )}
        </WSElement>
      ) : null}

      {children}
    </WSElement>
  </WSLayout>
);
