import {
  WSButton,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSCentered,
  WSIconList,
  WSPanel,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useCollaboratorGroupsQuery,
  useCollaboratorsQuery
} from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { useUpdateBulkCollaboratorBatch } from "../../../modules/BulkImporter/query/bulkCollaborator/mutations";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";

export interface BulkUploadCollaboratorsStep2Props {}

export const defaultCollaboratorGroupOption = {
  label: "Do not add contractors to group",
  value: "NONE"
};

export const BulkUploadCollaboratorsStep2: React.FC<BulkUploadCollaboratorsStep2Props> = () => {
  useBrowserPageTitle("Wingspan - Bulk upload contractors");
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const bulkBatchId = match.params.bulkBatchId;

  const [selectedCollaboratorGroup, setSelectedCollaboratorGroup] = useState(
    defaultCollaboratorGroupOption
  );

  const qCollaborators = useCollaboratorsQuery();
  const qFeatureFlags = useFeatureFlags();
  const qCollaboratorGroups = useCollaboratorGroupsQuery();

  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const [
    updateBulkCollaboratorBatch,
    updateBulkCollaboratorBatchMeta
  ] = useUpdateBulkCollaboratorBatch();
  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];

  const requiredFields = ["First Name", "Last Name", "Email"];
  const optionalFields = ["Phone number", "External Contractor ID"];

  collaboratorCustomFields.forEach(field => {
    if (field.required) {
      requiredFields.push(field.name);
    } else {
      optionalFields.push(field.name);
    }
  });

  const title = qFeatureFlags.data?.bulkImporterCollaborator
    ? "Import contractors with a spreadsheet"
    : "Bulk upload contractors with a spreadsheet";

  const gotoBulkBatchDetails = async () => {
    if (selectedCollaboratorGroup.value === "NONE") {
      history.push(`/member/imports/collaborators/${bulkBatchId}`);
    } else {
      await updateBulkCollaboratorBatch(
        {
          id: bulkBatchId,
          data: {
            labels: {
              collaboratorGroupId: selectedCollaboratorGroup.value
            }
          }
        },
        {
          onSuccess: () => {
            history.push(`/member/imports/collaborators/${bulkBatchId}`);
          }
        }
      );
    }
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 3) * 2}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          {qFeatureFlags.data?.bulkImporterCollaborator ? null : (
            <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
              2 of 3
            </WSText.ParagraphSm>
          )}
          <WSText.Heading4>{title}</WSText.Heading4>
          <WSPanel mt="2XL">
            <WSQueries queries={{ qCollaborators, qCollaboratorGroups }}>
              {({ qCollaboratorGroups: { data: collaboratorGroups } }) => {
                const collaboratorGroupOptions = [
                  defaultCollaboratorGroupOption,
                  ...collaboratorGroups.map(collaboratorGroup => ({
                    label: collaboratorGroup.name,
                    value: (collaboratorGroup as any).collaboratorGroupId
                  }))
                ];

                const onSelectCollaboratorGroup = (value: any) => {
                  const collaboratorGroup = collaboratorGroupOptions.find(
                    cg => cg.value === value
                  );
                  setSelectedCollaboratorGroup(
                    collaboratorGroup ?? defaultCollaboratorGroupOption
                  );
                };

                return (
                  <>
                    <WSText>
                      Don't worry if your spreadsheet has other details, but
                      please make sure it contains the following columns.
                    </WSText>
                    <WSFlexBox mt="2XL" mb="XL">
                      <WSElement style={{ flex: 1 }}>
                        <WSText weight="medium" mb="M">
                          Required
                        </WSText>
                        <WSIconList.Checks items={requiredFields} mb="XL" />
                      </WSElement>
                      <WSElement style={{ flex: 1 }}>
                        <WSText weight="medium" mb="M">
                          Optional
                        </WSText>
                        <WSIconList.Checks items={optionalFields} mb="XL" />
                      </WSElement>
                    </WSFlexBox>

                    {collaboratorGroups.length > 0 && (
                      <WSFormOld
                        defaultValues={{
                          collaboratorGroupId: selectedCollaboratorGroup.value
                        }}
                      >
                        <WSFormOld.Field
                          name="collaboratorGroupId"
                          label="Contractor group"
                          component={WSSelectOld}
                          componentProps={{
                            options: collaboratorGroupOptions,
                            onChange: onSelectCollaboratorGroup
                          }}
                          my="XL"
                        />
                      </WSFormOld>
                    )}

                    <WSButton
                      name="bulkImportCollaboratorsStep2"
                      icon="upload"
                      fullWidth
                      onClick={gotoBulkBatchDetails}
                      loading={updateBulkCollaboratorBatchMeta.isLoading}
                    >
                      Import contractors
                    </WSButton>
                  </>
                );
              }}
            </WSQueries>
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
