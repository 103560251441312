import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPill,
  WSText,
  useModalContext,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import {
  ICollaboration,
  ICollaboratorSchema
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import { WSQueries } from "../../../query/WSQuery";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useAddCollaboratorToGroup,
  useRemoveCollaboratorFromGroup
} from "../../../query/payments/mutations";
import { useCollaboratorGroupsQuery } from "../../../query/payments/queries";
import {
  REMOVE_COLLABORATOR_FROM_GROUP,
  RemoveCollaboratorFromGroupModal
} from "./RemoveCollaboratorFromGroupModal";

type Props = {
  collaboratorName: string;
  collaboration: ICollaboration | ICollaboratorSchema;
} & WSElementProps;

export const CollaboratorsGroupsForm: React.FC<Props> = ({
  collaboration,
  collaboratorName,
  ...elementProps
}) => {
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const qCollaboratorGroups = useCollaboratorGroupsQuery();
  const qFeatureFlag = useFeatureFlags();
  const { openSnackbar } = useWSSnackbar();
  const { openModal } = useModalContext();

  const [
    addCollaboratorToGroup,
    addCollaboratorToGroupMeta
  ] = useAddCollaboratorToGroup();

  const [
    removeCollaboratorFromGroup,
    removeCollaboratorFromGroupMeta
  ] = useRemoveCollaboratorFromGroup();

  return (
    <WSElement
      {...elementProps}
      shimmer={
        addCollaboratorToGroupMeta.isLoading ||
        removeCollaboratorFromGroupMeta.isLoading
      }
    >
      <WSQueries queries={{ qCollaboratorGroups, qFeatureFlag }}>
        {({
          qCollaboratorGroups: { data: allGroups },
          qFeatureFlag: { data: featureFlags }
        }) => {
          const addedGroups = allGroups.filter(group =>
            (collaboration.collaboratorGroupIds || []).includes(
              group.collaboratorGroupId
            )
          );

          const availableToAddGroups = allGroups.filter(
            group =>
              !addedGroups
                .map(g => g.collaboratorGroupId)
                .includes(group.collaboratorGroupId)
          );

          const noAddedGroups = !addedGroups.length;

          return (
            <>
              <WSFlexBox.CenterY justify="space-between" mb="M">
                <RemoveCollaboratorFromGroupModal />
                <WSText.ParagraphSm color="gray500">Groups</WSText.ParagraphSm>
                {allGroups.length && (addedGroups.length || isEdit) ? (
                  <WSButton.Link
                    onClick={
                      isEdit
                        ? async () => {
                            setIsEdit(false);
                            setIsAdding(false);
                          }
                        : () => {
                            setIsEdit(true);
                          }
                    }
                  >
                    {isEdit ? "Done" : "Edit"}
                  </WSButton.Link>
                ) : null}
              </WSFlexBox.CenterY>

              {allGroups.length ? (
                <>
                  {noAddedGroups && isEdit ? (
                    <WSFlexBox.Center my="XS">
                      <WSText.ParagraphSm color="gray400">
                        No added groups
                      </WSText.ParagraphSm>
                    </WSFlexBox.Center>
                  ) : null}

                  {addedGroups.map(group => (
                    <WSPill
                      key={group.collaboratorGroupId}
                      mr="M"
                      mb="M"
                      px="XS"
                      theme="success"
                      text={group.name}
                      onClick={
                        isEdit
                          ? undefined
                          : () => {
                              history.push(
                                `/member/invoices/contacts/collaborators/groups/${group.collaboratorGroupId}`
                              );
                            }
                      }
                      onDismiss={
                        isEdit
                          ? () => {
                              openModal(REMOVE_COLLABORATOR_FROM_GROUP, {
                                collaboratorName,
                                collaboratorId: collaboration.collaboratorId,
                                groupName: group.name,
                                groupId: group.collaboratorGroupId
                              });
                            }
                          : undefined
                      }
                    />
                  ))}

                  {isEdit || noAddedGroups ? (
                    <>
                      {isAdding ? (
                        <>
                          <WSElement my="XS">
                            <WSText.ParagraphSm color="gray500" mb="M">
                              Available groups
                            </WSText.ParagraphSm>
                            {availableToAddGroups.length ? (
                              availableToAddGroups.map(group => (
                                <WSPill
                                  onClick={() => {
                                    if (
                                      featureFlags.collaboratorGroupsLimit &&
                                      addedGroups.length >= 1
                                    ) {
                                      openSnackbar({
                                        message: "You can add only one group!",
                                        type: "warning"
                                      });
                                      return;
                                    }

                                    addCollaboratorToGroup({
                                      collaboratorId:
                                        collaboration.collaboratorId,
                                      groupId: group.collaboratorGroupId
                                    });
                                  }}
                                  key={group.collaboratorGroupId}
                                  mr="M"
                                  mb="M"
                                  px="XS"
                                  theme="blue"
                                  icon="plus-circle"
                                  text={group.name}
                                />
                              ))
                            ) : (
                              <WSFlexBox.Center my="XS">
                                <WSText.ParagraphSm color="gray400">
                                  All groups added
                                </WSText.ParagraphSm>
                              </WSFlexBox.Center>
                            )}
                          </WSElement>
                        </>
                      ) : (
                        <WSFlexBox.Center>
                          <WSButton.Link
                            icon="plus-circle"
                            onClick={() => {
                              setIsEdit(true);
                              setIsAdding(true);
                            }}
                          >
                            Add to group
                          </WSButton.Link>
                        </WSFlexBox.Center>
                      )}
                    </>
                  ) : (
                    <WSElement />
                  )}
                </>
              ) : (
                <WSFlexBox.Center>
                  <WSText color="gray500">
                    No groups yet.{" "}
                    <WSPersistentUpgradeButton
                      feature={WSFrontendFeature.InviteAndPayCollaborator}
                      kind="Link"
                      onClick={() => {
                        history.push(
                          "/member/invoices/contacts/collaborators/groups"
                        );
                      }}
                    >
                      Manage groups
                    </WSPersistentUpgradeButton>
                  </WSText>
                </WSFlexBox.Center>
              )}
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
