import {
  WSField,
  WSFlexBox,
  WSForm,
  WSInputMask,
  WSInputText,
  WSList,
  WSSelect,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import { COUNTRY_OPTIONS, US_STATES_OPTIONS } from "../../../../constants/user";
import { SELECT_OPTIONS_CANADIAN_PROVINCES } from "../../../../shared/constants/user";

export type FormDataAddress = {
  country: string | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string | null;
  postalCode: string;
};

type Props = {
  name: string;
  label: string;
};

export const AddressFields: React.FC<Props> = ({ label, name }) => {
  const isMobile = useIsMobile();
  return (
    <WSField as="div" label={label} required>
      <WSList gap="M">
        <WSForm.Field
          name={name + ".country"}
          component={WSSelect}
          componentProps={{
            placeholder: "Country",
            internalSearch: true,
            options: COUNTRY_OPTIONS
          }}
        />
        <WSForm.Field
          name={name + ".addressLine1"}
          component={WSInputText}
          componentProps={{
            placeholder: "Street address",
            fsExclude: true
          }}
        />
        <WSForm.Field
          name={name + ".addressLine2"}
          component={WSInputText}
          componentProps={{
            placeholder: "Street address line 2",
            fsExclude: true
          }}
        />

        <WSForm.Value name={name + ".country"}>
          {country =>
            country === "US" ? (
              <WSFlexBox
                wrap="nowrap"
                gap="M"
                key={country + "-city-state-postal"}
                direction={isMobile ? "column" : "row"}
              >
                <WSForm.Field
                  name={name + ".city"}
                  component={WSInputText}
                  componentProps={{ placeholder: "City" }}
                />

                <WSForm.Field
                  name={name + ".state"}
                  component={WSSelect}
                  componentProps={{
                    placeholder: "State",
                    internalSearch: true,
                    options: US_STATES_OPTIONS
                  }}
                />

                <WSForm.Field
                  name={name + ".postalCode"}
                  component={WSInputMask}
                  componentProps={{ placeholder: "Zip code", mask: "99999" }}
                />
              </WSFlexBox>
            ) : country === "CA" ? (
              <WSFlexBox
                wrap="nowrap"
                gap="M"
                key={country + "-city-state-postal"}
                direction={isMobile ? "column" : "row"}
              >
                <WSForm.Field
                  name={name + ".city"}
                  component={WSInputText}
                  componentProps={{ placeholder: "City" }}
                />

                <WSForm.Field
                  name={name + ".state"}
                  component={WSSelect}
                  componentProps={{
                    placeholder: "Province",
                    internalSearch: true,
                    options: SELECT_OPTIONS_CANADIAN_PROVINCES
                  }}
                />

                <WSForm.Field
                  name={name + ".postalCode"}
                  component={WSInputText}
                  componentProps={{ placeholder: "Postal code" }}
                />
              </WSFlexBox>
            ) : (
              <WSFlexBox
                wrap="nowrap"
                gap="M"
                key={country + "-city-state-postal"}
                direction={isMobile ? "column" : "row"}
              >
                <WSForm.Field
                  name={name + ".city"}
                  component={WSInputText}
                  componentProps={{ placeholder: "City / Town" }}
                />

                <WSForm.Field
                  name={name + ".state"}
                  component={WSInputText}
                  componentProps={{
                    placeholder: "Region/state (optional)"
                  }}
                />

                <WSForm.Field
                  name={name + ".postalCode"}
                  component={WSInputText}
                  componentProps={{ placeholder: "Postal code" }}
                />
              </WSFlexBox>
            )
          }
        </WSForm.Value>
      </WSList>
    </WSField>
  );
};
