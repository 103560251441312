import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { FormPersonalInformation } from "../../components/FormPersonalInformation";
import { useOnboardingContext } from "../../Context";
import { OnboardingStep } from "../../types";
import {
  getAddressData,
  getPersonalData
} from "../../utils/getDataFromProfile";
import { CreateAccountContext } from "./types";

export const PersonalInformation: React.FC<OnboardingStep> = ({
  onNext,
  onBack
}) => {
  const [context, setContext] = useOnboardingContext<CreateAccountContext>();

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const profileData = useMemo(
    () => ({
      person: {
        ...getPersonalData(queryUser.data?.profile),
        country:
          queryMember.data?.profile.address?.country ||
          context.accountType?.country,
        address: getAddressData(queryMember.data?.profile)
      }
    }),
    [
      context.accountType?.country,
      queryMember.data?.profile,
      queryUser.data?.profile
    ]
  );

  return (
    <WSList gap="2XL">
      <FormPersonalInformation
        country={context.accountType?.country || "US"}
        defaultValues={context.personalInformation || profileData}
        onBack={onBack}
        onSubmit={data => {
          setContext(prev => ({ ...prev, personalInformation: data }));
          onNext?.();
        }}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
