import {
  useIsDesktop,
  WSButton,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdatePayrollSettings } from "../../query/payments/mutations";
import {
  useCollaboratorsQuery,
  usePayrollSettings
} from "../../query/payments/queries";
import {
  getEligibleCollaboratorsFor1099Filing,
  getMissingInformationCollaborators,
  getVisibleCollaborators
} from "../../query/payments/selectors";
import { WSQueries } from "../../query/WSQuery";
import { openInNewTab } from "../../utils/openInNewTab";
import { openIntercom } from "../../shared/utils/intercom";

export interface Collaborators1099NECReviewProps {}

interface Enabled1099FilingReviewPanelProps {
  noOfEligibleCollaboratorsForFiling: number;
  noOfMissingInformationCollaborators: number;
}

const Enabled1099FilingReviewPanel: React.FC<Enabled1099FilingReviewPanelProps> = ({
  noOfEligibleCollaboratorsForFiling,
  noOfMissingInformationCollaborators
}) => {
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const currentYear = new Date().getFullYear();

  const userId = useUserId();
  const [
    updatePayrollSettings,
    updatePayrollSettingsMeta
  ] = useUpdatePayrollSettings(userId);

  const disable1099Filing = async () => {
    await updatePayrollSettings({
      issue1099s: false
    });
  };

  return (
    <WSPanel>
      <WSFlexBox.CenterY
        direction="column"
        justify="flex-start"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <WSText.Heading5 mb="2XL">Your filling summary</WSText.Heading5>

        <WSText>
          1099 filling is enable. 1099 forms will be submitted in{" "}
          {differenceInBusinessDays(new Date(currentYear, 0, 26), new Date())}{" "}
          days on{" "}
          <WSText inline formatDate="monthDayYear">
            {new Date(currentYear, 0, 26)}
          </WSText>
          .
        </WSText>

        <WSText mt="XL" mb="XS">
          {noOfEligibleCollaboratorsForFiling} contractors are eligible for
          filing.
        </WSText>
        <WSButton.Link
          rightIcon="arrow-right"
          onClick={() =>
            history.push(
              "/member/invoices/contacts/collaborators/1099/adjust-compensation/download"
            )
          }
        >
          Bulk review & adjust compensation amounts
        </WSButton.Link>

        <WSText mt="XL" mb="XS">
          {noOfMissingInformationCollaborators} contractors are missing
          information required to file.
        </WSText>
        <WSButton.Link
          rightIcon="arrow-right"
          onClick={() =>
            history.push(
              "/member/invoices/contacts/collaborators/1099/missing-information/download"
            )
          }
        >
          Bulk update missing information
        </WSButton.Link>

        <WSText mt="XL" inline>
          Filing outside of Wingspan?
          <WSButton.Link
            ml={isDesktop ? "XS" : "NONE"}
            onClick={disable1099Filing}
            loading={updatePayrollSettingsMeta.isLoading}
          >
            Disable 1099 filling
          </WSButton.Link>
        </WSText>
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};

interface Disabled1099FilingReviewPanelProps {
  noOfEligibleCollaboratorsForFiling: number;
}

const Disabled1099FilingReviewPanel: React.FC<Disabled1099FilingReviewPanelProps> = ({
  noOfEligibleCollaboratorsForFiling
}) => {
  const isDesktop = useIsDesktop();
  const currentYear = new Date().getFullYear();
  const userId = useUserId();
  const [
    updatePayrollSettings,
    updatePayrollSettingsMeta
  ] = useUpdatePayrollSettings(userId);

  const enable1099Filing = async () => {
    await updatePayrollSettings({
      issue1099s: true
    });
  };

  return (
    <WSPanel>
      <WSFlexBox.CenterY
        direction="column"
        justify="flex-start"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <WSText.Heading5 mb="2XL">Your filling summary</WSText.Heading5>

        <WSText>
          1099 filling is disabled. Enable filing by{" "}
          <WSText inline formatDate="monthDayYear">
            {new Date(currentYear, 0, 26)}
          </WSText>{" "}
          in{" "}
          {differenceInBusinessDays(new Date(currentYear, 0, 26), new Date())}{" "}
          days to automatically file 1099 forms.{" "}
        </WSText>

        <WSText mt="XL" mb="XS">
          {noOfEligibleCollaboratorsForFiling} contractors are eligible for
          filing.
        </WSText>
        <WSButton.Link
          rightIcon="arrow-right"
          onClick={enable1099Filing}
          loading={updatePayrollSettingsMeta.isLoading}
        >
          Enable 1099 filling
        </WSButton.Link>
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};

export const Collaborators1099NECReview: React.FC<Collaborators1099NECReviewProps> = () => {
  useBrowserPageTitle("Wingspan - 1099 Review");
  const userId = useUserId();
  const collaboratorQuery = useCollaboratorsQuery();
  const payrollSettingsQuery = usePayrollSettings(userId);
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;

  return (
    <ProjectOnboardingLayout progress={(100 / 3) * 1}>
      <WSQueries queries={{ collaboratorQuery, payrollSettingsQuery }}>
        {({
          collaboratorQuery: { data },
          payrollSettingsQuery: { data: payrollSettings }
        }) => {
          const collaborators = getVisibleCollaborators(data);
          const eligibleCollaboratorsForFiling = getEligibleCollaboratorsFor1099Filing(
            collaborators
          );
          const missingInformationCollaborators = getMissingInformationCollaborators(
            collaborators
          );
          return (
            <WSContainer verticalPadding>
              <WSCentered span={{ s: "6", m: "8" }}>
                <WSText my="XL">
                  Wingspan will automatically file 1099 forms for payments made
                  to your contractors on Wingspan in {prevYear}.
                </WSText>
                {payrollSettings.issue1099s ? (
                  <Enabled1099FilingReviewPanel
                    noOfEligibleCollaboratorsForFiling={
                      eligibleCollaboratorsForFiling.length
                    }
                    noOfMissingInformationCollaborators={
                      missingInformationCollaborators.length
                    }
                  />
                ) : (
                  <Disabled1099FilingReviewPanel
                    noOfEligibleCollaboratorsForFiling={
                      eligibleCollaboratorsForFiling.length
                    }
                  />
                )}
                {/* FAQs */}

                <WSText.ParagraphSm weight="medium" mt="2XL">
                  What is a 1099?
                </WSText.ParagraphSm>
                <WSText.ParagraphSm>
                  Non-employee compensation-money paid to freelancers,
                  independent contractors, and gig workers must be reported to
                  the IRS on Form 1099.
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" mt="XL">
                  Who should receive a 1099?
                </WSText.ParagraphSm>
                <WSText.ParagraphSm>
                  If you paid any part-time workers or freelancers more than{" "}
                  <WSText.ParagraphSm inline formatMoney>
                    600
                  </WSText.ParagraphSm>{" "}
                  during {prevYear}, you'll need to send them a 1099. The form
                  allows you to report wage information to the IRS and enables
                  your contractor to do their taxes.
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" mt="XL">
                  How can Wingspan help me file?
                </WSText.ParagraphSm>
                <WSText.ParagraphSm>
                  When you pay a contractor with Wingspan, we'll automatically
                  file a 1099 on your behalf. If you've paid a contractor
                  outside of Wingspan, or would like to make any adjustments to
                  the reported compensation amount, you can bulk update the
                  compensation amounts via a Spreadsheet above or update a
                  contractor individually.
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" mt="XL">
                  Need more help?
                </WSText.ParagraphSm>
                <WSText.ParagraphSm>
                  Review the 1099{" "}
                  <WSButton.Link
                    onClick={() =>
                      openInNewTab("https://help.wingspan.app/en/")
                    }
                    mx="XS"
                  >
                    filling help docs
                  </WSButton.Link>{" "}
                  or{" "}
                  <WSButton.Link mx="XS" onClick={openIntercom}>
                    contact support
                  </WSButton.Link>
                </WSText.ParagraphSm>
              </WSCentered>
            </WSContainer>
          );
        }}
      </WSQueries>
    </ProjectOnboardingLayout>
  );
};
