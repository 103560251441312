import {
  WSFlexBox,
  WSFlexBoxProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export type FieldViewProps = WSFlexBoxProps & {
  label: string;
  value?: React.ReactNode;
};

export const FieldView: React.FC<FieldViewProps> = ({
  label,
  value = "-",
  ...otherProps
}) => {
  return (
    <WSFlexBox
      direction="column"
      gap="S"
      alignItems="stretch"
      style={{ minWidth: 0, maxWidth: "100%", flex: 1 }}
      {...otherProps}
    >
      <WSText.ParagraphSm color="gray500" singleLine>
        {label}
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        as={React.isValidElement(value) ? "div" : undefined}
        color="gray700"
        singleLine
      >
        {value}
      </WSText.ParagraphSm>
    </WSFlexBox>
  );
};
