import { useUserProfileData } from "./utils/useUserProfileData";
import { ORGANIZATION_NAMES } from "./helpers";

export const useUserOrganizationName = () => {
  const userData = useUserProfileData();
  const ownerIds = userData?.userRoles?.ownerIds || [];

  const organization = ORGANIZATION_NAMES.find(({ ids }) => {
    return ownerIds.length
      ? ownerIds.some(ownerId => ids.some(id => ownerId.includes(id)))
      : false;
  });

  return organization?.name;
};
