import {
  IPendingTransactionResponse,
  PendingTransactionSubType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import {
  toWSDateString,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import sortBy from "lodash/sortBy";
import React from "react";
import { useUrlQueryFilters } from "../../../utils/router";
import { defaultWalletFilters } from "../routes/RouteTransactions";
import { AmountColumn } from "./AmountColumn";

// export const columns: Array<WSTableColumn> = [
//   {
//     config: {
//       header: {
//         text: "Description"
//       },
//       gridTemplateSizeMax: "1fr"
//     },
//     renderFunction: row => (
//       <WSText.ParagraphSm singleLine color="gray600">
//         {row.data.title}
//       </WSText.ParagraphSm>
//     )
//   },
//   {
//     config: {
//       header: {
//         text: "Status"
//       },
//       justify: "end"
//     },
//     renderFunction: row => (
//       <WSText.ParagraphSm color="gray600">{row.data.status}</WSText.ParagraphSm>
//     )
//   },
//   {
//     config: {
//       header: {
//         text: "Date"
//       },
//       justify: "end"
//     },
//     renderFunction: row => (
//       <WSText.ParagraphSm color="gray600" formatDate="monthDayYear">
//         {row.data.date}
//       </WSText.ParagraphSm>
//     )
//   },
//   {
//     config: {
//       header: {
//         text: "Amount"
//       },
//       justify: "end"
//     },
//     renderFunction: row => (
//       <WSText.ParagraphSm
//         color="gray600"
//         formatMoney="taxes"
//         data-testid="transactionAmount"
//         data-testamount={Math.abs(row.data.amount)}
//       >
//         {row.data.amount}
//       </WSText.ParagraphSm>
//     )
//   }
// ];

const columns: Array<WSTableColumn> = [
  {
    config: {
      header: { text: "Merchant" },
      gridTemplateSizeMax: "0.5fr"
    },
    renderFunction: rowData => (
      <WSTableCell
        avatar={{
          type: "icon",
          icon: "transfer",
          color: "gray500"
        }}
        text="Pending transaction"
        // pill={rowData.data.business ? undefined : { text: "Ignored" }}
        // secondaryTextColor={
        //   rowData.data.business &&
        //   rowData.data.type !== TransactionType.Transfer
        //     ? "gray600"
        //     : "gray400"
        // }
        secondaryText={toWSDateString(rowData.data.date, "monthDayYear")}
      />
    )
  },
  {
    config: {
      hideOnScreens: ["XS", "S", "M"],
      header: { text: "Description" },
      gridTemplateSizeMax: "0.5fr"
      // justify: "end"
    },
    renderFunction: rowData => <WSTableCell text="Transfer" />
  },
  {
    config: {
      hideOnScreens: ["XS"],
      header: { text: "Account" },
      gridTemplateSizeMax: "0.2fr"
      // justify: "end"
    },
    renderFunction: rowData => (
      <WSTableCell
        text={rowData.data.account ? `•••${rowData.data.account}` : "--"}
      />
    )
  },
  {
    config: {
      // justify: "end",
      header: { text: "Amount" },
      gridTemplateSizeMax: "0.1fr"
    },
    renderFunction: rowData => (
      <AmountColumn transaction={rowData.data.source} />
    )
  }
];

export const TablePendingTransactions: React.FC<{
  pendingTransactions: IPendingTransactionResponse[];
}> = ({ pendingTransactions }) => {
  const { redirectWithFilters } = useUrlQueryFilters(defaultWalletFilters);

  const tableData = sortBy(
    pendingTransactions.map(transaction => {
      return {
        data: {
          source: transaction,
          amount:
            Math.abs(transaction.amount) *
            (transaction.subType === PendingTransactionSubType.Deposit
              ? 1
              : -1),
          status: transaction.status,
          title: transaction.subType,
          date: transaction.createdAt
        },
        id: transaction.pendingTransactionId
      };
    }),
    "createdAt"
  ).reverse();

  if (!tableData.length) {
    return null;
  }

  return (
    <WSTable
      mt="XL"
      tableData={tableData}
      columns={columns}
      forceShowGroups
      showHeader
      getGroupName={item => "Pending"}
      onRowClick={item => {
        redirectWithFilters("/member/wallet/transactions/pending/" + item.id);
      }}
      // rowActions={item => {
      //   const {
      //     id,
      //     data: { source }
      //   } = item;
      //
      //   const actions: WSTableAction[] = [];
      //
      //   if (source.status === PendingTransactionStatus.Pending) {
      //     actions.push({
      //       text: "Cancel",
      //       onAsyncClick: async () => {
      //         await updateTransaction({
      //           id: source.pendingTransactionId,
      //           status: PendingTransactionStatus.Cancelled
      //         });
      //       }
      //     });
      //   }
      //
      //   return actions;
      // }}
    />
  );
};
