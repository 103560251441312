import {
  WSButton,
  WSControlGroup,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { MIGRATION_BASE_PATH } from "..";
import { openIntercom } from "../../../shared/utils/intercom";
import { Layout } from "../../Onboarding/components/Layout";

export const RouteIndex = () => {
  const history = useHistory();
  const [value, setValue] = useState("migrate");

  const handleSumbit = () => {
    if (value === "migrate") {
      history.push(MIGRATION_BASE_PATH + "/verify");
    } else {
      history.push(MIGRATION_BASE_PATH + "/withdraw");
    }
  };

  return (
    <Layout
      title="Banking service updates"
      onClose={() => {
        history.push("/member");
      }}
    >
      <WSList gap="2XL">
        <WSText.Paragraph weight="medium">
          Action required: changes to your Wingspan banking services
        </WSText.Paragraph>

        <WSPanel>
          <WSList gap="L">
            <WSInfoBox title="">
              We’re transitioning our banking services from Blue Ridge Bank,
              N.A. to Lead Bank This change affects your Wingspan Wallet and Tax
              Withholding accounts.{" "}
              <a
                href="https://help.wingspan.app/en/articles/9534667-banking-transition-faq#h_6f661669bb"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </WSInfoBox>

            <WSControlGroup
              name="migration"
              type="radio"
              label="You have two (2) options:"
              required
              value={value}
              onChange={setValue}
              options={[
                {
                  label: "Option 1: Migrate your account to Lead Bank",
                  value: "migrate",
                  description: (
                    <ul>
                      <li>
                        Your Wingspan Wallet and Tax Withholding will continue
                        to work
                      </li>
                      <li>
                        Your funds remain FDIC insured throughout the transition
                      </li>
                      <li>No fees for this migration</li>
                    </ul>
                  )
                },
                {
                  label: "Option 2: Withdraw funds and close accounts",
                  value: "withdraw",
                  description: (
                    <ul>
                      <li>
                        Withdraw your balance by July 29th, 2024 at 12:00am EST
                      </li>
                      <li>
                        Funds will be sent to your destination of choosing
                      </li>
                      <li>
                        Your Wingspan Wallet and Tax Withholding accounts will
                        be closed
                      </li>
                    </ul>
                  )
                }
              ]}
              chip
            />

            <WSInfoBox title="Important dates:">
              <ul>
                <li>
                  July 25th - July 30th, 2024: Daily migrations after 2pm ET (if
                  you complete before 1pm ET, you'll be included that day;
                  otherwise, the next day)
                </li>
                <li>
                  July 29th, 2024 at 12:00am EST: Deadline to choose Option 1 or
                  2
                </li>
                <li>
                  July 30th, 2024 at 5:30pm EST: Final migration for those who
                  chose Option 1
                </li>
              </ul>
            </WSInfoBox>
          </WSList>
        </WSPanel>

        <WSText.ParagraphSm color="gray600">
          If no action is taken by the July 29th, 2024 deadline. Any remaining
          balance will be mailed as a check to your address on file and may take
          6-12 weeks.{" "}
          <a
            href="https://help.wingspan.app/en/articles/9534667-banking-transition-faq#h_6f661669bb"
            target="_blank"
            rel="noreferrer"
          >
            Learn more here
          </a>
        </WSText.ParagraphSm>

        <WSButton fullWidth onClick={handleSumbit}>
          Continue
        </WSButton>

        <WSText.ParagraphSm color="gray600">
          If you have any questions or concerns, you can find more information
          and connect with our <a onClick={openIntercom}>support team here</a>.
        </WSText.ParagraphSm>
      </WSList>
    </Layout>
  );
};
