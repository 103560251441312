import { PayeeTaxDocumentSharePermission } from "@wingspanhq/payments/dist/interfaces/payerPayee";
import {
  PayeeRowsQueryFilter,
  PayeeRowsQuerySort
} from "../../../../services/search";
import { ListRequestQuery } from "../../../../utils/serviceHelper";

export enum PayeeTaxInfoPermission {
  Shared = "Shared",
  NotShared = "NotShared"
}

const mappingSchema: Record<
  keyof ExtensionPayeeRowsQueryFilter,
  Record<string, Record<string, any>>
> = {
  "payeeOwnedData.shareTaxDocument": {
    [PayeeTaxInfoPermission.Shared]: [PayeeTaxDocumentSharePermission.Allow],
    [PayeeTaxInfoPermission.NotShared]: [
      PayeeTaxDocumentSharePermission.Unspecified,
      PayeeTaxDocumentSharePermission.Decline
    ]
  }
};

type ExtensionPayeeRowsQueryFilter = {
  "payeeOwnedData.shareTaxDocument"?: PayeeTaxInfoPermission;
};

export type RedactedPayeeRowsQueryFilter = PayeeRowsQueryFilter &
  ExtensionPayeeRowsQueryFilter;

export type RedactedPayeeRowsQuery = ListRequestQuery<
  RedactedPayeeRowsQueryFilter,
  PayeeRowsQuerySort
>;

export const mapPayeeRowsQueryFilter: (
  filter: RedactedPayeeRowsQueryFilter
) => PayeeRowsQueryFilter = filter => {
  let result: PayeeRowsQueryFilter = { ...filter };

  Object.keys(mappingSchema).forEach(key => {
    const name = key as keyof RedactedPayeeRowsQueryFilter;
    const value = filter[name];

    if (value && typeof value === "object") {
      const mapping = (mappingSchema as any)[name];
      const mappedValue = mapping ? value.in?.map(v => mapping[v]).flat() : [];

      if (mappedValue?.length) {
        (result as any)[name] = { in: mappedValue };
      }
    }
  });

  return result as PayeeRowsQueryFilter;
};
