import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { WSQueries } from "../../../query/WSQuery";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { PayableForm } from "../../components/PayableForm/PayableForm";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";

export const CreatePayable: React.FC<RouteComponentProps<
  any,
  any,
  { collaboratorId?: string; email?: string; backPath?: string }
>> = ({ location }) => {
  const collaboratorId = location.state?.collaboratorId;
  const email = location.state?.email;
  const queryFeatureFlags = useFeatureFlags();
  const collaboratorsQuery = useCollaboratorsQuery(
    {},
    queryFeatureFlags.data?.engagements
      ? {
          enabled: false,
          retry: false,
          initialData: [],
          refetchOnMount: false
        }
      : {}
  );
  const queryCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const backPath = location.state?.backPath;

  return (
    <WSQueries queries={{ collaboratorsQuery, queryCustomFields }}>
      {({
        collaboratorsQuery: { data: collaborators },
        queryCustomFields: { data: customFields }
      }) => {
        let collaborator;
        if (collaboratorId) {
          collaborator = collaborators.find(
            c => c.collaboratorId === collaboratorId
          );
        }

        return (
          <PayableForm
            backPath={backPath}
            collaborator={collaborator}
            customFields={customFields}
            email={email}
          />
        );
      }}
    </WSQueries>
  );
};
