import {
  detachLayoutProps,
  useIsMobile,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { WSVirtualAssistant } from "../WSVirtualAssistant/WSVirtualAssistant";

import styles from "./FeatureInfo.module.scss";

export interface FeatureInfoProps extends WSElementProps {
  title: string;
  description?: string;
  featureItems: Array<string>;
  virtualAssistantMessage?: string;
}

export const FeatureInfo: React.FC<FeatureInfoProps> = ({
  title,
  description,
  featureItems,
  virtualAssistantMessage,
  ...otherProps
}) => {
  const isMobile = useIsMobile();
  const { layoutProps } = detachLayoutProps(otherProps);
  return (
    <WSFlexBox direction="column" {...layoutProps}>
      <WSText.Heading1 mb={isMobile ? "XL" : "2XL"}>{title}</WSText.Heading1>
      {description && (
        <WSText
          color="gray600"
          mb={isMobile ? "XL" : "2XL"}
          className={styles.description}
        >
          {description}
        </WSText>
      )}
      {featureItems.map((feature: string) => (
        <WSFlexBox.CenterY key={feature} direction="row" mb="M" wrap="nowrap">
          <WSIcon block name="check" mr="M" color="green500" />
          <WSFlexBox wrap="wrap">
            <WSText>{feature}</WSText>
          </WSFlexBox>
        </WSFlexBox.CenterY>
      ))}
      {virtualAssistantMessage && (
        <WSVirtualAssistant message={virtualAssistantMessage} mt="2XL" />
      )}
    </WSFlexBox>
  );
};
