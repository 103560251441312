import { WSElement, WSIcon } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import styles from "./StepPoint.module.scss";

type Props = {
  isActive?: boolean;
  isComplete?: boolean;
};

export const StepPoint: React.FC<Props> = ({ isActive, isComplete }) => (
  <WSElement
    className={classNames(styles.point, {
      [styles.active]: isActive,
      [styles.complete]: isComplete && !isActive
    })}
  >
    {isComplete && !isActive ? <WSIcon name="check" size="S" /> : null}
  </WSElement>
);
