export const PAYEES_ROOT_PATH = "/member/payees";

export const PAYEES_LIST_PATH = PAYEES_ROOT_PATH + "";
export const PAYEES_SEARCH_PATH = PAYEES_ROOT_PATH + "/search";
export const PAYEES_ARCHIVED_PATH = PAYEES_ROOT_PATH + "/archived";
export const PAYEES_DETAILS_ROUTE = PAYEES_ROOT_PATH + "/:payeeId";

export const PAYEES_DETAILS_ENGAGEMENT_DETAILS_ROUTE =
  PAYEES_DETAILS_ROUTE + "/engagement/:engagementId";

export const PAYEES_ENGAGEMENTS_LIST_PATH = PAYEES_ROOT_PATH + "/engagements";
export const PAYEES_ENGAGEMENTS_DETAILS_PATH =
  PAYEES_ENGAGEMENTS_LIST_PATH + "/:engagementId";
export const PAYEES_GROUPS_LIST_PATH = PAYEES_ROOT_PATH + "/groups";
export const PAYEES_ENGAGEMENTS_DETAILS_ROUTE =
  PAYEES_ROOT_PATH + "/groups/:collaboratorGroupId";
export const PAYEES_ENGAGEMENTS_ADD_REQUIREMENT_ROUTE =
  PAYEES_ENGAGEMENTS_DETAILS_ROUTE + "/add-requirement";
export const PAYEES_ENGAGEMENTS_REQUIREMENTS_DETAILS_ROUTE =
  PAYEES_ENGAGEMENTS_DETAILS_ROUTE + "/requirements/:eligibilityRequirementId";

export const buildPath = (path: string, value: string, param: string) => {
  return path.replace(`:${param}`, value);
};

export const buildPayeeDetailsPath = (payeeId: string) => {
  return buildPath(PAYEES_DETAILS_ROUTE, payeeId, "payeeId");
};

export const buildEngagementDetailsPath = (engagementId: string) => {
  return buildPath(
    PAYEES_ENGAGEMENTS_DETAILS_PATH,
    engagementId,
    "engagementId"
  );
};

export const buildGroupDetailsPath = (groupId: string) => {
  return buildPath(
    PAYEES_ENGAGEMENTS_DETAILS_ROUTE,
    groupId,
    "collaboratorGroupId"
  );
};

export const buildGroupAddRequirementPath = (groupId: string) => {
  return buildPath(
    PAYEES_ENGAGEMENTS_ADD_REQUIREMENT_ROUTE,
    groupId,
    "collaboratorGroupId"
  );
};

export const buildGroupRequirementDetailsPath = (
  groupId: string,
  requirementId: string
) => {
  return PAYEES_ENGAGEMENTS_REQUIREMENTS_DETAILS_ROUTE.replace(
    `:collaboratorGroupId`,
    groupId
  ).replace(`:eligibilityRequirementId`, requirementId);
};

export const buildPayeeDetailsEngagementDetailsPath = (
  payeeId: string,
  engagementId: string
) => {
  return PAYEES_DETAILS_ENGAGEMENT_DETAILS_ROUTE.replace(
    ":payeeId",
    payeeId
  ).replace(`:engagementId`, engagementId);
};
