import { useState } from "react";
import { Layout } from "../../Onboarding/components/Layout";
import { Confirmation } from "../components/Confirmation";
import { Form } from "../components/Form";
import { FormData } from "../types";
import { useQueryCustomerEntity } from "../../../query/onboarding/queries/useQueryCustomerEntity";

export const RouteIndex = () => {
  const [preview, setPreview] = useState(true);
  const [submittedValues, setSubmittedValues] = useState<
    FormData | undefined
  >();

  const queryCustomEntity = useQueryCustomerEntity();

  // TODO: for Artem(we should add prefill in general for this form I think)
  if (!queryCustomEntity.isFetched) {
    return <Layout title="Create account" />;
  }

  return (
    <Layout title="Create account">
      {preview && submittedValues ? (
        <Confirmation
          data={submittedValues}
          onEdit={() => {
            setPreview(false);
          }}
        />
      ) : (
        <Form
          disabledEditAccountType={!!queryCustomEntity.data?.type}
          defaultValues={
            queryCustomEntity.data?.type
              ? {
                  ...submittedValues,
                  type: queryCustomEntity.data.type,
                  country: queryCustomEntity.data.country
                }
              : submittedValues
          }
          onSubmit={newValues => {
            setSubmittedValues(newValues);
            setPreview(true);
          }}
        />
      )}
    </Layout>
  );
};
