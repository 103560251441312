import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSQuery } from "../../../helpers";
import {
  getPayeeRowsSummary,
  IPayeeSearchRowsSummary
} from "../../../../services/search";
import { mapPayeeRowsQueryFilter, RedactedPayeeRowsQuery } from "./utils";

export const QUERY_PAYEE_ROWS_SUMMARY = "QUERY_PAYEE_ROWS_SUMMARY";

export const usePayeeRowsSummaryQuery = (
  params?: RedactedPayeeRowsQuery,
  config?: QueryConfig<IPayeeSearchRowsSummary, WSServiceError>
) => {
  return useWSQuery<IPayeeSearchRowsSummary, WSServiceError>(
    [QUERY_PAYEE_ROWS_SUMMARY, params],
    () =>
      getPayeeRowsSummary({
        ...params,
        filter: mapPayeeRowsQueryFilter(params?.filter || {}),
        page: { size: 1, number: 1 }
      }),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
